import React, { useEffect, useRef, useState } from "react";
import "./CarHome.css";
// import iMetanic from '../../assets/Imetaniclogo.png'
import iMetanic from "../../../assets/Images/imetanic-nameing-logo.png";
import Logo from "../../common-component/Logo";
import { IconButton } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import api from "../../axiosConfig";

interface Props {
  Carheader: string;
}

const CarHeaderBox: React.FC<Props> = ({ Carheader }) => {
  const [data, setData] = useState<string | null>("");
  const [background, setBackground] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [fontcolor, setFontColor] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);

  useEffect(() => {
    const business = sessionStorage.getItem("organization_name");
    setBusinessName(String(business));

    const profileName = sessionStorage.getItem("Profilename");
    setData(profileName);

    const storedColor = sessionStorage.getItem(`background_color${business}`);
    if (storedColor) {
      setBackground(storedColor);
      document.documentElement.style.setProperty(
        "--background-color",
        storedColor
      );
    }
    const fontcolor = sessionStorage.getItem(`font_color${business}`);
    if (fontcolor) {
      setFontColor(fontcolor);
      document.documentElement.style.setProperty("--font-color", fontcolor);
    }
    const fontfamily = sessionStorage.getItem(`font_family${business}`);
    if (fontfamily) {
      document.documentElement.style.setProperty("--fontfamily", fontfamily);
    }
  }, []);

    const Logout = () => {
      api.get(`/pos/logout`)
        .then((res) => {
          console.log(res.data);
          sessionStorage.removeItem(`Logo${businessName}`);
          sessionStorage.removeItem("jsonwebtoken");
          navigate('/');
        });
    };


  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!profileDropdownVisible);
  };

  const openEmailCompose = () => {
    const name = data;
    const email = 'metanic1234@gmail.com';
    const subject = 'Customer Feedback';
    const body = `Business Name: ${name}\nMy Feedback: `;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const newTab = window.open(mailtoLink, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      window.location.href = mailtoLink;
    }
  };

  return (
    <header className="car-header">
      <div className="left-section">
        <div className="business-logo">
          <Logo />
          <div className="organiztion-name">{businessName}
          </div>
        </div>
        <span className="business-name">{Carheader}</span>
      </div>
      <div className="right-section">
        <div>
          <img src={iMetanic} alt="iMetanic logo" />
        </div>
      </div>
      <div className="heder_profiles">
        <div className="heder-profile-laundry" onClick={toggleProfileDropdown}>
          <IconButton>
            <AccountCircleIcon
              style={{ fontSize: "30px", color: background }}
            />
            <h4 style={{ width: "170px", color: "black", display: "flex" }}>
              {data}
            </h4>
            {profileDropdownVisible ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </IconButton>
          {profileDropdownVisible && (
            <div className="dropdown-content" ref={dropdownRef}>
              <Link to={"/v1/profile"} id="Profile">
                Profile{" "}
              </Link>
              <Link to="" onClick={openEmailCompose} id="feedback">
                Feedback
              </Link>
              <Link
                onClick={Logout}
                to={"/"}
                style={{ color: "#F14545" }}
                id="logout"
              >
                Logout
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default CarHeaderBox;
