import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Reshome.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { InputBase, styled } from "@mui/material";
import api from "../../axiosConfig";

interface Props {
  Resheader: string;
  OldOrders:(orders:any)=>void
}

interface Order {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  Customerid: string;
  GrandTotal: string;
  OrderStatus: string;
  Orderdate: string;
  Orderid: string;
  Ordertime: string;
  Paymentmode: string;
  Products: Array<{
      Amount: string;
      ArabicName: string;
      ProductName: string;
      category_id: string;
      createdBy: string;
      Type:string;
      createdOn: {
          $date: string;
      };
      image_url: string;
      quantity: number;
      _id: {
          $oid: string;
      };
  }>;
  ServiceCharge: number;
  Subtotal: string;
  table_number: string;
  totalAmount: number;
  totalQuantity: number;
  _id: {
      $oid: string;
  };
  total_orders_count: number;
}
interface configurationtable {
  _id: {
    $oid: string;
  };
  seats: number;
  tablename: string;
}

const Resheader: React.FC<Props> = ({ Resheader,OldOrders }) => {
  const [data, setData] = useState<string | null>("");
  const [configure, setConfigure] = useState<configurationtable[]>([]);
  // const [businessType, setBusinessType] = useState<string>("");
  const [background, setBackground] = useState<string>("");
  const [fontcolor, setFontColor] = useState<string>("");
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const profileDropdownRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<string>("");
  const [orders, setOrders] = useState<Order[]>([]);
  const navigate = useNavigate();

  const openEmailCompose = () => {
    const name = data;
    const email = "metanic1234@gmail.com";
    const subject = "Customer Feedback";
    const body = `Business Name: ${name}\nMy Feedback: `;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    const newTab = window.open(mailtoLink, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      window.location.href = mailtoLink;
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await api.get("/app/v1/pos/order");
      const pendingOrders = response.data.result.filter((order: Order) => order.OrderStatus === "pending");
      setOrders(pendingOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);
  
  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) suffix = "st";
    else if (day === 2 || day === 22) suffix = "nd";
    else if (day === 3 || day === 23) suffix = "rd";

    const formattedDate = `${new Intl.DateTimeFormat("en-US", options).format(
      date
    )}${suffix}`;
    return formattedDate;
  };

  const formatTime = (date: Date): string => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  };

  useEffect(() => {
    const profileName = sessionStorage.getItem("ResProfilename");
    setData(profileName);

    const storedColor = sessionStorage.getItem("restaurant_background_color");
    if (storedColor) {
      setBackground(storedColor);
      document.documentElement.style.setProperty(
        "--background-color",
        storedColor
      );
    }

    const fontcolor = sessionStorage.getItem("restaurant_font_color");
    if (fontcolor) {
      setFontColor(fontcolor);
      document.documentElement.style.setProperty("--font-color", fontcolor);
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target as Node)
      ) {
        setProfileDropdownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Logout = () => {
    api.get(`/pos/logout`).then((res) => {
      sessionStorage.removeItem("restaurant_name");
      sessionStorage.removeItem("jsonwebtoken");
      navigate("/");
    });
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setCurrentTime(formatTime(now));
      setCurrentDate(formatDate(now));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);


  // const handleAddBusinessClick = () => {
  //   setAddBusinessDialogOpen(true);
  // };

  // const handleDialogClose = () => {
  //   setAddBusinessDialogOpen(false);
  //   setBusinessType("");
  // };

  // const handleAddBusinessSubmit = () => {
  //   if (businessType) {
  //     toast.success("Business added successfully!", {
  //       position: "top-center",
  //       autoClose: 2000,
  //       theme: "colored",
  //       transition: Flip,
  //     });
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);
  //     handleDialogClose();
  //   } else {
  //     toast.error("Please select a business type.", {
  //       position: "top-center",
  //       autoClose: 2000,
  //     });
  //   }
  // };

  // const handleDropdownSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setBusinessType(event.target.value);
  // };

  const toggleProfileDropdown = () => {
    setProfileDropdownVisible(!profileDropdownVisible);
  };

  const handleOrderSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const orderid = event.target.value;
  navigate("/v1/Reshome");
  const order = orders.find((item) => item.Orderid === orderid);
  if (order) {
    OldOrders(order);
  } else {
    OldOrders('null');
  }
};


  return (
    <header className="reshead">
      <h1 className="head_style">{Resheader}</h1>
      <div className="date_time_style">
        <CalendarTodayIcon style={{ marginTop: "3px" }} />
        <p style={{ padding: "5px" }}>
          {currentDate}, {currentTime}
        </p>
      </div>
      {Resheader === "Home" && 
      <div className="running_head">
        <label className="running_label">Running orders:
        </label>
        <select className="running_select" onChange={handleOrderSelect}>
        <option value="">Select Order</option>
          {orders.map((order) => (
            <option key={order.Orderid} value={order.Orderid}>
              {order.Orderid}
            </option>
          ))}
        </select>
      </div>}
      {/* <div className="table_head">
        <label className="table_lable">Order table:</label>
        <select className="table_select">
          <option value="">Select Table</option>
          {configure.map((item) => (
            <option key={item._id.$oid} value={item.tablename}>
              {item.tablename} - {item.seats}
            </option>
          ))}
        </select>
      </div> */}
      

      <div className="heder_profiles" >
        <div className="heder_profile" onClick={toggleProfileDropdown}>
          <IconButton>
            <AccountCircleIcon
              style={{ fontSize: "30px", color: background }}
            />
            {/* <h4 style={{ width: '170px', color: 'black', display: 'flex' ,backgroundColor:"blue"}}>{data}</h4> */}
            {profileDropdownVisible ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </IconButton>
          {profileDropdownVisible && (
            <div className="dropdown-content-restaruent" ref={profileDropdownRef}>
              <Link to={"/v1/res-profile"} id="Profile">
                Profile
              </Link>
              <Link to="" onClick={openEmailCompose} id="feedback">
                Feedback
              </Link>
              <Link
                onClick={Logout}
                to={"/"}
                style={{ color: "#F14545" }}
                id="logout"
              >
                Logout
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Resheader;
 