import React, { useEffect, useState } from "react";
import "./Model.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import Payment from "./Payment";
import PopupScreen from "./PopupScreen";
// import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Tables from "../../common-component/Table";
import api from "../../axiosConfig";
interface Props {
  cartId: string | null;
  customerData: Customerdata | null;
  customerid: string;
  backHome: any;
  backHomebom: any;
  backHomepay: any;
}
interface Product {
  _id: {
    $oid: string;
  };
  Finalamount: number;
  products: Array<{
    amount: string;
    productId: string;
    productName: string;
    arabicword:string;
    quantity: string;
    totalPrice: string;
  }>;
  totalAmount: string;
  vat: string;
  Customerid: string;
  _ids: string;
}
interface Customerdata {
  _id: {
    $oid: string;
  };
  Name: string;
  phone_number: string;
  Email: string;
  Address: string;
}
const Bom: React.FC<Props> = ({ cartId, customerData, customerid, backHomebom, backHome }) => {
  const [bproduct, setBproduct] = useState<Product[]>([]);
  const [payment, setPayment] = useState<boolean>(false);
  const [backpage, setBackpage] = useState<boolean>(false);
  const [content ,setcontent] = useState<Customerdata>();
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get(`/app/v1/pos/shopping_cart/${cartId}`)
      .then((res) => {
        const categoryData: Product[] = res.data.result;
        setBproduct(categoryData)
      })
      .catch((err) => {
        console.log(err);
      });
      console.log(content);
      
  }, [cartId, content, customerid]);
  console.log("bproduct", bproduct);
  const updatedata = (updatedCustomerData: Partial<Customerdata>) => {
    api
      .put(`/app/v1/pos/shopping_cart/${cartId}`, { Customerid: customerid })
      .then((response) => {
        console.log("Updated customer data:", updatedCustomerData);
        setPayment(!payment);
      })
      .catch((error) => {
        console.error("Error updating customer data:", error);
      });

    setPayment(!payment);
  };

  const roundToTwoDecimalPlaces = (num: number): number => {
    const rounded = Math.round(num * 1000) / 1000;
    return Math.round(rounded * 100) / 100;
  };

  const totalAmount = bproduct.reduce((sum, item) => sum + parseFloat(item.totalAmount || "0"), 0);
  const roundedTotalAmount = roundToTwoDecimalPlaces(totalAmount);
  const roundValue = (value: number | string) => {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(num)) return '0.00';

    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };
  const products = {
    titlesno: "S.No",
    titlename1: "Product name",
    titlename2: "Arabic word",
    titlename3: "Quantity",
    titlename4: "Price (QAR)",
    S_no: bproduct?.flatMap((item) =>
      item.products.map((product, index) => index + 1)
    ),
    bodyname1: bproduct?.flatMap((item) =>
      item.products.map((product, index) => product.productName)
    ),
    bodyname2: bproduct?.flatMap((item) =>
      item.products.map((product, index) => product.arabicword)
    ),
    bodyname3: bproduct?.flatMap((item) =>
      item.products.map((product, index) => product.quantity)
    ),
    bodyname4: bproduct?.flatMap((item) =>
      item.products.map((product, index) => roundValue(product.totalPrice))
    ),
  };
  const OrderConfirm= {
    Blue: "blue",
    name: "Order Confirm",
  };
  const AddProduct3 = {
    Blue: "blue",
    name: "Add Product",
  }

  const backHomepay = (data: any) => {
    if (data === 'false') {
      setPayment(!payment)
    }else if(data === 'onlyfalse'){
      setPayment(false)
    }
  }

  return (
    <div>
      {backpage ? (
        <div>
          <PopupScreen cartId={cartId} backHome={backHomebom} />
        </div>
      ) : (
        <div>
          {payment ? (
            <Payment cartId={cartId} backHomepay={backHomepay} backHome={backHome} />
          ) : (
            <div className="Popup" id="Popup">
               <Button onClick={() => setBackpage(!backpage)} style={{ cursor: "pointer", marginRight: "450px",border:"none" }} >
                 <ArrowBackIcon />
               </Button>
              {/* <Tooltip title="Add product" placement="bottom" arrow>
                  <button
                    onClick={() => backHomebom('false')}
                    className="backhome-btn"
                  >
                    <ControlPointDuplicateIcon style={{ cursor: "pointer",border:"none"}} />
                  </button>
              </Tooltip> */}
              <button
                onClick={() => setBackpage(!backpage)}
                className="cancel-icon-product"
                style={{ cursor: "pointer", marginRight: "350px" }} >
                <CloseIcon />
              </button>
              {customerData && (
                <div>
                  <h2 className="proplay">Product details</h2>
                  <br />
                  <div style={{ display: "flex", marginBottom: "10px",justifyContent:"space-between",width:"80%" }}>
                    <div>
                      <p className="order-customer">Customer name :</p>
                      <p className="order-customer">Phone number  :</p>
                    </div>
                    <div>
                      <p className="order-phno">{!customerData?content?.Name:customerData.Name}</p>
                      <p className="order-phno">{!customerData?content?.phone_number:customerData.phone_number}</p>
                    </div>
                    <div onClick={() => backHomebom('false')} style={{marginRight:"70px"}}>
                    <Buttons Buttondata={AddProduct3} />
                    </div>

                  </div>
                </div>
              )}
              <div className="Table_product">
                <Tables product={products} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "500px",
                  marginTop: "10px",
                }}>
                <h3>
                  Total price : {roundedTotalAmount.toFixed(2)} QAR
                </h3>
                <div
                  onClick={() =>
                    updatedata({
                      Name: customerData?.Name,
                      phone_number: customerData?.phone_number,
                      Email: customerData?.Email,
                      Address: customerData?.Address,
                    })
                  }>
                  <Buttons Buttondata={OrderConfirm} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Bom;