import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, Box } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";
import "./Ressetting.css";
import api from "../../axiosConfig";
import Resmenu from "../../components/Restaurant/Resmenu";
import Ressetting from "./Ressetting";
import Resheader from "../../components/Restaurant/Resheader";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}

interface Productbtn {
  image_url: any;
  _id: { $oid: string };
  Amount: string;
  ProductName: string;
  category_id: string;
  image_file?: File;
  Type: string;
}

function Resaddmenu() {
  const [loading, setLoading] = useState<boolean>(false);
  const [productName, setProductName] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [products, setProducts] = useState<Productbtn[]>([]);
  const [categories, setCategories] = useState<CategoryButton[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<"add" | "edit" | "delete">("add");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [productToDelete, setProductToDelete] = useState<string | null>(null);
  const productNameRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const typeRef = useRef<HTMLInputElement>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);


  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    nextRef?: React.RefObject<HTMLElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextRef?.current?.focus();
    }
  };

  useEffect(() => {
    document.title = "Products - Settings - iMetanic";
    fetchCategories();
    fetchProducts();
  }, []);

  const fetchCategories = () => {
    setLoading(true);
    api.get("/app/v1/pos/category")
      .then((res) => setCategories(res.data.result))
      .catch(() => toast.error("Failed to fetch categories", { theme: "colored" }))
      .finally(() => setLoading(false));
  };
  const fetchProducts = () => {
    setLoading(true);
    api.get("/app/v1/pos/product")
      .then((res) => setProducts(res.data.result))
      .catch(() => toast.error("Failed to fetch products", { theme: "colored" }))
      .finally(() => setLoading(false));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const openModal = (action: "add" | "edit" | "delete", id?: string, name?: string, amount?: string, categoryId?: string, image?: string | File | null) => {
    setModalAction(action);
    if (action === "edit") {
      setEdit(true);
      setId(id!);
      setType(type!);
      setProductName(name!);
      setAmount(amount!);
      setCategoryId(categoryId!);
      if (typeof image === "string") {
        setPreviewImage(image);
        setImage(null);
      } else {
        setPreviewImage(null);
        setImage(image || null);
      }
    } else if (action === "delete") {
      setProductToDelete(id!)
    } else {
      setProductName("");
      setAmount("");
      setType("")
      setCategoryId("");
      setImage(null);
      setEdit(false);
    }
    setModal(true);
  };

  const handleProductUpdate = async () => {
    if (!productName.trim() || !amount.trim() || !categoryId.trim()) {
      toast.error("Please fill in all required fields", { theme: "colored" });
      return;
    }

    const productData = new FormData();
    productData.append("ProductName", productName);
    productData.append("Amount", amount);
    productData.append("category_id", categoryId);
    productData.append("Type", type);

    if (image) {
      productData.append("image", image);
    } else if (previewImage) {
      productData.append("existingImage", previewImage);
    }

    setLoading(true);
    try {
      if (edit) {
        await api.put(`/app/v1/pos/product/${id}`, productData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success("Product updated successfully", { theme: "colored" });
      } else {
        await api.post("/app/v1/pos/product", productData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success("Product added successfully", { theme: "colored" });
      }
      fetchProducts();
    } catch (error) {
      toast.error("Failed to process product", { theme: "colored" });
    } finally {
      closeModal();
    }
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreviewImage(reader.result as string);
      reader.readAsDataURL(file);
      setImage(file);
    }
  };


  const handleDelete = () => {
    if (productToDelete) {
      setLoading(true);
      api.delete(`/app/v1/pos/product/${productToDelete}`)
        .then(() => {
          toast.success("Product deleted successfully", { theme: "colored" });
          fetchProducts();
        })
        .catch(() => toast.error("Failed to delete product", { theme: "colored" }))
        .finally(() => {
          setLoading(false);
          setModal(false);
          setProductToDelete(id!)
        })
    }
  };

  const closeModal = () => {
    setModal(false);
    setProductName("");
    setAmount("");
    setType("")
    setCategoryId("");
    setImage(null);
  };
  const resetForm = () => {
    setProductName("");
    setAmount("");
    setCategoryId("");
    setType("")
    setImage(null);
  };

  const oldOrders = () => {

  }

  return (
    <div style={{ display: "flex", flexDirection: 'column', overflow: 'hidden' }}>
      <Resheader Resheader={"Products"} OldOrders={oldOrders} />
      <div style={{ display: "flex" }}>
        <Resmenu Resmenu={""} />
        <div style={{ display: 'flex', flexDirection: "column" }}>
          <Ressetting />
          <div style={{ padding: "20px" }}>
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={() => openModal("add")}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Add Product"}
              </Button>
            </div>
            <TableContainer component={Paper} style={{ marginTop: "20px", width: "58%", marginLeft: "70px", height: "480px", overflow: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="Res-table-header">S.No</TableCell>
                    <TableCell className="Res-table-header">Product Name</TableCell>
                    <TableCell className="Res-table-header">Amount</TableCell>
                    <TableCell className="Res-table-header">Category</TableCell>
                    <TableCell className="Res-table-header">Image</TableCell>
                    <TableCell className="Res-table-header">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.length > 0 ? (
                    products
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((product, index) => (
                        <TableRow key={product._id.$oid}>
                          <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                          <TableCell>{product.ProductName}</TableCell>
                          <TableCell>{product.Amount}</TableCell>
                          <TableCell>
                            {categories.find((category) => category._id.$oid === product.category_id)?.CategoryName || "Unknown"}
                          </TableCell>
                          <TableCell>
                            {product.image_url ? (
                              <img
                                src={product.image_url}
                                alt={product.ProductName}
                                style={{ width: "40px", height: "40px", objectFit: "cover" }}
                              />
                            ) : (
                              "No Image"
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() =>
                                  openModal(
                                    "edit",
                                    product._id.$oid,
                                    product.ProductName,
                                    product.Amount,
                                    product.category_id,
                                    product.image_file
                                  )
                                }
                              >
                                <EditIcon className="action-icon" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => openModal("delete", product._id.$oid)}>
                                <DeleteOutlineIcon className="action-icon" style={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No products available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>

            {modal && (
              <div className="Res-modals">
                <div className="Res-overlays">
                  <div className="Res-modal-contents">
                    <Box>
                      {modalAction === "delete" ? (
                        <div className="popup">
                          <div className="delete-product" style={{ marginTop: "-20px" }}>
                            <h1 style={{ marginTop: "20px" }}>Confirm to Delete</h1>
                            <p style={{ fontWeight: 'bold', marginTop: "15px" }}>Product Name: {products.find((product) => product._id.$oid === productToDelete)?.ProductName || "Unknown"} </p>
                            <p style={{ marginTop: "20px" }}>Are you sure you want to delete this product?</p>
                            <div className="btn-cancel" style={{ marginTop: "20px", width: "100%" }}>
                              <div onClick={() => setModal(false)} style={{ width: "45%" }}>
                                <Button variant="outlined" style={{ width: "100%" }}>
                                  Cancel
                                </Button>
                              </div>
                              <div onClick={handleDelete} style={{ width: "45%" }}>
                                <Button
                                  variant="contained"
                                  style={{ width: "100%" }}
                                  disabled={loading}
                                >
                                  {loading ? <CircularProgress size={24} /> : "Confirm"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <h3 style={{ margin: "0px 150px 20px" }}>
                            {modalAction === "edit" ? "Edit Product" : "Add Product"}
                          </h3>
                          <div style={{ display: "flex", flexDirection: "column", marginBottom: "30px" }}>
                            {/* Product Form Fields */}
                            <TextField
                              label="Product Name"
                              variant="outlined"
                              fullWidth
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, amountRef)}
                              inputRef={productNameRef}
                              style={{ marginBottom: '16px', width: '420px' }}
                            />
                            <TextField
                              label="Product price"
                              variant="outlined"
                              fullWidth
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                              onKeyDown={(event) => handleKeyDown(event, typeRef)}
                              inputRef={amountRef}
                              style={{ marginBottom: '16px' }}
                            />
                            <FormControl fullWidth style={{ marginBottom: "16px", width: "420px" }}>
                              <InputLabel>Type</InputLabel>
                              <Select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                label="Type"
                                onKeyDown={(event) => handleKeyDown(event, categoryRef)}
                                inputRef={typeRef}
                              >
                                <MenuItem value="Veg">Veg</MenuItem>
                                <MenuItem value="Non-Veg">Non-Veg</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              select
                              label="Category"
                              value={categoryId}
                              onChange={(e) => setCategoryId(e.target.value)}
                              style={{ marginBottom: '16px', width: '420px' }}
                              onKeyDown={(event) => handleKeyDown(event, uploadImageRef)}
                              inputRef={categoryRef}
                              fullWidth
                              SelectProps={{ MenuProps: { PaperProps: { style: { maxHeight: 200, overflowY: 'auto' } } } }}
                            >
                              {categories.map((category) => (
                                <MenuItem key={category._id.$oid} value={category._id.$oid}>
                                  {category.CategoryName}
                                </MenuItem>
                              ))}
                            </TextField>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <input
                                type="file"
                                accept="image/*"
                                ref={uploadImageRef}
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              <Button
                                variant="outlined"
                                onClick={() => uploadImageRef.current?.click()}
                              >
                                Upload Image
                              </Button>
                              {previewImage && (
                                <img
                                  src={typeof previewImage === "string" ? previewImage : URL.createObjectURL(previewImage)}
                                  alt="Preview"
                                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            {loading && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              >
                                <CircularProgress size={50} />
                              </Box>
                            )}
                            <Button
                              variant="outlined"
                              onClick={() => {
                                resetForm();
                                setModal(false);
                                fetchProducts();
                              }}
                              style={{ width: "45%" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleProductUpdate}
                              style={{ width: "45%" }}
                              disabled={loading}
                            >
                              {loading ? <CircularProgress size={24} /> : modalAction === "edit" ? "Update" : "Add"}
                            </Button>
                          </div>
                          <ToastContainer />
                        </>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Resaddmenu;