import React, { useState } from "react";
import "./Edit.css";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  id: string | undefined;
  Name: string;
  onDelete: (id: string) => void;
  onClose: () => void; 
}

const DeletedCategory: React.FC<Props> = ({ id, Name, onDelete, onClose }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  const Deletecategory = async () => {
    if (!id) return; 

    try {
      const res = await api.delete(`/app/v1/pos/category/${id}`);
      console.log(res);

      toast.success("Category deleted successfully", {
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });

      setIsModalVisible(false);
      onDelete(id); 
      onClose(); 
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Failed to delete category.", {
        position: "top-right",
        autoClose: 2100,
        theme: "colored",
      });
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
    onClose(); 
  };

  const ConfirmButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };

  return (
    <div>
      {isModalVisible && (
        <div className="modals">
          <div className="overlays">
            <div className="modal-contents-product">
              <div className="edit-popupscreen">
                <button
                        onClick={handleClose}
                        className="cancel-icon-cat-delete"
                      >
                        <CloseIcon />
                      </button>
                <div className="addcategory-edit" style={{ marginTop: "-20px" }}>
                  <h1 style={{ marginTop: "20px" }}>Delete Confirmation</h1>
                  {Name && (
                    <p style={{ fontWeight: "bold", marginTop: "15px" }}>
                      Category name: {Name}
                    </p>
                  )}
                  <p style={{ marginTop: "30px" }}>
                    Are you sure you want to delete this category?
                  </p>
                  <p
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#d6dbdf",
                      borderRadius: "3px",
                      padding: "10px",
                    }}
                  >
                    <strong>Note:</strong> When you delete the category, the added products are
                    also deleted.
                  </p>
                  <div className="btnconfirm-edit">
                    <div onClick={handleClose}>
                      <Buttons Buttondata={CancelButton} />
                    </div>
                    <div onClick={Deletecategory}>
                      <Buttons Buttondata={ConfirmButton} />
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeletedCategory;
