import React, { useEffect, useState } from "react";
import "./Reshome.css";
import Resheader from "./Resheader";
import Resmenu from "./Resmenu";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  Switch,
  SwitchProps,
  TextField,
  Tooltip,
} from "@mui/material";

import { toast, ToastContainer } from "react-toastify";
// import noimage from "../../../../assets/Images/no icon.png";
import noimage from "../../../assets/Images/no icon.png";
import { useParams } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import RateReviewIcon from "@mui/icons-material/RateReview";
import api from "../../axiosConfig";
import { event } from "jquery";

interface catagoryres {
  _id: { $oid: string };
  CategoryName: string;
}
interface Customer {
  _id: { $oid: string };
  name: string;
  phone_number: string;
  table_number: string;
}

interface productres {
  id: number;
  notes: string;
  quantity: number;
  ProductName: string;
  Amount: string;
  Type: string;
  image_url: any;
  category_id: string;
  _id: { $oid: string };
}

interface OrderDetails {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  Customerid: string;
  GrandTotal: string;
  OrderStatus: string;
  Orderdate: string;
  Orderid: string;
  Ordertime: string;
  Paymentmode: string;
  Products: Array<{
    Amount: string;
    ArabicName: string;
    ProductName: string;
    category_id: string;
    createdBy: string;
    Type: string;
    createdOn: {
      $date: string;
    };
    image_url: string;
    quantity: number;
    _id: {
      $oid: string;
    };
  }>;
  ServiceCharge: number;
  Subtotal: string;
  table_number: string;
  totalAmount: number;
  totalQuantity: number;
  _id: {
    $oid: string;
  };
  total_orders_count: number;
}

const Reshome = () => {
  const { id } = useParams<{ id: string }>();
  const [getorder, setGetorder] = useState<catagoryres[]>([]);
  const [getproduct, setGetproduct] = useState<productres[]>([]);
  const [getcustomer, setGetcustomer] = useState<Customer[]>([]);
  const [discount, setDiscount] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedCategoryradio, setSelectedCategoryradio] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<productres[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [editingProduct, setEditingProduct] = useState<string | null>(null);
  const [editedAmount, setEditedAmount] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [opensource, setOpensource] = useState<string>("");
  const [openpop, setOpenpop] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);

  const [time, setTime] = useState({
    hours: "",
    minutes: "",
    seconds: "",
    day: "",
    month: "",
    year: "",
  });
  const [open, setOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const { orderid } = useParams<{ orderid: string }>();
  const [orderDetails, setOrderDetails] = useState<OrderDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPrintContent, setShowPrintContent] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderDetails | null>(null);
  const [oldProducts, setOldProducts] = useState<OrderDetails>();
  const [newopen, setNewOpen] = useState(false);
  const [editingNote, setEditingNote] = useState<string | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchOrder = async (id: string) => {
      try {
        setLoading(true);
        const response = await api.get(`/app/v1/pos/order${id}`);
        setOrderDetails(response.data.result || response.data);
        console.log("getorder", response);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (orderid) {
      fetchOrder(orderid);
    }
  }, [orderid]);

  const handleCategoryChange = (event: { target: { value: string } }) => {
    const selectedValue = event.target.value;
    setSelectedCategoryradio(selectedValue);

    if (selectedValue === "none") {
      setSelectedCategory(null);
    }
  };

  const handleAmountClick = (orderId: string, amount: string) => {
    setEditingProduct(orderId);
    setEditedAmount(amount);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedAmount(e.target.value);
  };

  const handleAmountBlur = (orderId: string) => {
    setSelectedProducts((prevOrders) =>
      prevOrders.map((order) =>
        order._id.$oid === orderId ? { ...order, Amount: editedAmount } : order
      )
    );
    setEditingProduct(null);

    // Recalculate subtotal and grand total after updating the amount
    const newSubtotal = calculateSubtotal();
    const newGrandTotal = calculateTotal(newSubtotal);
    // You can set these values to state if needed, or just use them directly in the render method
  };

  const calculateSubtotal = (): number => {
    return selectedProducts.reduce((acc, order) => {
      const amount = parseFloat(order.Amount) || 0;
      return acc + order.quantity * amount;
    }, 0);
  };

  const calculateTotal = (subtotal: number): number => {
    return subtotal - discount;
  };

  const subtotal = calculateSubtotal();
  const grandTotal = calculateTotal(subtotal);
  const handleAddToOrder = (foodItem: productres) => {
    const existingOrder = selectedProducts.find(
      (order) => order.ProductName === foodItem.ProductName
    );

    if (existingOrder) {
      setSelectedProducts(
        selectedProducts.map((order) =>
          order.ProductName === foodItem.ProductName
            ? { ...order, quantity: order.quantity + 1 }
            : order
        )
      );
    } else {
      setSelectedProducts([...selectedProducts, { ...foodItem, quantity: 1 }]);
    }
  };

  const handleIncrement = (foodItem: productres) => {
    setSelectedProducts((prevSelectedProducts) => {
      const existingOrder = prevSelectedProducts.find(
        (order) => order.ProductName === foodItem.ProductName
      );

      if (existingOrder) {
        return prevSelectedProducts.map((order) =>
          order.ProductName === foodItem.ProductName
            ? { ...order, quantity: order.quantity + 1 }
            : order
        );
      } else {
        return [...prevSelectedProducts, { ...foodItem, quantity: 1 }];
      }
    });
  };

  const handleDecrement = (foodItem: productres) => {
    setSelectedProducts((prevOrderList) => {
      const existingOrder = prevOrderList.find(
        (order) => order.ProductName === foodItem.ProductName
      );

      if (existingOrder) {
        if (existingOrder.quantity > 1) {
          return prevOrderList.map((order) =>
            order.ProductName === foodItem.ProductName
              ? { ...order, quantity: order.quantity - 1 }
              : order
          );
        } else {
          return prevOrderList.filter(
            (order) => order.ProductName !== foodItem.ProductName
          );
        }
      } else {
        return prevOrderList;
      }
    });
  };

  // const calculateSubtotal = (): number => {
  //   return selectedProducts.reduce((acc, order) => {
  //     const amount = parseFloat(order.Amount) || 0;
  //     return acc + order.quantity * amount;
  //   }, 0);
  // };

  // const calculateTotal = (): number => {
  //   const subtotal = calculateSubtotal();
  //   return subtotal - serviceCharge;
  // };

  const date = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: day,
      month: month,
      year: year,
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(event.target.value) || 0;
    const subtotal = calculateSubtotal();
    setIsEditing(true);
    if (value < 0) {
      value = 0;
    } else if (value > subtotal) {
      value = subtotal;
    }
    setDiscount(value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    const subtotal = calculateSubtotal();
    if (discount < 0) {
      setDiscount(0);
    } else if (discount > subtotal) {
      setDiscount(subtotal);
    }
    if (!discount) {
      setDiscount(0);
    }
  };

  useEffect(() => {
    date();
    categoryGet();
    productGet();
    // fetchOrder();
    document.title = "Restaurant - iMetanic";
  }, []);

  const categoryGet = async () => {
    try {
      const res = await api.get(`/app/v1/pos/category`);
      const categoryElement: catagoryres[] = res.data.result;
      setGetorder(categoryElement);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const productGet = async () => {
    try {
      const res = await api.get(`/app/v1/pos/product`);
      console.log("firstgobi", res);
      const products = res.data.result;
      setGetproduct(products);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await api.get("/app/v1/pos/customers");
        console.log("getcustomer", response);
        setGetcustomer(response.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchCustomers();
  }, []);

  // const fetchOrder = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await api.get(`/app/v1/pos/order/${id}`);
  //     setOrder(response.data.result || response.data);
  //     console.log("responseitem", response);
  //   } catch (err) {
  //     console.error(err);
  //     setError("Failed to fetch order details");
  //   }
  //   finally {
  //     setLoading(false);
  //   }
  // };

  const handleCancelOrder = () => {
    const orderCancel = {
      OrderStatus: "Cancel",
    };
    if (oldProducts) {
      api
        .put(`/app/v1/pos/order/${oldProducts.Orderid}`, orderCancel)
        .then(() => {
          toast.error("Order cancelled successfully!");
          setSelectedProducts([]);
          setCustomerName("");
          setPhoneNumber("");
          setTableNumber("");
          setDiscount(0);
        })
        .catch((error) => {
          console.error("Error cancelling the order:", error);
          toast.error("Failed to cancel the order. Please try again.");
        });
    } else {
      toast.warning("No order to cancel!");
    }
  };

  const handleUpdatOrder = () => {
    const addNewproducts = {
      guess: opensource,
      OrderStatus: "pending",
      Subtotal: calculateSubtotal().toFixed(2),
      ServiceCharge: discount,
      GrandTotal: grandTotal.toFixed(2),
      Products: selectedProducts,
    };
    if (oldProducts) {
      const res = api.put(
        `/app/v1/pos/order/${oldProducts.Orderid}`,
        addNewproducts
      );
      setSelectedProducts([]);
      setCustomerName("");
      setPhoneNumber("");
      setTableNumber("");
      setDiscount(0);
      toast.success("Order Updated successfully!");
    } else {
      if (selectedProducts.length === 0) {
        toast.warning("Please select at least one product!");
        return;
      }
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setOpenpop(false);
    const customerData = {
      Name: customerName,
      phone_number: phoneNumber,
      table_number: tableNumber,
    };
    try {
      const customerResponse = await api.post("/app/v1/pos/customer", customerData);
      console.log("Customer created successfully:", customerResponse.data);
      sendorder(customerResponse.data._id);
      setCustomerName("");
      setPhoneNumber("");
      setTableNumber("");
      setOpenpop(true);
      handleClose();
    } catch (error:any) {
      console.error("Error creating customer:", error);
      if (error.response?.data?.message === "Phone Number already Exists") {
        toast.error("Phone Number already exists", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }else {
        toast.error("An unexpected error occurred.", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };

  const sendorder = async (customerData: string) => {
    const orderDetails = {
      Customerid: customerData,
      OrderStatus: "pending",
      guess: opensource,
      Subtotal: calculateSubtotal().toFixed(2),
      Discount: discount,
      GrandTotal: grandTotal.toFixed(2),
      Products: selectedProducts,
      Orderdate: `${time.year}-${time.month}-${time.day}`,
      Ordertime: `${time.hours}:${time.minutes}`,
      OrderType: isDelivery ? "Delivery" : "Table",
    };

    try {
      const res = await api.post(`/app/v1/pos/order`, orderDetails);
      console.log("Order sent successfully:", res.data);
      // Clear the selected products and reset form fields after successful order submission
      setSelectedProducts([]);
      setCustomerName("");
      setPhoneNumber("");
      setTableNumber("");
      setDiscount(0);
      toast.success("Order updated successfully!");
    } catch (error) {
      console.error("Error sending order:", error);
    }
  };

  const filteredProducts = getproduct.filter((item) => {
    const matchesCategory =
      !selectedCategory || item.category_id === selectedCategory;

    const matchesSearch = item.ProductName.toLowerCase().includes(
      searchQuery.toLowerCase()
    );

    const matchesType =
      selectedCategoryradio === "none" ||
      !selectedCategoryradio ||
      (item.Type &&
        item.Type.toLowerCase() === selectedCategoryradio.toLowerCase());

    return matchesCategory && matchesSearch && matchesType;
  });

  const handleCategorySelectbutton = (categoryId: string | null) => {
    setSelectedCategory(categoryId);
  };

  const handleClosepop = () => {
    setOpenpop(false);
    if (selectedOrder) {
      printpreview(); // Automatically print if an order is selected
    }
  };

  const printerDetails = (order: OrderDetails) => (
    <div className="print-section">
      <div className="Print_popup_restarunt_home">
        <h3 style={{ fontFamily: "cursive" }}>Invoice</h3>
        <div
          style={{
            padding: "3px",
            display: "grid",
            gridAutoFlow: "dense",
            gridTemplateRows: "auto auto auto",
            direction: "rtl",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>:Date</p>
            <span>{`${new Date().getDate()}-${
              new Date().getMonth() + 1
            }-${new Date().getFullYear()}`}</span>
          </div>
          <div style={{ display: "flex" }}>
            <span>{order.table_number || "N/A"}</span>
            <p>:Dine In</p>
          </div>
        </div>
        <hr style={{ width: "99%", border: "1px dotted black" }} />

        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>Name:</p>
            <span>{order.CustomerName || "N/A"}</span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Type:</p>
            <span>{order.Paymentmode}</span>
          </div>
        </div>

        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>Items:</p>
            <span>{order.Products.length}</span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Ph.No:</p>
            <span>{order.CustomerPhoneNo || "N/A"}</span>
          </div>
        </div>

        <hr style={{ width: "99%" }} />

        <div style={{ width: "100%", marginTop: "5px" }}>
          <div
            style={{
              backgroundColor: "#e9eaea",
              display: "flex",
              justifyContent: "space-around",
            }}
          />
          <hr style={{ width: "99%" }} />

          <div style={{ display: "grid", width: "100%" }}>
            <div
              style={{
                backgroundColor: "#f4f4f4",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#e9eaea",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                <h4 style={{ margin: 0, flex: 1, textAlign: "left" }}>
                  Item(s)
                </h4>
                <h4 style={{ margin: 0, flex: 1, textAlign: "center" }}>Qty</h4>
                <h4 style={{ margin: 0, flex: 1, textAlign: "right" }}>
                  Price
                </h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                {order.Products.map((product, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #ddd",
                      fontSize: "14px",
                      backgroundColor: "#ffffff",
                      borderRadius: "5px",
                    }}
                  >
                    <div style={{ flex: 1, textAlign: "left" }}>
                      {product.ProductName}
                    </div>
                    <div style={{ flex: 1, textAlign: "center" }}>
                      {product.quantity}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {product.Amount}
                    </div>
                  </div>
                ))}
              </div>

              <hr style={{ width: "100%", marginTop: "10px" }} />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>Sub Total</div>
                <div>
                  <b>{order.Subtotal}</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <b style={{ width: "60%" }}>Total Price</b>
            <b>{order.GrandTotal}</b>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginRight: "50px",
            }}
          >
            <b style={{ width: "60%", marginRight: "55px" }}>Discount</b>
            <b>{0}</b> {/* Assuming no discount for now */}
          </div>

          <hr style={{ width: "99%" }} />

          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "5px",
              }}
            >
              <h2 style={{ width: "60%" }}>Grand Total</h2>
              <h2>{order.GrandTotal}</h2>
            </div>
          </div>

          <hr style={{ width: "99%" }} />
        </div>

        <div className="footer">
          <i>Thank you !!! & Visit Again !!!</i>
          <b>Powered by iMetanic</b>
        </div>
      </div>
    </div>
  );

  // const printpreview = () => {
  //   setShowPrintContent(true);
  //   setTimeout(() => {
  //     window.print();
  //     setShowPrintContent(false);
  //     setOpenpop(false)
  //   }, 500);
  // };

  const printpreview = () => {
    if (selectedOrder) {
      setShowPrintContent(true);
      setTimeout(() => {
        window.print();
        setShowPrintContent(false);
        setOpenpop(false);
      }, 500);
    } else {
      toast.warning("Please select an order to print.");
    }
  };

  const deleteProduct = (id: string) => {
    const updatedProducts = selectedProducts.filter(
      (item) => item._id.$oid !== id
    );
    setSelectedProducts(updatedProducts);
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "#2ECA45",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));

  const oldOrders = (orders: OrderDetails) => {
    if (orders.Orderid) {
      setOldProducts(orders);
      orders.Products.map((item) => {
        const filterProduct = getproduct.filter(
          (i) => i._id.$oid === item._id.$oid
        );
        handleIncrement(filterProduct[0]);
        if (item.quantity > 0) {
          for (let count = 0; count < item.quantity; count++) {
            handleIncrement(filterProduct[0]);
          }
        }
      });
    } else {
      handleReload();
    }
  };

  const handleOpenDialog = () => {
    setNewOpen(true);
  };

  const handleCloseDialog = () => {
    setNewOpen(false);
  };

  const handleReload = () => {
    // Clear all fields and start a new order
    setSelectedProducts([]);
    setCustomerName("");
    setPhoneNumber("");
    setTableNumber("");
    setDiscount(0);
    setOldProducts(undefined);
    handleCloseDialog();
  };

  const handleNoteChange = (orderId: string, note: string) => {
    console.log(`Note change for orderId: ${orderId}, note: ${note}`);
    setSelectedProducts((prevProducts) =>
      prevProducts.map((item) =>
        item._id.$oid === orderId ? { ...item, notes: note } : item
      )
    );
  };

  const handleNoteClick = (productId: string) => {
    setEditingNote(productId);
  };

  const handleNoteBlur = (orderId: string) => {
    setEditingNote(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getWidth = () => {
    if (screenWidth <= 768) {
      return "90%";
    } else {
      return "400px";
    }
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <div>
        <Resheader Resheader={"Home"} OldOrders={oldOrders} />
        <div style={{ display: "flex" }}>
          <Resmenu Resmenu={""} />
          <div className="home_page_overall">
            <div style={{ display: "flex" }}>
              <div className="search_content">
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div style={{ marginTop: "12px", marginRight: "10px" }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{ margin: "0px 400px 0px 0px", color: "white" }}
                      onClick={handleOpenDialog}
                    >
                      New Order
                    </Button>
                    {/* Confirmation Dialog */}
                    <Dialog open={newopen} onClose={handleCloseDialog}>
                      <DialogTitle>Confirm New Order</DialogTitle>
                      <DialogContent>
                        <DialogContentText sx={{ width: "400px" }}>
                          You have unsaved changes. Are you sure you want to
                          start a new order? All changes will be lost items.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                          Cancel
                        </Button>
                        <Button
                          onClick={handleReload}
                          color="primary"
                          autoFocus
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedCategoryradio}
                        onChange={handleCategoryChange}
                      >
                        <FormControlLabel
                          value="veg"
                          control={<Radio />}
                          label="Veg"
                        />
                        <FormControlLabel
                          value="Non-veg"
                          control={<Radio />}
                          label="Non-Veg"
                        />
                        {/* <FormControlLabel
                          value="none"
                          control={<Radio />}
                          label="none"
                        /> */}
                        <FormControlLabel
                          value="none"
                          control={<Radio />}
                          label="All"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="search_home">
                    <TextField
                      id="search-food-items"
                      label="Search food items"
                      variant="outlined"
                      className="search-input"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
                <div className="food_buttons">
                  <div className="food_select">
                    <button
                      className={
                        selectedCategory === null ? "active-category" : ""
                      }
                      onClick={() => handleCategorySelectbutton(null)}
                    >
                      ALL
                    </button>
                    {getorder?.map((i) => (
                      <button
                        key={i._id.$oid}
                        className={
                          selectedCategory === i._id.$oid
                            ? "active-category"
                            : ""
                        }
                        onClick={() => handleCategorySelectbutton(i._id.$oid)}
                      >
                        {i.CategoryName}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="food_alignment-restarunt">
                  <div className="content-restarunt">
                    <div className="food-grid-restarunt">
                      {filteredProducts.slice(0, 50).map((item) => (
                        <div key={item.ProductName} className="food-items">
                          <img
                            src={item.image_url || noimage}
                            alt={item.ProductName}
                            onClick={() => handleAddToOrder(item)}
                            style={{ cursor: "pointer" }}
                            onError={(e) => {
                              e.currentTarget.src = noimage;
                            }}
                          />
                          <div style={{ width: "100%" }}>
                            <ul
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <li>{item.ProductName}</li>
                              <li>{item.Amount}</li>
                            </ul>
                            <div className="handle-design">
                              <IconButton onClick={() => handleDecrement(item)}>
                                <RemoveIcon />
                              </IconButton>
                              <span>
                                {selectedProducts.find(
                                  (order) =>
                                    order.ProductName === item.ProductName
                                )?.quantity || 0}
                              </span>
                              <IconButton onClick={() => handleIncrement(item)}>
                                <AddIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_home">
                <div className="toogle-btn">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <TableRestaurantIcon className="table" />
                    <p style={{ margin: 0, fontSize: "17px" }}>Table</p>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={isDelivery}
                          onChange={(e) => setIsDelivery(e.target.checked)}
                        />
                      }
                      label="Delivery"
                    />
                    <DeliveryDiningIcon className="delivery" />
                  </div>
                </div>

                <div className="order_inline_align">
                  <div style={{ textAlign: "right", width: "73%" }}>
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      <p
                        style={{
                          fontSize: "20px",
                          // fontWeight: "bold",
                          position: "relative",
                          bottom: "4px",
                        }}
                      >
                        Order No:
                      </p>
                      <li style={{ color: "#666" }}>
                        {isDelivery ? "Delivery" : "Dine In:"}
                      </li>
                    </ul>
                  </div>
                  <div style={{ flex: 1, textAlign: "right" }}>
                    <ul
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      <li style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {!oldProducts?.Orderid
                          ? "New Order"
                          : `#${oldProducts?.Orderid}`}
                      </li>
                      <input
                        type="number"
                        style={{
                          width: "80px",
                          height: "30px",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          fontSize: "15px",
                          padding: "5px",
                          marginTop: "5px",
                        }}
                        value={opensource}
                        onChange={(e) => setOpensource(e.target.value)}
                      />
                    </ul>
                  </div>
                </div>

                <div className="input_alignment">
                  {selectedProducts.length > 0 && (
                    <div style={{ marginTop: "5px" }}>
                      <ul>
                        {selectedProducts.map((order, index) => (
                          <li
                            key={index}
                            style={{
                              backgroundColor: "#d9eeee",
                              // backgroundColor: "green",
                              // width: "100%",
                              width: getWidth(),
                              height: "auto",
                              padding: "10px",
                              borderRadius: "8px",
                              gap: "10px",
                              marginBottom: "10px",
                              marginRight: "20px",
                              // margin:"0px"
                            }}
                          >
                            <div className="order_na_quen">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    // justifyContent: "space-between",
                                    width: "320px",
                                    // backgroundColor:"red"
                                  }}
                                >
                                  <img
                                    src={order.image_url || noimage}
                                    alt={order.ProductName}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                    }}
                                    onError={(e) => {
                                      e.currentTarget.src = noimage;
                                    }}
                                  />
                                  <div
                                    style={{
                                      width: "200px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <li>{order.ProductName}</li>
                                    <div
                                      className="order_name"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <ul
                                        style={{
                                          listStyle: "none",
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        <li
                                          // style={{
                                          //   cursor: "pointer",
                                          //   padding: "8px",
                                          //   border: "1px solid #ddd",
                                          //   borderRadius: "4px",
                                          //   marginBottom: "4px",
                                          //   backgroundColor: "#f9f9f9",
                                          // }}
                                          onClick={() =>
                                            handleAmountClick(
                                              order._id.$oid,
                                              order.Amount
                                            )
                                          }
                                          role="button"
                                          tabIndex={0}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "Enter" ||
                                              e.key === " "
                                            ) {
                                              handleAmountClick(
                                                order._id.$oid,
                                                order.Amount
                                              );
                                            }
                                          }}
                                        >
                                          {editingProduct === order._id.$oid ? (
                                            <TextField
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  width: "70%",
                                                  marginTop: "5px",
                                                },
                                              }}
                                              value={editedAmount}
                                              onChange={handleAmountChange}
                                              onBlur={() =>
                                                handleAmountBlur(order._id.$oid)
                                              }
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter")
                                                  handleAmountBlur(
                                                    order._id.$oid
                                                  );
                                              }}
                                              autoFocus
                                              inputProps={{
                                                "aria-label": "Edit amount",
                                                style: {
                                                  textAlign: "right",
                                                  width: "50%",
                                                },
                                              }}
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                textAlign: "right",
                                                display: "flex",

                                                alignItems: "center",
                                                height: "32px",
                                                width: "120px",
                                              }}
                                            >
                                              <span
                                                style={{ display: "block" }}
                                              >
                                                {Number(order.quantity) || 0}
                                              </span>{" "}
                                              x
                                              <span
                                                style={{ display: "block" }}
                                              >
                                                {Number(order.Amount) || 0}
                                              </span>{" "}
                                            </div>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        position: "relative",
                                        left: "22px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {(
                                        Number(order.Amount || 0) *
                                        Number(order.quantity || 0)
                                      ).toFixed(2)}
                                    </span>
                                    <div
                                      style={{
                                        position: "relative",
                                        bottom: "5px",
                                        left: "20px",
                                      }}
                                    >
                                      <Tooltip title="Delete">
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                            deleteProduct(order._id.$oid)
                                          }
                                        >
                                          <DeleteOutlineIcon
                                            style={{ color: "red" }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="order_notes"
                                    style={{ display: "flex", width: "260px" }}
                                  >
                                    <i style={{ fontWeight: "bold" }}>Notes:</i>
                                    {editingNote === order._id.$oid ? (
                                      <TextField
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            height: "25px",
                                          },
                                        }}
                                        placeholder="Add a note..."
                                        value={order.notes || ""}
                                        onChange={(e) => {
                                          handleNoteChange(
                                            order._id.$oid,
                                            e.target.value
                                          );
                                        }}
                                        variant="outlined"
                                        size="medium"
                                        InputLabelProps={{ shrink: false }}
                                        onBlur={() =>
                                          handleNoteBlur(order._id.$oid)
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter")
                                            handleNoteBlur(order._id.$oid);
                                        }}
                                        autoFocus
                                        style={{
                                          marginLeft: "5px",
                                          width: "270px",
                                          fontSize: "12px",
                                        }}
                                      />
                                    ) : (
                                      <div>
                                        <span
                                          onClick={() =>
                                            handleNoteClick(order._id.$oid)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i style={{ fontSize: "13px" }}>
                                            {order.notes || <RateReviewIcon />}
                                          </i>
                                        </span>
                                      </div>
                                    )}
                                    <div className="order_quentity">
                                      <ul></ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="bill_order">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "20px",
                      gap: "60px",
                      marginRight: "50px",
                    }}
                  >
                    <p>Sub total</p>
                    <p>{`${calculateSubtotal().toFixed(2)}`}</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "20px",
                      gap: "20px",
                      marginRight: "50px",
                    }}
                  >
                    <p style={{ position: "relative", top: "8px" }}>Discount</p>
                    <input
                      type="number"
                      style={{
                        width: "80px",
                        height: "40px",
                        border: "1px solid black",
                        borderRadius: "10px",
                        fontSize: "20px",
                        padding: "5px",
                        textAlign: "right",
                      }}
                      value={isEditing ? discount || "" : discount} // Show empty string when editing & value is 0
                      onChange={handleCheck}
                      onFocus={() => setIsEditing(true)}
                      onBlur={handleBlur}
                    />
                  </div>

                  <hr style={{ border: "1px dotted black", margin: "8px 0" }} />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      bottom: "15px",
                      gap: "60px",
                      marginRight: "50px",
                    }}
                  >
                    <h3>Grand Total</h3>
                    <h3>{`${grandTotal.toFixed(2)}`}</h3>
                  </div>

                  <div className="btn_send">
                    <button
                      style={{ backgroundColor: "#fc5a5a" }}
                      onClick={handleCancelOrder}
                    >
                      ORDER CANCEL
                    </button>
                    <button
                      style={{ backgroundColor: "#67bb67" }}
                      onClick={handleUpdatOrder}
                    >
                      SEND ORDER
                    </button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      sx={{
                        "& .MuiDialog-paper": {
                          width: "400px",
                          maxWidth: "none",
                          borderRadius: "16px",
                        },
                      }}
                    >
                      <DialogTitle>Enter Customer Details</DialogTitle>
                      <DialogContent>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Customer Name"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                        <TextField
                          margin="dense"
                          label="Phone Number"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <TextField
                          margin="dense"
                          label="Table Number"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={tableNumber}
                          onChange={(e) => setTableNumber(e.target.value)}
                        />
                      </DialogContent>
                      <DialogActions
                        sx={{
                          gap: "30px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          style={{ width: "45%" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          style={{ width: "45%" }}
                        >
                          Add Customer
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div>
                    {/* <div>
                      <Dialog
                        open={openpop}
                        onClose={handleClosepop}
                        classes={{ paper: "dialogPaper" }}
                      >
                        <CloseIcon
                          style={{ marginLeft: "330px" }}
                          onClick={handleClosepop}
                        />
                        <DialogContent className="dialogContent">
                          <h2 className="dialogMessage">
                            Do you want to print?
                          </h2>
                          <div className="buttonContainer">
                            <button
                              className="cancelButton"
                              onClick={handleClosepop}
                            >
                              Cancel
                            </button>
                            <div>
                              {loading && <p>Loading...</p>}
                              {orderDetails.map((order) => (
                                <div key={order.Orderid}>
                                  <p>{order.CustomerName}</p>
                                  <button
                                    onClick={() => {
                                      setSelectedOrder(order); 
                                      setOpenpop(true); 
                                    }}
                                  >
                                    Select Order
                                  </button>
                                </div>
                              ))}

                              <div
                                id="print-section"
                                className="print-section"
                                style={{
                                  display: showPrintContent ? "block" : "none",
                                }}
                              >
                                {selectedOrder && printerDetails(selectedOrder)}
                              </div>

                              <button
                                className="printButton"
                                onClick={printpreview}
                              >
                                Print
                              </button>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={1000} />
    </div>
  );
};

export default Reshome;
