import React, { useState } from "react";
import "./Edit.css";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import CloseIcon from "@mui/icons-material/Close";
interface Props {
  id: string | undefined;
  Name: string;
  onDelete: (id: string) => void;
}
const DeleteProducts: React.FC<Props> = ({ id, Name, onDelete }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const Deleteproduct = () => {
    if (!id) return;
    api.delete(`/app/v1/pos/product/${id}`).then((res) => {
      toast.success("Product deleted successfully", {
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      setIsModalVisible(false);
      onDelete(id);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 100);
    });
  };
  const ConfirmButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };
  return (
    <div>
      {isModalVisible && (
        <div className="modals">
          <div className="overlays"></div>
          <div className="modal-contents">
            <div className="edit-popupscreen">
              <button
                onClick={() => setIsModalVisible(false)}
                className="cancel-icon-productdelete"
              >
                <CloseIcon />
              </button>
              <div
                className="addcategory-edit-product"
                style={{ marginTop: "-20px" }}
              >
                <h1 style={{ marginTop: "20px" }}>Confirm to Delete</h1>
                <p style={{ fontWeight: "bold", marginTop: "15px" }}>
                  Product Name : {Name}
                </p>
                <p style={{ marginTop: "20px" }}>
                  Are you sure you want to delete this product?
                </p>
                <div className="btnconfirm-edit">
                  <div onClick={() => setIsModalVisible(false)}>
                    <Buttons Buttondata={CancelButton} />
                  </div>
                  <div onClick={() => Deleteproduct()}>
                    <Buttons Buttondata={ConfirmButton} />
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteProducts;
