import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ViewListIcon from '@mui/icons-material/ViewList';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaymentIcon from '@mui/icons-material/Payment';
import Logo from '../../common-component/Logo';


interface Props {
  Resmenu: string;
}

const Resmenu: React.FC<Props> = ({ Resmenu }) => {
  const [clickmenu, setClickMenu] = useState(false);
  const [data, setData] = useState<any>(); 
  const [loading, setLoading] = useState(false);
  const openResmenu = useRef<HTMLDivElement>(null);
  const [overlayMenu, setOverlayMenu] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [background, setbackground] = useState<any>("");
  const [employees, setEmployees] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleDrawerToggle = () => {
    setOverlayMenu((prev) => !prev);
  };

  useEffect(() => {
      setLoading(true);
      const business = sessionStorage.getItem("organization_name")
      const storedColor = sessionStorage.getItem(`background_color${business}`);
      setData(business)
      const employee_login = sessionStorage.getItem("employee")
      if (employee_login) {
        console.log('employee_login');
        setEmployees(true)
      } else {
        setEmployees(false)
        console.log('not employee_login');
      }
      
      if (storedColor) {
        setbackground(storedColor);
        document.documentElement.style.setProperty('--color', storedColor);
      }
      const fontfamily = sessionStorage.getItem(`font_family${business}`);
      if (fontfamily) {
        document.documentElement.style.setProperty('--fontfamily', fontfamily);
      }
      setLoading(!true);
    }, [background])

  return (
    <div style={{ marginTop: "-60px" }}>
      <div className='Res_menu_container'>
      <div
          style={{
            width: clickmenu ? "120px" : "70px",
            height: "100vh",
            display: "flex",
            backgroundColor: "#e1e1e1",
            borderTopRightRadius: clickmenu ? "30px" : "25px",
            borderBottomRightRadius: clickmenu ? "30px" : "25px"
          }}
        >
          {clickmenu ? (
            <menu style={{ width: '120px', marginTop: '10px', display: 'flex', flexDirection: 'column' }} ref={openResmenu}>
              {data && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                  <div style={{ width: '70px', height: '70px', borderRadius: '60px' }}>
                    
                  </div>
                  <h3 style={{ marginBottom: '10px', width: '100px', paddingLeft: "5px", marginRight: '-10px' }}><i>{data}</i></h3>
                 <Link to={"/v1/Dashboard"} className="menu_Res_select" >
                    <IconButton >
                      <DashboardRoundedIcon
                        className="menu-icons"
                        style={{ fontSize: "30px",  }}
                        id="Inventory_icon"
                      />
                    </IconButton>
                    <p >Dashboard</p>
                  </Link>
                 <Link to={"/v1/Reshome"} className="menu_Res_select" >
                    <IconButton >
                      <HomeIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                        id="Inventory_icon"
                      />
                    </IconButton>
                    <p >Home page</p>
                  </Link>
                   <Link to={"/v1/Order"} className="menu_Res_select">
                    <IconButton>
                      <ListAltIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                    <p>Orders</p>
                  </Link>
                  {/* <Link to={"/v1/Resreservation"} id="Reservation_icon" className="menu_Res_select">
                    <IconButton>
                      <BookOnlineIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                    <p>Reservation</p>
                  </Link> */}
                  {/* <Link to={"/v1/Respayment"} id="payment_icon" className="menu_Res_select">
                    <IconButton>
                      <OutdoorGrillIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                    <p>Payment</p>
                  </Link> */}
                  {/* <Link to={"/v1/Reskitchen"} id="kitchen_icon" className="menu_Res_select">
                    <IconButton>
                      <OutdoorGrillIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                    <p>Kitchen</p>
                  </Link> */}
                </div>
              )}
            </menu>
          ) : (
            <menu
              style={{ width: "60px",marginLeft:"30px", display: "flex", flexDirection: "column", marginTop: '10px', alignItems: "center" }}
            >
              <div style={{ width: '70px',marginRight:"50px" ,height: '70px', borderRadius: '60px' }}>
                <Logo />
              </div>
              <Link to={"/v1/Resdeshboard"} className="menu_Res_select" >
                <Tooltip title="Dashboard" placement="left" arrow>
                  <IconButton >
                    <DashboardRoundedIcon
                      className="menu-icon"
                      id="Inventory_icon"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Link>
             <Link to={"/v1/Reshome"} className="menu_Res_select">
                <Tooltip title="Home" placement="left" arrow>
                  <IconButton>
                    <HomeIcon
                      className="menu-icon"
                      id="Home"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Link>
               <Link to={"/v1/Resorders"} className="menu_Res_select">
                <Tooltip title="orders" placement="left" arrow>
                  <IconButton>
                    <ListAltIcon
                      className="menu-icon"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Link>
              {/* <Link to={"/v1/Resreservation"} id="Shoppingcart_icon" className="menu_Res_select">
                <Tooltip title="Reservation" placement="left" arrow>
                  <IconButton>
                    <BookOnlineIcon
                      className="menu-icon"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Link> */}
              {/* <Link to={"/v1/ResKitchen"} id="Shoppingcart_icon" className="menu_Res_select">
                <Tooltip title="Kitchen" placement="left" arrow>
                  <IconButton>
                    <OutdoorGrillIcon
                      className="menu-icon"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Link> */}
                {/* <Link to={"/v1/Respayment"}  className="menu_Res_select">
                  <Tooltip title="Payment" placement="left" arrow>
                    <IconButton>
                      <PaymentIcon
                        className="menu-icon"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Link> */}
              <Link to={"/v1/Ressettings/Rescategory"}  className="menu_Res_select">
                  <Tooltip title="Settings" placement="left" arrow>
                    <IconButton>
                      <SettingsSuggestIcon
                        className="menu-icon"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Link>
            </menu>
          )}
          <button
            style={{
              marginTop: "50px",
              width: "20px",
              height: "20px",
              marginLeft: clickmenu ? "-5px" : '',
              backgroundColor: "white",
              borderRadius: "20px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              border: 'none'
            }}
            id="Expandbtn"
            onClick={() => setClickMenu(!clickmenu)}
          >
            {clickmenu ? <ArrowBackIosIcon style={{ fontSize: "20px", marginLeft: '6px' }} /> : <ArrowForwardIosIcon style={{ fontSize: "20px" }} />}
          </button>
        </div>
      </div>
      <div className="resmenu-container">
        <IconButton onClick={handleDrawerToggle}>
          {overlayMenu ? <CloseIcon style={{ fontSize: '30px' }} /> : <MenuIcon style={{ fontSize: '30px' }} />}
        </IconButton>
        {overlayMenu && (
          <div className="resmenuicons" ref={overlayRef}>
            <div className="res-overlay-content">
              <div style={{ width: '50px', height: '60px', borderRadius: '60px' }}>
                <Logo />
              </div>
              <Link to={"v1/Reshome"}>
                <Tooltip title="Home" placement="left" arrow>
                  <IconButton>
                    <HomeIcon style={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              </Link>
              <Link to="/Resdeshboard">
                <Tooltip title="Dashboard" placement="left" arrow>
                  <IconButton>
                    <DashboardRoundedIcon style={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              </Link>
              <Link to={"v1/Resorders"}>
                <Tooltip title="Orders" placement="left" arrow>
                  <IconButton>
                    <ListAltIcon style={{ fontSize: '30px' }} />
                  </IconButton>
                </Tooltip>
              </Link>
              <Link to={"/v1/Resreservation"} id="reservation_icon" className="menu_Res_select">
                <Tooltip title="Reservation" placement="left" arrow>
                  <IconButton>
                    <BookOnlineIcon
                      className="menu-icon"
                      style={{ fontSize: "30px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Link>
              {/* <Link to={"/v1/Respayment"} id="payment_icon" className="menu_Res_select">
                    <IconButton>
                      <OutdoorGrillIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                    <p>Payment</p>
                  </Link> */}
                  <Link to={"/v1/Reskitchen"} id="kitchen_icon" className="menu_Res_select">
                    <IconButton>
                      <OutdoorGrillIcon
                        className="menu-icons"
                        style={{ fontSize: "30px" }}
                      />
                    </IconButton>
                    <p>Kitchen</p>
                  </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Resmenu;
