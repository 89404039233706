import React, { ChangeEvent, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Order.css";
import { Flip, ToastContainer, toast } from "react-toastify";
import api from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
// import LoadingScreen from "../../common-component/LoadingScreen";
import PopupScreen from "./PopupScreen";
import LoadingScreen from "../../common-component/LoadingScreen";
import { IconButton, TextField } from "@mui/material";
import noimage from "../../../assets/laundrynoimage.jpg" 
interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}
interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ArabicName: string;
  ProductName: string;
  category_id: string;
  image_url: string;
}
function Category() {
  const [addproduct, setProducts] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [toggle, setToggle] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [selectedProducts, setSelectedProducts] = useState<Productbtn[]>([]);
  const [clickedButton, setClickedButton] = useState<string | null>(null);
  const [valueMap, setValueMap] = useState<{ [id: string]: number }>({});
  const [, setModalOpen] = useState(false);
  const [cartId, setCartId] = useState(null);
  const [dis_value, setdis_value] = useState<number>(0);
  const [condition, setcondition] = useState<any>(0);
  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const [selectedProductslist, setSelectedProductslist] = useState<
    Productbtn[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [modal, setModal] = useState(false);
  const navigation = useNavigate();
  const [background, setbackground] = useState<any>("");

  const backHome = (data: any) => {
    if (data === "false") {
      setModal(!modal);
    }
  };

  const Checkout = () => {
    setLoading(true);
    Savecart("close");
    // setTotalAmount(0);
    // setTotalQuantity(0);
    // setcondition(0);
    // setdis_value(0);
    if (totalAmount) {
      setModalOpen(true);
      setTimeout(() => {
        setModal(!modal);
      }, 1000);
    }
    setLoading(false);
  };
  const Deleted = (id: string, amount: string) => {
    setSelectedProductslist((prevList) =>
      prevList.filter((product) => product._id.$oid !== id)
    );

    const identifier = `${id}-${amount}`;
    setValueMap((prevMap) => {
      const updatedMap = { ...prevMap };
      delete updatedMap[identifier];
      return updatedMap;
    });

    const updatedTotalAmount = selectedProductslist
      .filter((product) => product._id.$oid !== id)
      .reduce((acc, product) => {
        const quantity = valueMap[`${product._id.$oid}-${product.Amount}`] || 1;
        return acc + parseFloat(product.Amount) * quantity;
      }, 0);

    const discountValue = condition || 0;
    const finalAmount = updatedTotalAmount - discountValue;

    setTotalAmount(updatedTotalAmount);
    setdis_value(finalAmount < 0 ? 0 : finalAmount);
    setcondition(0);
    setdis_value(0);
  };

  const addValue = (productId: string, amount: string) => {
    const identifier = `${productId}-${amount}`;
    console.log("okay", identifier);

    setValueMap((prevMap) => ({
      ...prevMap,
      [identifier]: (prevMap[identifier] || 1) + 1,
    }));
    setTotalQuantity((prevQuantity) => prevQuantity + 1);
    setTotalAmount((prevAmount) => {
      const newTotal = prevAmount + parseFloat(amount);
      const discountValue = condition || 0;
      const finalAmount = newTotal - discountValue;
      setdis_value(finalAmount < 0 ? 0 : finalAmount);
      return newTotal;
    });
  };
  const subValue = (productId: string, amount: string) => {
    const identifier = `${productId}-${amount}`;
    console.log("minus", identifier);

    setValueMap((prevMap) => {
      const updatedMap = {
        ...prevMap,
        [identifier]: Math.max((prevMap[identifier] || 1) - 1, 0),
      };
      if (updatedMap[identifier] === 0) {
        setSelectedProductslist((prevList) =>
          prevList.filter((product) => product.Amount !== amount)
        );
      }
      return updatedMap;
    });
    if (totalQuantity === 1) {
      setcondition(0);
    }
  };

  useEffect(() => {
    const updatedTotalQuantity = selectedProductslist.reduce((acc, product) => {
      return acc + (valueMap[`${product._id.$oid}-${product.Amount}`] || 1);
    }, 0);
    const updatedTotalAmount = selectedProductslist.reduce((acc, product) => {
      const quantity = valueMap[`${product._id.$oid}-${product.Amount}`] || 1;
      return acc + parseFloat(product.Amount) * quantity;
    }, 0);
    setTotalQuantity(updatedTotalQuantity);
    setTotalAmount(updatedTotalAmount);
    const discountValue = condition || 0;
    const finalAmount = updatedTotalAmount - discountValue;
    setdis_value(finalAmount < 0 ? 0 : finalAmount);
  }, [selectedProductslist, valueMap, condition]);
  const getCategory = () => {
    setLoading(true);
    api
      .get(`/app/v1/pos/category`)
      .then((res) => {
        console.log("Response", res);
        const categoryData: CategoryButton[] = res.data.result;
        setButtonNames(categoryData);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    // setcondition(0)
    getCategory();
    const business = sessionStorage.getItem("organization_name");
    const storedColor = sessionStorage.getItem(`background_color${business}`);
    if (storedColor) {
      setbackground(storedColor);
    }
    const fontfamily = sessionStorage.getItem(`font_family${business}`);
    if (fontfamily) {
      document.documentElement.style.setProperty("--fontfamily", fontfamily);
    }
  }, []);
  const handleButtonClick = (categoryId: string, category: string) => {
    setLoading(true);
    setProducts(true);
    api.get(`/app/v1/pos/product`).then((res) => {
      console.log("productgetapi", res);
      const products: Productbtn[] = res.data.result;
      const productcategory = products.filter(
        (products) => products.category_id === categoryId
      );
      setClickedButton(category);
      setSelectedProducts(productcategory);
    });
    setLoading(false);
  };

  const formatAmount = (amount: string | number): string => {
    const num = parseFloat(amount.toString());
    if (isNaN(num)) return "0.00";
    const roundedAmount = Math.round(num * 100) / 100;
    return roundedAmount.toFixed(2);
  };
  
  const openbom = (
    ProductId: string,
    ProductName: string,
    Amount: string,
    arabicword: string,
    category_id: string,
    image_url: string
  ) => {
    const productToAdd: Productbtn = {
      _id: { $oid: ProductId },
      ProductName: ProductName,
      Amount: Amount,
      ArabicName: arabicword,
      category_id: category_id,
      image_url: image_url,
    };
    const existingProductIndex = selectedProductslist.findIndex(
      (product) => product.ProductName === ProductName
    );
    if (existingProductIndex === -1) {
      setSelectedProductslist((prevList) => [...prevList, productToAdd]);
    } else {
      const identifier = `${ProductId}-${Amount}`;
      setValueMap((prevMap) => ({
        ...prevMap,
        [identifier]: (prevMap[identifier] || 1) + 1,
      }));
      setTotalQuantity((prevQuantity) => prevQuantity + 1);
      setTotalAmount((prevAmount) => prevAmount + parseFloat(Amount));
    }
  };
  const Savecart = async (checkout: string | null) => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    setLoading(true);

    if (totalAmount > 0) {
      const ordetData = {
        discount: condition,
        totalPrice: dis_value,
        products: selectedProductslist.map((product) => ({
          productId: product._id.$oid,
          productName: product.ProductName,
          amount: Number(product.Amount),
          arabicword: product.ArabicName,
          quantity: Number(
            valueMap[`${product._id.$oid}-${product.Amount}`] || 1
          ),
          totalPrice: Number(
            (valueMap[`${product._id.$oid}-${product.Amount}`] || 1) *
              parseFloat(product.Amount)
          ),
        })),
        totalAmount: totalAmount,
        Finalamount: dis_value ? dis_value : totalAmount,
        totalQuantity: totalQuantity,
        cartId: `${month}${day}${hours}${minutes}${seconds}`,
      };

      try {
        const res = await api.post(`/app/v1/pos/shopping_cart`, ordetData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Cart saved successfully:", res.data);
        const cartid = res.data._id;
        setCartId(cartid);

        if (checkout) {
          setToggle((prev) => !prev);
        } else {
          // await sleep(100);
          navigation(`/v1/cart/${cartid}`);
        }
      } catch (error) {
        console.error("Error saving cart:", error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Please select at least one product", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      setLoading(false);
    }
  };

  const handleclick = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (Number(value) >= totalAmount) {
      e.target.value = value.slice(0, -1);
      return;
    }
    if (value === "") {
      setcondition(0);
      setdis_value(totalAmount);
      return;
    }
    if (value.length > 1 && value.startsWith("0")) {
      value = value.replace(/^0+/, "");
      e.target.value = value;
    }
    const numericValue = Number(value);
    if (numericValue >= 0 && numericValue < totalAmount) {
      setcondition(numericValue);
      const dis = totalAmount - numericValue;
      setdis_value(dis);
    } else {
      setdis_value(totalAmount);
      setcondition(0);
    }
  };

  return (
    <div className="Homepage-Category" id="Homepage_category">
      <div className="Homepage-Category_2" id="Homepage_category_2">
        <div className="body-home" id="boby_home">
          <div>
            <div style={{ display: "flex", height: "40px" }}>
              <div className="Category-title" id="Category_title">
                <h2 style={{ marginLeft: "10px" }}>Category</h2>
              </div>
              <div className="search_home">
                <TextField
                  sx={{ position: "relative", bottom: "10px" }}
                  id="search-food-items"
                  label="Search the product"
                  variant="outlined"
                  className="search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="body-Category" id="boby_category">
              <div className="Category-name" id="category_name">
                {Array.isArray(buttonNames) &&
                  buttonNames.map((item) => (
                    <button
                      key={item._id.$oid}
                      className="c-p-view"
                      id="c_p_view"
                      style={{
                        backgroundColor:
                          clickedButton === item.CategoryName
                            ? `lightblue`
                            : background,
                      }}
                      onClick={() =>
                        handleButtonClick(item._id.$oid, item.CategoryName)
                      }
                    >
                      <h3>{item.CategoryName}</h3>
                    </button>
                  ))}
              </div>
              {/* <div className="body-Product" id="boby_product">
                {Array.isArray(selectedProducts) &&
                  selectedProducts.map((product: Productbtn) => (
                    <button
                      key={product._id.$oid}
                      className="Pbutton"
                      id="product_btn"
                      onClick={() =>
                        openbom(
                          product._id.$oid,
                          product.ProductName,
                          product.Amount,
                          product.ArabicName,
                          product.category_id
                        )
                      }
                    >
                      <h4
                        style={{
                          color: "#0071a4",
                          width: "99px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {product.ProductName}
                        <br />
                        {product.ArabicName}
                      </h4>
                      <br />
                      <h3 style={{ color: "#f61616" }}>{product.Amount}</h3>
                    </button>
                  ))}
                {addproduct ? (
                  ""
                ) : (
                  <div className="Welcome">
                    <h1 style={{ fontSize: "30px", margin: "20px" }}>
                      Welcome!
                    </h1>
                    <ul style={{ listStyleType: "square", fontSize: "22px" }}>
                      <li>
                        Explore our <b>CATEGORIES</b> to find exactly what
                        you’re looking for.
                      </li>
                      <li>
                        Check out our featured <b>PRODUCTS</b> for the latest
                        trends.
                      </li>
                      <li>
                        Need help? Reach out to our friendly support team
                        anytime.
                      </li>
                    </ul>
                    <h2>Get started now!</h2>
                    <p style={{ fontSize: "18px", margin: "15px" }}>
                      <b>
                        <i>
                          NOTE:- You should go to the settings page and add the
                          CATEGORIES or PRODUCTS.
                        </i>
                      </b>
                    </p>
                  </div>
                )}
              </div> */}

              <div>
                <div className="content-laundry">
                  <div className="food-grid-laundry">
                    {Array.isArray(selectedProducts) &&
                      selectedProducts
                        .filter((product) =>
                          product.ProductName.toLowerCase().includes(
                            searchQuery.toLowerCase()
                          )
                        )
                        .map((product: Productbtn) => (
                          <div
                            key={product._id.$oid}
                            className="food-items-laundary"
                            onClick={() =>
                              openbom(
                                product._id.$oid,
                                product.ProductName,
                                product.Amount,
                                product.ArabicName,
                                product.category_id,
                                product.image_url
                              )
                            }
                          >
                            <img
                              src={product.image_url || noimage}
                              alt={product.ProductName}
                              style={{ cursor: "pointer" }}
                            />
                            <div style={{display:"grid", width: "100%", margin:"10px 0px 0px 0px"}}>
                              <ul
                                 style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  textAlign: "center",
                                  padding: 0,
                                  listStyleType: "none",
                                }}
                              >
                                <li style={{fontSize:"12px"}}>{product.ProductName}</li>
                                <li style={{fontSize:"12px"}}>{product.ArabicName}</li>
                              </ul>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0px 0",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {product.Amount}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
                {addproduct ? (
                  ""
                ) : (
                  <div className="Welcome">
                    <h1 style={{ fontSize: "30px", margin: "20px" }}>
                      Welcome!
                    </h1>
                    <ul style={{ listStyleType: "square", fontSize: "22px" }}>
                      <li>
                        Explore our <b>CATEGORIES</b> to find exactly what
                        you’re looking for.
                      </li>
                      <li>
                        Check out our featured <b>PRODUCTS</b> for the latest
                        trends.
                      </li>
                      <li>
                        Need help? Reach out to our friendly support team
                        anytime.
                      </li>
                    </ul>
                    <h2>Get started now!</h2>
                    <p style={{ fontSize: "18px", margin: "15px" }}>
                      <b>
                        <i>
                          NOTE:- You should go to the settings page and add the
                          CATEGORIES or PRODUCTS.
                        </i>
                      </b>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* order_summart */}
          <div className="body-Order" id="boby_order">
            <div className="Order-title" id="order_summary">
              <h2 style={{ marginLeft: "10px" }}>Order summary</h2>
            </div>
            <div>
              <div
                className="calculate-view-options"
                id="calculate-view-options"
              >
                <div className="order-details" id="order_details">
                  {selectedProductslist.map((product: Productbtn) => (
                    <div key={product._id.$oid}>
                      <div
                        className="Product-name-amount"
                        id="Product-name-amount"
                      >
                        <h3 style={{ marginRight: "10px" }}>
                          {product.ProductName}
                          <br />
                          {product.ArabicName}
                        </h3>
                        <button
                          style={{
                            background: "none",
                            border: "none",
                          }}
                          onClick={() =>
                            Deleted(product._id.$oid, product.Amount)
                          }
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                      <div className="calculater" id="calculater">
                        <div className="Value" id="value">
                          <button
                            className="button"
                            onClick={() =>
                              subValue(product._id.$oid, product.Amount)
                            }
                          >
                            <RemoveIcon className="icon" />
                          </button>
                          <h1 className="Number">
                            {valueMap[
                              `${product._id.$oid}-${product.Amount}`
                            ] || 1}
                          </h1>
                          <button
                            className="button"
                            onClick={() =>
                              addValue(product._id.$oid, product.Amount)
                            }
                          >
                            <AddIcon className="icon" />
                          </button>
                        </div>
                        <h3
                          style={{
                            color: "#D70015",
                            marginLeft: "110px",
                            padding: "10px",
                          }}
                        >
                          {(
                            (valueMap[
                              `${product._id.$oid}-${product.Amount}`
                            ] || 1) * parseFloat(product.Amount)
                          ).toFixed(2)}{" "}
                          QAR
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="order-calculater">
                  <div className="Bom">
                    <p className="fonts">Sub total</p>
                    <p className="fonts_value">
                      <h2 style={{ color: "black" }}>:</h2>
                      <p>
                        {(Math.round(totalAmount * 100) / 100).toFixed(2)} QAR
                      </p>
                    </p>
                  </div>
                  <div className="Bom">
                    <p className="fonts">Total quantity</p>
                    <p className="fonts_value">
                      <h2 style={{ color: "black" }}>:</h2>
                      <p>{totalQuantity} Nos</p>
                    </p>
                  </div>
                  <div className="Bom">
                    <p className="fonts">Discount </p>
                    <p className="fonts_value">
                      <h2 style={{ color: "black" }}>:</h2>
                      <input
                        className="discount_type"
                        value={condition}
                        onChange={handleclick}
                        type="number"
                      />
                    </p>
                  </div>
                  <div className="Bom">
                    <p className="fonts">Total (Incl Discount)</p>
                    <p className="fonts_value">
                      <h2 style={{ color: "black" }}>:</h2>
                      {dis_value ? (
                        <p>{formatAmount(dis_value)} QAR</p>
                      ) : (
                        <p>{formatAmount(totalAmount)} QAR</p>
                      )}
                    </p>
                  </div>
                  <div className="bom-overview">
                    {/* <button className="BtnS" onClick={() => Savecart(null)}> */}
                    <button
                      className="neworder"
                      onClick={() => window.location.reload()}
                    >
                      NEW ORDER
                    </button>
                    <button className="checkout" onClick={Checkout}>
                      CHECKOUT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <div className="modal">
          <div className="overlay">
          <div className="modal-content">
            <PopupScreen cartId={cartId} backHome={backHome} />
          </div>
          </div>
        </div>
      )}
      {Loading ? <LoadingScreen /> : ""}
      <ToastContainer />
    </div>
  );
}

export default Category;
