import React, { useEffect, useState } from "react";
import "./CarWash.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { log } from "console";
// import CarHeaderBox from "./CarHeaderBox";
import api from "../../axiosConfig";
import CarMenuBox from "./CarMenuBox";
import CarHeaderBox from "./CarHeaderBox";

interface Order {
  _id: { $oid: string };
  customername: string;
  customerphone: string | number;
  car_number: string;
  date: string;
  totalPrice: string;
  customerDedails: {
    cusName: string;
    cusPhone: string | number;
    cusCar: string[];
    Date: string;
    totalPrice: string;
  };
  serviceDetails: Array<{
    carNumber: string;
    productDetails: Array<{
      cName: string;
      price: string; 
      product: string;
    }>;
    totalPrice: string | number;
  }>;
  subtotal?: number;
  totaltax?: number;
  discount?: number;
  total?: number;
}

interface CarDetailState {
  _id: { $oid: string };
  CategoryName: string;
}

const CarPaymentDetails = () => {
  const [orderdata, setOrderdata] = useState<Order | null>(null);
  const [totalPrice, setTotalPrice] = useState<string>("");
  const [discount, setdiscount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [getdata, setGetdata] = useState<CarDetailState[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
    const [time, setTime] = useState({
      hours: "",
      minutes: "",
      seconds: "",
      day: "",
      month: "",
      year: ""
    })

  useEffect(() => {
    date()
    const fetchOrderData = async () => {
      if (!id) {
        setError("Invalid order ID.");
        // setLoading(false);
        return;
      }
      try {
        const res = await api.get(`/app/v1/pos/customer/${id}`);
        if (res.data && res.data.result) {
          setOrderdata(res.data.result);
          console.log(res.data.result)
          setTotalPrice(res.data.result.totalPrice || "");
          console.log("navee",res)
        } else {
          setError("Unexpected response structure.");
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
        setError("Failed to fetch order details. Please try again later.");
      } finally {
        // setLoading(false);
      }
      api.get(`/app/v1/pos/category`).then((res) => {
      const cardetail: CarDetailState[] = res.data.result;
      setGetdata(cardetail);
      console.log("gobires", res);
    });
    };

    fetchOrderData();
  }, [id]);
   
  const date = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: day,
      month: month,
      year: year,
    })
  }

  let pricevalue = Number(orderdata?.customerDedails.totalPrice) || 0;
  let discountvalue = Number(discount) || 0;

  const finalprice = pricevalue - discountvalue;

  // const passdata = () => {

  //   const update = {
  //     customerDedails :{
  //       cusName:orderdata?.customername || "",
  //       cusPhone:orderdata?.customerphone || "",
  //       cusCar:[orderdata?.car_number || ""],
  //       Date: orderdata?.date || "",
  //       totalPrice: finalprice || "",
  //     },
  //     // productDetails:newpoint
  //   };
  //   console.log(update)

  //   api
  //     .put(`/car_wash/car_wash_order`,update)
  //     .then((res) => {
  //       console.log("Car details updated successfully:", res);
  //       if (res.data && id) {
  //         // navigate(`/v1/car-message-conformation?id=${id}`);
  //       } else {
  //         console.error("Error: Missing '_id' in API response");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error updating car details:", err);
  //     });
  // };

  // const carWashProducts = orderdata?.productDetails.filter(
  //   (item) => item.cName.trim().toLowerCase() === "car wash"
  // );

  // const carPolishProducts = orderdata?.productDetails.filter(
  //   (item) => item.cName.trim().toLowerCase() === "car polish"
  // );

  // const naviprogram = () => {
  //   navigate("/v1/car-message-conformation");
  // };
  const carordersuccess = () => {
    const currentDate = new Date();
    const orderId = `${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}${String(currentDate.getDate()).padStart(2, "0")}${String(
      currentDate.getHours()
    ).padStart(2, "0")}${String(currentDate.getMinutes()).padStart(
      2,
      "0"
    )}${String(currentDate.getSeconds()).padStart(2, "0")}`;
    
    const carorder = {
      Orderid: orderId,
      productDetails:orderdata?.serviceDetails[0]?.productDetails,
      Customerid: orderdata?._id.$oid,
      customer: orderdata?.customerDedails,
      subtotal: finalprice,
      discount: Number(discount),
      Paymentmode: "Cash",
      OrderStatus: "Confirm",
      Orderdate: `${time.year}-${time.month}-${time.day}`,
      Ordertime: `${time.hours}:${time.minutes}`,
    };
    console.log("productDetails", orderdata?.serviceDetails[0]?.productDetails);

    api
      .post(`/app/v1/pos/order`, carorder)
      .then((res) => {
        console.log("Car order confirmed successfully:", res);
        // navigate(`/v1/car-message-conformation?id=${res.data.Id}`);
      })
      .catch((error) => {
        console.log(error);
      });

    const update = {
      customerDedails: {
        cusName: orderdata?.customername || "",
        cusPhone: orderdata?.customerphone || "",
        cusCar: [orderdata?.car_number || ""],
        Date: orderdata?.date || "",
        totalPrice: finalprice + discount || "",
        Balence: finalprice || 0,
        Discount: discount,
      },
    };
    console.log(update);

    api
      .put(`/app/v1/pos/customer/${id}`, update)
      .then((res) => {
        console.log("Car details updated successfully:", res);
        if (res.data && id) {
          navigate(`/v1/car-message-conformation?id=${id}`);
        } else {
          console.error("Error: Missing '_id' in API response");
        }
      })
      .catch((err) => {
        console.error("Error updating car details:", err);
      });
  };

  const handleback =()=>{
    navigate(-1);
  }
  return (
    <div>
      <CarHeaderBox Carheader={"Order details"} />
      <div className="overbody-cash-payment">
        <div className="inside_image">
          <div style={{ width: "100%", display: "flex" }}>
            <CarMenuBox />
            <div style={{ display: "flex", margin: "20px 0px 0px 230px" }}>
              <div className="payment-section">
                <div className="payment-bar">
                  <div className="radio-content" style={{display:"flex"}}>
                    <div style={{position:"relative",right:"100px"}} onClick={handleback}>

                  <ArrowBackIcon style={{width:"70px",height:"30px"}}/> 
                    </div>
                    <div>

                    <h3 style={{ color: "green" }}>
                      Please verify your payment information and product details
                    </h3>
                    </div>
                  </div>
                </div>
                <div className="pay-process-flow-check">
                  <div className="full-file-content">
                    <div className="carorder-datails-flow">
                      <ul>
                        <li>
                          <span className="label">Customer Name</span>
                          <span className="value">
                            {orderdata?.customerDedails.cusName}
                          </span>
                        </li>
                        <li>
                          <span className="label">Car Number</span>
                          <span className="value">
                            {orderdata?.customerDedails.cusCar}
                          </span>
                        </li>
                        <li>
                          <span className="label">Phone Number</span>
                          <span className="value">
                            {orderdata?.customerDedails.cusPhone}
                          </span>
                        </li>
                        <li>
                          <span className="label">Date</span>
                          <span className="value">
                            {orderdata?.customerDedails.Date}
                          </span>
                        </li>
                        <li>
                          <span className="label">Payment Type</span>
                          <span className="value">Cash</span>
                        </li>
                        <li>
                          <span className="label">Total Amount</span>
                          <span className="value">
                            {orderdata?.customerDedails.totalPrice}
                          </span>
                        </li>
                        <li>
                          <span className="label">Discount</span>
                          <span>
                            <input
                              style={{ padding: "10px", fontSize: "15px" }}
                              type="number"
                              className="value"
                              onChange={(e) =>
                                setdiscount(Number(e.target.value) || 0)
                              }
                            />
                          </span>
                        </li>
                        <li>
                          <span className="label">Balance</span>
                          <span className="value">{finalprice}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="service-plan-details">
                      <h3>Types of Service</h3>
                      <div
                        style={{
                          overflowY: "auto",
                          maxHeight: "344px",
                          border: "1px solid #ddd",
                        }}
                      >
                        {getdata?.map((category) => {
                          const filteredProducts =
                            orderdata?.serviceDetails?.flatMap((service) =>
                              service.productDetails.filter(
                                (product) =>
                                  product.cName.trim().toLowerCase() ===
                                  category.CategoryName.trim().toLowerCase()
                              )
                            ) || [];

                          return (
                            <div
                              style={{ marginBottom: "20px" }}
                              key={category.CategoryName}
                            >
                              <h4>{category.CategoryName}</h4>
                              <table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                  marginBottom: "10px",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    {/* <th>Car No</th> */}
                                    <th>Type</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredProducts.length > 0 ? (
                                    filteredProducts.map((product, index) => (
                                      <tr key={index}>
                                        <td>{index+1}</td>
                                        {/* <td>{orderdata?.serviceDetails.map(i => i.carNumber)}</td> */}

                                        <td>{product.product}</td>
                                        <td>{product.price}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={4}
                                        style={{ textAlign: "center" }}
                                      >
                                        No products available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="button-structure">
                    <button>CANCEL</button>
                    <button className="pay-button" onClick={carordersuccess}>
                      CONFIRM
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarPaymentDetails;