import React from 'react'
import './Ressetting.css'
import { Link } from 'react-router-dom'

const Ressetting = () => {
  return (
    
    <div className='header_bar'>
    <div className='header_menu'>
      <ul>
        < Link to={"/v1/Ressettings/Resbranch"}> Branches
        </Link>
        <Link to={"/v1/Ressettings/Resuser"}>Users</Link>
        <Link to={"/v1/Ressettings/Rescategory"}>
        Categories
        </Link>
        < Link to={"/v1/Ressettings/Resaddmenu"}> Products
        </Link>
        <Link to={"/v1/Ressettings/Rescustomer"}>Customer</Link>
        < Link to={"/v1/Ressettings/Configurations"}> Configurations
        </Link>
      </ul>
    </div>
  </div>
  )
}

export default Ressetting