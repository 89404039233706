import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import "./Master.css";
import api from "../../axiosConfig";

interface Master {
  _id: { $oid: string };
  user_name: string;
  phone_number: string;
  organization_name: string;
  email_id: string;
  employee_name: string;
  date_of_joined: string;
}

function MasterHome() {
  const [masterData, setMasterData] = useState<Master[]>([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   document.title = "MasterHome - iMetanic";
  //   const business = sessionStorage.getItem("organization_name");
  //   console.log("Business name from session storage:", business);
  //   fetchMasterData(business);
  // }, []);

  // const fetchMasterData = (business: string | null) => {
  //   api
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/pos/master`, {
  //       params: { organization_name: business },
  //     })
  //     .then((res) => {
  //       console.log("API response:", res.data);
  //       const masters: Master[] = res.data.result;
  //       const filteredMasters = masters.filter((master) => master.user_name !== "iMetanic");
  //       const filtered_employee = filteredMasters.filter((master) => !master.employee_name);
  //       setMasterData(filtered_employee);
  //     })
  // };

  const handleSignOut = () => {
    sessionStorage.removeItem("jsonwebtoken");
    navigate("/");
  };

  return (
    <div>
      <div className="Master_header">
        <Typography variant="h4" style={{ marginRight: "auto" }}>
          IMetanic
        </Typography>
        <Button style={{backgroundColor:"red"}} className="signout_btn" onClick={handleSignOut}>
          Sign Out
        </Button>
      </div>
      <TableContainer component={Paper} className="master_table_container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Business Name</TableCell>
              <TableCell>User Name</TableCell>
              {/* <TableCell>Employee Name</TableCell> */}
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date of Joined</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masterData.length > 0 ? (
              masterData.map((master) => (
                <TableRow key={master._id.$oid}>
                  <TableCell>{master.organization_name}</TableCell>
                  <TableCell>{master.user_name}</TableCell>
                  {/* <TableCell>{master.employee_name}</TableCell> */}
                  <TableCell>{master.phone_number}</TableCell>
                  <TableCell>{master.email_id}</TableCell>
                  <TableCell>{master.date_of_joined}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No master data available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default MasterHome;
