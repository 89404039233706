import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Snackbar,
  CircularProgress,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Print, Cancel } from "@mui/icons-material";
import api from "../../axiosConfig";
// import CarHeaderBox from "../../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../components/car-wash/CarMenuBox";
import CarHeaderBox from "../../components/car-wash/CarHeaderBox";

interface Item {
  amount: string;
  category: string;
  itemId: number;
  itemName: string;
  tax: string;
}
interface product {
  product: string;
  CarproductId: number;
  category: string;
  cName: string;
  price: number;
  tax: number;
}

interface Order {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  car_details_id: string;
  Paymentmode: string;
  Orderdate: string;
  Ordertime: string;
  productDetails: product[];
  order: {
    items: Item[];
    total: string;
    totaltax: string;
  };
  subtotal: number;
  discount: number;
  totalQuantity: number;
  Orderid: number;
  customer: {
    cusName: string;
    cusPhone: string | number;
    cusCar: string[];
  };
  payments?: { ReceivedAmount: number; Paymentmethod: string }[]; 
  totalAmount: string;
}

const CarOrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const { orderid } = useParams<{ orderid: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [status, setStatus] = useState<string>("Confirm");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [openCashModal, setOpenCashModal] = useState(false); 
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [employeesData, setemployeesData] = useState(false);
  const [receivedPrice, setReceivedPrice] = useState<number>(0);  

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/app/v1/pos/order/${orderid}`);
        setOrder(response.data.result || response.data);
        setStatus(response.data.result?.OrderStatus || 'Confirm');
      } catch (err) {
        console.error(err);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };
    fetchOrder();
  }, [orderid]);

  useEffect(() => {
    const employee = () => {
      const employeeData = sessionStorage.getItem('employee')
      if (employeeData) {
        setemployeesData(true);
      } else {
        setemployeesData(false);
      }
    }
    employee()
  }, []);

  const handlePaymentMethodChange = (event: any) => {
    const selectedMethod = event.target.value;
    setPaymentMethod(selectedMethod);
    if (selectedMethod === "Cash") {
      setOpenCashModal(true);
      setOpenCreditModal(false);
    } else if (selectedMethod === "Buy on Credit") {
      setOpenCreditModal(true);
      setOpenCashModal(false);
    }
  };

  const handleCloseModal = () => {
    setOpenCashModal(false);
    setOpenCreditModal(false);
    setPaymentMethod("");
  };

  const handleReceivedPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);

    if (!isNaN(value) && value <= (order?.subtotal || 0)) {
      setReceivedPrice(value);
    } else if (value > (order?.subtotal || 0)) {
      setReceivedPrice(order?.subtotal || 0);
    } else {
      setReceivedPrice(0);
    }
  };

  const remainingPrice = order?.subtotal && receivedPrice >= 0
    ? order.subtotal - receivedPrice
    : 0;

    const handleStatusChange = (event: any) => {
      const newStatus = event.target.value;
      setStatus(newStatus);
      if (newStatus !== "Pending") {
        setPaymentMethod("");
      }
      try {
        api.put(`/app/v1/pos/order/${orderid}`, { OrderStatus: newStatus });
        setError(null);
        console.log("Status updated successfully");
      } catch (err) {
        console.error("Failed to update status", err);
        setError("Failed to update the order status");
      }
    };
    
    const cancelButton = {
      color: "red",
      name: "Cancel",
    };
    const printReceipt = {
      color: "blue",
      name: "Print receipt",
      textColor: "white"
    }
    const handleConfirmPayment = (Payment: string) => {
      if (!order) {
        console.error("No order found");
        return;
      }
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");

      const previousReceivedAmount = order?.payments && order.payments.length > 0
        ? order.payments[0].ReceivedAmount
        : 0;
  
      const TOTALAMOUNT = Number(order?.totalAmount);
      const RESAMOUNT = previousReceivedAmount + receivedPrice;
  
      let ReceivedAmount = 0;
      let RemainingAmount = 0;
  
      if (TOTALAMOUNT >= RESAMOUNT) {
        ReceivedAmount = RESAMOUNT;
        RemainingAmount = TOTALAMOUNT - RESAMOUNT;
      } else {
        ReceivedAmount = TOTALAMOUNT;
        RemainingAmount = 0;
      }
  
      const update = {
        Finalamount: TOTALAMOUNT,
        RemainingAmount: RemainingAmount,
        OrderStatus: "Delivered",
        DeliveryDate: `${year}-${month}-${day}`,
        DeliveryTime: `${hours}:${minutes}:${seconds}`,
        Paymentmode: paymentMethod,
        payments: [
          {
            Paymentmethod: Payment,
            ReceivedAmount: ReceivedAmount,
          },
        ],
      };
      api
        .patch(`/app/v1/pos/order/${orderid}`, update)
        .then((res) => {
          setStatus("Delivered");
          setOrder(res.data.result);
          console.log("Order updated successfully");
          api.get(`/app/v1/pos/order/${orderid}`).then((res) => {
            setOrder(res.data.result);
          });
        })
        .catch((err) => {
          console.error("Error updating order:", err);
          setError("Failed to update the order");
        });
        setOpenCashModal(false);
        setOpenCreditModal(false);
    };
    
  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Order details" />
      <div style={{ display: "flex" }}>
        <CarMenuBox />
        <div style={{ margin: "20px", width: "100%" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={4}>
              {/* Order Details Table */}
              <Grid item xs={12} md={8}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Product Details
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ zIndex: '0' }}>S.no</TableCell>
                            <TableCell sx={{ zIndex: '0' }}>Product name</TableCell>
                            <TableCell sx={{ zIndex: '0' }}>Category</TableCell>
                            <TableCell sx={{ zIndex: '0' }}>Price (QAR)</TableCell>
                            <TableCell sx={{ zIndex: '0' }}>Tax (QAR)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order?.productDetails && order.productDetails.length > 0 ? (
                            order.productDetails.map((product, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{product.product}</TableCell>
                                <TableCell>{product.cName}</TableCell>
                                <TableCell>{product.price}</TableCell>
                                <TableCell>{product.tax}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5}>No products available</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              {/* Order Summary Section */}
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <strong>Order Summary</strong>
                      <div>
                        <Typography component="span" sx={{ marginRight: '16px' }}><strong>Date:</strong> {order?.Orderdate}</Typography>
                        <Typography component="span"><strong>Time:</strong> {order?.Ordertime}</Typography>
                      </div>
                    </Typography>
                    <Box mb={2} sx={{ padding: '16px', border: '1px solid #ccc', borderRadius: '8px' }}>
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ flex: 1, textAlign: 'left' }}><strong>Order ID</strong></span>
                        <span style={{ marginRight: '20px' }}>:</span>
                        <span style={{ flex: 1, textAlign: 'left' }}>{orderid}</span>
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ flex: 1, textAlign: 'left' }}><strong>Name</strong></span>
                        <span style={{ marginRight: '20px' }}>:</span>
                        <span style={{ flex: 1, textAlign: 'left' }}>{order?.customer?.cusName || "N/A"}</span>
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ flex: 1, textAlign: 'left' }}><strong>Discount</strong></span>
                        <span style={{ marginRight: '20px' }}>:</span>
                        <span style={{ flex: 1, textAlign: 'left' }}>{order?.discount || "0.00"} QAR</span>
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ flex: 1, textAlign: 'left' }}><strong>Subtotal</strong></span>
                        <span style={{ marginRight: '20px' }}>:</span>
                        <span style={{ flex: 1, textAlign: 'left' }}>{order?.subtotal || "0.00"} QAR</span>
                      </Typography>
                      <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ flex: 1, textAlign: 'left' }}><strong>Payment mode</strong></span>
                        <span style={{ marginRight: '20px' }}>:</span>
                        <span style={{ flex: 1, textAlign: 'left' }}>{order?.Paymentmode}</span>
                      </Typography>
                    </Box>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="order-status-label" style={{ color: 'blue' }} >Order status</InputLabel>
                      <Select
                        labelId="order-status-label"
                        value={status}
                        onChange={handleStatusChange}
                        label="Order status"
                        sx={{
                          "& .MuiInputBase-root": {
                            paddingTop: '16px',
                          }
                        }}
                      >
                        <MenuItem value="Confirm">Confirm</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Delivered">Delivered</MenuItem>
                      </Select>
                    </FormControl>
                    {status === "Pending" && (
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={paymentMethod}
                          onChange={handlePaymentMethodChange}
                        //row
                        >
                          <Box display="flex" alignItems="center" marginLeft='10px'>
                            <FormControlLabel value="Cash" control={<Radio />} label="Cash" disabled={paymentMethod !== "" && paymentMethod !== "Cash"} />
                            <FormControlLabel value="Buy on Credit" control={<Radio />} label="Buy on Credit" disabled={paymentMethod !== "" && paymentMethod !== "Buy on Credit"} />
                            <FormControlLabel value="Card" control={<Radio />} label="Card" disabled={true} />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "16px",
                        gap: '40px'
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<Print />}
                        style={{
                          textTransform: 'none',
                          color: 'white',
                        }}
                      >
                        {printReceipt.name}
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<Cancel />}
                        style={{
                          color: cancelButton.color,
                          textTransform: 'none',
                        }}
                        onClick={() => navigate("/v1/order-list")}
                      >
                        {cancelButton.name}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      {openCashModal && (
        <div style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div className="cash">
            <div className="cash-header">
              Payment method for {paymentMethod}
            </div>
            <div className="order-info">
              <div className="order-id"> Order ID:
                <div className="order-id-value">{orderid}</div>
              </div>
              <div className="order-time-date">
                <div className="order-detail">
                  <span className="label">Time</span>
                  <span className="colon">:</span>
                  <span className="value">{order?.Ordertime}</span>
                </div>
                <div className="order-detail">
                  <span className="label">Date</span>
                  <span className="colon">:</span>
                  <span className="value">{order?.Orderdate}</span>
                </div>
              </div>
            </div>
            <div className="cash-details">
              <div className="cash-detail">
                <span className="label">Customer name</span>
                <span className="colon">:</span>
                <span className="value">{order?.customer?.cusName}</span>
              </div>
              <div className="cash-detail">
                <span className="label">Car number</span>
                <span className="colon">:</span>
                <span className="value">{order?.customer?.cusCar}</span>
              </div>
              <div className="cash-detail">
                <span className="label">Phone number</span>
                <span className="colon">:</span>
                <span className="value">{order?.customer?.cusPhone}</span>
              </div>
              <div className="cash-detail">
                <span className="label">Payment type</span>
                <span className="colon">:</span>
                <span className="value">{paymentMethod}</span>
              </div>
              <div className="cash-detail">
                <span className="label">Discount</span>
                <span className="colon">:</span>
                <span className="value">{order?.discount} QAR</span>
              </div>
              <div className="cash-detail">
                <span className="label">Total amount</span>
                <span className="colon">:</span>
                <span className="value">{order?.subtotal} QAR</span>
              </div>
              <div className="cash-detail">
                <span className="label">Received price</span>
                <span className="colon">:</span>
                <input
                  type="number"
                  value={receivedPrice || ""}
                  onChange={handleReceivedPriceChange}
                  className="received-input"
                  placeholder="Enter received price"
                />
              </div>
              <div className="cash-detail">
                <span className="label">Remaining price</span>
                <span className="colon">:</span>
                <span className="value"> {remainingPrice.toFixed(2)} QAR</span>
              </div>
            </div>
            <div className="cash-actions">
              <Button onClick={handleCloseModal} className="Cancel-btn">Cancel</Button>
              <Button onClick={() =>  handleConfirmPayment(paymentMethod)} className="Confirm-btn">Confirm Payment</Button>
            </div>
          </div>
        </div>
      )}

      {/* Buy on Credit Payment Modal */}
      {openCreditModal && (
        <div style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div className="buy_on_credit">
            <div className="buy_on_credit-header">
              Payment method for Buy on Credit
            </div>
            <div className="order-infos">
              <div className="order-id"> Order ID:
                <div className="order-id-value">{orderid}</div>
              </div>
              <div className="order-time-dates">
                <div className="order-detail">
                  <span className="label">Time</span>
                  <span className="colon">:</span>
                  <span className="value">{order?.Ordertime}</span>
                </div>
                <div className="order-detail">
                  <span className="label">Date</span>
                  <span className="colon">:</span>
                  <span className="value">{order?.Orderdate}</span>
                </div>
              </div>
            </div>
            <div className="buy_on_credit-details">
              <div className="buy_on_credit-detail">
                <span className="label">Customer name</span>
                <span className="colon">:</span>
                <span className="value">{order?.customer?.cusName}</span>
              </div>
              <div className="buy_on_credit-detail">
                <span className="label">Car number</span>
                <span className="colon">:</span>
                <span className="value">{order?.customer?.cusCar}</span>
              </div>
              <div className="buy_on_credit-detail">
                <span className="label">Phone number</span>
                <span className="colon">:</span>
                <span className="value">{order?.customer?.cusPhone}</span>
              </div>
              <div className="buy_on_credit-detail">
                <span className="label">Payment type</span>
                <span className="colon">:</span>
                <span className="value">{paymentMethod}</span>
              </div>
              <div className="buy_on_credit-detail">
                <span className="label">Discount</span>
                <span className="colon">:</span>
                <span className="value">{order?.discount} QAR</span>
              </div>
              <div className="buy_on_credit-detail">
                <span className="label">Total amount</span>
                <span className="colon">:</span>
                <span className="value">{order?.subtotal} QAR</span>
              </div>
            </div>
            <div className="buy_on_credit-actions">
              <Button onClick={handleCloseModal} className="Cancel_btn">Cancel</Button>
              <Button onClick={() => handleConfirmPayment(paymentMethod)} className="Confirm_btn">Confirm Payment</Button>
            </div>
          </div>
        </div>
      )}
      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />
      )}
    </div>
  );
};

export default CarOrderDetails;
