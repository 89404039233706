import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Editcustomer from "./EditCustomer";
import Deletescreen from "./DeleteScreen";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Editmenu from "./EditMenu";
import api from "../../axiosConfig";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";
import Buttons from "../../common-component/Buttons";
import { Flip, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { AxiosError } from "axios";
import Resheader from "../../components/Restaurant/Resheader";
import CarHeaderBox from "../../components/car-wash/CarHeaderBox";
import Resmenu from "../../components/Restaurant/Resmenu";
import CarMenuBox from "../../components/car-wash/CarMenuBox";

interface Customer_data {
  _id: {
    $oid: string;
  };
  Name: string;
  phone_number: string;
  alternativenumber: string;
  Email: string;
  Address: {
    customeraddress1: string;
    customeraddress2: string;
    customeraddress3: string;
  };
}

const CustomerData = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [customerdata, setcustomerdata] = useState<Customer_data[]>([]);
  const [filteredData, setFilteredData] = useState<Customer_data[]>([]);
  const [openscreen, setopenscreen] = useState<boolean>(false);
  const [deletescreen, setdeletescreen] = useState<boolean>(false);
  const [openid, setopenid] = useState("");
  const [Name, setName] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [alternative, setAlternative] = useState<string>("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [address2, setaddress2] = useState("");
  const [address3, setaddress3] = useState("");
  const [data, setdata] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [error, setError] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [cusname, setCutname] = useState<string>("");
  const [cusphone, setCutphone] = useState<string>("");
  const [cusemail, setCutemail] = useState<string>("");
  const [cusaddress, setCutaddress] = useState<string>("");
  const [cusaddress2, setCutaddress2] = useState<string>("");
  const [cusaddress3, setCutaddress3] = useState<string>("");
  const [cusalterphone, setalterphone] = useState("");
  const [visible, setvisible] = useState<boolean>(false);
  const [phonenumbererror, setphonenumbererror] = useState<boolean>(false);
  const [bussinessType, setBussinessType] = useState<string>("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchGetCustomer = async () => {
    try {
      const response = await api.get("/app/v1/pos/customer");
      console.log("API Response:", response.data);

      const customerdatas: Customer_data[] = response.data.result.map(
        (customer: { _id: { $oid: any } }) => ({
          ...customer,
          _id: { $oid: customer._id?.$oid || customer._id },
        })
      );

      setcustomerdata(customerdatas);
      setFilteredData(customerdatas);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    fetchGetCustomer();
  }, []);

  const refreshCustomerList = () => {
    fetchGetCustomer();
  };

  const closeEditcustomer = () => {
    setopenscreen(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g, "");
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredData(customerdata);
      setError("");
    } else {
      const formattedValue = value.toLowerCase().trim();
      const filtered = customerdata.filter(
        (item) =>
          (item.phone_number &&
            item.phone_number.toLowerCase().includes(formattedValue)) ||
          item.alternativenumber.toLowerCase().includes(formattedValue) ||
          (item.Name && item.Name.toLowerCase().includes(formattedValue))
      );
      if (filtered.length === 0) {
        setError("No results found.");
      } else {
        setError("");
      }
      setFilteredData(filtered);
    }
  };
  const handleclose = () => {
    setOpen(false);
  };

  const openscreepopulay = () => {
    setOpen(!open);
  };

  const addCustomer = async () => {
    try {
      const customerData = {
        Name: cusname,
        phone_number: cusphone,
        alternativenumber: cusalterphone,
        Email: cusemail,
        Address: {
          street: cusaddress,
          street2: cusaddress2,
          street3: cusaddress3,
        },
      };

      const response = await api.post("/app/v1/pos/customer", customerData);
      if (response.data) {
        toast.success("Customer added successfully", {
          position: "top-right",
          autoClose: 2100,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
        setOpen(false);
        fetchGetCustomer();
      }
    } catch (error) {
      const err = error as AxiosError<{ message?: string }>;

      if (err.response && err.response.data) {
        if (err.response.data.message === "Phone Number already Exists") {
          toast.error("Phone number already exists!", {
            position: "top-right",
          });
        } else if (err.response.data.message === "Email already Exists") {
          toast.error("Email already exists!", { position: "top-right" });
        }
      } else {
        console.log("The post response error:", error);
      }
    }
  };

  const closedatamodel = () => {
    setOpen(false);
    setCutname("");
    setCutphone("");
    setCutemail("");
    setalterphone("");
    setCutaddress("");
    setCutaddress2("");
    setCutaddress3("");
  };

  const AddCustomerBB = {
    error: "blue",
    name: "Add Customer",
  };

  const renderButton = () => {
    if (visible) {
      return (
        <div
          onClick={() => {
            addCustomer();
            closedatamodel();
          }}
        >
          <Buttons Buttondata={Confirm} />
        </div>
      );
    } else {
      return (
        <div>
          <Buttons Buttondata={AddCustomerBB} />
        </div>
      );
    }
  };

  useEffect(() => {
    if (cusname && cusphone.length > 7) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [cusname, cusphone.length]);

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    setCutphone(value);
    if (phonenumbererror) {
      setphonenumbererror(false);
    }
  };

  const handleAlternativePhoneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    setalterphone(value);
    if (phonenumbererror) {
      setphonenumbererror(false);
    }
  };

  const tableformet = () => {
    if (filteredData === null) {
      return <h3> No result found</h3>;
    }
    return (
      <div className="customer_Table">
        <Paper sx={{ width: "89vw", zIndex: "0" }} id="Table">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Customer name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Email ID
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Phone number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Alternative Phone number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      sx={{ color: "black", fontSize: "large" }}
                    >
                      <p>No data available</p>
                    </TableCell>
                  </TableRow>
                ) : (
                  (rowsPerPage > 0
                    ? filteredData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredData
                  ).map((row, index: number) => (
                    <TableRow>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="center">{row.Name}</TableCell>
                      <TableCell align="center">{row.Email}</TableCell>
                      <TableCell align="center">{row.phone_number}</TableCell>
                      <TableCell align="center">
                        {row.alternativenumber}
                      </TableCell>
                      <TableCell align="center">
                        <>
                          <button
                            className="icon-btn-edit"
                            onClick={() =>
                              editname(
                                row._id.$oid,
                                row.Name,
                                row.phone_number,
                                row.alternativenumber,
                                row.Email,
                                row.Address.customeraddress1,
                                row.Address.customeraddress2,
                                row.Address.customeraddress3
                              )
                            }
                          >
                            <Tooltip title="Edit" placement="bottom" arrow>
                              <IconButton>
                                <EditIcon style={{ color: "black" }} />
                              </IconButton>
                            </Tooltip>
                          </button>
                          <button
                            className="icon-btn-edit"
                            onClick={() =>
                              deletename(
                                row._id.$oid,
                                row.Name ? row.Name.toString() : ""
                              )
                            }
                          >
                            <Tooltip title="Delete" placement="bottom" arrow>
                              <IconButton>
                                <DeleteOutlineIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </button>
                        </>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        </Paper>
      </div>
    );
  };
  const editname = (
    id: string,
    name: string,
    phonenumber: string,
    alternative: string,
    Email: string,
    Address1: string,
    Address2: string,
    Address3: string
  ) => {
    setopenid(id);
    setName(name);
    setphonenumber(phonenumber);
    setAlternative(alternative);
    setemail(Email);
    setaddress(Address1);
    setaddress2(Address2);
    setaddress3(Address3);
    setdata(!data);
    setopenscreen(!openscreen);
  };
  const deletename = (id: string, name: string) => {
    setopenid(id);
    setName(name);
    setdata(!data);
    setdeletescreen(!deletescreen);
  };

  const menu = {
    Customer: "customer",
  };

  const addbutton = {
    Blue: "blue",
    name: "Add Customer",
  };

  const Cancel = {
    Cancel: "Blue",
    name: "Cancel",
  };

  const Confirm = {
    Blue: "Blue",
    name: "Confirm",
  };

  useEffect(() => {
    const bussinessType = sessionStorage.getItem("business_type");
    setBussinessType(String(bussinessType));
  }, []);

  const OldOrders = () => {};

  return (
    <div>
      {bussinessType === "Laundry" && <Header Header="Settings - Customers" />}
      {bussinessType === "Restaurant" && (
        <Resheader Resheader={"Settings - Customers"} OldOrders={OldOrders} />
      )}
      {bussinessType === "Carwash" && (
        <CarHeaderBox Carheader="Settings - Categories" />
      )}
      <div style={{ display: "flex" }}>
        {bussinessType === "Laundry" && <Menu />}
        {bussinessType === "Restaurant" && <Resmenu Resmenu={""} />}
        {bussinessType === "Carwash" && <CarMenuBox />}
        <div className="Customer_data">
          <Editmenu editmenu={menu} />
          <div className="customer-add">
            <div className="header-product-structure-category-customer">
              <div className="search-box">
                <TextField
                  value={searchTerm}
                  id="Search-Phone-number"
                  label={"Search by Name or Phone number"}
                  variant="outlined"
                  sx={{ width: 400 }}
                  type="tel"
                  onChange={handleSearch}
                />
              </div>

              <div className="add-button" onClick={openscreepopulay}>
                <Buttons Buttondata={addbutton} />
              </div>
            </div>
          </div>

          {tableformet()}
          {openscreen && (
            <Editcustomer
              id={openid}
              phone={phonenumber}
              alternativephone={alternative}
              name={Name}
              email={email}
              address={address}
              address2={address2}
              address3={address3}
              closeEditcustomer={closeEditcustomer}
            />
          )}
          {deletescreen && (
            <Deletescreen
              name={Name}
              id={openid}
              refreshCustomerList={refreshCustomerList}
            />
          )}
          <div>
            <Dialog open={open} onClose={handleclose}>
              <DialogTitle>
                <div className="over-head-style">
                  <div>
                    <h2 className="customer-heading">Enter customer details</h2>
                  </div>
                  <div
                    onClick={() => {
                      handleclose();
                      closedatamodel();
                    }}
                    className="customer-close"
                  >
                    <CloseIcon />
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="customer-form">
                  <TextField
                    id="name"
                    label={
                      <span>
                        Name <span className="required">*</span>
                      </span>
                    }
                    variant="outlined"
                    className="input-field"
                    value={cusname}
                    onChange={(e) => setCutname(e.target.value)}
                  />

                  <TextField
                    id="phone"
                    label={
                      <span>
                        Phonenumber <span className="required">*</span>
                      </span>
                    }
                    variant="outlined"
                    className="input-field"
                    value={cusphone}
                    onChange={handlePhoneChange}
                  />

                  <TextField
                    id="alt-phone"
                    label="Alternative Phonenumber"
                    variant="outlined"
                    className="input-field"
                    value={cusalterphone}
                    onChange={handleAlternativePhoneChange}
                  />

                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    className="input-field"
                    value={cusemail}
                    onChange={(e) => setCutemail(e.target.value)}
                  />
                </div>
                <div className="customer-Aaddress ">
                  <div className="customer-input-address">
                    <p style={{ fontWeight: "normal", marginTop: "5px" }}>
                      Address (Optional):
                    </p>
                    <ul>
                      <TextField
                        id="standard-basic"
                        label="Street/Village"
                        variant="standard"
                        value={cusaddress}
                        onChange={(e) => setCutaddress(e.target.value)}
                      />
                      <TextField
                        id="standard-basic"
                        label="District/State"
                        variant="standard"
                        value={cusaddress2}
                        onChange={(e) => setCutaddress2(e.target.value)}
                      />
                      <TextField
                        id="standard-basic"
                        label="Pincode/Zipcode"
                        variant="standard"
                        value={cusaddress3}
                        onChange={(e) => setCutaddress3(e.target.value)}
                      />
                    </ul>
                  </div>
                </div>
                <div className="button-data">
                  <div
                    onClick={() => {
                      setOpen(false);
                      closedatamodel();
                    }}
                  >
                    <Buttons Buttondata={Cancel} />
                  </div>

                  <div>{renderButton()}</div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerData;
