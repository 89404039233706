import React, { useEffect, useState } from "react";
import Ressetting from "./Ressetting";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../axiosConfig";
import Resheader from "../../components/Restaurant/Resheader";
import Resmenu from "../../components/Restaurant/Resmenu";

interface EmployeeData {
  _id: { $oid: string };
  Branch_name: string;
  user_name: string;
  name: string;
  employee_name: string;
  phone_number: string;
  email_id: string;
  address: string;
  password: string;
  Delete: string;
  status: string;
  employee: Array<{ Branch_name: string }>;
  date_of_joined: string;
  date_of_leaving: string;
  role: string;
}

const Resuser = () => {
  const [employeedata, setEmployeedata] = useState<EmployeeData[]>([]);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [deletepopup, setDeletepopup] = useState<boolean>(false);
  const [updatepop, setUpdatepop] = useState<boolean>(false);
  const [addname, setAddname] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");
  const [confirmmail, setConfirmmail] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [doj, setDoj] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [editname, setEditname] = useState<string>("");
  const [editusername, setEditusername] = useState<string>("");
  const [editphone, setEditphone] = useState<string>("");
  const [editmail, setEditmail] = useState<string>("");
  const [editaddress, setEditAddress] = useState<string>("");
  const [editdoj, setEditdoj] = useState<string>("");
  const [updateId, setUpdateId] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<EmployeeData | null>(null);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const rowsPerPageOptions = [5, 10, 15, { label: "All", value: -1 }];

  useEffect(() => {
    getUser();
  }, []);

  const fetchUser = async () => {
    const newUser = {
      employee_name: addname,
      user_name: username,
      phone_number: phonenumber,
      email_id: confirmmail,
      date_of_joined: doj,
      address: address,
    };

    try {
      const res = await api.post("/pos/add", newUser);
      console.log("User added:", res.data);
      getUser();
      setOpenPopup(false);
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const getUser = async () => {
    try {
      const res = await api.get("/pos/get_user");
      const userData: EmployeeData[] = res.data.result;
      setEmployeedata(userData);
      console.log("Fetched users:", res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const deleteuser = async () => {
    try {
      const res = await api.delete(`/pos/add/${deleteId}`);
      console.log(res);
      getUser();
      setDeletepopup(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const updateuser = async () => {
    if (!selectedUser) return; 

    const updatedUser = {
      name: editname,
      user_name: editusername,
      phone_number: editphone,
      email_id: editmail,
      address: editaddress,
      date_of_joined: editdoj,
    };

    try {
      const res = await api.put(`/pos/add/${updateId}`, updatedUser); 
      console.log("User updated:", res.data);
      getUser();
      setUpdatepop(false);
      setSelectedUser(null); 
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };


  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handledelete = () => {
    setDeletepopup(false);
  };

  const handledeleteclick = (id: string) => {
    if (!showDeleted) {
      setDeleteId(id);
      setDeletepopup(true);
    }
  };
  
  const handleedit = () => {
    setUpdatepop(false);
  };
  const handleeditclick = async (user: EmployeeData) => {
    setSelectedUser(user); 
    setUpdateId(user._id.$oid);
    setEditname(user.name || "");
    setEditusername(user.user_name || "");
    setEditphone(user.phone_number || "");
    setEditmail(user.email_id || "");
    setEditAddress(user.address || "");
    setEditdoj(user.date_of_joined || "");
    setUpdatepop(true);
  };
  const filters = (showDeleted: boolean) => {
    console.log("Showing Deleted:", showDeleted);
    if (!showDeleted) {
      api.get("/pos/get_user").then((res) => {
        const activeUsers: EmployeeData[] = res.data.result.filter((user: EmployeeData) => user.Delete !== "1");
        setEmployeedata(activeUsers);
      }).catch((error) => {
        console.error("Error fetching active users:", error);
      });
    } else {
      api.get("/pos/add_employee/DeletedUsers").then((res) => {
        const deletedUsers: EmployeeData[] = res.data.result;
        if (res.data.message === "No deleted users found") {
          console.log("No deleted users found");
          toast("No deleted users found");
        }
        setEmployeedata(deletedUsers);
      }).catch((error) => {
        const err = error.response?.data?.message || "An unknown error occurred";
        toast.error(err);
      });
    }
  };

  const handleCheckboxChange = () => {
    setShowDeleted((prevState) => {
      const newState = !prevState;
      filters(newState);
      return newState;
    });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = rowsPerPage === -1
    ? employeedata  
    : employeedata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Resheader Resheader={"User"} OldOrders={() => { }} />
      <div style={{ display: "flex" }}>
        <Resmenu Resmenu={""} />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Ressetting />
          <div>
            <div className="adduser-button">
              <div className="view-deleted">
                <FormControlLabel control={<Checkbox checked={showDeleted} onChange={handleCheckboxChange} />} label="View deleted users" />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenPopup(true)}
              >
                Add User
              </Button>
              <Dialog
                open={openPopup}
                onClose={handleClosePopup}
                className="user-dialog"
              >
                <DialogTitle className="user-dialogtitle">Add User</DialogTitle>
                <DialogContent>
                  <div className="user-textfield">
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={addname}
                      onChange={(e) => setAddname(e.target.value)}
                    />
                    <TextField
                      label="Username"
                      variant="outlined"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      value={phonenumber}
                      onChange={(e) => setPhonenumber(e.target.value)}
                    />
                    <TextField
                      label="Date of Joining"
                      variant="outlined"
                      value={doj}
                      onChange={(e) => setDoj(e.target.value)}
                    />
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={confirmmail}
                      onChange={(e) => setConfirmmail(e.target.value)}
                    />
                    <TextField
                      label="Address"
                      variant="outlined"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "20px", textAlign: "right" }}>
                    <Button
                      onClick={handleClosePopup}
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={fetchUser}
                    >
                      Confirm
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            <div>
              <Dialog open={deletepopup} onClose={handledelete}>
                <DialogTitle>Delete User</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this user?
                  <div>
                    <Button
                      onClick={handledelete}
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={deleteuser}
                    >
                      Confirm
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            <div>
            <Dialog open={updatepop} onClose={() => setUpdatepop(false)}>
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                  <div className="user-textfield">
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={editname}
                      onChange={(e) => setEditname(e.target.value)}
                    />
                    <TextField
                      label="Username"
                      variant="outlined"
                      value={editusername}
                      onChange={(e) => setEditusername(e.target.value)}
                    />
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      value={editphone}
                      onChange={(e) => setEditphone(e.target.value)}
                    />
                    <TextField
                      label="Date of Joining"
                      variant="outlined"
                      value={editdoj}
                      onChange={(e) => setEditdoj(e.target.value)}
                    />
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={editmail}
                      onChange={(e) => setEditmail(e.target.value)}
                    />
                    <TextField
                      label="Address"
                      variant="outlined"
                      value={editaddress}
                      onChange={(e) => setEditAddress(e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "20px", textAlign: "right" }}>
                    <Button
                      onClick={handleedit}
                      style={{ marginRight: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={updateuser}
                    >
                      Confirm
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>

            <div className="usertable">
              <Paper id="Table" sx={{ width: "90%", margin: "20px auto" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>Date of Joining</TableCell>
                        <TableCell>Date of Leaving</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Selected branch</TableCell>
                        <TableCell>Roles</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.length > 0 ? (
                        paginatedData.map((item, index) => (
                          <TableRow key={item._id.$oid}>
                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.employee_name}</TableCell>
                            <TableCell>{item.phone_number}</TableCell>
                            <TableCell>{item.date_of_joined}</TableCell>
                            <TableCell>{item.date_of_leaving || "N/A"}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.Branch_name || ""}</TableCell>
                            <TableCell>{item.role}</TableCell>
                            <TableCell>
                              <Tooltip title="Edit User" placement="bottom" arrow>
                                <IconButton onClick={() => handleeditclick(item)}>
                                  <EditIcon sx={{ color: "black" }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete User" placement="bottom" arrow>
                                <IconButton onClick={() => handledeleteclick(item._id.$oid)}  disabled={showDeleted}>
                                  <DeleteOutlineIcon sx={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} style={{ textAlign: "center" }}>
                            No users found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={employeedata.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resuser;
