import React, { useEffect, useState } from "react";
import "./Edit.css";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Editcategory from "./EditCategory";
import Deletedcat from "./DeletedCategory";
import { Flip, ToastContainer, toast } from "react-toastify";
import Editmenu from "./EditMenu";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";
import Tables from "../../common-component/Table";
import LoadingScreen from "../../common-component/LoadingScreen";
import Resheader from "../../components/Restaurant/Resheader";
import Resmenu from "../../components/Restaurant/Resmenu";
import CarHeaderBox from "../../components/car-wash/CarHeaderBox";
import CarMenuBox from "../../components/car-wash/CarMenuBox";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
  Delete: string;
}

interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ProductName: string;
  category_id: string;
}

function AddCategory() {
  const [errormsg, setErrormsg] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [categoryname, setCategoryname] = useState<string>("");
  const [products, setProducts] = useState<Productbtn[]>([]);
  const [remove, setRemove] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [Name, setName] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filteredCategory, setFilteredCategory] = useState<CategoryButton[]>(
    []
  );
  const [bussinessType, setBussinessType] = useState<string>("");
  const [showDeletedCategories, setShowDeletedCategories] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Categories - Settings - iMetanic";
  }, []);

  const category = () => {
    api.get(`/app/v1/pos/category?is_deleted=False`).then((res) => {
      const categoryData: CategoryButton[] = res.data.result;
      setButtonNames(categoryData);
    });
  };

  const product = () => {
    api
      .get(`/app/v1/pos/product`)
      .then((res) => {
        const productdata: Productbtn[] = res.data.result;
        setProducts(productdata);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (categoryname) {
        categoryupdate();
      } else {
        toast.error("Please fill in required fields", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (empty === false) {
      category();
      product();
    }
    setLoading(false);
    if (categoryname) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [categoryname, empty]);

  const categoryupdate = async () => {
    const updatename = {
      CategoryName: categoryname,
    };

    if (categoryname) {
      setLoading(true);
      try {
        const res = await api.post(`/app/v1/pos/category`, updatename);
        console.log(res);
        toast.success("Category added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCategoryname("");
        setModal(false);
        category();
      } catch (error: any) {
        const errormsgs =
          error.message === "Request failed with status code 403";
        setErrormsg(errormsgs);
        if (errormsgs) {
          toast.error("Category name already exists", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const editname = (id: string, Name: string) => {
    setEdit(!edit);
    setId(id);
    setName(Name);
  };

  const deletename = (id: string, Name: string) => {
    setRemove(!remove);
    setId(id);
    setName(Name);
  };

  const handleUpdate = (id: string, newName: string) => {
    setButtonNames((prevButtonNames) =>
      prevButtonNames.map((category) =>
        category._id.$oid === id
          ? { ...category, CategoryName: newName }
          : category
      )
    );
    setEdit(false);
  };

  const handleDelete = (id: string) => {
    setLoading(true);
    api
      .delete(`/app/v1/pos/category/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Category deleted successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setButtonNames((prevButtonNames) =>
            prevButtonNames.filter((category) => category._id.$oid !== id)
          );
        }
      })
      .catch((error) => {
        toast.error("Failed to delete category", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        console.error("Error deleting category:", error);
      })
      .finally(() => {
        setLoading(false);
        setRemove(false); // Close the delete modal
      });
  };

  const Products = {
    titlesno: "S.No",
    titlename1: "Categorie name",
    titlename2: "Number of products",
    titlename3: "Actions",
    S_no: filteredCategory.map((item, index) => index + 1),
    bodyname1: filteredCategory.map((item) => item.CategoryName),
    bodyname2: filteredCategory.map(
      (item) =>
        products.filter((product) => product.category_id === item._id.$oid)
          .length
    ),
    bodyname3: filteredCategory.map((item) => (
      <>
        <button
          className="icon-btn-edit"
          onClick={() => editname(item._id.$oid, item.CategoryName)}
        >
          <Tooltip title="Edit" placement="bottom" arrow>
            <IconButton>
              <EditIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </button>
        <button
          className="icon-btn-edit"
          onClick={() => deletename(item._id.$oid, item.CategoryName)}
        >
          <Tooltip title="Delete" placement="bottom" arrow>
            <IconButton>
              <DeleteOutlineIcon style={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </button>
      </>
    )),
  };

  const AddCategoryButton = {
    Blue: "blue",
    name: "Add Category",
  };
  const ConfirmButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const errorconfirmButtonerr = {
    error: "blue",
    name: "Confirm",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };

  const confirm = () => {
    if (visible === true) {
      return (
        <div id="confirm_btn" onClick={categoryupdate}>
          <Buttons Buttondata={ConfirmButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={errorconfirmButtonerr} />
        </div>
      );
    }
  };

  const categoryrename = () => {
    setCategoryname("");
    setErrormsg(false);
  };

  const menu = {
    Category: "Category",
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(inputValue)) {
      setSearch(inputValue);
    }
  };

  const editCategory = () => {
    setEdit(false);
  };

  useEffect(() => {
    const bussinessTypes = sessionStorage.getItem("business_type");
    setBussinessType(String(bussinessTypes));
    setFilteredCategory(
      buttonNames.filter((category) =>
        category.CategoryName.toLowerCase().includes(search.toLowerCase())
      )
    );
    console.log("Filtered Categories:", filteredCategory);
  }, [search, buttonNames]);


  const OldOrders = () => {};
  const filters = (showDeleted: boolean) => {
    console.log("Showing Deleted:", showDeleted);
    if (!showDeleted) {
      api.get(`/app/v1/pos/category?is_deleted=False`).then((res) => {
        const activeCategories: CategoryButton[] = res.data.result.filter(
          (category: CategoryButton) => category.Delete !== "1"
        );
        setButtonNames(activeCategories);
      }).catch((error) => {
        console.error("Error fetching categories:", error);
      });
    } else {
      api.get(`/app/v1/pos/category?is_deleted=True`).then((res) => {
        console.log("Deleted categories:", res.data.result);
        const deletedCategories: CategoryButton[] = res.data.result;
        setButtonNames(deletedCategories);
      }).catch((error) => {
        console.error("Error fetching deleted categories:", error);
      });
    }
  };
  const handleCheckboxChange = () => {
  setShowDeletedCategories((prevState) => {
    const newState = !prevState;
    filters(newState);
    return newState;
  });
};
useEffect(() => {
  filters(showDeletedCategories);
}, [showDeletedCategories]);

  return (
    <div>
      {bussinessType === "Laundry" && <Header Header="Settings - Categories" />}
      {bussinessType === "Restaurant" && (
        <Resheader Resheader={"Categories"} OldOrders={OldOrders} />
      )}
      {bussinessType === "Carwash" && (
        <CarHeaderBox Carheader="Settings - Categories" />
      )}
      <div style={{ display: "flex" }}>
        {bussinessType === "Laundry" && <Menu />}
        {bussinessType === "Restaurant" && <Resmenu Resmenu={""} />}
        {bussinessType === "Carwash" && <CarMenuBox />}
        <div className="Edit-overall">
          <Editmenu editmenu={menu} />
          <div className="category-add" id="category_add">
            <div className="header-product-structure-catogory" >
            <div
            
              onClick={() => {
                setModal(true);
                setEmpty(true);
              }}
              id="Addcategory"
            >
              <Buttons Buttondata={AddCategoryButton} />
            </div>
            <div className="search_box_user">
              <TextField
                id="Search-Category-name"
                label={"Search by Categorie name"}
                variant="outlined"
                sx={{ width: 400, zIndex: "0"}}
                type="tel"
                onChange={handleSearch}
              />
            </div>
            <div className="Deleted_check">
              <FormControlLabel control={<Checkbox checked={showDeletedCategories} onChange={handleCheckboxChange} />} label="View deleted categories" />
            </div>
            </div>
          </div>
          <Table sx={{ marginLeft: "70px" ,width:"89vw"}}>
            <TableBody>
              {filteredCategory.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                    sx={{ color: "black", fontSize: "large" }}
                  >
                    No categories found
                  </TableCell>
                </TableRow>
              ) : (
                <Tables product={Products} />
              )}
            </TableBody>
          </Table>

          {modal && (
            <div className="modals">
              <div className="overlays"></div>
              <div className="modal-contents">
                <div className="edit-popupscreen">
                  <div className="addcategory-editadd">
                    <h2>
                      Add category
                      <button
                        onClick={() => setModal(false)}
                        className="cancel-icon-Addcategory"
                      >
                        <CloseIcon />
                      </button>
                    </h2>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: 1,
                          width: "50ch",
                          marginTop: "40px",
                          borderBlockWidth: "20px",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {errormsg ? (
                        <TextField
                          id="Category_Name"
                          error
                          label={
                            <span>
                              Category name{" "}
                              <span
                                style={{ color: "#f04141", fontSize: "15px" }}
                              >
                                *
                              </span>
                            </span>
                          }
                          value={categoryname}
                          onChange={(e) => setCategoryname(e.target.value)}
                          onClick={() => categoryrename()}
                        />
                      ) : (
                        <TextField
                          id="Category_Name"
                          label={
                            <span>
                              Category name{" "}
                              <span
                                style={{ color: "#f04141", fontSize: "15px" }}
                              >
                                *
                              </span>
                            </span>
                          }
                          value={categoryname}
                          onKeyPress={handleKeyPress}
                          onChange={(e) =>
                            setCategoryname(e.target.value.toUpperCase())
                          }
                        />
                      )}
                    </Box>
                    <div className="btnconfirm-edit">
                      <div
                        id="cancelbtn"
                        onClick={() => {
                          setModal(false);
                          categoryrename();
                        }}
                      >
                        <Buttons Buttondata={CancelButton} />
                      </div>
                      {confirm()}
                    </div>
                  </div>
                </div>
                <ToastContainer />
              </div>
            </div>
          )}
          {edit && (
            <Editcategory
              id={id}
              Name={Name}
              onUpdate={handleUpdate}
              editCategory={editCategory}
            />
          )}
          {remove && (
            <Deletedcat
              id={id}
              Name={Name}
              onDelete={() => handleDelete(id)}
              onClose={() => setRemove(false)}
            />
          )}
          {Loading ? <LoadingScreen /> : ""}
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
