import React, { useState, useEffect, ReactNode } from "react";
import {
  Modal,
  Button,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  Paper,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Ressetting from "./Ressetting";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../axiosConfig";
import Resheader from "../../components/Restaurant/Resheader";
import Resmenu from "../../components/Restaurant/Resmenu";

interface Branches {
  _id: { $oid: string };
  Branch_name: string;
  Branch_Opening_date: string;
  Address: {
    address1: string;
    street: string;
    selectedCountry: ReactNode;
    selectedState: ReactNode;
    selectedCity: ReactNode;
    Pincode: string;
  };
  contact_person: string | null;
  branch_number: string | null;
}

const Resbranch = () => {
  const [branches, setBranches] = useState<Branches[]>([]);
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState<"add" | "edit" | "delete">(
    "add"
  );
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [contactPerson, setContactPerson] = useState<string>("");
  const [branchToDelete, setBranchToDelete] = useState<string>("");
  const [branchOpeningDate, setBranchOpeningDate] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = () => {
    setLoading(true);
    api
      .get("/app/v1/pos/branch")
      .then((response) => {
        console.log("API Response:", response.data);
        setBranches(response.data.result);
      })
      .catch(() =>
        toast.error("Failed to fetch branches", { theme: "colored" })
      )
      .finally(() => setLoading(false));
  };

  const openModal = (
    action: "add" | "edit" | "delete",
    id?: string,
    branchName?: string,
    address?: string,
    branch?: string
  ) => {
    setModalAction(action);
  
    if (action === "edit") {
        setEdit(true);
        setId(id!);
        setBranchName(branchName || "");
        setAddress(address || "");
        setContactPerson(contactPerson || ""); 
        setBranchOpeningDate(branchOpeningDate || "");
    } else if (action === "delete" && branch) {
        setBranchToDelete(id!);
    } else {
        setBranchName("");
        setAddress("");
        setContactPerson("");
        setEdit(false);
    }
    setModal(true);
  };
  
  const closeModal = () => {
    setModal(false);
    resetForm();
  };

  const resetForm = () => {
    setId("");
    setBranchName("");
    setAddress("");
    setContactPerson("");
  };

  const handleBranchUpdate = () => {
    if (!branchName.trim() || !address.trim() || !contactPerson.trim()) {
      toast.error("Please fill in all required fields", { theme: "colored" });
      return;
    }

    const branchData = {
      Branch_name: branchName,
      Address: address,
      ContactPerson: contactPerson,
    };

    setLoading(true);
    if (edit) {
      api
        .put(`/app/v1/pos/branch/${id}`, branchData)
        .then(() => {
          toast.success("Branch updated successfully", { theme: "colored" });
          fetchBranches();
          closeModal();
        })
        .catch(() =>
          toast.error("Failed to update branch", { theme: "colored" })
        )
        .finally(() => setLoading(false));
    } else {
      api
        .post("/app/v1/pos/branch", branchData)
        .then(() => {
          toast.success("Branch added successfully", { theme: "colored" });
          fetchBranches();
          closeModal();
        })
        .catch(() => toast.error("Failed to add branch", { theme: "colored" }))
        .finally(() => setLoading(false));
    }
  };

  const handleDelete = () => {
    if (!branchToDelete) return;

    setLoading(true);
    api
      .delete(`/app/v1/pos/branch/${branchToDelete}`)
      .then(() => {
        toast.success("Branch deleted successfully", { theme: "colored" });
        fetchBranches();
        closeModal();
      })
      .catch(() => toast.error("Failed to delete branch", { theme: "colored" }))
      .finally(() => setLoading(false));
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const oldOrders = () => {};

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Resheader Resheader={"Branches"} OldOrders={oldOrders} />
      <div style={{ display: "flex" }}>
        <Resmenu Resmenu={""} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Ressetting />
          <div style={{ padding: "20px" }}>
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={() => openModal("add")}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Add Branch"}
              </Button>
            </div>
            <TableContainer
              component={Paper}
              style={{
                marginTop: "20px",
                width: "58%",
                marginLeft: "70px",
                height: "480px",
                overflow: "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="Res-table-header">S.No</TableCell>
                    <TableCell className="Res-table-header">
                      Branch Name
                    </TableCell>
                    <TableCell className="Res-table-header">Address</TableCell>
                    <TableCell className="Res-table-header">
                      Contact Person
                    </TableCell>
                    <TableCell className="Res-table-header">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branches.length > 0 ? (
                    branches
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((branches, index) => (
                        <TableRow key={branches._id.$oid}>
                          <TableCell>
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell>{branches.Branch_name}</TableCell>
                          <TableCell>
                            {branches.Address
                              ? typeof branches.Address === "string"
                                ? branches.Address
                                : `${branches.Address?.address1 || ""}, ${
                                    branches.Address?.street || ""
                                  }, ${
                                    branches.Address?.selectedCountry ?? ""
                                  }, ${
                                    branches.Address?.selectedState ?? ""
                                  }, ${branches.Address?.selectedCity ?? ""}, ${
                                    branches.Address?.Pincode || ""
                                  }`
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {branches.contact_person || "N/A"}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => openModal("edit", "jhbjk")}
                              >
                                <EditIcon className="action-icon" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => openModal("delete", "nbk")}
                              >
                                <DeleteOutlineIcon
                                  className="action-icon"
                                  style={{ color: "red" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Branches available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                component="div"
                count={branches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>

            {modal && (
              <div className="Res-modals">
                <div className="Res-overlays">
                  <div className="Res-modal-contents">
                    <Box>
                      {modalAction === "delete" ? (
                        <>
                          <h3
                            style={{
                              margin: "0px 15px 22px",
                              marginBottom: "50px",
                            }}
                          >
                            Are you sure you want to delete this branch?
                          </h3>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => setModal(false)}
                              style={{ width: "45%" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleDelete}
                              style={{ width: "45%" }}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress size={24} />
                              ) : (
                                "Confirm"
                              )}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <h3 style={{ margin: "0px 150px 20px" }}>
                            {modalAction === "edit"
                              ? "Edit Branch"
                              : "Add Branch"}
                          </h3>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "30px",
                            }}
                          >
                            <TextField
                              label="Branch Name"
                              value={branchName}
                              onChange={(e) => setBranchName(e.target.value)}
                              fullWidth
                              margin="normal"
                            />
                            <TextField
                              label="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              fullWidth
                              margin="normal"
                            />
                            <TextField
                              label="Contact Person"
                              value={contactPerson}
                              onChange={(e) => setContactPerson(e.target.value)}
                              fullWidth
                              margin="normal"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            {loading && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <CircularProgress size={50} />
                              </Box>
                            )}
                            <Button
                              variant="outlined"
                              onClick={() => {
                                resetForm();
                                setModal(false);
                                fetchBranches();
                              }}
                              style={{ width: "45%" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleBranchUpdate}
                              style={{ width: "45%" }}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress size={24} />
                              ) : modalAction === "edit" ? (
                                "Update"
                              ) : (
                                "Add"
                              )}
                            </Button>
                          </div>
                          <ToastContainer />
                        </>
                      )}
                    </Box>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resbranch;
