import React, { useState } from 'react'
import "./Resdeshboard.css"
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer,
  Customized
} from 'recharts';
import Resheader from '../../components/Restaurant/Resheader';
import Resmenu from '../../components/Restaurant/Resmenu';

const dataMonthlySales = [
  { month: 'Jan', sales: 0 },
  { month: 'Feb', sales: 0 },
  { month: 'Mar', sales: 0 },
  { month: 'Apr', sales: 0 },
  { month: 'May', sales: 0 },
  { month: 'Jun', sales: 0 },
  { month: 'Jul', sales: 0 },
  { month: 'Aug', sales: 0 },
  { month: 'Sep', sales: 63 },
  { month: 'Oct', sales: 80 },
  { month: 'Nov', sales: 0 },
  { month: 'Dec', sales: 0 },
];

const monthColors = {
  'Jan': '#00a2e8', 'Feb': '#22b14c', 'Mar': '#ed1c24', 'Apr': '#ffc90e',
  'May': '#a349a4', 'Jun': '#3f48cc', 'Jul': '#b97a57', 'Aug': '#7092be',
  'Sep': '#b5e61d', 'Oct': '#ff7f27', 'Nov': '#00bff3', 'Dec': '#99d9ea',
};

const renderCustomTicks = (props: any) => {
  const { x, y, payload } = props;
  const month = payload.value as keyof typeof monthColors;
  const color = monthColors[month] || '#fff';
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0} y={0} dy={16} textAnchor="middle"
        fill={color} style={{ fontSize: 14 }}
      >
        {month}
      </text>
    </g>
  );
};

const dataHourlySales = [
  { hour: '14:00', sales: 63 }
];
const dataPie = [
  { name: 'Dry Clean Normal', value: 63 }
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const Resdeshboard: React.FC = () => {
  const [year, setYear] = useState(2024);
  const [startDate, setStartDate] = useState(''); // State for start date
  const [endDate, setEndDate] = useState('');


  const handlePreviousYear = () => {
    setYear(year - 1);
  };
  const handleNextYear = () => {
    setYear(year + 1);
  };

  const oldOrders =()=>{
    
  }


  return (
    <div className='dashboard-container'>
      <div>
        <Resheader Resheader={"Dashboard"}  OldOrders={oldOrders}/>
        <div style={{ display: "flex" }}>
          <Resmenu Resmenu={""} />
          <div className="dashboard-main">
            {/* Sales chart */}
            <div className="dashboard-full-card">
              <div className="chart-header">
                <h2>
                  Monthly Sales - {year}
                </h2>
                <div className="year-controls">
                  <button onClick={handlePreviousYear} className="dashboard-button">
                    <FaChevronLeft />
                  </button>
                  <span className="year-display">{year}</span>
                  <button onClick={handleNextYear} className="dashboard-button">
                    <FaChevronRight />
                  </button>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dataMonthlySales}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="month"
                    tick={<Customized component={renderCustomTicks} />}
                  />
                  <YAxis tick={{ fill: 'black' }} />
                  <Tooltip />
                  <Bar dataKey="sales" fill="#FF6B6B" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Periodic Reports Section */}
            {/* <div className='periodic-box'>
              <div className="periodic-reports">
                <h3>Periodic Reports</h3>
                <div className="date-selection">
                  <label>Start Date:</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="date-input"
                  />
                  <label>End Date:</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="date-input"
                  />
                </div>
              </div>
            </div> */}

            {/* Total Sales */}
            <div className="dashboard-total-sales">
              <h3>Total Sales</h3>
              <h1>63</h1>
              <p>Top performing month: September</p>
            </div>

            {/* Hourly Sales */}
            <div className="dashboard-Hourly-sales-card">
              <h3>Hourly Sales</h3>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={dataHourlySales}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="hour" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="sales" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Top Categoties */}
            <div className="dashboard-top-categories-card">
              <h3>Top Categories</h3>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie data={dataPie} dataKey="value" outerRadius={80} fill="#8884d8" label>
                    {dataPie.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>

            {/* Top Products
            <div className='dashboard-product-list'>
              <h3>Top Products</h3>
              <ul>
                <li>Thobe: 63.00</li>
              </ul>
            </div>

            Top Customers
            <div className="dashboard-top-customer-card">
              <h3>Top Customers</h3>
              <p>No data available</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resdeshboard