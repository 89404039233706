import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import IconButton from "@mui/material/IconButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import SearchIcon from "@mui/icons-material/Search";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";
import LoadingScreen from "../../common-component/LoadingScreen";
// import LoadingScreen from "../../../common-component/LoadingScreen";

interface Getdata {
  Customerid: any;
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    amount: number;
    quantity: number;
    totalprice: number;
  }>;
  Orderdate: string;
  Ordertime: string;
  ReceivedAmount: number;
  RemainingAmount: number;
  Paymentmode: string;
  OrderStatus: string;
  discount: number;
  Finalamount: number;
  totalAmount: number;
  totalQuantity: number;
  Email: string;
  CustomerName: string;
  CustomerPhoneNo: number;
  Orderid: number;
}

const OrderSearch: React.FC = () => {
  const [getdata, setOrders] = useState<Getdata[]>([]);
  const [filteredData, setFilteredData] = useState<Getdata[]>([]);
  const [datafilter, setdatafilter] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [tempId, setTempId] = useState<number | null>(null);
  const [oredrdetails, setoredrdetails] = useState<boolean>(false);
  const navigation = useNavigate();
  const [error, setError] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedOptionnew, setSelectedOptionnew] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedDate2, setSelectedDate2] = useState<string>("");
  const [employeesData, setemployeesData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const generatePdf = async (): Promise<Blob> => {
    const generateTableRows = (getdata: any[]) => {
      return getdata
        .map(
          (item, index) => `
        <tr>
          <td>${index + 1}</td>
          <td>${item.Orderid}</td>
          <td>${item.CustomerName}</td>
          <td>${item.CustomerPhoneNo}</td>
          <td>${item.totalAmount}</td>
        </tr>
      `
        )
        .join("");
    };

    const htmlcontent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Pdf Generate</title>
        <style>
            /* General container styling */
            div {
                font-family: Arial, sans-serif;
                color: #333;
            }
            h1 {
                text-align: center;
                margin-bottom: 20px;
                color: #4CAF50;
            }
            h3 {
                margin: 10px 0;
            }
            .overview_pdf {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                padding: 10px;
                background-color: #f9f9f9;
                border: 1px solid #ddd;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin: 10px;
            }
            thead {
                background-color: #4CAF50;
                color: white;
            }
            th, td {
                padding: 12px;
                text-align: left;
                border-bottom: 1px solid #ddd;
            }
            tbody tr:nth-child(odd) {
                background-color: #f3f3f3; 
            }
            tbody tr:nth-child(even) {
                background-color: #ffffff; 
            }
            @media (max-width: 600px) {
                .overview_pdf {
                    flex-direction: column;
                }
                table, thead, tbody, th, td, tr {
                    display: block;
                    width: 100%;
                }
                tr {
                    margin-bottom: 10px;
                }
                th, td {
                    text-align: right;
                    padding: 8px;
                }
                th {
                    background-color: #4CAF50;
                    color: white;
                }
                td {
                    background-color: #f9f9f9;
                    border-bottom: 1px solid #ddd;
                }
            }
        </style>
    </head>
    <body>
        <div>
            <div>
                <h1> Export Statement</h1>
            </div>
            <div className='overview_pdf'>
               <div>
                <h3>Company Details:</h3>
                <p><p/>
               </div>
               <div>
                <h3>Date</h3>
                <h3>12/12/2022</h3>
               </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Order Id</th>
                        <th>Customer Name</th>
                        <th>Phone Number</th>
                        <th>Order Amount</th>
                    </tr>
                </thead>
                <tbody>
                    ${generateTableRows(getdata)}
                </tbody>
                <tfoot></tfoot>
            </table>
        </div>
    </body>
    </html>
    `;

    const container = document.createElement("div");
    container.style.position = "fixed";
    container.style.top = "0";
    container.style.left = "0";
    container.style.zIndex = "-9999";
    container.innerHTML = htmlcontent;
    document.body.appendChild(container);

    const canvas = await html2canvas(container);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    const pdfBlob = pdf.output("blob");
    document.body.removeChild(container);
    return pdfBlob;
  };

  const Cancel = {
    Cancel: "blue",
    name: "cancel",
  };
  const Confirm = {
    Blue: "blue",
    name: "Confirm",
  };

  const sendPdfViaEmail = async (download = false) => {
    const pdfBlob = await generatePdf();
    if (download) {
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Export.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
    }
  };
  const orderpage = (orderid: string) => {
    if (employeesData) {
      navigation(`/v1/employee/order-details/${orderid}`);
      setoredrdetails(!oredrdetails);
    } else {
      navigation(`/v1/order-details/${orderid}`);
      setoredrdetails(!oredrdetails);
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  useEffect(() => {
    document.title = "Orders - iMetanic";
    // setloading(true)
    Filter("");
    setSelectedDate2("");
    setSelectedDate("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const employee = () => {
      const employeeData = sessionStorage.getItem("employee");
      if (employeeData) {
        setemployeesData(true);
      } else {
        setemployeesData(false);
      }
    };
    employee();
  }, []);
  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleDownload = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOption = event.target.value;
    const query = getdata
      .map((data) => `order_id=${encodeURIComponent(data.Orderid)}`)
      .join("&");
    setloading(true);

    if (selectedOption === "CSV") {
      try {
        const response = await api.get(`/app/v1/import_export?${query}`);
        if (response.status === 201) {
          toast.success("CSV email sent successfully");

          // await sleep(5000);
          window.location.reload();
        } else {
          toast.error("Failed to send CSV email");
        }
      } catch (error) {
        console.error("Error sending CSV via email:", error);
        toast.error("Error sending CSV via email. Please try again.");
      } finally {
        setloading(false);
      }
    } else if (selectedOption === "PDF") {
      try {
        const response = await api.get(`/pos/order_export_pdf?${query}`);
        if (response.status === 201) {
          toast.success("PDF email sent successfully");

          // await sleep(2000);
          window.location.reload();
        } else {
          toast.error("Failed to send PDF email");
        }
      } catch (error) {
        console.error("Error sending PDF:", error);
        toast.error("Error sending PDF via email. Please try again.");
      } finally {
        setloading(false);
      }
    }
  };

  <select className="select_expo" onChange={handleDownload}>
    <option value="">Select</option>
    <option value="CSV">CSV</option>
    <option value="PDF">PDF</option>
  </select>;

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      api
        .post(`/app/v1/import_export`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((error) => {
          console.error("Error importing file:", error);
        });
    }
  };

  const Filter = (filter: string) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    if (filter === "Customize date") {
      setPage(0);
      api.get(`/app/v1/pos/order`).then(async (res) => {
        console.log(res);
        const data: Getdata[] = res.data.result.filter(
          (order: { Orderdate: any }) =>
            order.Orderdate >= selectedDate && order.Orderdate <= selectedDate2
        );
        // console.log('data', data);
        setFilteredData(data);
        setOrders(data);
      });
    } else if (filter === "Last Month") {
      setdatafilter(false);
      setPage(0);
      api.get(`/app/v1/pos/order`).then(async (res) => {
        console.log(res);
        const sixDaysAgo = new Date(currentDate);
        sixDaysAgo.setDate(currentDate.getDate() - 30);
        const formatDate = (date: any) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
        const formattedSixDaysAgo = formatDate(sixDaysAgo);
        const data: Getdata[] = res.data.result.filter(
          (order: { Orderdate: any }) => order.Orderdate >= formattedSixDaysAgo
        );
        // console.log('data', data);
        setFilteredData(data);
        setOrders(data);
      });
    } else if (filter === "180 days") {
      setdatafilter(false);
      setPage(0);
      api.get(`/app/v1/pos/order`).then(async (res) => {
        const sixDaysAgo = new Date(currentDate);
        sixDaysAgo.setDate(currentDate.getDate() - 180);
        const formatDate = (date: any) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
        const formattedSixDaysAgo = formatDate(sixDaysAgo);
        const data: Getdata[] = res.data.result.filter(
          (order: { Orderdate: any }) => order.Orderdate >= formattedSixDaysAgo
        );
        setFilteredData(data);
        setOrders(data);
      });
    } else if (filter === "Yesterday") {
      setdatafilter(false);
      setPage(0);
      api.get(`/app/v1/pos/order`).then(async (res) => {
        const sixDaysAgo = new Date(currentDate);
        sixDaysAgo.setDate(currentDate.getDate() - 1);
        const formatDate = (date: any) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
        const yesderday = formatDate(sixDaysAgo);
        const data: Getdata[] = res.data.result.filter(
          (order: { Orderdate: any }) => order.Orderdate === yesderday
        );
        setFilteredData(data);
        // console.log('data', data);
        setOrders(data);
      });
    } else if (filter === "Last Week") {
      setdatafilter(false);
      setPage(0);
      api.get(`/app/v1/pos/order`).then(async (res) => {
        setFilteredData(getdata);
        const sixDaysAgo = new Date(currentDate);
        sixDaysAgo.setDate(currentDate.getDate() - 6);

        const formatDate = (date: any) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
        const formattedSixDaysAgo = formatDate(sixDaysAgo);
        const data: Getdata[] = res.data.result.filter(
          (order: { Orderdate: any }) => order.Orderdate >= formattedSixDaysAgo
        );
        setOrders(data);
        setFilteredData(data);
      });
    } else {
      setdatafilter(false);
      setPage(0);
      api.get(`/app/v1/pos/order`).then(async (res) => {
        console.log(res);

        setFilteredData(getdata);
        const today = `${year}-${month}-${day}`;
        const data: Getdata[] = res.data.result.filter(
          (data: { Orderdate: any }) => data.Orderdate === today
        );
        setOrders(data);
        setFilteredData(data);
      });
    }
  };

  const handleChange = (event: {
    target: {
      selectedIndex: number;
      options: HTMLOptionsCollection;
      value: string;
    };
  }) => {
    const value = event.target.value;
    setSelectedOption(value);
    clearSearchTerm();

    const options = event.target.options;
    for (let i = 0; i < options.length; i++) {
      options[i].setAttribute("data-selected", "false");
    }

    const selectedOptionElement = options[event.target.selectedIndex];
    selectedOptionElement.setAttribute("data-selected", "true");

    console.log("Selected option:", value);

    if (value === "CustomizeDate") {
      setdatafilter(true);
    } else {
      Filter(value);
    }
    // window.location.reload();
  };

  const Mutton = {
    Cancel: "blue",
    name: "Confirm",
  };
  const Import = {
    Blue: "blue",
    name: "Import",
  };
  const button_Export = {
    Blue: "blue",
    name: "Export",
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(
      /[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g,
      ""
    );
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredData(getdata);
      setError("");
      return;
    }
    const formattedValue = value.toLowerCase().trim();
    if (formattedValue === "") return;

    const filtered = getdata.filter((item) => {
      const nameMatch =
        item.CustomerName?.toLowerCase().includes(formattedValue);
      const phoneMatch = item.CustomerPhoneNo?.toString()
        .toLowerCase()
        .includes(formattedValue);
      const orderMatch = item.Orderid?.toString()
        .toLowerCase()
        .includes(formattedValue);
      return nameMatch || phoneMatch || orderMatch;
    });

    if (filtered.length === 0) {
      setError("No results found.");
    } else {
      setError("");
    }
    setFilteredData(filtered);
  };

  const handleOpenDialog = (orderId: number) => {
    setSelectedOrderId(orderId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOrderId(null);
  };

  const handleConfirmDelete = () => {
    if (selectedOrderId !== null) {
      handleDelete(selectedOrderId);
    }
    handleCloseDialog();
  };

  const handleDelete = (orderId: number) => {
    const updatedData = getdata.filter((item) => item.Orderid !== orderId);
    setOrders(updatedData);
    setFilteredData(updatedData);
    api
      .delete(`/app/v1/pos/order/${orderId}`)
      .then((res) => {
        console.log(res);
        setTempId(null);
        toast.success("Order deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
          transition: Flip,
        });
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
        toast.error("Failed to delete order. Please try again.");
      });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const tableformet = () => {
    if (filteredData === null) {
      return <h3>No data available</h3>;
    }
    const displayedRows =
      rowsPerPage === -1
        ? filteredData
        : filteredData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          );
    return (
      <Paper id="Table" sx={{ width: "88vw", marginTop: "10px" }}>
        <TableContainer sx={{ maxWidth: 2050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                >
                  S.No
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                >
                  Phone number
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                >
                  Order ID
                </TableCell>
                {/* <TableCell
                align="center"
                sx={{ color: "white", fontSize: "large" }}>
                Delivery Date
              </TableCell> */}
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                >
                  Order status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    sx={{ color: "black", fontSize: "large" }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                filteredData &&
                displayedRows.map((item, index) => (
                  <TableRow key={item.Orderid}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.CustomerName}</TableCell>
                    <TableCell align="center">{item.CustomerPhoneNo}</TableCell>
                    <TableCell align="center">{item.Orderid}</TableCell>
                    <TableCell align="center">{item.OrderStatus}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        className="icon-btn-edit"
                        onClick={() => orderpage(String(item.Orderid))}
                      >
                        <Tooltip title="View order" placement="bottom" arrow>
                          <ViewListIcon className="inventoryicon" />
                        </Tooltip>
                      </IconButton>
                      {item.OrderStatus === "Delivered" ? null : (
                        <DeleteIcon
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "-8px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenDialog(item.Orderid)}
                        />
                      )}
                    </TableCell>
                    <Dialog
                      style={{
                        marginBottom: "90px",
                        backgroundColor: "#f1f1f1",
                      }}
                      open={openDialog}
                      onClose={handleCloseDialog}
                      aria-labelledby="confirm-delete-title"
                      aria-describedby="confirm-delete-description"
                    >
                      <button
                        onClick={handleCloseDialog}
                        className="cancel-icon-branchdelete"
                      >
                        <CloseIcon />
                      </button>
                      <h2 id="confirm-delete-title" style={{ marginLeft: "130px", fontSize: "bold", marginTop: "10px" }}>Delete order</h2>
                      <DialogContent>
                        <DialogContentText id="confirm-delete-description">
                          Are you sure you want to delete this order?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <div id="cancel_btn" onClick={handleCloseDialog}>
                          <Buttons Buttondata={Cancel} />
                        </div>
                        <div onClick={handleConfirmDelete}>
                          <Buttons Buttondata={Confirm} />
                        </div>
                      </DialogActions>
                    </Dialog>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Table>
        </TableContainer>
      </Paper>
    );
  };
  const clearSearchTerm = () => {
    setSearchTerm("");
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateRegex.test(value)) {
      const [year, month, day] = value.split("-");
      if (year.length === 4 && month.length === 2 && day.length === 2) {
        setSelectedDate(value);
      }
    }
  };

  const handleDateChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateRegex.test(value)) {
      const [year, month, day] = value.split("-");
      if (year.length === 4 && month.length === 2 && day.length === 2) {
        setSelectedDate2(value);
      }
    }
  };

  return (
    <div>
      <Header Header="Orders" />
      <div style={{ display: "flex" }}>
        <Menu />
        <div className="Overall_Order">
          <div>
            <div className="header-product-structure-category-ordersearch">
            <div className="import_Export">
              <div className="search_item_order">
                <TextField
                  value={searchTerm}
                  id="Search-Phone-number"
                  placeholder="Search by name or phone number or order ID"
                  variant="outlined"
                  sx={{
                    width: 400,
                    height: "60px",
                    marginBottom: "50px",
                    "& input::placeholder": {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  type="tel"
                  onChange={handleSearch}
                />
              </div>
              <div
                className="Im_Expo"
                onClick={() => inputRef.current?.click()}
              >
                <Buttons Buttondata={Import} />
                <input
                  ref={inputRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImport}
                />
              </div>
              {/* <div className="Im_Expo" onClick={handleDownload}>
              <Buttons Buttondata={button_Export} />
            </div> */}
              <div className="selection_option">
                <label className="lable_export">Choose export file:</label>
                <select className="select_expo" onChange={handleDownload}>
                  <option value="">Select</option>
                  <option value="CSV">CSV</option>
                  <option value="PDF">PDF</option>
                </select>
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div style={{ position: "relative", right: "10px" }}>
                  <label
                    htmlFor="options"
                    className="option"
                    style={{ marginLeft: "10px" }}
                  >
                    Choose an option:
                  </label>
                  <select
                    id="options"
                    name="options"
                    value={selectedOption}
                    onChange={handleChange}
                    className="input_section"
                  >
                    <option
                      value="Today"
                      data-selected={selectedOption === "Today"}
                    >
                      Today
                    </option>
                    <option
                      value="Yesterday"
                      data-selected={selectedOption === "Yesterday"}
                    >
                      Yesterday
                    </option>
                    <option
                      value="Last Week"
                      data-selected={selectedOption === "Last Week"}
                    >
                      Last 7 days
                    </option>
                    <option
                      value="Last Month"
                      data-selected={selectedOption === "Last Month"}
                    >
                      Last 30 days
                    </option>
                    <option
                      value="180 days"
                      data-selected={selectedOption === "180 days"}
                    >
                      Last 180 days
                    </option>
                    <option
                      value="CustomizeDate"
                      data-selected={selectedOption === "CustomizeDate"}
                    >
                      Customize date
                    </option>
                  </select>
                </div>
              </form>
            </div>
            </div>
          </div>
          <div>
            {datafilter ? (
              <p className="Select_Date_cus">
                From:
                <input
                  className="input_date"
                  id="from_Date"
                  value={selectedDate}
                  type="Date"
                  onChange={handleDateChange}
                />
                To:
                <input
                  className="input_date"
                  id="to_Date"
                  value={selectedDate2}
                  type="Date"
                  onChange={handleDateChange2}
                />
                <button>
                  <SearchIcon
                    id="search_icon"
                    onClick={() => Filter("Customize date")}
                  />
                </button>
              </p>
            ) : (
              ""
            )}
          </div>
          {tableformet()}
        </div>
        {/* {selectedOptionnew &&
          <div className="modals">
            <div className="overlays"></div>
            <div className="modal-contents">
              <div className="edit-popupscreen">
                <div className="header_divpart">
                <h1>Confirm to delete</h1>
                <CloseIcon className="close_order"  onClick={() => window.location.reload()}/>
               </div>
                {getdata?.map((item) => (
                  <div key={item.Orderid} className="Deleted_details_order">
                    <p >Customer name:{item.CustomerName}</p>
                    <p >Phone number:{item.CustomerPhoneNo}</p>
                    <p >Order id: {item.Orderid}</p>
                  </div>
                ))}
                <p className="warning_message">Are you sure you want to delete this Order?</p>
                <div className="Delete_btn_order">
                  <div id="cancel_btn" onClick={() => window.location.reload()}>
                    <Buttons Buttondata={Button} />
                  </div>
                  <div id="confirm_btn" onClick={() => tempId !== null && handleDelete(tempId)}>
                    <Buttons Buttondata={Mutton} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        } */}
        {loading && <LoadingScreen />}
        <ToastContainer />
      </div>
    </div>
  );
};

export default OrderSearch;
