import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./Respayment.css";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GroupIcon from "@mui/icons-material/Group";
import { toast, ToastContainer } from "react-toastify";
import api from "../../axiosConfig";
import Resheader from "./Resheader";
import Resmenu from "./Resmenu";
interface Order {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  Customerid: string;
  GrandTotal: string;
  OrderStatus: string;
  Orderdate: string;
  Orderid: string;
  Ordertime: string;
  Paymentmode: string;
  Products: Array<{
    Amount: string;
    ArabicName: string;
    ProductName: string;
    category_id: string;
    createdBy: string;
    createdOn: {
      $date: string;
    };
    image_url: string;
    quantity: number;
    _id: {
      $oid: string;
    };
  }>;
  Discount: number;
  Subtotal: string;
  table_number: string;
  totalAmount: number;
  totalQuantity: number;
  guess: string;
  _id: {
    $oid: string;
  };
}

const Respayment = () => {
  const { id } = useParams<{ id: string }>();
  console.log(id);
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order | null>(null);
  const [showPrintContent, setShowPrintContent] = useState(false);
  const [tip, setTip] = useState<number>(0);
  const [customTip, setCustomTip] = useState<string>("");
  const [guests] = useState<number>(2);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [time, setTime] = useState({
    hours: "",
    minutes: "",
    seconds: "",
    day: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    date();
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/app/v1/pos/order/${id}`);
        setOrder(response.data.result || response.data);
        console.log("responseitem", response);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch order details");
      } finally {
        setLoading(false);
      }
    };
    fetchOrder();
  }, [id]);

  const total =
    (Number(order?.Subtotal) || 0) + tip - (Number(order?.Discount) || 0);

  const handleCustomTipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomTip(value);
    const numericValue = Number(value);
    setTip(numericValue);
  };
  const date = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: day,
      month: month,
      year: year,
    });
  };

  const totalProducts = order?.Products?.length || 0;

  const handleTipChange = (tipAmount: number) => {
    setTip(tipAmount);
    setCustomTip("");
  };

  const handlePayNow = async () => {
    const paymentupdate = {
      OrderStatus: "Completed",
      Paymenttime: `${time.hours}:${time.minutes}`,
      payments: [
        {
          Paymentmethod:paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1),
          ReceivedAmount: total,
        },
      ],
      Tip: tip,
      RemainingAmount: 0,
      Discount: order?.Discount,
      GrandTotal: order?.GrandTotal,
    };

    try {
      await api.put(`/app/v1/pos/order/${id}`, paymentupdate);
      toast.success("Order payment completed successfully");
      setTimeout(() => {
        setShowPrintContent(true);
        setTimeout(() => {
          window.print();
          setShowPrintContent(false);
          navigate("/v1/Reshome");
        }, 500);
      }, 1000);
    } catch (error) {
      console.error("Error updating payment", error);
      toast.error("Failed to complete the payment. Please try again.");
    }
  };

  const printerDetails = ({ order }: { order: any }) => (
    <div>
      <div className="Print_popup_restarunt">
        <h3 style={{ fontFamily: "cursive" }}>Invoice</h3>
        <div
          style={{
            padding: "3px",
            display: "grid",
            gridAutoFlow: "dense",
            gridTemplateRows: "auto auto auto",
            direction: "rtl",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <span>{order?.Orderdate}</span>
            <p>:Date</p>
          </div>
          <div style={{ display: "flex" }}>
            <span>{order?.guess || "N/A"}</span>
            <p>:Dine In</p>
          </div>
        </div>
        <hr style={{ width: "99%", border: "1px dotted black" }} />

        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>Name:</p>
            <span>{order?.CustomerName || "N/A"}</span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Type:</p>
            <span>Cash</span>
          </div>
        </div>


        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>items:</p>
            <span>{totalProducts}</span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Ph.No:</p>
            <span>{order?.CustomerPhoneNo || "N/A"}</span>
          </div>
        </div>

        <hr style={{ width: "99%" }} />

        <div style={{ width: "100%", marginTop: "5px" }}>
          <div
            style={{
              backgroundColor: "#e9eaea",
              display: "flex",
              justifyContent: "space-around",
            }}
          />
          <hr style={{ width: "99%" }} />

          <div style={{ display: "grid", width: "100%" }}>
            <div
              style={{
                backgroundColor: "#f4f4f4",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#e9eaea",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                <h4 style={{ margin: 0, flex: 1, textAlign: "left" }}>
                  Item(s)
                </h4>
                <h4 style={{ margin: 0, flex: 1, textAlign: "center" }}>Qty</h4>
                <h4 style={{ margin: 0, flex: 1, textAlign: "right" }}>
                  Price
                </h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                {order?.Products?.map(
                  (
                    product: { ProductName: any; quantity: any; Amount: any },
                    index: React.Key | null | undefined
                  ) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        fontSize: "14px",
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                      }}
                    >
                      <div style={{ flex: 1, textAlign: "left" }}>
                        {product.ProductName || "N/A"}
                      </div>
                      <div style={{ flex: 1, textAlign: "center" }}>
                        {product.quantity || 1}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        {product.Amount || 0}
                      </div>
                    </div>
                  )
                )}
              </div>

              <hr style={{ width: "100%", marginTop: "10px" }} />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>Sub Total</div>
                <div>
                  {/* {order?.Products?.reduce(
                    (acc: number, product: { price: any; quantity: any }) =>
                      acc + (product.price || 0) * (product.quantity || 1),
                    0
                  )} */}
                  <b>{order?.Subtotal || 0}</b>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <b style={{ width: "60%" }}>Total Price</b>
            <b>{order?.Subtotal || 0}</b>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginRight: "50px",
            }}
          >
            <b style={{ width: "60%", marginRight: "55px" }}>Service Charges</b>
            <b>{order?.ServiceCharge || 0}</b>
          </div>

          <hr style={{ width: "99%" }} />

          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "5px",
              }}
            >
              <h2 style={{ width: "60%" }}>Grand Total</h2>
              <h2>{order?.GrandTotal || 0}</h2>
            </div>
          </div>

          <hr style={{ width: "99%" }} />
        </div>

        <div className="footer">
          <i>Thank you !!! & Visit Again !!!</i>
          <b>Powered by iMetanic</b>
        </div>
      </div>
    </div>
  );

  const oldOrders = () => {};

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Resheader Resheader={"Payment"} OldOrders={oldOrders} />
      <div style={{ display: "flex", flex: 1 }}>
        <Resmenu Resmenu={""} />

        {/* Main Content */}
        <div style={{ flex: 2, padding: "20px" }}>
          <div className="order-no">
            <h3>ORDER #: {order?.Orderid}</h3>
            <div className="order-no">
              <h3>Table:{order?.table_number}</h3>
            </div>
          </div>
          <hr className="custom-line" />

          {/* Material UI Table */}
          <div className="custom-table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>ITEM</th>
                  <th>PRICE</th>
                  <th>QTY</th>
                </tr>
              </thead>
              <tbody>
                {order?.Products?.map((product, index) => (
                  <tr key={index}>
                    <td>{product.ProductName}</td>
                    <td>{product.Amount}</td>
                    <td>{product.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        
        {/* Payment Section */}
        <div className="payment-section">
          <div className="payment-box-1">
            <h4>PAYABLE AMOUNT</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>${(Number(order?.GrandTotal ?? 0) + tip).toFixed(2)}</h2>
              <p>
                <GroupIcon style={{ marginRight: "5px" }} /> Guests: {guests}
              </p>
            </div>
          </div>
          <hr className="custom-line" />

          {/* Tip Selection */}
          <div className="tip-buttons">
            <p>ADD TIP</p>
            <Button
              variant={tip === 0 ? "contained" : "outlined"}
              onClick={() => handleTipChange(0)}
            >
              No Tip
            </Button>

            <TextField
              label="Custom Tip"
              variant="outlined"
              type="number"
              value={customTip}
              onChange={handleCustomTipChange}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <hr className="custom-line" />

          <div className="payment-options">
            <div className="payment-options-btn">
              <div
                className="icon-button-box"
                onClick={() => setPaymentMethod("cash")}
                style={{
                  backgroundColor:
                    paymentMethod === "cash" ? "green" : "transparent",
                }}
              >
                <IconButton style={{ marginTop: "-15px", bottom: "5px" }}>
                  <PaymentsIcon />
                  <p
                    style={{
                      marginTop: "38px",
                      fontSize: "small",
                      marginLeft: "-32px",
                    }}
                  >
                    CASH
                  </p>
                </IconButton>
              </div>

              <div
                className="icon-button-box"
                onClick={() => setPaymentMethod("card")}
                style={{
                  backgroundColor:
                    paymentMethod === "card" ? "green" : "transparent",
                }}
              >
                <IconButton style={{ marginTop: "-15px", bottom: "5px" }}>
                  <CreditCardIcon />
                  <p
                    style={{
                      marginTop: "38px",
                      fontSize: "small",
                      marginLeft: "-32px",
                    }}
                  >
                    CARD
                  </p>
                </IconButton>
              </div>
              {/* <div className="icon-button-box" onClick={() => setPaymentMethod('voucher')}>
                                <IconButton style={{ marginTop: "-18px", marginLeft: '10px', bottom: "5px" }}>
                                    <PaymentsIcon />
                                    <p style={{ marginTop: "40px", fontSize: "small", marginLeft: "-40px" }}>VOUCHER</p>
                                </IconButton>
                            </div> */}
            </div>
          </div>
          <hr className="custom-line" />

          <hr className="custom-line" />

          {/* Total Summary */}
          <dl className="total-summary" style={{ marginBottom: "20px" }}>
            <dt>
              {" "}
              <h4>Subtotal</h4> <p> {order?.Subtotal} </p>
            </dt>
            <dt>
              <h4>Tips</h4> <p> {tip.toFixed(2)}</p>
            </dt>
            <dt>
              <h4>Discount </h4> <p> {order?.Discount}</p>
            </dt>
          </dl>
          <hr className="custom-line" />

          <div className="total-payment">
            <h3>Total: </h3>
            <h3>{total.toFixed(2)}</h3>
          </div>

          {/* Pay Now Button */}
          {order?.OrderStatus.toLowerCase() === "completed" ? (
            <div className="payment-completed-note">
              <h2 style={{ color: "green", textAlign: "center" }}>
                Payment is completed
              </h2>
            </div>
          ) : (
            <div className="pay-now-btn">
              <Button
                onClick={handlePayNow}
                disabled={!paymentMethod}
                className={`paynow-enable-btn ${
                  !paymentMethod ? "disabled" : ""
                }`}
              >
                <h2
                  style={{
                    fontSize: "x-large",
                    textAlign: "center",
                    margin: 0,
                  }}
                >
                  P A Y &nbsp; N O W
                </h2>
              </Button>
            </div>
          )}
          {/* <Button variant="contained" color="primary" onClick={handlePrint}>
            Print Invoice
          </Button> */}
          <ToastContainer autoClose={1000} />
        </div>
        {showPrintContent && (
          <div className="print-section">{printerDetails({ order })}</div>
        )}
      </div>
    </div>
  );
};

export default Respayment;
