import React, { ChangeEvent, useEffect, useState } from "react";
import "./OrderSearch.css";
import { Checkbox, FormControl, FormControlLabel, InputBase, InputLabel, NativeSelect, Radio, RadioGroup, styled, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { Flip, ToastContainer, toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/Check';
// import money from "../../../assets/Images/1635d86b32b60583e7f022b9c3324b3d.png"
import money from '../../../assets/Images/1635d86b32b60583e7f022b9c3324b3d.png';
// import payimg from "../../../assets/Images/pay.png"
import payimg from '../../../assets/Images/pay.png';
// import Dones from "../../../assets/Images/Done.png"
// import Dones from "../../../../assets/Images/success-image.png";
import Dones from '../../../assets/Images/success-image.png';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import api from "../../axiosConfig";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";
import Buttons from "../../common-component/Buttons";
import LoadingScreen from "../../common-component/LoadingScreen";
import Print from "../../components/laundry/Print";
// import LoadingScreen from "../../../common-component/LoadingScreen";

interface Getdata {
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    arabicword: string;
    amount: number;
    quantity: number;
    totalPrice: number;
  }>;
  Orderdate: string;
  Ordertime: string;
  payments: [
    {
      Paymentmethod: string;
      ReceivedAmount: number;
    }
  ]
  RemainingAmount: number;
  Paymentmode: string;
  OrderStatus: string;
  discount: number;
  Finalamount: number;
  totalAmount: number;
  totalQuantity: number;
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: number;
  Customerid: string;
  Orderid: string;
}
interface Customerdata {
  _id: {
    $oid: string;
  };
  Name: string;
  phone_number: string;
  Email: string;
  Out_standing_balance: string;
  Order_Details: Array<{
    ordernaviid: string;
    Orderdate: string;
    ReceivedAmount: string;
    RemainingAmount: number;
    orderid: string;
    Totalamount: string;
    Orderamount: string;
  }>;
  Address: {
    customeraddress1: string;
    customeraddress2: string;
    customeraddress3: string;
  };
}

interface Product {
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    amount: number;
    quantity: number;
    totalprice: number;
  }>;
  Orderdate: string;
  Ordertime: string;
  ReceivedAmount: number;
  RemainingAmount: number;
  Paymentmethod: string;
  OrderStatus: string;
  discount: number;
  Finalamount: number;
  totalAmount: number;
  totalQuantity: number;
  CustomerEmail: string;
  CustomerName: string;
  customer_id: number;
  CustomerPhoneNo: number;
  Customerid: string;
  Orderid: string;
}

interface OrganizationDetails {
  address_line_1: string;
  business_type: string;
  country: string;
  state: string;
  org_arabic_name?: string;
  business_location?: string | null;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 22px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdf",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const OrderDisplay: React.FC = () => {
  const [getorder, setGetOrder] = useState<Getdata>();
  const [buttonsname, setBproducts] = useState<Product>();
  const [bproducts, setbuttonname] = useState<Getdata>();
  const [confirmemail, setconfirmemail] = useState("");
  const [emailerror, setEmailerror] = useState<boolean>(false);
  const [email, setEmail] = useState("")
  const [geterror, setGeterror] = useState<any>("");
  const [move, setmove] = useState<string>("");
  const [loading, setloading] = useState<boolean>(false);
  const [shopping, setshopping] = useState<boolean>(false);
  const [model, setmodel] = useState<boolean>(false);
  const [glowery, setglowery] = useState<boolean>(false);
  const [agent, setAgent] = useState<boolean>(false);
  const [paylate, setpaylate] = useState<boolean>(false);
  const [paycard, setpaycard] = useState<boolean>(false);
  const [cashpay, setcashpay] = useState<boolean>(false);
  const [Customer, setCustomer] = useState<Customerdata>();
  const [statuscancel, setStatuscancel] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [condition, setcondition] = useState<number>(0);
  const [emaihas, setEmailhas] = useState<boolean>(false);
  const [dis_value, setdis_value] = useState<number>()
  const [total, setTotal] = useState<number>();
  const [discount] = useState<number>()
  const [ordersucess, setOrdersucess] = useState<Getdata>();
  const [orderconfirm, setorderconfirm] = useState<string>("")
  const [showPaymentMethods, setShowPaymentMethods] = useState<string>("");
  const [ffamilys, setffamily] = useState<string>("")
  const [background, setbackground] = useState<any>("");
  const [showBalance, setShowBalance] = useState<boolean>(false);
  const [outstangingamount, setoutstangingamount] = useState<Array<any>>([]);
  const [employeesData, setemployeesData] = useState(false);
  const [logo, setlogo] = useState<any>();
  const [filteredData, setFilteredData] = useState<OrganizationDetails[]>([]);
  const [filteredCustomerId, setFilteredCustomerId] = useState<number | string>("");
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [time, setTime] = useState({
    hours: "",
    minutes: "",
    seconds: "",
    day: "",
    month: "",
    year: ""
  })
  const navigate = useNavigate();
  const { orderid } = useParams();
  useEffect(() => {
    date()
    const bussness = sessionStorage.getItem("organization_name")
    const storedColor = sessionStorage.getItem(`background_color${bussness}`);
    const ffamily = sessionStorage.getItem(`font_family${bussness}`);
    if (ffamily) {
      setffamily(ffamily)
      document.documentElement.style.setProperty('--fontfamily', ffamily);
    }
    if (storedColor) {
      setbackground(storedColor);
      document.documentElement.style.setProperty('--background-color', storedColor);
    }
  }, [])
  useEffect(() => {
    document.title = "Order Details - Orders - iMetanic";

    if (!orderid) {
      return;
    }

    const getOrdersIn = async () => {
      try {
        const res = await api.get(`/app/v1/pos/order/${orderid}`);
        const buttonData: Getdata = res.data.result;
        setbuttonname(buttonData);
        setOrdersucess(buttonData);
        const emailvf = res.data.result.CustomerEmail;
        if (!emailvf) {
          setEmailhas(false)
        } else {
          setEmailhas(true)
        }
        const customerId = buttonData?.Customerid;
        if (customerId) {
          const customerRes = await api.get(`/app/v1/pos/customer/${customerId}`);
          const customerData: Customerdata = customerRes.data.result;
          setCustomer(customerData);
        }

        const customerid = buttonData?.Customerid;
        console.log(customerid);

        if (customerid) {
          const ledgerRes = await api.get(`/pos/ledger/${customerid}`);
          const osb = ledgerRes.data.ledger.map((i: { total_outstanding_balance: any; }) => i.total_outstanding_balance)
          setoutstangingamount(osb);
        }

        if (buttonData?.OrderStatus.includes("Waiting for delivery")) {
          setShowPaymentMethods("true");
        }
        // if(buttonData?.OrderStatus.includes("delivered")){
        //   setShowPaymentMethods("true");
        // }

        setGetOrder(buttonData);

        if (buttonData?.OrderStatus === "Cancel") {
          setStatuscancel(true);
        }
        setmove(buttonData?.OrderStatus || "");
        setTotal(buttonData?.Finalamount || 0);
      } catch (err) {
        console.log(err);
      }
    };

    getOrdersIn();
  }, [orderid])

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const updateCustomerEmail = async () => {
      if (!getorder || !getorder.Customerid) {
          console.error("Customer ID is missing or getorder is undefined.");
          toast.error("Customer ID is missing, cannot update email.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
          return;
      }
  
      if (confirmemail) {
          const updatePayload = { Email: confirmemail };
          let toastId1 = toast.success("Customer email updated successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
  
          try {
              await api.put(`/app/v1/pos/customer/${getorder.Customerid}`, updatePayload);
              console.log("Customer email updated.");
  
              // await sleep(2000); 
              toast.dismiss(toastId1);
  
              setTimeout(() => {
                  setAgent(false);
                  if (confirmemail && ordersucess) {
                      orderemailmsg(
                          email,
                          String(ordersucess.payments[0].ReceivedAmount),
                          String(ordersucess.Orderid),
                          ordersucess.Finalamount,
                          String(ordersucess.RemainingAmount),
                          Customer?.Name
                      );
                  }
              }, 2000);
  
              setGeterror("");
              setAgent(false);
              if (confirmemail && ordersucess) {
                  orderemailmsg(
                      email,
                      String(ordersucess.payments[0].ReceivedAmount),
                      String(ordersucess.Orderid),
                      ordersucess.Finalamount,
                      String(ordersucess.RemainingAmount),
                      Customer?.Name
                  );
              }
          } catch (err) {
              console.error("Error updating customer email:", err);
              toast.error("Email already exists", {
                  position: "top-right",
                  autoClose: 2100,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
          }
      } else {
          toast.error("No email provided to update.", {
              position: "top-right",
              autoClose: 2100,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
      }
  };
  
  const orderemailmsg = (
    Email: any,
    ResAmount: string,
    id: string,
    Tamount: number,
    Ramount: string,
    cnames: any
  ) => {
    const toastId3 = toast.loading("Waiting...", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    const sendmail = {
      receiver_email: Customer?.Email ? Customer?.Email : email,
      cnames: Customer?.Name,
      id: id,
      TotalAmount: `${Tamount}`,
      ReceivedAmount: ResAmount,
      RemainingAmount: Ramount,
      file: sendpdf(),
    };
    console.log("sendmail", sendmail);
    api
      .post(`/app/v1/pos/orders_send_email`, sendmail)
      .then((res) => {
        console.log(res);
        toast.dismiss(toastId3);
        const toastId4 = toast("Mail sent successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.href = "/v1/home";
        }, 2000);
      });
  };

  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log(value)
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const validDomains = ['.com', '.in', '.co'];
      const hasValidDomain = validDomains.some(domain => value.endsWith(domain));
      return hasValidDomain;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setEmail(value);
      setconfirmemail(value)
      setEmailerror(false)
    } else {
      setEmail(value);
      setEmailerror(true)
    }
  }
  useEffect(() => {
    const employee = () => {
      const employeeData = sessionStorage.getItem('employee')
      if (employeeData) {
        setemployeesData(true);
      } else {
        setemployeesData(false);
      }
    }
    employee()
    const bussnessname = sessionStorage.getItem("organization_name")
     const logo = sessionStorage.getItem(`Logo${bussnessname}`)
     setlogo(logo)
  }, []);

  api.get(`/app/v1/pos/order/${orderid}`)
    .then((res) => {
      setorderconfirm(res.data._id);
    });
    
    const cancelOrder = () => {
      setIsCancelDisabled(true)
      api.put(`/app/v1/pos/cancel_order?orderid=${orderid}`, { OrderStatus: "Canceled" })
        .then((res) => {
          console.log("Order canceled response:", res);
          setmove("Canceled");
          console.log("Calling toast success");
          toast.success("Order canceled successfully", { autoClose: 2000 , theme: "colored", position: "top-right",});
          setTimeout(() => {
            navigate("/v1/order");
          }, 2000);
        })
        .catch((err) => {
          console.log("Calling toast error");
          toast.error("Failed to cancel order", { autoClose: 2000 });
          console.log(err);
        });
    };

  const navigationprint = () => {
    if (employeesData) {
      navigate(`/v1/employee/print/${orderid}`)
    } else {
      navigate(`/v1/print/${orderid}`)
    }
  }

  const SendMail = {
    Blue: "blue",
    name: "Send Mail",
  };
  const SendMessage = {
    Blue: "blue",
    name: "Send Message",
  };
  const Done = {
    Blue: "blue",
    name: "done",
  };
  const generatePdf = async (): Promise<Blob> => {
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Invoice</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  background-color: #f8f9fa;
              }
              .invoice-box {
                  max-width: 800px;
                  margin: auto;
                  padding: 20px;
                  border: 1px solid #ddd;
                  background-color: #fff;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
              }
              .invoice-header {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .invoice-header h1 {
                  margin: 0;
              }
              .invoice-header p {
                  margin: 0;
                  font-size: 14px;
                  color: #888;
              }
              .invoice-details {
                  margin-bottom: 20px;
              }
              .invoice-details .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
              }
              .invoice-details .detail span {
                  font-size: 16px;
              }
              .invoice-details .detail .label {
                  font-weight: bold;
              }
              .product-list {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
              }
              .product-list th, .product-list td {
                  padding: 10px;
                  border: 1px solid #ddd;
                  text-align: left;
              }
              .product-list th {
                  background-color: #f1f1f1;
              }
              .total {
                  text-align: right;
                  font-size: 18px;
                  font-weight: bold;
              }
              .payment-info {
                  margin-top: 20px;
              }
              .payment-info .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
              }
          </style>
      </head>
      <body>
      <div class="invoice-box">
          <div class="invoice-header">
              <h1>Invoice</h1>
              <p>Company Name: <strong>Imetanic LLP</strong></p>
          </div>
          <div class="invoice-details">
              <div class="detail">
                  <span class="label">Customer Name:</span>
                  <span>{CustomerName}</span>
              </div>
              <div class="detail">
                  <span class="label">Order Date:</span>
                  <span>{Orderdate}</span>
              </div>
          </div>
          <table class="product-list">
              <thead>
                  <tr>
                      <th>S No</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Total</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>1</td>
                      <td>Product 1</td>
                      <td>2</td>
                      <td>50 QAR</td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>Product 2</td>
                      <td>1</td>
                      <td>25 QAR</td>
                  </tr>
              </tbody>
          </table>
          <div class="total">
              <span>Total Amount: 75 QAR</span>
          </div>
          <div class="payment-info">
              <div class="detail">
                  <span class="label">Received Amount:</span>
                  <span>75 QAR</span>
              </div>
              <div class="detail">
                  <span class="label">Remaining Amount:</span>
                  <span>0 QAR</span>
              </div>
              <div class="detail">
                  <span class="label">Payment Mode:</span>
                  <span>Cash</span>
              </div>
              <div class="detail">
                  <span class="label">Order Status:</span>
                  <span>Paid</span>
              </div>
          </div>
      </div>
      </body>
      </html>
    `;

    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.zIndex = '-9999';
    container.innerHTML = htmlContent;
    document.body.appendChild(container);

    const canvas = await html2canvas(container);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    const pdfBlob = pdf.output('blob');
    document.body.removeChild(container);
    return pdfBlob;
  };
  const sendpdf = async () => {
    const pdfBlob = await generatePdf();

    const formData = new FormData();
    formData.append('file', pdfBlob, 'Invoice.pdf');

    try {
      const response = await fetch('YOUR_SERVER_ENDPOINT', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('PDF sent successfully:', responseData);
    } catch (error) {
      console.error('Error sending PDF:', error);
    }
  }

  useEffect(() => {
    api
      .get("/pos/get_user")
      .then((res) => {
        console.log("API Response:", res.data.result);
        setFilteredData(res.data.result);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const customerId  = bproducts?.Customerid;
  useEffect(() => {
    const fetchCustomerData = async () => {
      if (customerId ) {
        try {
          const response = await api.get(`/app/v1/pos/customer/${customerId }`);
          console.log("Full API Response:", response.data);
  
          const result = response.data.result || {};
  
          if (result && typeof result === "object") {
            const clientid = result.customer_ref_id;
            console.log("Extracted Customer ID:", clientid);
            setFilteredCustomerId(clientid);
          } 
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      } 
    };
  
    fetchCustomerData();
  }, [customerId ]);

  const businessArabicName =
  filteredData.find((item): item is OrganizationDetails => "org_arabic_name" in item)?.org_arabic_name || "";

  const businessLocation = filteredData.find((item): item is OrganizationDetails =>(item as OrganizationDetails).business_location !== undefined)?.business_location || "";

  const printData = {
    CustomerName: bproducts?.CustomerName || "",
    CustomerPhon: bproducts?.CustomerPhoneNo?.toString() || "",
    OrderId: bproducts?.Orderid || "",
    OrderStatus: bproducts?.OrderStatus || "",
    Date: bproducts?.Orderdate || "",
    Time: bproducts?.Ordertime || "",
    Products: bproducts?.Products || [],
    Paid: Math.max(
      0,
      bproducts?.payments.reduce((sum, i) => sum + (i.ReceivedAmount || 0), 0) || 0
    ),
    unpaid: Math.max(
      0,
      Number(bproducts?.Finalamount || 0) -
        Number(
          bproducts?.payments.reduce((sum, i) => sum + (i.ReceivedAmount || 0), 0) || 0
        )
    ),    
    OSB: Number(outstangingamount),
    busnessName: sessionStorage.getItem("organization_name") || "",
    busnessArabicName: businessArabicName,
    businessLocation: businessLocation,
    phoneNum: sessionStorage.getItem("business_phone") || "",
    total: bproducts?.totalAmount || "",
    clientId: filteredCustomerId,
    imglogo:logo
  };


  const date = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: day,
      month: month,
      year: year,
    })
  }
  const remaining = () => {
    if (Number(outstangingamount) === 0) {
      return (0);
    } else {
      const balance = getorder?.RemainingAmount
      if (Number(balance) >= 0) {
        return (balance)
      } else {
        return (0);
      }
    }
  }

  const Remainingbalance = () => {
    if (Number(getorder?.payments[0].ReceivedAmount) > Number(getorder?.Finalamount)) {
      return (getorder?.Finalamount);
    } else {
      return (getorder?.payments[0].ReceivedAmount);
    }
  }

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const order = { OrderStatus: event.target.value }
    api.patch(`/app/v1/pos/order/${orderid}`, order)
      .then((res) => {
        setmove(event.target.value);
        window.location.reload();
      });
  }

  const droupdown = () => {
    const handleDelivered = () => {
      toast.success("Order has been delivered successfully!", {
        autoClose: 2000,
      });
    };
    if (move === "Confirm") {
      // setloading(true)
      return (
        <FormControl sx={{ m: 1, fontFamily: ffamilys }} variant="standard" className="dropp_dow">
          <InputLabel htmlFor="demo-customized-select-native">
            Order status
          </InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={move}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <option className="dropp_dowwnn" value={"Confirm"} style={{ color: "green" }}>
              Confirm
            </option>
            <option className="dropp_downn" value={"In-process"} style={{ color: "#FFD700" }}>
              In-process
            </option>
            <option className="dropp_downn" value={"Waiting for delivery"} style={{ color: "blue" }}>
              Waiting for delivery
            </option>
            {/* <option
              className="dropp_dowwnn"
              value={"Delivered"}
              style={{ color: "green" }}
              onClick={handleDelivered}
            >
              Delivered
            </option> */}
          </NativeSelect>
        </FormControl>

      );
    } else if (move === "In-process") {
      return (
        <FormControl sx={{ m: 1 }} variant="standard" className="dropp_dow">
          <InputLabel htmlFor="demo-customized-select-native">
            Order status
          </InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={move}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <option className="dropp_downn" value={"In-process"} style={{ color: "#FFD700" }}>
              In-process
            </option>
            <option className="dropp_downn" value={"Waiting for delivery"} style={{ color: "blue" }}>
              Waiting for delivery
            </option>
            <option
              className="dropp_dowwnn"
              value={"Delivered"}
              style={{ color: "green" }}
              onClick={handleDelivered}
            >
              Delivered
            </option>
          </NativeSelect>
        </FormControl>
      );
    } else if (move === "Waiting for delivery") {
      return (
        <FormControl sx={{ m: 1 }} variant="standard" className="dropp_dow">
          <InputLabel htmlFor="demo-customized-select-native">
            Order status
          </InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={move}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <option className="dropp_downn" value={"Waiting for delivery"} style={{ color: "blue" }}>
              Waiting for delivery
            </option>
            {/* {buttonsname.map((item) => (
              <option className="dropp_dowwnn" value={"Delivered"} style={{ color: "green" }}>
                Deliverd
              </option>
            ))} */}
            <option
              className="dropp_dowwnn"
              value={"Delivered"}
              style={{ color: "green" }}
              onClick={handleDelivered}
            >
              Delivered
            </option>
          </NativeSelect>
        </FormControl>)
    }
    if (move === "Delivered") {
      return (
        <FormControl sx={{ m: 1 }} variant="standard" className="dropp_dow" >
          <InputLabel htmlFor="demo-customized-select-native">
            Order status
          </InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={move}
            onChange={handleChange}
            input={<BootstrapInput />}>
            <option className="dropp_dowwnn" value={"Delivered"} style={{ color: "green" }}>
              Delivered
            </option>
          </NativeSelect>
        </FormControl>
      );
    } else if (move === "Delivered") {
      return (
        <div>
          <p style={{ color: "green", fontSize: 18, fontWeight: 500 }}>
            Order has been delivered
          </p>
        </div>
      );
    } else if (move === "Canceled") {
      return (
        <FormControl sx={{ m: 1 }} variant="standard" className="dropp_dow">
          <InputLabel htmlFor="demo-customized-select-native">
            Order status
          </InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={move}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            <option className="dropp_dowwnn" value={"Canceled"} style={{ color: "red" }}>
              Canceled
            </option>
          </NativeSelect>
        </FormControl>
      );
    }
    return null
  }
  const orderback = () => {
    window.location.href = `/v1/order-details/${orderid}`
  }

  const COMPLETE = {
    Blue: "blue",
    name: "COMPLETE",
  };
  const CANCEL= {
    Cancel: "red",
    name: "CANCEL",
  };
  const Update= {
    Blue: "blue",
    name: "update",
  };
  const Cancel = {
    Cancel: "red",
    name: "Cancel",
  };
  const handleConfirm = (Payment: string) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const TOTALAMOUNT = Number(getorder?.totalAmount)
    const RESAMOUNT = Number(getorder?.payments[0].ReceivedAmount) + condition
    let ReceivedAmount = 0
    let RemainingAmount = 0
    if (TOTALAMOUNT >= RESAMOUNT) {
      ReceivedAmount = Number(getorder?.payments[0].ReceivedAmount) + condition
      RemainingAmount = TOTALAMOUNT - RESAMOUNT
    } else if (TOTALAMOUNT < RESAMOUNT) {
      ReceivedAmount = Number(getorder?.totalAmount)
      RemainingAmount = 0
    }
    const update = {
      Finalamount: Number(getorder?.totalAmount),
      RemainingAmount: RemainingAmount,
      OrderStatus: "Delivered",
      DeliveryDate: `${year}-${month}-${day}`,
      DeliveryTime: `${hours}:${minutes}:${seconds}`,
      // Paymentmode: Payment
      payments: [
        {
          Paymentmethod: Payment,
          ReceivedAmount: ReceivedAmount,
        },
      ],
    }
    console.log('update:', update);
    api.patch(`/app/v1/pos/order/${orderid}`, update)
      .then((res) => {
        setmodel(false);
        setglowery(false);
        setConfirm(true);
        api.get(`/app/v1/pos/order/${orderid}`)
          .then((res) => {
            const orderCollection: Getdata = res.data.result;
            setGetOrder(orderCollection);
            update_customer();
          })
      });
  };
  const Doneorder = () => {
    toast.success("The order has been taken successfully", { 
      autoClose: 10000, theme: "colored", position: "top-right", });
    setTimeout(() => {
      window.location.reload();
}, 2000);;
  };
  const update_customer = () => {
    const customerupdate = {
      id: getorder?.Customerid,
      outstanding_balance: Number(outstangingamount),
      new_order_amount: 0,
      pay_amount: condition,
      order_id: getorder?.Orderid,
      Orderdate: `${time.year}-${time.month}-${time.day}`,
      Ordertime: `${time.hours}:${time.minutes}`,

    };
    api.post(`/pos/ledger`, customerupdate)
      .then(res => { console.log(res) }
      ).catch(err => { console.log(err) })
  }

  let onename = Number(Customer?.Out_standing_balance)

  if (onename === 0) {
    let twonaame = Customer?.Order_Details.map(i => i.RemainingAmount)
    console.log(twonaame);

  }
  const handleclick = (e: ChangeEvent<HTMLInputElement>) => {
    let term = e.target.value;
    if (term.includes(".")) {
      let [integerPart, decimalPart] = term.split(".");
      integerPart = integerPart.replace(/^0+(?=\d)/, "");
      term = integerPart + (decimalPart ? "." + decimalPart : "");
    } else {
      term = term.replace(/^0+(?=\d)/, "");
    }
    if (term === "") {
      term = "0";
    }
    const value = parseFloat(term);
    const totalValue = total !== undefined ? total : 0;
    const outAmountValue = Outamount() !== undefined ? Outamount() : 0;

    if (typeof totalValue === 'number' && typeof outAmountValue === 'number') {
      if (value >= 0 && value <= totalValue) {
        setcondition(value);
      } else if (value >= 0 && value <= outAmountValue) {
        setcondition(value);
      } else {
        setcondition(0);
      }
    }
  };

  const outstandingvalue = () => {
    setcondition(0)
    setShowBalance(!showBalance)
  }

  const Outamount = () => {
    if (showBalance === true) {
      // setcondition(0)
      const outstanging = Number(cashoutstanging)
      const remaining = Number(getorder?.RemainingAmount)
      const amount_in = outstanging + remaining
      return amount_in
    } else if (showBalance === false) {
      // setcondition(0)
      const remaining = getorder?.RemainingAmount
      return (remaining)
    }
  }

  //cash Out standing balance
  const cashoutstanging = Number(outstangingamount) - Number(getorder?.RemainingAmount)
  const formatNumber = (num: number): string => {
    return num.toFixed(2);
  };

  //cash Remaining balance
  const remainingBalance = Number(Outamount()) - condition;
  const formattedBalance = remainingBalance.toFixed(2);

  //cash Remaining order price & Total order price
  const formatNumbers = (num: number): string => {
    return Number(num.toFixed(2)).toString();
  };

  const roundValue = (value: number | undefined) => {
    if (value === undefined) return 0;
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };

  const roundToTwoDecimal = (value: number) => {
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };
  const balanceAmount = roundToTwoDecimal(Number(Outamount()) - condition);

  const outstandingsuborderprice = Number(outstangingamount) - Number(getorder?.Finalamount);

  const payments = () => {
    if (paylate) {
      setmodel(true);
      setcashpay(false);
    }
    if (cashpay) {
      setglowery(true);
      setpaylate(false);
    }
  }

  const paymentmethods = () => {
    return (
      <FormControl component="fieldset" className="payment-methods-container">
        <RadioGroup
          value={paylate ? "paylate" : cashpay ? "cashpay" : "default"}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "paylate") {
              setpaylate(true);
              setcashpay(false);
            } else if (value === "cashpay") {
              setcashpay(true);
              setpaylate(false);
            }
          }}
          row
        >
          <FormControlLabel value="paylate" control={<Radio />} label="Buy on credit" disabled={paycard || cashpay} style={{ cursor: "pointer" }} />
          <FormControlLabel value="paycard" control={<Radio />} label="Card" disabled style={{ cursor: "wait" }} />
          <FormControlLabel value="cashpay" control={<Radio />} label="Cash" onClick={() => { setcashpay(true); setpaylate(false); }} style={{ cursor: "pointer" }} />
        </RadioGroup>
      </FormControl>
    );
  };
  return (
    <div style={{ width: "100%", height: '100%', overflow: "hidden", fontFamily: ffamilys, position: 'absolute' }}>
      <Header Header="Orders - details" />
      <div style={{ display: 'flex' }}>
        <Menu />
        <div className="order_stage">
          <div className="overall_page" >
            <ul style={{ fontWeight: 'bold' }} className="product_details">
              <li style={{ width: '100px' }}>S.No</li>
              <li style={{ width: '170px' }}>Product name</li>
              <li style={{ width: '120px' }}>Price(QAR)</li>
              <li style={{ width: '120px' }}>Total quantity</li>
              <li style={{ width: '120px' }}>Total(QAR)</li>
            </ul>
            {getorder?.Products.map((product, index) =>
              <ul key={product.amount} className="product_details">
                <li style={{ width: '100px' }}>{index + 1}</li>
                <li style={{ width: '170px' }}>{product.productName}</li>
                <li style={{ width: '120px' }}>{(product.amount ? Number(product.amount).toFixed(2) : '0.00')}</li>
                <li style={{ width: '120px' }}>{product.quantity}</li>
                <li style={{ width: '120px' }}>{product.totalPrice ? Number(product.totalPrice).toFixed(2) : '0.00'}</li>
              </ul>
            )}
          </div>
          <div className="customer_page">
            <h4 className="cart_no">ORDER ID: {getorder?.Orderid}</h4>
            <div className="Produt_name"><h3>Name</h3><h3>: {getorder?.CustomerName}</h3></div>
            <div className="price_detailss">
              <ul>
                <li><h3>Sub total</h3><p> {roundValue(getorder?.Finalamount)}</p></li>
                <li><h3>Discount</h3><p> {roundValue(getorder?.discount)}</p></li>
                <li><h3>Received price</h3><p> {roundValue(Remainingbalance())}</p></li>
                <li><h3>Remaining balance</h3><p> {roundValue(remaining())}</p></li>
                <li><h3>Outstanding balance</h3><p>{outstangingamount && outstangingamount.length 
                  > 0? roundValue(outstangingamount.reduce((sum, amount) => sum + amount, 0)): "No outstanding balance"}</p></li>
                <li><h3>Payment mode</h3><p>{getorder?.payments[0].Paymentmethod}</p></li>
                <li><h3>Order date</h3><p>{getorder?.Orderdate}</p></li>
                <li><h3>Order time</h3><p>{getorder?.Ordertime}</p></li>
              </ul>
            </div>
            <div className="drop_down_oredr" style={{ fontFamily: ffamilys }}>
              {droupdown()}
            </div>
            {
              showPaymentMethods && <>
                <div className="payment_type">Payment methods
                </div>
                < div className="payment_Methodss">
                  {remaining() === 0 ? (
                    <div>
                      <p>You have no remaining balance for this orders.</p>
                    </div>
                  ) : (
                    <div>{paymentmethods()}</div>
                  )}
                  {/* {
                    remaining() === 0 && <button>Delivered</button>
                  } */}
                  {remaining() === 0 ?
                    (<div>
                      <p>Please change the order status to delivered.</p>
                    </div>) :
                    (
                      <div className="payment_btn" onClick={() => payments()} >
                        <Buttons Buttondata={COMPLETE} />
                      </div>
                    )}
                </div>
              </>}
            <div className="button_tags" >
              {getorder?.OrderStatus !== 'Canceled' && (
                <div style={{ marginTop: '10px' }}>
                  <Print PrintData={{ ...printData, Paid: printData.Paid ?? 0 }} BtnName={'PRINT RECEIPT'} username=""/>
                </div>
              )}
              <div>
               <button
                 className={`cancel ${getorder?.OrderStatus === 'Canceled' ? 'disabled' : ''}`}
                 onClick={getorder?.OrderStatus !== 'Canceled' ? cancelOrder : undefined}
                 style={{backgroundColor: getorder?.OrderStatus === 'Canceled' ? '#B8B8B8' : '',
                 cursor: getorder?.OrderStatus === 'Canceled' ? 'not-allowed' : 'pointer', 
                 }}
                 disabled={getorder?.OrderStatus === 'Canceled' || isCancelDisabled}
                >
                 CANCEL
               </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        {model &&
          <div className="modals">
            <div className="overlays"></div>
            <div className="payment_popup">
              < div className="conform">
                <div className="popup-cash">
                  <div className="back-Arrow2-order">
                    <button onClick={orderback} className="atom-order">
                      <ArrowBackIcon style={{ marginTop: "-80px", marginBottom: "-20px", marginLeft: "-40px" }} />
                    </button>
                  </div>
                  <div className="popup-bill-cash">
                    <h1 style={{ marginTop: "20px" }}>
                      <button
                        onClick={orderback}
                        className="cancel-icon-order"
                      >
                        <CloseIcon />
                      </button>
                      Payment summary</h1>
                    <img
                      src={payimg}
                      alt=""
                      style={{ width: "54px", height: "54px" }}
                    />
                    <h3>Selected customer</h3>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      color: "#035bc9",
                      marginRight: '150px',
                      fontSize: "20px",
                    }}>
                      <p>Payment method </p>
                      <p>Customer name</p>
                      <p>Order date</p>
                      <p>Order time</p>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginLeft: '160px',
                      position: 'relative',
                      color: "#035bc9",
                      bottom: '112px',
                      fontSize: "20px",
                    }}>
                      <p>: Buy on credit</p>
                      <p>: {getorder?.CustomerName}</p>
                      <p>: {getorder?.Orderdate}</p>
                      <p>: {getorder?.Ordertime}</p>
                    </div>
                  </div>
                </div>
                <div style={{ display: "grid", marginTop: '-330px', marginLeft: '40px' }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        maxWidth: "220px",
                        minWidth: "190px",
                        flexDirection: "column",
                        marginLeft: "-10px",
                        height: "174px",
                        justifyContent: "space-around",
                      }}>
                      <p style={{ fontSize: '18px' }}>Outstanding balance:</p>
                      <p style={{ fontSize: '18px' }}>Order price:</p>
                      {/* <p style={{ fontSize: '18px' }}>Total quantity :</p> */}
                      <p style={{ fontSize: '18px' }}>Discount :</p>
                      <p style={{ fontSize: '18px' }}>Final price (Incl Discount) :</p>
                      <p style={{ fontSize: '18px' }}>Total (Incl Outstanding):</p>
                    </div>
                    <div style={{ display: "grid" }}>
                      <img
                        src={money}
                        alt=""
                        style={{ height: "35px", width: "45px" }}
                      />
                      <img
                        src={money}
                        alt=""
                        style={{ height: "35px", width: "45px" }}
                      />
                      <img
                        src={money}
                        alt=""
                        style={{ height: "35px", width: "45px" }}
                      />
                      <img
                        src={money}
                        alt=""
                        style={{ height: "35px", width: "45px" }}
                      />
                      <img
                        src={money}
                        alt=""
                        style={{ height: "35px", width: "45px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "174px",
                        justifyContent: "space-around",
                      }}>
                      <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>{roundValue(outstandingsuborderprice)} QAR</p>
                      <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>{roundValue(typeof getorder?.totalAmount === 'number' ? getorder.totalAmount : undefined)} QAR</p>
                      {/* <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>{getorder?.totalQuantity} QAR</p> */}
                      <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>{roundValue(typeof getorder?.discount === 'number' ? getorder.discount : undefined)} QAR</p>
                      <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>{roundValue(typeof getorder?.Finalamount === 'number' ? getorder.Finalamount : undefined)} QAR</p>
                      <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>{roundValue(outstangingamount[0])} QAR</p>
                    </div>
                  </div>
                </div>
                <div className="btn_con">
                  <div onClick={orderback}>
                    <Buttons Buttondata={CANCEL} />
                  </div>
                  <div onClick={() => handleConfirm("Buy on Credit")}>
                    <Buttons Buttondata={COMPLETE} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }{
          confirm &&
          <div className="modals">
            <div className="overlays"></div>
            {/* <div className="payment_popup">
              <div className="confirmhead">
                <div className="Payconfirm_Icon">
                  <CheckIcon className="icons_success" sx={{ fontSize: '50px', }} />
                </div>
                <div ><p style={{ display: "flex", justifyContent: "center", width: '350px', fontSize: "20px", }}>Order was delivered successfully</p>
                </div>
              </div>
              <div className="conform">
                <Buttons Buttondata={Button} />
              </div>
            </div> */}
          </div>
        }
      </div>
      {glowery &&
        <div className="modals">
          <div className="overlays"></div>
          <div className="payment_popup">
            <div className="conform">
              <div className="popup-cash">
                <div className="back-Arrow2-order">
                  <button onClick={orderback} className="atom-order">
                    <ArrowBackIcon style={{ marginBottom: "5px", marginLeft: "-30px" }} />
                  </button>
                </div>
                <div className="popup-bill-cash">
                  <h1>
                    <button
                      onClick={orderback}
                      className="cancel-icon-order-cash"
                    >
                      <CloseIcon />
                    </button>
                    Payment summary</h1>
                  <img
                    src={payimg}
                    alt=""
                    style={{ width: "45px", height: "45px", marginTop: "10px" }}
                  />
                  <h3>Selected customer</h3>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    color: "#035bc9",
                    minWidth: "190px",
                    maxWidth: "210px",
                    marginRight: '130px',
                    fontSize: "20px",
                  }}>
                    <p>Payment method</p>
                    <p>Customer name</p>
                    <p>Order date</p>
                    <p>Order time</p>
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginLeft: '140px',
                    position: 'relative',
                    color: "#035bc9",
                    bottom: '112px',
                    fontSize: "20px",
                  }}>
                    <p>: Cash</p>
                    <p>: {getorder?.CustomerName}</p>
                    <p>: {getorder?.Orderdate}</p>
                    <p>: {getorder?.Ordertime}</p>
                  </div>
                </div>
              </div>
              <div style={{ display: "grid", marginTop: '-330px', marginLeft: '40px' }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      maxWidth: "220px",
                      minWidth: "190px",
                      flexDirection: "column",
                      height: "240px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ display: 'flex', marginLeft: '-42px', marginTop: '-10px' }} >
                      <Checkbox onClick={outstandingvalue} />
                      <p style={{ marginTop: '10px', fontSize: '18px' }}>Outstanding balance:</p></div>
                    <p style={{ fontSize: '18px', }}>Order price      :</p>
                    <p style={{ fontSize: '18px', }}>Discount  :</p>
                    <p style={{ fontSize: '18px', }}>Final price (Incl Discount)   :</p>
                    <p style={{ fontSize: '18px', }}>Received price   :</p>
                    <p style={{ fontSize: '18px', }}>Remaining price  :</p>
                  </div>
                  <div style={{
                    display: "flex",
                    width: "",
                    flexDirection: "column",
                    height: "250px",
                    justifyContent: "space-around",
                  }}>
                    <img
                      src={money}
                      alt=""
                      style={{ height: "38px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "38px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "38px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "38px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "38px", width: "50px" }}
                    />
                    <img
                      src={money}
                      alt=""
                      style={{ height: "38px", width: "50px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "250px",
                      justifyContent: "space-around",
                    }}>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      {formatNumber(cashoutstanging)} QAR</p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      {/* {formatNumbers(getorder?.RemainingAmount ?? 0)} QAR */}
                      {roundValue(getorder?.totalAmount)} QAR
                    </p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      {roundValue(getorder?.discount)} QAR
                    </p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      {formatNumbers(Outamount() ?? 0)} QAR
                    </p>
                    {/* <p style={{ color: "#D70015",fontSize: "20px", fontWeight: "bold" }}>
                          value={discount}
                        </p> */}
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      <input
                        className="psinput-cash"
                        value={condition.toString()}
                        type="number"
                        step="any"
                        onChange={handleclick}
                      /> QAR
                    </p>
                    <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                      {formattedBalance} QAR
                    </p>
                  </div>
                </div>
              </div>
              <div className="btn_con_cash">
                <div onClick={orderback}>
                  <Buttons Buttondata={CANCEL} />
                </div>
                <div onClick={() => handleConfirm("Cash")}>
                  <Buttons Buttondata={COMPLETE} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }{
        confirm &&
        <div className="modals">
          <div className="overlays">
            <div className="conform-done">
              <div className="popup-cash">
                <img
                  src={Dones}
                  alt=""
                  style={{
                    width: "250px",
                    height: "250px",
                    marginLeft: "65px",
                    marginBottom: "70px",
                    marginTop: "-20%",
                  }}
                />
                <p style={{ marginLeft: "20%", width: "90%", marginTop: '-10%', textAlign: "center" }}>Your payment has been completed successfully</p>
                {/*  <button onClick={() => navigationprint()}
                  className="print-button"
                  style={{ marginLeft: "80px", marginBottom: '20px', position: 'relative', bottom: '10px', backgroundColor: 'rgb(93, 138, 235)', color: 'white', fontWeight: 'bold', width: '34%', border: 'none' }}>

                  PRINT RECEIPT
                </button>*/}

                <div className="Confirm_Print_mail">

                  {emaihas ? (<div
                    onClick={() => {
                      orderemailmsg(
                        Customer?.Email,
                        String(ordersucess?.payments[0].ReceivedAmount),
                        String(ordersucess?.Orderid),
                        Number(ordersucess?.Finalamount),
                        String(ordersucess?.RemainingAmount),
                        Customer?.Name
                      );
                    }}
                  >
                    <Buttons Buttondata={SendMail} />
                  </div>) : (
                    <>
                      <div
                        style={{ marginRight: "10px" }}
                        id="done_btn"
                        onClick={() => setAgent(!agent)}
                      >
                        <Buttons Buttondata={SendMail} />
                      </div>
                      {!Customer?.Email && (
                        agent && (
                          <div className="modals">
                            <div className="overlays"></div>
                            <div className="modal-contents">
                              <div className="edit-popupscreen">
                                <div className="modal-order_header">
                                  <h4>Please enter the Email address</h4>
                                </div>
                                <div className="input_textfield_order">
                                  <TextField
                                    className="input_email_text"
                                    id="outlined-basic"
                                    label="Email ID"
                                    sx={{ width: "200%" }}
                                    value={email}
                                    error={!!geterror}
                                    helperText={geterror || "Please enter your email in the format 'id@example.com'"}
                                    onChange={customeremailid}
                                    variant="outlined"
                                  />
                                </div>
                                <div className="modal-footer">
                                  <div onClick={() => setAgent(false)}>
                                    <Buttons Buttondata={Cancel} />
                                  </div>
                                  <div style={{ marginLeft: "40px" }} onClick={updateCustomerEmail}>
                                    <Buttons Buttondata={Update} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </>)}
                  <div>
                    <Buttons Buttondata={SendMessage} />
                  </div>
                  <div onClick={Doneorder}>
                    {/* <Buttons Buttondata={Done} /> */}
                    <Print PrintData={{ ...printData, Paid: printData.Paid ?? 0 }} BtnName={'Done'} username=""/>
                  </div>
                </div>
              </div>
            </div>
            {loading ? <LoadingScreen /> : ""}
            <ToastContainer />
          </div>
        </div>
      }
      {/* {print?<Print id={orderid}/>:''} */}
    </div >
  );
};

export default OrderDisplay;
