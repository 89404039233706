import { Box, TextField } from "@mui/material";
import "./Edit.css";
import React, { useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import LoadingScreen from "../../common-component/LoadingScreen";

interface Props {
  id: string;
  Name: string;
  onUpdate: (id: string, newName: string) => void;
  editCategory: () => void;
}

const EditCategory: React.FC<Props> = ({
  id,
  Name,
  onUpdate,
  editCategory,
}) => {
  const [errormsg, setErrormsg] = useState<boolean>();
  const [Loading, setLoading] = useState<boolean>(false);
  const [categoryname, setCategoryname] = useState<any>(Name);
  const [update, setUpdate] = useState<boolean>(false);
  const [visible, setvisible] = useState<Boolean>(false);
  const [modal, setModal] = useState<Boolean>(true);

  const handlecategorynamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (categoryname !== Name) {
        if (categoryname) {
          categoryupdate();
        } else {
          toast.error("Please fill in required fields.");
        }
      }
    }
  };

  useEffect(() => {
    setCategoryname(Name);
  }, [Name]);

  useEffect(() => {
    setvisible(categoryname !== Name && categoryname.length > 0);
  }, [categoryname, Name]);

  const categoryupdate = async () => {
    if (categoryname) {
      const categorynamechange = {
        CategoryName: categoryname,
      };

      try {
        const res = await api.put(
          `/app/v1/pos/category/${id}`,
          categorynamechange
        );
        console.log("res", res.status);

        toast.success("Category updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        editCategory();
        setLoading(false);
        onUpdate(id, categoryname);
      } catch (error: unknown) {
        if (error instanceof Error) {
          const errormsgs =
            error.message === "Request failed with status code 403";
          setErrormsg(errormsgs);

          if (errormsgs) {
            toast.error("Category name already exists", {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }
        } else {
          console.error("An unexpected error occurred:", error);
        }
        setLoading(false);
      }
    } else {
      toast.error("Please fill in required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const updateButton = {
    Blue: "blue",
    name: "update",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const errorupdateButtonerr = {
    error: "blue",
    name: "update",
  };

  const confirm = () => {
    if (visible && categoryname.length > 0) {
      return (
        <div onClick={categoryupdate}>
          <Buttons Buttondata={updateButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={errorupdateButtonerr} />
        </div>
      );
    }
  };

  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="modal-contents">
        <div className="edit-popupscreen">
          <div className="addcategory-editadd">
            <h2>
              Edit category
              <button
                onClick={() => editCategory()}
                className="cancel-icon-Editcategory"
              >
                <CloseIcon />
              </button>
            </h2>
            <div className="addcategory-edit">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": {
                    m: 1,
                    width: "50ch",
                    marginTop: "40px",
                    borderBlockWidth: "20px",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                {errormsg ? (
                  <TextField
                    label="Category Name"
                    error
                    value={categoryname}
                    onChange={(e) =>
                      setCategoryname(e.target.value.toUpperCase())
                    }
                    onClick={() => setUpdate(!update)}
                    onKeyPress={handlecategorynamesKeyPress}
                  />
                ) : (
                  <TextField
                    label={
                      <span>
                        Category name{" "}
                        <span style={{ color: "#f04141", fontSize: "15px" }}>
                          *
                        </span>
                      </span>
                    }
                    value={categoryname}
                    onChange={(e) =>
                      setCategoryname(e.target.value.toUpperCase())
                    }
                    onKeyPress={handlecategorynamesKeyPress}
                  />
                )}
              </Box>
              <div className="btnconfirm-edit">
                <div onClick={() => editCategory()}>
                  <Buttons Buttondata={CancelButton} />
                </div>
                {confirm()}
              </div>
              <ToastContainer />
            </div>
            {Loading ? <LoadingScreen /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
