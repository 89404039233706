import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import { Flip, toast, ToastContainer } from 'react-toastify';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import api from '../../axiosConfig';
import Buttons from '../../common-component/Buttons';
import LoadingScreen from '../../common-component/LoadingScreen';
// import LoadingScreen from '../../../common-component/LoadingScreen';

interface userprofile {
    userprofile: any;
    _id: { $oid: string };
    name: string;
    date_of_birth: string;
    email_id: string;
    address_line_1: string;
}
function EditUserProfile() {
    const [userprofile, setuserprofile] = useState<userprofile | null>(null);
    const [loading, setloading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [dob, setDob] = useState<string>("");
    const [gmail, setGmail] = useState<string>("");
    const [visible, setvisible] = useState<boolean>(false);
    const [address, setAddress] = useState<string>("");
    useEffect(() => {
        api
            .get(`/pos/profile`)
            .then((res) => {
                const profileData: userprofile = res.data.message;
                setuserprofile(profileData);
                setName(profileData?.name);
                setDob(profileData?.date_of_birth);
                setGmail(profileData?.email_id);
                setAddress(profileData?.address_line_1);
            })
    }, []);
    useEffect(() => {
        if (
            userprofile &&
            (name === userprofile?.name &&
                dob === userprofile?.date_of_birth &&
                gmail === userprofile?.email_id &&
                address === userprofile?.address_line_1)
        ) {
            setvisible(false);
        } else {
            if (
                userprofile &&
                (name &&
                    dob &&
                    gmail &&
                    address)
            ) {
                setvisible(true);
            } else {
                setvisible(false);
            }
        }
    }, [userprofile, name, dob, gmail, address]);
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const updateuser = () => {
  const id = userprofile?._id.$oid;
  setloading(true);
  
  const user = {
    name: name,
    date_of_birth: dob,
    email_id: gmail,
    address_line_1: address,
  };

  api
    .put(`/pos/add/${id}`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      console.log(res);
      toast.success("User updated successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });

    //   await sleep(2000); 
      setloading(false);
      window.location.reload(); 
    })
    .catch((error) => {
      console.error("Error updating user:", error);
      setloading(false);
    });
};

    const confirm = () => {
        if (visible === true) {
            return (<div onClick={updateuser}>
                <Buttons Buttondata={updateButton} />
            </div>)
        } else {
            return (<div id="confirm_btn">
                <Buttons Buttondata={errorupdateButtonerr} />
            </div>)
        }
    }
    const updateButton = {
        Blue: "blue",
        name: "update",
    };
    const CancelButton = {
        Cancel: "red",
        name: "Cancel",
    };
    const errorupdateButtonerr = {
        error: "blue",
        name: "update",
    };
    function setDate(value: string): void {
        throw new Error('Function not implemented.');
    }

    return (
        <div>
            <div>
                <h2 className='Edit_head'>Edit User Profile</h2>
                <div>
                    <button
                        onClick={() => window.location.reload()}
                        className="cancel-icon-Edituser-profile"
                    >
                        <Tooltip title="Close" placement="bottom" arrow>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </button>
                </div>
                <div className='Edit_business_overall'>
                    <TextField
                        sx={{ marginLeft: "20px", marginTop: "10px" }}
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        sx={{ marginLeft: "70px", marginTop: "10px" }}
                        id="outlined-basic"
                        label="Date of Birth"
                        variant="outlined"
                        value={dob}
                        disabled
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <TextField
                        sx={{ marginTop: "20px", marginLeft: "20px" }}
                        id="outlined-basic"
                        label="G-mail"
                        variant="outlined"
                        value={gmail}
                        onChange={(e) => setGmail(e.target.value)}
                    />
                    <TextField
                        sx={{ marginLeft: "70px", marginTop: "20px" }}
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className='business_btn'>
                    <div onClick={() => window.location.reload()}>
                        <Buttons Buttondata={CancelButton} />
                    </div>
                    {/* <div onClick={updateuser}>
                        <Buttons Buttondata={GButton} />
                    </div> */}
                    {confirm()}
                </div>
            </div>
            <ToastContainer />
            {loading ? <LoadingScreen /> : ""}
        </div>
    )
}

export default EditUserProfile
