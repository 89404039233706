import React from 'react'
import Resheader from '../pages/components/Restaurant/Resheader'
import Resmenu from '../pages/components/Restaurant/Resmenu'

const Reskitchen = () => {

  const oldOrders =()=>{
    
  }
  
  return (
    <div style={{ display: "flex", flexDirection: 'column',overflow:'hidden'}}>
      <Resheader Resheader={"Kitchen"}  OldOrders={oldOrders}/>
      <div style={{display:"flex"}}>
            <Resmenu Resmenu={""} />
            <h1 style={{color:"red"}}>Kitchen Is Ready, It's Time For Cooking.Waiting for bharathi</h1>
      </div>
     </div>
  )
}

export default Reskitchen

