import React, { useEffect, useState } from "react";
import Ressetting from "./Ressetting";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../axiosConfig";
import Resheader from "../../components/Restaurant/Resheader";
import Resmenu from "../../components/Restaurant/Resmenu";

interface PostData {
  _id: { $oid: string };
  conname: string;
  convalue: number | null;
}

const ResConfigurations = () => {
  const [configurations, setConfigurations] = useState<PostData[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [conname, setconname] = useState<string>("");
  const [convalue, setConvalue] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [error, setError] = useState<string | null>(null);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [currentConfig, setCurrentConfig] = useState<PostData | null>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [currentDeleteConfig, setCurrentDeleteConfig] = useState<PostData | null>(null);
  const fetchConfigurations = async () => {
    setLoading(true);
    try {
      const response = await api.get("/app/v1/configuration");
      console.log("Fetched configurations:", response.data.result);
      setConfigurations(response.data.result);
    } catch (error) {
      toast("Failed to fetch configurations.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const openAddModal = () => {
    setconname("");
    setConvalue(null);
    setOpen(true);
    setOpenAdd(true);
  };

  const handleCloseAddPopup = () => {
    setOpen(false);
    setconname("");
    setConvalue(null);
    setOpenAdd(false);
  };

  const openEditModal = (config: PostData) => {
    console.log("Opening edit modal for config:", config);
    setCurrentConfig(config);
    setconname(config.conname);
    setConvalue(config.convalue);
    setOpenEdit(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEdit(false);
    setconname("");
    setConvalue(null);
    setCurrentConfig(null);
  };

  const handleSubmit = async () => {
    if (!conname || convalue === null) {
      toast("Please fill in all fields.");
      return;
    }

    const newConfiguration = {
      conname: conname,
      convalue: convalue,
    };

    try {
      setLoading(true);
      const res = await api.post(`/app/v1/configuration`, newConfiguration);
      toast.success("Configuration added successfully", { theme: "colored" });
      fetchConfigurations();
      handleCloseAddPopup();
    } catch (error) {
      setError("Failed to add configuration.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitEdit = async () => {
    if (!conname || convalue === null) {
      toast("Please fill in all fields.");
      return;
    }

    if (!currentConfig || !currentConfig._id || !currentConfig._id.$oid) {
      toast("Invalid configuration ID.");
      console.error("currentConfig is invalid:", currentConfig);
      return;
    }

    const updatedConfiguration = { conname, convalue };

    try {
      setLoading(true);
      const configId = currentConfig._id.$oid;
      const res = await api.put(`/app/v1/configuration/${configId}`, updatedConfiguration);
      toast.success("Configuration updated successfully", { theme: "colored" });
      fetchConfigurations();
      handleCloseEditPopup();
    } catch (error) {
      toast("Failed to update configuration.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = event.target.value === "All" ? -1 : parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const displayedConfigurations = rowsPerPage === -1
    ? configurations
    : configurations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const oldOrders = () => {
  }

  const handleDelete = async (configId: string) => {
    if (!currentDeleteConfig || !currentDeleteConfig._id || !currentDeleteConfig._id.$oid) {
      toast("Invalid configuration ID.");
      console.error("currentDeleteConfig is invalid:", currentDeleteConfig);
      return;
    }
    try {
      setLoading(true);
      const configId = currentDeleteConfig._id.$oid;
      const res = await api.delete(`/app/v1/configuration/${configId}`);
      toast.success("Configuration deleted successfully", { theme: "colored" });
      setConfigurations(prevConfigurations =>
        prevConfigurations.filter(config => config._id.$oid !== configId)
      );
      setOpenDelete(false);
    } catch (error) {
      toast.error("Failed to delete configuration.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (config: PostData) => {
    setCurrentDeleteConfig(config);
    setOpenDelete(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDelete(false);
    setCurrentDeleteConfig(null);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
      <Resheader Resheader={"Configurations"} OldOrders={oldOrders} />
      <div style={{ display: "flex" }}>
        <Resmenu Resmenu={""} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Ressetting />
          <div>
            <div className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={openAddModal}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Add Configuration"}
              </Button>
            </div>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <div>
              <TableContainer
                component={Paper}
                style={{
                  marginTop: "20px",
                  width: "58%",
                  marginLeft: "70px",
                  overflow: "auto",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow style={{ width: "70%" }}>
                      <TableCell sx={{ zIndex: "0" }}>S.No</TableCell>
                      <TableCell sx={{ zIndex: "0" }}>Configuration Name</TableCell>
                      <TableCell sx={{ zIndex: "0" }}>Configuration Value</TableCell>
                      <TableCell sx={{ zIndex: "0" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {configurations.length > 0 ? (
                      displayedConfigurations.map((row, index) => (
                        <TableRow key={row._id.$oid}>
                          <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                          <TableCell>{row?.conname}</TableCell>
                          <TableCell>{row?.convalue}</TableCell>
                          <TableCell>
                            <EditIcon onClick={() => openEditModal(row)} />
                            <DeleteOutlineIcon style={{ color: "red" }} onClick={() => openDeleteModal(row)} />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>No data available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                  component="div"
                  count={configurations.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
            <div>
              {openAdd && (
                <div
                  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      width: "400px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <h3>Add Configuration</h3>
                    <div className="form-container">
                      <div className="input-group">
                        <TextField
                          label="Configuration Name"
                          variant="outlined"
                          type="text"
                          value={conname}
                          onChange={(e) => setconname(e.target.value)}
                          fullWidth
                        />
                        <TextField
                          label="Configuration Value"
                          variant="outlined"
                          type="number"
                          value={convalue === null ? "" : convalue}
                          onChange={(e) =>
                            setConvalue(e.target.value === "" ? null : Number(e.target.value))
                          }
                          fullWidth
                        />
                      </div>
                      <div className="button-container-popup">
                        <button className="cancel-btn" onClick={handleCloseAddPopup}>
                          Cancel
                        </button>
                        <button className="submit-btn" onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {openEdit && currentConfig && (
                <div
                  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      width: "400px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <h3>Edit Configuration</h3>
                    <div className="form-container">
                      <div className="input-group">
                        <TextField
                          label="Configuration Name"
                          variant="outlined"
                          type="text"
                          value={conname}
                          onChange={(e) => setconname(e.target.value)}
                          fullWidth
                        />
                        <TextField
                          label="Configuration Value"
                          variant="outlined"
                          type="number"
                          value={convalue === null ? "" : convalue}
                          onChange={(e) =>
                            setConvalue(e.target.value === "" ? null : Number(e.target.value))
                          }
                          fullWidth
                        />
                      </div>
                      <div className="button-container-popup">
                        <button className="cancel-btn" onClick={handleCloseEditPopup}>
                          Cancel
                        </button>
                        <button className="submit-btn" onClick={handleSubmitEdit}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {openDelete && currentDeleteConfig && (
                <div className="Res-overlays">
                  <div className="popup">
                    <div className="resconfig-delete" style={{ marginTop: "-20px" }}>
                      <h1 style={{ marginTop: "20px" }}>Confirm to Delete Configuration</h1>
                      <p style={{ fontWeight: 'bold', marginTop: "15px" }}>Name: {currentDeleteConfig.conname}</p>
                      <p style={{ marginTop: "20px" }}>Are you sure you want to delete this configuration?</p>
                      <div className="resconfig-cancel" style={{ marginTop: "20px", width: "100%" }}>
                        <div onClick={handleCloseDeletePopup} style={{ width: "45%" }}>
                          <Button variant="outlined" style={{ width: "100%" }}>
                            Cancel
                          </Button>
                        </div>
                        <div onClick={() => handleDelete(currentDeleteConfig?._id.$oid)} style={{ width: "45%" }}>
                          <Button
                            variant="contained"
                            style={{ width: "100%" }}
                          >
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResConfigurations;
