import Buttons from "../../common-component/Buttons";

interface PrintData {
  CustomerName: string;
  CustomerPhon: string;
  OrderId: string;
  OrderStatus: string;
  Date: string;
  Time: string;
  Products: Array<{
    productName: string;
    arabicword: string;
    amount: number;
    quantity: number;
    totalPrice: number;
  }>;
  Paid: number;
  unpaid: number;
  OSB: string | number;
  busnessName?: string;
  busnessArabicName?: string;
  phoneNum?: string;
  total: string | number;
  imglogo?: any;
  businessLocation?: string;
  clientId?: number | string
}

interface ThermalPrinterTestProps {
  PrintData: PrintData;
  BtnName: string;
  username: string;
}

const Print: React.FC<ThermalPrinterTestProps> = ({
  PrintData,
  BtnName,
  username,
}) => {

  const roundAmount = (value: any): string => {
    const num = Number(value);
    if (isNaN(num)) {
      return '0.00';
    }
    const rounded = Math.round(num * 100) / 100;
    return rounded.toFixed(2);
  };
  
  const generateOrderList = () => {
    return `
      <table style="width: 95%; margin: 10px; border-collapse: collapse; font-size: 20px">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 5px;">S.No</th>
            <th style="border: 1px solid black; padding: 5px; text-align: left;">Product Name <br/> دولة قط</th>
            <th style="border: 1px solid black; padding: 5px;">Price <br/> السعر<br/>(ر.ق)</th>
            <th style="border: 1px solid black; padding: 5px;">Qty <br/> كمية</th>
            <th style="border: 1px solid black; padding: 5px;">Total <br/> المجموع<br/>(ر.ق)</th>
          </tr>
        </thead>
        <tbody>
          ${PrintData.Products.map(
            (product, index) => `
            <tr>
              <td style="border: 1px solid black; padding: 5px; text-align: center;">${index + 1}</td>
              <td style="border: 1px solid black; padding: 5px; text-align: left;">${product.productName} <br/> ${product.arabicword}</td>
              <td style="border: 1px solid black; padding: 5px; text-align: right;">${roundAmount(product.amount)}</td>
              <td style="border: 1px solid black; padding: 5px; text-align: right;">${product.quantity}</td>
              <td style="border: 1px solid black; padding: 5px; text-align: right;">${roundAmount(product.totalPrice)}</td>
            </tr>
          `
          ).join("")}
        </tbody>
      </table>
    `;
  };

  const customerDetails = () => {
    return `
      <table style="width: 100%; margin-bottom: 10px; border-top: 1px solid black; font-size: 20px">
        <tr>
          <td style="width: 50%; border-bottom: 1px solid black;">
            <p><strong>Order ID (رقم الطلب):</strong> ${PrintData.OrderId}</p>
            <p><strong>Date | Time :</strong> ${PrintData.Date} | ${PrintData.Time}</p>
          </td>
        </tr>
        <tr>
          <td style="width: 50%;">
            <p><strong>Customer Details</strong></p>
            <p><strong>Name:</strong> ${PrintData.CustomerName}</p>
            <p><strong>Phone:</strong> ${PrintData.CustomerPhon}</p>
            <p><strong>Client Id:</strong> ${PrintData.clientId ? PrintData.clientId : "null"}</p>
          </td>
        </tr>
      </table>
    `;
  };

  // <div style="width:100%; text-align: center;">
  //       ${PrintData.imglogo ? `<img src="${PrintData.imglogo}" alt="Logo" style="width: 100px; height: 100px; border-radius: 50%;" />` : ''}
  //       </div>

  const content = `
    <div style="font-family: monospace; padding: 10px; color: black; font-size: 20px; font-family: "Times New Roman", Times, serif;">
    <div>
      <h1 style="text-align: center;"></h1>
     </div>
    <div style="display: flex; flex-direction: column; align-items: center; gap: 10px; text-align: center;">
       <!-- Logo -->
        ${PrintData.imglogo 
         ? `<img src="${PrintData.imglogo}" alt="Logo" style="width: 100px; height: 100px; border-radius: 50%;" />` 
         : ''}

       <!-- Business Name -->
       <h1 style="margin: 0; padding: 2px; width: 100%; max-width: 600px; word-wrap: break-word;">${PrintData.busnessName}</h1>
       
        <!-- Business Arabic Name -->
          ${PrintData.busnessArabicName 
          ? `<h1 style="margin: 0; padding: 2px; width: 100%; max-width: 600px; word-wrap: break-word;">${PrintData.busnessArabicName}</h1>` 
          : ''}

         <!-- Business Location -->
           ${PrintData.businessLocation
            ? `<h2 style="margin: 0; padding: 2px; width: 100%; max-width: 600px; word-wrap: break-word;">${PrintData.businessLocation}</h2>` 
          : ''}

        <!-- Phone Number -->
       <p style="margin: 0; padding: 5px;">${PrintData.phoneNum}</p>
    </div>

      ${customerDetails() || ""} 
      ${generateOrderList() || ""} 

      <div style="width: 100%; padding: 5px; border-bottom: 1px solid black;">
        <p style="display: flex; justify-content: space-between; width: 98%;">
          <strong>Total (المجموع):</strong> ${roundAmount(PrintData.total)} ر.ق
        </p>
      </div>

      <div style="display: flex; justify-content: space-around; width: 100%; border-bottom: 1px solid black; padding: 5px 0;">
        <p style="text-align: center;">
          <strong>Paid <br/> (مدفوع)</strong><br/> ${roundAmount(Number(PrintData.Paid) || 0)} ر.ق
        </p>
        <p style="text-align: center;">
          <strong>Unpaid <br/> (غير مدفوع)</strong><br/> ${roundAmount(Number(PrintData.unpaid) || 0)} ر.ق
        </p>
        <p style="text-align: center;">
          <strong>Amount Due <br/> (المبلغ المتبقي)</strong><br/> ${roundAmount(Number(PrintData.unpaid) || 0)} ر.ق
        </p>
      </div>

      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; border-bottom: 1px solid black; padding: 5px 0;">
  <p style="margin: 0;">Outstanding Balance <br/> (الرصيد المتبقي):</p>
  <p style="margin: 0;">${roundAmount(Number(PrintData.OSB) || 0)} ر.ق</p>
</div>

      <p style="text-align: center; margin-top: 20px;">Thank you for your purchase!</p>
      <p style="text-align: center;">Powered by iMetanic</p>
    </div>
`;

  const handlePrint = () => {
    const printWindow = document.createElement("iframe");
    printWindow.style.position = "absolute";
    printWindow.style.width = "0";
    printWindow.style.height = "0";
    printWindow.style.border = "none";
    document.body.appendChild(printWindow);

    const printDoc = printWindow.contentWindow?.document;

    if (printDoc) {
      printDoc.open();
      let doc = `
      <html>
        <head>
          <style>
            @media print {
              body {
                font-size: 24px;
                margin: 10;
              }
            }
          </style>
        </head>
        <body>${content}</body>
      </html>
    `
      console.log(doc)
      printDoc.write(doc);
      printDoc.close();

      printWindow.onload = () => {
        try {
          printWindow.contentWindow?.focus();
          printWindow.contentWindow?.print();
        } catch (error) {
          console.error("Error triggering print:", error);
        }
      };
    } else {
      console.error("Failed to access iframe content document.");
    }
  };

  const DONE = {
    Blue: "blue",
    name: BtnName,
  };

  return (
    <div>
      <div onClick={handlePrint}>
        <Buttons Buttondata={DONE} />
      </div>
    </div>
  );
};

export default Print;