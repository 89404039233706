import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./OrderStatus.css";
import React, { useEffect, useState } from "react";
import api from "../../axiosConfig";
interface Props {
  id: string | undefined;
}
interface Getdata {
  _id: {
    $oid: string;
  };
  Products: Array<{
    Name: string;
    productId: number;
    phone_number: string;
    Address: string;
    Email: string;
    products: Array<{
      productName: string;
      amount: string;
      quantity: string;
      totalPrice: string;
    }>;
    totalAmount: string;
    totalQuantity: number;
    vat: string;
  }>;
  Orderdate: string;
  Ordertime: string;
  ReceivedAmount: string;
  RemainingAmount: string;
  Paymentmode: string;
  OrderStatus: string;
  DeliveryDate: string;
  Orderid: number;
  createdBy: {
    user_name: string;
    name: string;
    organization_name: string;
    address: string;
    phone_number: number;
  };
}
const PopupBill: React.FC<Props> = ({ id }) => {
  const [getdata, setGetdata] = useState<Getdata[]>([]);
  useEffect(() => {
    api.get(`/app/v1/pos/order/${id}`).then((res) => {
      console.log(res);
      const getdata: Getdata[] = res.data.result;
      setGetdata(getdata);
    });
  }, [id]);

  return (
    <div>
      {getdata &&
        getdata.map((item) => (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-20px",
              }}
            >
              <h3>
                Order Id : <br />
                {item.Orderid}
              </h3>
              <h3>
                Order Status: <br />
                {item.OrderStatus}
              </h3>
            </div>
            <div className="over-y-flow">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.no</TableCell>
                      <TableCell>Products</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  {item.Products.map((products) => (
                    <TableBody>
                      {products.products.map((items, index) => (
                        <TableRow key={items.productName}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{items.productName}</TableCell>
                          <TableCell>{items.quantity}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <b>Total Products</b>
                        </TableCell>
                        <TableCell>
                          <b>{products.totalQuantity}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PopupBill;
