import React, { useEffect, useState } from "react";
import "./CarWash.css";
// import Paycash from "../../../src/assets/cash-on-delivery.png";
import Paycash from '../../../../src/assets/Images/cash-on-delivery.png';
// import paycard from "../../../src/assets/debit.png";
import paycard from '../../../../src/assets/Images/debit-card.png';
import { useLocation, useNavigate } from "react-router-dom";
// import CarHeaderBox from "./CarHeaderBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "../../axiosConfig";
import CarMenuBox from "./CarMenuBox";
import CarHeaderBox from "./CarHeaderBox";

const CarPaymentType = () => {
  // const [orderdata, setOrderdata] = useState<CarDetail | null>(null);
  // const [totalPrice, setTotalPrice] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    // console.log(categoryId)
    if (id) {
      // apiformet();
    }
  }, [id]);

  const passdata = () => {
    const update = {
      payment: {
        paymentMethod: "cash",
      },
    };

    api
      .put(`/app/v1/pos/customer/${id}`, update)
      .then((res) => {
        console.log("Car details updated successfully:", res);
        if (res.data && id) {
          navigate(`/v1/car-Payment-cash?id=${id}`);
        } else {
          console.error("Error: Missing '_id' in API response");
        }
      })
      .catch((err) => {
        console.error("Error updating car details:", err);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };
  
  return (
    <div className="home-container">
      <CarHeaderBox Carheader={"Order details"} />
      <div className="carwash_bodyalignment_payment">
        <div className="inside_image">
          <CarMenuBox />
          <div className="structure-alignment">
            <div style={{display:"flex",marginRight:"100px"}}>
            <div style={{position:"relative",right:"80px",top:"-10px"}}onClick={handleBack}>
              <ArrowBackIcon style={{width:"70px",height:"30px"}}/>
            </div>
            <div>
            <h1>Payment Details</h1>
            </div>
            </div>
            <p>Choose the payment option that fits your needs</p>
            <div>
              <div className="payment-option" onClick={passdata}>
                <img src={paycard} alt="Pay with card" />
                <span>Card</span>
              </div>
              <div className="payment-option" onClick={passdata}>
                <img src={Paycash} alt="Cash on delivery" />
                <span>Cash</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarPaymentType;
