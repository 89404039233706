import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Flip, ToastContainer, toast } from "react-toastify";
import Deleteproducts from "./DeleteProducts";
import Editmenu from "./EditMenu";
import CloseIcon from "@mui/icons-material/Close";
import { InsertEmoticon } from "@mui/icons-material";
import browserImageCompression from "browser-image-compression";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Header from "../../components/laundry/Header";
import Resheader from "../../components/Restaurant/Resheader";
import CarHeaderBox from "../../components/car-wash/CarHeaderBox";
import Menu from "../../components/laundry/Menu";
import Resmenu from "../../components/Restaurant/Resmenu";
import CarMenuBox from "../../components/car-wash/CarMenuBox";
import Tables from "../../common-component/Table";
import EditProduct from "./EditProduct";
import LoadingScreen from "../../common-component/LoadingScreen";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}
interface Productbtn {
  _id: { $oid: string };
  Amount: string;
  ArabicName: string;
  ProductName: string;
  category_id: string;
  image_url?: string;
  image_file?: File;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 22px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdf",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function AddProduct() {
  const [errormsg, setErrormsg] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [SelectedProducts, setSelectedProducts] = useState<Productbtn[]>([]);
  const [filteredProducts, setFilteredProducts] = useState(SelectedProducts);
  const [modal, setModal] = useState<boolean>(false);
  const [Edit, setEdit] = useState<boolean>(false);
  const [productdata, setProductdata] = useState<any>("");
  const [arabic, setarabic] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [id, setId] = useState<any>();
  const [Name, setName] = useState<any>();
  const [Amountedit, setAmountedit] = useState<any>();
  const [Categorytype, setCategorytype] = useState<any>();
  const [Delete, setDelete] = useState<boolean>(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [selectcategory, setSelectCategory] = React.useState("");
  const [filtercategory, setFilterCategory] = React.useState("");
  const [empty, setempty] = useState<boolean>(false);
  const productnamesref = useRef<HTMLInputElement>(null);
  const selectcategorys = useRef<HTMLInputElement>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [search, setsearch] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [bussinessType, setBussinessType] = useState<string>("");
  const [originalProducts, setOriginalProducts] = useState<Productbtn[]>([]);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [type, setType] = useState<string>("");
  const typeRef = useRef<HTMLInputElement>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const [showDeletedProducts, setShowDeletedProducts] = useState(false);
  const handleproductnamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      productnamesref.current?.focus();
    }
  };
  const handleproductamountsKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      selectcategorys.current?.focus();
    }
  };
  const handlecategoryselectKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (productdata && amount && selectcategory) {
        updateproduct();
      } else {
        toast.error("Please fill in required fields", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
          transition: Flip,
        });
      }
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    nextRef?: React.RefObject<HTMLElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextRef?.current?.focus();
    }
  };

  useEffect(() => {
    document.title = "Products - Settings - iMetanic";
    if (empty === false) {
      category();
      product();
    }
    if (productdata && amount && arabic && selectcategory) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [amount, arabic, empty, productdata, selectcategory]);

  const category = () => {
    api
      .get(`/app/v1/pos/category?is_deleted=False`)
      .then((res) => {
        const categoryData: CategoryButton[] = res.data.result;
        setButtonNames(categoryData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const product = () => {
    api
      .get("/app/v1/pos/product?is_deleted=False")
      .then((res) => {
        const Products: Productbtn[] = res.data.result;
        setSelectedProducts(Products);
        setOriginalProducts(Products);
      })
      .catch((error) => {});
  };

  const handleProductEdit = (editedProduct: Productbtn) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id.$oid === editedProduct._id.$oid ? editedProduct : product
      )
    );
  };

  const updateproduct = async () => {
    setloading(true);

    const updates: {
      ProductName: string;
      Amount: number;
      ArabicName: string;
      category_id: string;
      image?: File;
      type: any;
    } = {
      ProductName: productdata,
      Amount: amount,
      type: type,
      ArabicName: arabic,
      category_id: selectcategory,
    };
    if (image) {
      updates.image = image;
    }

    if (productdata && amount && selectcategory) {
      try {
        const res = await api.post(`/app/v1/pos/product`, updates, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(res);

        toast.success("Product added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setProductdata("");
        setModal(false);
        product();

        // window.location.reload();
      } catch (error: any) {
        if (error.response?.data?.message === "Product already exists!") {
          setErrormsg(true);
          toast.error("Product already exists", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else if (
          error.response?.data?.message === "Arabic name already exists!"
        ) {
          toast.error("Arabic name already exists!", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else if (
          error.response?.data?.message === "Arabic name not provided"
        ) {
          toast.error("Arabic name not provided!", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast.error("An unexpected error occurred.", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      } finally {
        setloading(false);
      }
    } else {
      setloading(false);
      toast.error("Please fill in required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleClsoepop = () => {
    setModal(false);
  };

  const Edits = (
    id: string,
    Name: string,
    Amount: string,
    Category: string,
    arabic: string,
    image: string | File | null
  ) => {
    setId(id);
    setEdit(!Edit);
    setName(Name);
    setAmountedit(Amount);
    if (typeof image === "string") {
      setPreviewImage(image);
      setImage(null);
    } else {
      setPreviewImage(null);
      setImage(image || null);
    }
    const categoryObj = buttonNames.find(
      (button) => button._id.$oid === Category
    );
    setarabic(arabic);
    if (categoryObj) {
      setCategorytype(categoryObj.CategoryName);
      return categoryObj.CategoryName;
    } else {
      return "Unknown";
    }
  };
  const handleChangeCategoryFilter = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCategory = event.target.value;
    setFilterCategory(selectedCategory);
    if (selectedCategory === "all") {
      setFilteredProducts(SelectedProducts);
    } else {
      const categoryFiltered = SelectedProducts.filter(
        (product) => product.category_id === selectedCategory
      );
      setFilteredProducts(categoryFiltered);
    }
  };
  

  const finalFilteredProducts = filtercategory
    ? filteredProducts
    : SelectedProducts;

  const Products = {
    titlesno: "S.No",
    titlename1: "Product name",
    titlename2: "Arabic word",
    titlename3: "Product price (QAR)",
    titlename4: "Category type",
    titlename5: "Image",
    titlename6: "Actions",
    S_no: finalFilteredProducts.map((item, index) => index + 1),
    bodyname1: finalFilteredProducts.map((item, index) => item.ProductName),
    bodyname2: finalFilteredProducts.map((item) => item.ArabicName),
    bodyname3: finalFilteredProducts.map((item, index) => item.Amount),
    bodyname4: finalFilteredProducts.map((item) => {
      const category_id = item.category_id;
      const category = buttonNames.find(
        (button) => button._id.$oid === category_id
      );
      return category ? category.CategoryName : "Unknown";
    }),
    bodyname5: finalFilteredProducts.map((item) => {
      if (item.image_url) {
        return (
          <img
            src={item.image_url}
            alt={item.ProductName}
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
          />
        );
      } else {
        return (
          <span style={{ color: "gray", fontStyle: "italic" }}>No Image</span>
        );
      }
    }),

    bodyname6: finalFilteredProducts.map((item, index) => (
      <>
        <button
          className="icon-btn-edit"
          onClick={() =>
            Edits(
              item._id.$oid,
              item.ProductName,
              item.Amount,
              item.category_id,
              item.ArabicName,
              item.image_file ?? null
            )
          }
        >
          <Tooltip title="Edit" placement="bottom" arrow>
            <IconButton>
              <EditIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </button>
        <button
          className="icon-btn-edit"
          onClick={() => deleteproduct(item._id.$oid, item.ProductName)}
        >
          <Tooltip title="Delete" placement="bottom" arrow>
            <IconButton>
              <DeleteOutlineIcon style={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </button>
      </>
    )),
  };
  const deleteproduct = (id: string, Name: string) => {
    setId(id);
    setDelete(!Delete);
    setName(Name);
  };
  const AddProductButton = {
    Blue: "blue",
    name: "Add Product",
  };
  const ConfirmButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const errorconfirmButtonerr = {
    error: "blue",
    name: "Confirm",
  };
  const getCategoryInitials = (categoryName: string): string => {
    return categoryName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  };

  const handleChange = (event: { target: { value: string } }) => {
    const selectedCategoryId = event.target.value;
    setSelectCategory(selectedCategoryId);

    const selectedCategory = buttonNames.find(
      (button) => button._id.$oid === selectedCategoryId
    );
    if (selectedCategory) {
      const categoryInitials = getCategoryInitials(
        selectedCategory.CategoryName
      );
      setCategorytype(categoryInitials);
      setProductdata((prevProductData: string) => {
        const nameWithoutOldInitials = prevProductData.replace(/\[\w+\]$/, "");
        return `${nameWithoutOldInitials.trim()} [${categoryInitials}]`;
      });
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreviewImage(reader.result as string);
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  const handlesearch = (event: { target: { value: string } }) => {
    const term = event.target.value;
    if (Number(term) >= 0) {
      setAmount(term);
    }
  };
  const handleBlur = () => {
    if (!isNaN(Number(amount)) && amount.trim() !== "") {
      let formattedAmount = amount.trim();
      let [integerPart = "", decimalPart = ""] = formattedAmount.split(".");

      if (decimalPart === undefined || decimalPart === "") {
        decimalPart = "00";
      } else if (decimalPart.length === 1) {
        decimalPart = decimalPart + "0";
      } else if (decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      formattedAmount = `${integerPart}.${decimalPart}`;
      setAmount(formattedAmount);
    }
  };

  const changedata = () => {
    setProductdata("");
    setarabic("");
    setAmount("");
    setCategorytype("");
    setPreviewImage("");
    setErrormsg(false);
  };

  const menu = {
    Product: "product",
  };

  const ConfirmButtonComponent = () => {
    if (visible) {
      return (
        <div
          onClick={() => {
            updateproduct();
            changedata();
          }}
        >
          <Buttons Buttondata={ConfirmButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={errorconfirmButtonerr} />
        </div>
      );
    }
  };

  // const handletext = () =>{
  //   setProductdata("");
  //   setarabic("");
  //   setAmount("");
  //   setCategorytype("");
  //   setPreviewImage("");
  // }

  const handleSearchedProduct = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value.toLowerCase();
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(inputValue)) {
      setsearch(inputValue);
    }
  };

  const handleDeleteProduct = (deletedProductId: string) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product._id.$oid !== deletedProductId)
    );
    setOriginalProducts((prevProducts) =>
      prevProducts.filter((product) => product._id.$oid !== deletedProductId)
    );
  };

  const closeEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    if (search) {
      const filteredProducts = originalProducts.filter(
        (product) =>
          product.ProductName.toLowerCase().includes(search.toLowerCase()) ||
          buttonNames.some(
            (category) =>
              category._id.$oid === product.category_id &&
              category.CategoryName.toLowerCase().includes(search.toLowerCase())
          )
      );
      setSelectedProducts(filteredProducts);
    } else {
      setSelectedProducts(originalProducts);
    }
  }, [search, originalProducts, buttonNames]);
  const filters = (showDeleted: boolean) => {
    if (showDeleted) {
      api
        .get("/app/v1/pos/product?is_deleted=True")
        .then((res) => {
          const deletedProducts = res.data.result;
          setSelectedProducts(deletedProducts);
        })
        .catch((error) => {
          console.error("Error fetching deleted products:", error);
        });
    } else {
      api
        .get("/app/v1/pos/product?is_deleted=False")
        .then((res) => {
          const activeProducts = res.data.result;
          setSelectedProducts(activeProducts);
        })
        .catch((error) => {
          console.error("Error fetching active products:", error);
        });
    }
  };
  const handleShowDeletedToggle = () => {
    setShowDeletedProducts((prevState) => {
      const newState = !prevState;
      filters(newState);
      return newState;
    });
  };

  useEffect(() => {
    const bussinessType = sessionStorage.getItem("business_type");
    setBussinessType(String(bussinessType));
  }, []);

  const OldOrders = () => {};

  return (
    <div>
      {bussinessType === "Laundry" && <Header Header="Settings - Products" />}
      {bussinessType === "Restaurant" && (
        <Resheader Resheader={"Products"} OldOrders={OldOrders} />
      )}
      {bussinessType === "Carwash" && (
        <CarHeaderBox Carheader="Settings - Categories" />
      )}
      <div style={{ display: "flex" }}>
        {bussinessType === "Laundry" && <Menu />}
        {bussinessType === "Restaurant" && <Resmenu Resmenu={""} />}
        {bussinessType === "Carwash" && <CarMenuBox />}
        <div className="Edit-overall">
          <Editmenu editmenu={menu} />
          <div style={{ margin: "10px 80% -100px 0px", width: "250px" }}>
          <div className="category-add" style={{ marginRight: "105px" }}>
            <div
              onClick={() => {
                setModal(!modal);
                setempty(true);
              }}>
              <Buttons Buttondata={AddProductButton} />
            </div>
            <div className="search_box_user">
              <TextField
                id="Search-Category-name"
                label={"Search by Product name or Category type"}
                variant="outlined"
                sx={{ width: 400, marginRight: "10px", zIndex: "0" }}
                type="tel"
                onChange={handleSearchedProduct}
              />
            </div>
            <div className="Deleted_check">
            <FormControlLabel control={<Checkbox checked={showDeletedProducts} onChange={handleShowDeletedToggle} />}  label="View deleted products"/>
            </div>
            <div style={{ margin: "-25px 25% -10px 0px",width:"250px" }}>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="demo-customized-select-native">
                Search by selecting a category{" "}
                <span style={{ color: "#f04141", fontSize: "15px" }}>*</span>
              </InputLabel>
              <NativeSelect
                sx={{ width: "300px" }}
                id="demo-customized-select-native"
                value={filtercategory}
                onChange={handleChangeCategoryFilter}
                input={<BootstrapInput />}
              >
                <option value={""} disabled>
                  Search by selecting a category{" "}
                  <span style={{ color: "#f04141", fontSize: "15px" }}></span>
                </option>
              <option value="all">All</option> {/* "All" option */}
                {buttonNames.map((item) => (
                  <option key={item._id.$oid} value={item._id.$oid}>
                    {item.CategoryName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
       </div>
          </div>
          {SelectedProducts.length === 0 ? (
            <p>No products available</p>
          ) : (
            <div className="customer_Table">
              <Tables product={Products} />
              </div>
            )}
            {/* {modal && (
            <div className="modals">
              <div className="overlays"></div>
              <div className="modal-contenters">
                <div className="edit-product-popupscreens">
                  <button
                    onClick={() => setModal(false)}
                    className="cancel-icon-AddProduct"
                  >
                    <CloseIcon />
                  </button>
                  <div className="addcategory-edit-p">
                    <h2 >Add product</h2>
                    <div className="edit_productsty">
                      {errormsg ? (
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "500px",
                            marginTop: "20px",
                            borderBlockWidth: "20px",
                            marginLeft: "20px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            error
                            label={
                              <span>
                                Product Name{" "}
                                <span
                                  style={{ color: "#f04141", fontSize: "15px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={productdata}
                            onClick={changedata}
                            onChange={(e) => setProductdata(e.target.value)}
                          />
                          <TextField
                            label={
                              <span>
                                Arabic{" "}
                                <span
                                  style={{ color: "#f04141", fontSize: "15px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            sx={{
                              width: "500px",
                              marginTop: "18px",
                              dir: "rtl",
                            }}
                            value={arabic}
                            onChange={(e) => setarabic(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Product price{" "}
                                <span
                                  style={{ color: "#f04141", fontSize: "14px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={amount}
                            onClick={changedata}
                            onChange={handlesearch}
                            onBlur={handleBlur}
                            type="number"
                            sx={{ marginTop: "20px" }}
                          />
                          <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-select-native">
                              Category Type
                            </InputLabel>
                            <NativeSelect
                              onClick={() => setErrormsg(false)}
                              error
                              id="demo-customized-select-native"
                              value={selectcategory}
                              onChange={handleChange}
                              input={<BootstrapInput />}
                            >
                              <option onClick={() => setCategorytype(null)}>
                                Select category
                              </option>
                              {buttonNames.map((item) => (
                                <option
                                  key={item._id.$oid}
                                  value={item._id.$oid}
                                >
                                  {item.CategoryName}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      ) : (
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "510px",
                            marginTop: "20px",
                            borderBlockWidth: "20px",
                            marginLeft: "20px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            label={
                              <span>
                                Product name{" "}
                                <span
                                  style={{ color: "#f04141", fontSize: "15px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            sx={{ width: "485px", marginLeft: "10px" }}
                            value={productdata}
                            onChange={(e) => setProductdata(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Arabic name
                                <span
                                  style={{ color: "#f04141", fontSize: "15px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            sx={{
                              width: "485px",
                              marginLeft: "10px",
                              marginTop: "18px",
                            }}
                            value={arabic}
                            onChange={(e) => setarabic(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Product price{" "}
                                <span
                                  style={{ color: "#f04141", fontSize: "15px" }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={amount}
                            sx={{
                              width: "485px",
                              marginLeft: "10px",
                              marginTop: "20px",
                            }}
                            onChange={handlesearch}
                            onBlur={handleBlur}
                            type="number"
                            onKeyPress={handleproductamountsKeyPress}
                            inputRef={productnamesref}
                          />
                         { bussinessType === "Restaurant"  && <FormControl
                            fullWidth
                            style={{ width: "490px",marginLeft:"10px",marginTop:"25px" }}
                          >
                            <InputLabel>Type</InputLabel>
                            <Select
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                              label="Type"
                              onKeyDown={(event) =>
                                handleKeyDown(event, categoryRef)
                              }
                              inputRef={typeRef}
                            >
                              <MenuItem value="Veg">Veg</MenuItem>
                              <MenuItem value="Non-Veg">Non-Veg</MenuItem>
                            </Select>
                          </FormControl>}
                          <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-select-native">
                              Category type{" "}
                              <span
                                style={{ color: "#f04141", fontSize: "15px" }}
                              >
                                *
                              </span>
                            </InputLabel>
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={selectcategory}
                              onChange={handleChange}
                              onKeyPress={handlecategoryselectKeyPress}
                              inputRef={selectcategorys}
                              input={<BootstrapInput />}
                            >
                              <option value={""} disabled>
                                <span>
                                  Select category{" "}
                                  <span
                                    style={{
                                      color: "#f04141",
                                      fontSize: "15px",
                                    }}
                                  >
                                    *
                                  </span>
                                </span>
                              </option>
                              {buttonNames.map((item) => (
                                <option
                                  key={item._id.$oid}
                                  value={item._id.$oid}
                                >
                                  {item.CategoryName}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              margin: " 9px 9px 0px 9px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              ref={uploadImageRef}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            <Button
                              variant="outlined"
                              onClick={() => uploadImageRef.current?.click()}
                            >
                              Upload Image
                            </Button>
                            {previewImage && (
                              <img
                                src={
                                  typeof previewImage === "string"
                                    ? previewImage
                                    : URL.createObjectURL(previewImage)
                                }
                                alt="Preview"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        </Box>
                      )}
                    </div>
                    <div className="confirm_btn_edit">
                      <div
                        onClick={() => {
                          setModal(false);
                          changedata();
                        }}
                      >
                        <Buttons Buttondata={CancelButton} />
                      </div>
                      {ConfirmButtonComponent()}
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

            <Dialog open={modal} onClose={handleClsoepop}>
              <DialogContent>
                <div className="edit-product-popupscreens">
                  <button
                    onClick={() => setModal(false)}
                    className="cancel-icon-AddProduct"
                  >
                    <CloseIcon />
                  </button>
                  <div className="addcategory-edit-p">
                    <h2>Add product</h2>
                    <div className="edit_productsty">
                      {errormsg ? (
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "500px",
                            marginTop: "20px",
                            borderBlockWidth: "20px",
                            marginLeft: "20px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            error
                            label={
                              <span>
                                Product Name{" "}
                                <span
                                  style={{
                                    color: "#f04141",
                                    fontSize: "15px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={productdata}
                            onClick={changedata}
                            onChange={(e) => setProductdata(e.target.value)}
                          />
                          <TextField
                            label={
                              <span>
                                Arabic{" "}
                                <span
                                  style={{
                                    color: "#f04141",
                                    fontSize: "15px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            sx={{
                              width: "500px",
                              marginTop: "18px",
                              dir: "rtl",
                            }}
                            value={arabic}
                            onChange={(e) => setarabic(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Product price{" "}
                                <span
                                  style={{
                                    color: "#f04141",
                                    fontSize: "14px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={amount}
                            onClick={changedata}
                            onChange={handlesearch}
                            onBlur={handleBlur}
                            type="number"
                            sx={{ marginTop: "20px" }}
                          />
                          <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-select-native">
                              Category Type
                            </InputLabel>
                            <NativeSelect
                              onClick={() => setErrormsg(false)}
                              error
                              id="demo-customized-select-native"
                              value={selectcategory}
                              onChange={handleChange}
                              input={<BootstrapInput />}
                            >
                              <option onClick={() => setCategorytype(null)}>
                                Select category
                              </option>
                              {buttonNames.map((item) => (
                                <option
                                  key={item._id.$oid}
                                  value={item._id.$oid}
                                >
                                  {item.CategoryName}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      ) : (
                        <Box
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "510px",
                            marginTop: "20px",
                            borderBlockWidth: "20px",
                            marginLeft: "20px",
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            label={
                              <span>
                                Product name{" "}
                                <span
                                  style={{
                                    color: "#f04141",
                                    fontSize: "15px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            sx={{ width: "485px", marginLeft: "10px" }}
                            value={productdata}
                            onChange={(e) => setProductdata(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Arabic name
                                <span
                                  style={{
                                    color: "#f04141",
                                    fontSize: "15px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            sx={{
                              width: "485px",
                              marginLeft: "10px",
                              marginTop: "18px",
                            }}
                            value={arabic}
                            onChange={(e) => setarabic(e.target.value)}
                            onKeyPress={handleproductnamesKeyPress}
                          />
                          <TextField
                            label={
                              <span>
                                Product price{" "}
                                <span
                                  style={{
                                    color: "#f04141",
                                    fontSize: "15px",
                                  }}
                                >
                                  *
                                </span>
                              </span>
                            }
                            value={amount}
                            sx={{
                              width: "485px",
                              marginLeft: "10px",
                              marginTop: "20px",
                            }}
                            onChange={handlesearch}
                            onBlur={handleBlur}
                            type="number"
                            onKeyPress={handleproductamountsKeyPress}
                            inputRef={productnamesref}
                          />
                          {bussinessType === "Restaurant" && (
                            <FormControl
                              fullWidth
                              style={{
                                width: "490px",
                                marginLeft: "10px",
                                marginTop: "25px",
                              }}
                            >
                              <InputLabel>Type</InputLabel>
                              <Select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                label="Type"
                                onKeyDown={(event) =>
                                  handleKeyDown(event, categoryRef)
                                }
                                inputRef={typeRef}
                              >
                                <MenuItem value="Veg">Veg</MenuItem>
                                <MenuItem value="Non-Veg">Non-Veg</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                          <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-select-native">
                              Category type{" "}
                              <span
                                style={{ color: "#f04141", fontSize: "15px" }}
                              >
                                *
                              </span>
                            </InputLabel>
                            <NativeSelect
                              id="demo-customized-select-native"
                              value={selectcategory}
                              onChange={handleChange}
                              onKeyPress={handlecategoryselectKeyPress}
                              inputRef={selectcategorys}
                              input={<BootstrapInput />}
                            >
                              <option value={""} disabled>
                                <span>
                                  Select category{" "}
                                  <span
                                    style={{
                                      color: "#f04141",
                                      fontSize: "15px",
                                    }}
                                  >
                                    *
                                  </span>
                                </span>
                              </option>
                              {buttonNames.map((item) => (
                                <option
                                  key={item._id.$oid}
                                  value={item._id.$oid}
                                >
                                  {item.CategoryName}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              margin: " 9px 9px 0px 9px",
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              ref={uploadImageRef}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            <Button
                              variant="outlined"
                              onClick={() => uploadImageRef.current?.click()}
                            >
                              Upload Image
                            </Button>
                            {previewImage && (
                              <img
                                src={
                                  typeof previewImage === "string"
                                    ? previewImage
                                    : URL.createObjectURL(previewImage)
                                }
                                alt="Preview"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>
                        </Box>
                      )}
                    </div>
                    <div className="confirm_btn_edit">
                      <div
                        onClick={() => {
                          setModal(false);
                          changedata();
                        }}
                      >
                        <Buttons Buttondata={CancelButton} />
                      </div>
                      {ConfirmButtonComponent()}
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {Edit && (
              <EditProduct
                id={id}
                Name={Name}
                Amount={Amountedit}
                Category={Categorytype}
                arabic={arabic}
                Images={image}
                onEdit={handleProductEdit}
                closeEdit={closeEdit}
              />
            )}
            {Delete && (
              <Deleteproducts
                id={id}
                Name={Name}
                onDelete={handleDeleteProduct}
              />
            )}
          </div>
        </div>
        {loading && <LoadingScreen />}
        <ToastContainer />
      </div>
    </div>
  );
}

export default AddProduct;