import React, { useEffect, useState, useCallback } from "react";
import Ressetting from "./Ressetting";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import api from "../../axiosConfig";
import Resheader from "../../components/Restaurant/Resheader";
import Resmenu from "../../components/Restaurant/Resmenu";

interface CustomerData {
  _id: { $oid: string };
  Name: string;
  phone_number: string;
  table_number: string;
}

const Rescustomer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [getdata, setGetdata] = useState<CustomerData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [deletepop, setDeletepop] = useState<boolean>(false);
  const [editpop, setEditpop] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [deleteId, setDeleteId] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editname, setEditname] = useState<string>("");
  const [editphone, setEditphone] = useState<string>("");
  const [edittable, setEdittable] = useState<string>("");

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await api.get(`/app/v1/pos/customer`);
      console.log("Fetching customers:", res.data);
      setGetdata(res.data.result);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const deletecustomer = async () => {
    try {
      await api.delete(`/app/v1/pos/customer/${deleteId}`);
      setGetdata((prev) =>
        prev.filter((customer) => customer._id.$oid !== deleteId)
      );
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
    setDeletepop(false);
  };

  const editcustomer = async () => {
    try {
      const res = await api.put(`/app/v1/pos/customer/${editId}`, {
        Name: editname,
        phone_number: editphone,
        table_number: edittable,
      });
      console.log(res);
      setGetdata((prev) =>
        prev.map((customer) =>
          customer._id.$oid === editId
            ? {
                ...customer,
                Name: editname,
                phone_number: editphone,
                table_number: edittable,
              }
            : customer
        )
      );
      setEditpop(false);
    } catch (error) {
      console.error("Error editing customer:", error);
    }
  };

  const handledelete = (id: string) => {
    setDeleteId(id);
    setDeletepop(true);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = getdata.filter((customer) =>
    Object.values(customer)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleclose = () => {
    setEditpop(false);
  };

  const handleedit = (id: string) => {
    const customer = getdata.find((customer) => customer._id.$oid === id);
    if (customer) {
      setEditname(customer.Name);
      setEditphone(customer.phone_number);
      setEdittable(customer.table_number);
      setEditId(id);
      setEditpop(true);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Resheader Resheader={"Customers"} OldOrders={() => {}} />
      <div style={{ display: "flex" }}>
        <Resmenu Resmenu="" />
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Ressetting />
          <div>
            <div style={{ display: "flex" }}>
              <div className="search-container">
                <TextField
                  id="search-customers"
                  label="Search Customers"
                  variant="outlined"
                  className="search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <div className="body-of-table">
              <TableContainer component={Paper} className="category-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Table Number</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length > 0 ? (
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <TableRow key={item._id.$oid}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.Name}</TableCell>
                            <TableCell>{item.phone_number}</TableCell>
                            <TableCell>{item.table_number}</TableCell>
                            <TableCell>
                              <Tooltip
                                title="Edit User"
                                placement="bottom"
                                arrow
                              >
                                <IconButton
                                  onClick={() => handleedit(item._id.$oid)}
                                >
                                  <EditIcon sx={{ color: "black" }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Delete User"
                                placement="bottom"
                                arrow
                              >
                                <IconButton
                                  onClick={() => handledelete(item._id.$oid)}
                                >
                                  <DeleteOutlineIcon sx={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No customers found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </TableContainer>
            </div>

            <Dialog open={deletepop} onClose={() => setDeletepop(false)}>
              <DialogTitle>Delete User</DialogTitle>
              <DialogContent>
                <p>Are you sure you want to delete this user?</p>
                <div style={{ marginTop: "10px", textAlign: "right" }}>
                  <Button onClick={() => setDeletepop(false)} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={deletecustomer}
                    color="error"
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                  >
                    Confirm
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              open={editpop}
              onClose={handleclose}
              className="edit-dialog"
            >
              <DialogTitle className="edit-dialog-title">
                Edit Customer
              </DialogTitle>

              <DialogContent className="edit-dialog-content">
                <div className="user-textfield-customer">
                  <TextField
                    label="Name"
                    variant="outlined"
                    value={editname}
                    onChange={(e) => setEditname(e.target.value)}
                    fullWidth
                    className="edit-input"
                  />
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    value={editphone}
                    onChange={(e) => setEditphone(e.target.value)}
                    fullWidth
                    className="edit-input"
                  />
                  <TextField
                    label="Table"
                    variant="outlined"
                    value={edittable}
                    onChange={(e) => setEdittable(e.target.value)}
                    fullWidth
                    className="edit-input"
                  />

                  <div className="edit-dialog-actions">
                    <Button
                      onClick={handleclose}
                      color="secondary"
                      className="cancel-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={editcustomer}
                      className="confirm-btn"
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rescustomer;
