import React, { useEffect, useState } from "react";
import "./Bill.css";
// import Logosamble from "../../assets/logo192.png";
import Logosamble from '../../assets/Images/imetanic-logo.png';
import api from "../axiosConfig";

function Logo() {
  const [logo, setLogo] = useState<string>("");

  useEffect(() => {
    console.log("loading images")
    const busname = sessionStorage.getItem("organization_name")
    const imgs = sessionStorage.getItem(`Logo${busname}`);
    if (imgs) { setLogo(imgs) }
    console.log(imgs)
    imgurl()
  }, []);

  const imgurl = () => {
    const busname = sessionStorage.getItem("organization_name")
    const storedColor = sessionStorage.getItem(`background_color${busname}`);
    if(storedColor){
      document.documentElement.style.setProperty('--color', storedColor);
    }
    const img = busname + ".png";
    api
      .get(`/pos/logo/${img}`, { responseType: "arraybuffer" })
      .then((response) => {
        console.log("img", response);
        const blob = new Blob([response.data], { type: "image/png" });
        const objectUrl =  URL.createObjectURL(blob)
        sessionStorage.setItem(
          `Logo${busname}`,
          objectUrl
        );
        if (objectUrl) { setLogo(objectUrl) }
      });
  }
  return (
    <div>
      {logo ? (
        <img
          src={logo}
          alt="Uploaded"
          className="logo-container" id="business-logo"
        />
      ) : (
        <img
          src={Logosamble}
          alt=""
          className="logo-container" id="business-logo"
        />
      )}
    </div>
  );
}

export default Logo;
