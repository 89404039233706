import React, { useEffect, useState } from "react";
import "./Ledger.css";
import api from "../axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Header from "../components/laundry/Header";
import Menu from "../components/laundry/Menu";

interface Customerdata {
  customer: {
    Address: {
      customeraddress1: string;
      customeraddress2: string;
      customeraddress3: string;
    };
    Email: string;
    Name: string;
    phone_number: string;
    _id: {
      $oid: any;
    };
  };
  ledger: Array<{
    OrderDetails: Array<{
      Orderamount: string;
      Orderdate: string;
      ReceivedAmount: string;
      RemainingAmount: string;
      Totalamount: string;
      orderid: string;
    }>;
    customer_name: string;
    customer_phone_number: string;
    Email: string;
    total_outstanding_balance: string | null;
    pay_amount: string;
    outstanding_balance: string;
  }>;
}

const Ledger = () => {
  const { CustomerId } = useParams();
  const [customersData, setCustomersData] = useState<Customerdata[]>([]);
  const [filteredData, setFilteredData] = useState<Customerdata[]>([]);
  const [error, setError] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(0);
  const [employeesData, setEmployeesData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Ledgers - iMetanic";
    api.get(`/pos/ledger`).then((response) => {
      console.log(response);
      const customersdata: Customerdata[] = response.data.result;
      setCustomersData(customersdata);
      setFilteredData(customersdata);
      console.log("data", customersdata);
    });
    const employee = () => {
      const employeeData = sessionStorage.getItem("employee");
      setEmployeesData(Boolean(employeeData));
    };
    employee();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const opencustomer_data = (id: string) => {
    if (employeesData) {
      navigate(`/v1/employee/ledger/${id}`);
    } else {
      navigate(`/v1/ledger/${id}`);
    }
  };

  const roundValue = (value: number | undefined) => {
    if (value === undefined) return 0;
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(
      /[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g,
      ""
    );
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredData(customersData);
      setError("");
      return;
    }
    const formattedValue = value.toLowerCase().trim();
    const filtered = customersData.filter((item) => {
      const nameMatch =
        item.customer.Name &&
        item.customer.Name.toLowerCase().includes(formattedValue);
      const phoneMatch =
        item.customer.phone_number &&
        item.customer.phone_number.toLowerCase().includes(formattedValue);
      return nameMatch || phoneMatch;
    });

    if (filtered.length === 0) {
      setError("No results found.");
    } else {
      setError("");
    }
    setFilteredData(filtered);
  };
  const tableformet = () => {
    if (filteredData === null) {
      return <h3>No data available</h3>;
    }
    return (
      <div className="cointainer2">
        <Paper sx={{ width: "88vw", marginLeft: "40px", marginTop: "5px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large" }}
                  >
                    Phone number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontSize: "large" }}
                  >
                    Outstanding balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      sx={{ textAlign: "center", padding: "16px" }}
                    >
                      <p>No data available</p>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData &&
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow
                        key={index}
                        onClick={() =>
                          opencustomer_data(item.customer._id.$oid)
                        }
                      >
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {item.customer.Name}
                        </TableCell>
                        <TableCell align="center">
                          {item.customer.phone_number}
                        </TableCell>
                        <TableCell align="center">
                          {roundValue(
                            item.ledger.reduce(
                              (sum, i) =>
                                sum +
                                (parseFloat(
                                  i.total_outstanding_balance ?? "0"
                                ) || 0),
                              0
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: 50000 }]}
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </div>
    );
  };
  return (
    <div className="full_length">
      <Header Header="Ledger" />
      <div style={{ display: "flex" }}>
        <Menu />
        <div>
          <div className="leder_header_sec" id="ledger">
            <div className="total_records">
              <b>Total records:</b>
              <h1 className="backend_total"> {customersData.length}</h1>
            </div>
            <div className="oustand_bala">
              <b>Outstanding balance:</b>{" "}
              <h1 className="backend_total_bala">
                {roundValue(
                  customersData.reduce(
                    (acc, curr) =>
                      acc +
                      curr.ledger.reduce(
                        (sum, item) =>
                          sum +
                          parseFloat(item.total_outstanding_balance || "0"),
                        0
                      ),
                    0
                  )
                )}
              </h1>
            </div>

            <div className="search_item">
              <TextField
                value={searchTerm}
                id="Search-Phone-number"
                placeholder="Search by Name or Phone number"
                // label={"Search by Name or Phone number"}
                variant="outlined"
                sx={{ width: 400, height: "60px", marginBottom: "50px" }}
                type="tel"
                onChange={handleSearch}
              />
            </div>
          </div>
          <div>{tableformet()}</div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Ledger;
