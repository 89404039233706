import React, { useEffect, useState } from "react";
import "./CarWash.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { log } from "node:console";
import EditIcon from '@mui/icons-material/Edit';
// import CarHeaderBox from "./CarHeaderBox";
import { toast, ToastContainer } from "react-toastify";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from "../../axiosConfig";
import CarMenuBox from "./CarMenuBox";
import CarHeaderBox from "./CarHeaderBox";

interface OrderItem {
  Amount: string;
  ProductName: string;
  category_id: string;
  _id: { $oid: string };
}
interface CarDetailState {
  _id: { $oid: string };
  CategoryName: string;
}
interface CarDetail {
  _id: { $oid: string };
  customername: string;
  customerphone: number;
  car_number: [];
  date: string;
}
interface MultiCarItem {
  carNumber: string;
  productDetails: { product: string; price: string }[];
  totalPrice: number;
}

const CarWash = () => {
  const [orderlist, setOrderList] = useState<OrderItem[]>([]);
  const [getdata, setGetdata] = useState<CarDetailState[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<OrderItem[]>([]);
  const [newpoint, setNewPoint] = useState<any[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [filteredList, setFilteredList] = useState<OrderItem[]>([]);
  const [orderdata, setOrderdata] = useState<CarDetail>();
  const [carNumber, setCarNumber] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [multiCar, setMultiCar] = useState<MultiCarItem[]>([]);
  const [editCarIndex, setEditCarIndex] = useState<number | null>(null);
  const [productToEdit, setProductToEdit] = useState<OrderItem | null>(null);
  const [model, setmodel] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [carnumber, setCarnumber] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  
  useEffect(() => {
    document.title = " Product Details - iMetanic";
    getApi();
  }, []);

  const getApi = () => {
    api.get(`/app/v1/pos/product`).then((res) => {
      const userproduct: OrderItem[] = res.data.result;
      setOrderList(userproduct);

      if (activeId) {
        setFilteredList(
          userproduct.filter((item) => item.category_id === activeId)
        );
      }
    });

    api.get(`/app/v1/pos/category`).then((res) => {
      const cardetail: CarDetailState[] = res.data.result;
      setGetdata(cardetail);

      if (cardetail.length > 0) {
        const firstCategoryId = cardetail[0]._id.$oid;
        setActiveId(firstCategoryId);

        api.get(`/app/v1/pos/product`).then((productRes) => {
          const userproduct: OrderItem[] = productRes.data.result;
          setFilteredList(
            userproduct.filter((item) => item.category_id === firstCategoryId)
          );
        });
      }
    });

    api.get(`/app/v1/pos/customer/${id}`).then((res) => {
      const carDetail: CarDetail = res.data.result;
      setCarnumber(carDetail.car_number);
      setOrderdata(carDetail);
    });
  };

  const handleClick = (id: string) => {
    setActiveId(id);
    const filteredData = orderlist.filter((item) => item.category_id === id);
    setFilteredList(filteredData);
  };

  const finalTotal = () => {
    let priceValue = multiCar.reduce((acc, car) => acc + car.totalPrice, 0);
    let grandTotal = priceValue + totalPrice;
    console.log("Grand Total:", grandTotal);
    return grandTotal.toFixed(2);
  };

  // const passData = () => {
  //   let mulcarnum = multiCar.map((i) => i.carNumber).join(", ");
  //   let separatetotal = multiCar.map((i) => i.totalPrice.toFixed(2));
  //   const update = {
  //     customerDedails: {
  //       cusName: orderdata?.customername || "",
  //       cusPhone: orderdata?.customerphone || "",
  //       cusCar: [mulcarnum || ""],
  //       Date: orderdata?.date || "",
  //       totalPrice: finalTotal(),
  //       productTotal: separatetotal,
  //     },
  //     serviceDetails: multiCar,
  //   };
  //   console.log(update);

  //   api
  //     .put(`/app/v1/pos/customer/${id}`, update)
  //     .then((res) => {
  //       console.log("Car details updated successfully:", res);
  //       if (res.data && id) {
  //         navigate(`/v1/car-Payment?id=${id}`);
  //       } else {
  //         console.error("Error: Missing '_id' in API response");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error updating car details:", err);
  //     });
  // };
  const passData = () => {
    if (!multiCar.length || multiCar.every((car) => car.productDetails.length === 0)) {
      toast.warning("Please select at least one product before proceeding.");
      return;
    }

    let mulcarnum = multiCar.map((i) => i.carNumber).join(", ");
    let separatetotal = multiCar.map((i) => i.totalPrice.toFixed(2));
    const update = {
      customerDedails: {
        cusName: orderdata?.customername || "",
        cusPhone: orderdata?.customerphone || "",
        cusCar: [mulcarnum || ""],
        Date: orderdata?.date || "",
        totalPrice: finalTotal(),
        productTotal: separatetotal,
      },
      serviceDetails: multiCar,
    };
    console.log(update);
  
    api
      .put(`/app/v1/pos/customer/${id}`, update)
      .then((res) => {
        console.log("Car details updated successfully:", res);
        if (res.data && id) {
          navigate(`/v1/car-Payment?id=${id}`);
        } else {
          console.error("Error: Missing '_id' in API response");
        }
      })
      .catch((err) => {
        console.error("Error updating car details:", err);
      });
  };
  // const handleClick = (id: string) => {
  //   setActiveId(id);
  //   const filteredData = orderlist.filter((item) => item.category_id === id);
  //   setFilteredList(filteredData);
  // };

  
  const handleProductClick = (item: OrderItem) => {
    if (!multiCar.length) {
      toast.warning("Please add a car first.");
      return;
    }

    const cName = getdata.find((i) => i._id.$oid === item.category_id);
    const selectProductIs = {
      product: item.ProductName,
      price: item.Amount,
      cName: cName?.CategoryName || "",
    };

    const updatedCars = [...multiCar];
    if(editCarIndex === null) {
    const lastCar = updatedCars[updatedCars.length - 1];
    const productExists = lastCar.productDetails.some(
      (product) => product.product === selectProductIs.product
    )
    if (productExists) {
      //alert("Product already added to the car.");
      return;
    }
    lastCar.productDetails.push(selectProductIs);
    lastCar.totalPrice += parseFloat(item.Amount);
    setMultiCar(updatedCars);
  } else {
    const selectedCar = updatedCars[editCarIndex];
    const existingProductIndex = selectedCar.productDetails.findIndex(
      (prod) => prod.product === item.ProductName
    );

    if (existingProductIndex === -1) {
      selectedCar.productDetails.push({
        product: item.ProductName,
        price: item.Amount,
      });
      selectedCar.totalPrice += parseFloat(item.Amount);
    }
    setMultiCar(updatedCars);
  }
  };

  const addItems = () => {
    if (!carNumber.trim()) {
      alert("Please enter a valid car number.");
      return;
    }

    const newCar = {
      carNumber,
      productDetails: [],
      totalPrice: 0,
    };
    setMultiCar([...multiCar, newCar]);
    setCarNumber("");
    setmodel(false);
    setEditCarIndex(null);
  };
  const handleDeleteProduct = (productToDelete: any, carIndex: number) => {
    const updatedCars = [...multiCar];
    const selectedCar = updatedCars[carIndex];

    if (!productToDelete) {
      updatedCars.splice(carIndex, 1);
    } else {
      const updatedProducts = selectedCar.productDetails.filter(
        (product) => product.product !== productToDelete.product
      );

      const updatedTotalPrice =
        selectedCar.totalPrice - parseFloat(productToDelete.price);

      if (updatedProducts.length === 0) {
        updatedCars.splice(carIndex, 1);
      } else {
        updatedCars[carIndex] = {
          ...selectedCar,
          productDetails: updatedProducts,
          totalPrice: updatedTotalPrice,
        };
      }
    }

    setMultiCar(updatedCars);
  };
  const addProductToCar = (product: OrderItem) => {
    const updatedCars = [...multiCar];
    if (editCarIndex !== null) {
      const selectedCar = updatedCars[editCarIndex];
      const existingProductIndex = selectedCar.productDetails.findIndex(
        (prod) => prod.product === product.ProductName
      );

      if (existingProductIndex === -1) {
        selectedCar.productDetails.push({
          product: product.ProductName,
          price: product.Amount,
        });
        selectedCar.totalPrice += parseFloat(product.Amount);
      }
    } else {
      const lastCar = updatedCars[updatedCars.length - 1];
      const existingProductIndex = lastCar.productDetails.findIndex(
        (prod) => prod.product === product.ProductName
      );

      if (existingProductIndex === -1) {
        lastCar.productDetails.push({
          product: product.ProductName,
          price: product.Amount,
        });
        lastCar.totalPrice += parseFloat(product.Amount);
      }
    }

    setMultiCar(updatedCars);
    setProductToEdit(null);
  };


  const handleEditClick = (index: number) => {
    console.log("Editing car at index:", index);
    setEditCarIndex(index);
    setProductToEdit(null);
    setIsEditing(true);

  }
  const getEditIconColor = (index: number) => {
    return editCarIndex === index && isEditing ? 'green' : 'red';
  }
  const getCarBordersColor = (index: number) => {
    return editCarIndex === index && isEditing ? 'green' : 'none';
  };
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="carwash_bodyalignment">
      <div className="image_carwash">
        <CarHeaderBox Carheader="Carwash " />
        <div style={{ display: "flex" }}>
          <CarMenuBox />
          <div className="flow_alignment">
            <div className="cointainer">
              <div style={{ position: "relative", top: "30px", right: "25px" }}
                onClick={handleBack}
              >
                <ArrowBackIcon style={{ width: "40px", height: "30px" }} />
              </div>
              <div className="tab-container">
                {getdata.map((item) => (
                  <div
                    style={{ fontSize: "20px", color: "#1d6fde", zIndex: "0"}}
                    key={item._id.$oid}
                    className={`tab-item ${activeId === item._id.$oid ? "active-tab" : ""
                      }`}
                    onClick={() => handleClick(item._id.$oid)}
                  >
                    {item.CategoryName}
                  </div>
                ))}
              </div>

              {/* {orderlist.map((item) => (
                <div
                  key={item._id.$oid}
                  className="card"
                  onClick={() => handleProductClick(item)}
                >
                  <p className="product-name">{item.ProductName}</p>
                  <p className="amount">${item.Amount}</p>
                </div>
              ))} */}
              <div className="products">
                {filteredList.map((item) => (
                  <div
                  // style={{backgroundColor:"red"}}
                    key={item._id.$oid}
                    className="card"
                    onClick={() => {
                      handleProductClick(item);
                      setIsEditing(false);
                      addProductToCar(item);
                    }}
                  >
                    <p className="product-name">{item.ProductName}</p>
                    <p className="amount">${item.Amount}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="price_alignment">
            <div className="consider_price">
              <div className="price_structure">
                <div
                  style={{
                    width: "50%",
                    marginLeft: "-40px",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <p>Customer name:</p>
                    <b>{orderdata?.customername}</b>
                    {/* <p>Car number:</p> */}
                  </div>
                  {/* <b>{carnumber}</b>  */}
                </div>
                <div style={{ width: "50%" }}>
                  <p className="price_brify">
                    Total Price: <br />
                    <span className="total_price">{finalTotal()}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="placement-order">
              <div style={{ height: "380px", overflowY: "auto" }}>
                <h3 style={{ position: "relative", top: "8px" }}>Services:</h3>
                <div className="car-cards-container" style={{ overflow: "auto" }}>

                  {multiCar.map((car, index) => (
                    <div
                      className="car-card"
                      key={index}
                      style={{
                        backgroundColor: "#f0f8f9 ",
                        border: `3px solid ${getCarBordersColor(index)}`
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3>Car No: {car.carNumber}</h3>
                          <p>Total Price: ${car.totalPrice.toFixed(2)}</p>
                          <DeleteIcon
                            onClick={() => handleDeleteProduct(null, index)}
                            style={{ cursor: "pointer", color: "red" }}
                          />
                          <EditIcon
                            onClick={() => handleEditClick(index)}
                            style={{ cursor: "pointer", color: getEditIconColor(index) }}
                          />
                        </div>
                      </div>

                      <div className="product-list">
                        {car.productDetails.map(
                          (product: any, prodIndex: number) => (
                            <div key={prodIndex} className="product-item">
                              <p
                                style={{
                                  width: "60%",
                                  display: "flex",
                                  alignItems: "start",
                                }}
                              >
                                {product.product}
                              </p>
                              <p
                                style={{
                                  width: "18%",
                                  display: "flex",
                                  alignItems: "start",
                                }}
                              >
                                ${product.price}
                              </p>
                              <DeleteIcon
                                onClick={() =>
                                  handleDeleteProduct(product, index)
                                }
                                style={{ cursor: "pointer", color: "red" }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="add-car-actions">
                <button
                  className="Car-button"
                  onClick={() => setmodel(!model)}
                  style={{ fontSize: "16px" }}
                >
                  + CAR
                </button>
                <button
                  className="next-button"
                  onClick={passData}
                  // onClick={()=> navigate(`/v1/car-Payment`)}
                  style={{ fontSize: "16px" }}
                >
                  NEXT
                </button>
              </div>
              {model && (
                <div className="carmodals">
                  <div className="caroverlays">
                    <div className="carmodal-contents">
                      <div>
                        <h3>Add Car Number</h3>
                        <TextField
                          label="Car Number *"
                          variant="outlined"
                          fullWidth
                          value={carNumber}
                          onChange={(e) => setCarNumber(e.target.value)}
                          className="wide-textfield"
                          style={{ marginTop: "10px", width: "350px" }}
                        />
                        <div className="cancel-button">
                          <button
                            onClick={() => setmodel(false)}
                            className="next-button-popup"
                          >
                            Cancel
                          </button>
                          <button
                            className="Car-button-popup"
                            onClick={addItems}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ToastContainer/>
        </div>
      </div>
    </div>
  );
};
export default CarWash;
