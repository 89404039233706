import {
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCardIcon from "@mui/icons-material/AddCard";
import Editmenu from "./EditMenu";
import EditIcon from "@mui/icons-material/Edit";
import Roleselect from "./RoleSelect";
import CloseIcon from "@mui/icons-material/Close";
import Edituser from "./EditUser";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AddCardEditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";
import Resheader from "../../components/Restaurant/Resheader";
import CarHeaderBox from "../../components/car-wash/CarHeaderBox";
import Resmenu from "../../components/Restaurant/Resmenu";
import CarMenuBox from "../../components/car-wash/CarMenuBox";
interface Employee_data {
  [x: string]: any;
  _id: {
    $oid: string;
  };
  Branch_name: string;
  user_name: string;
  name: string;
  employee_name: string;
  phone_number: string;
  email_id: string;
  address: string;
  password: string;
  Delete: string;
  status: any;
  employee: Array<{
    Branch_name: string;
  }>;
  date_of_joined: string;
  date_of_leaving: string;
  role: string;
}
interface content {
  _id: {
    $oid: string;
  };
  status: any;
  date_of_joined: string;
  date_of_leaving: string;
}
function AddAgent() {
  const [Employeedata, setEmployeedata] = useState<Employee_data[]>([]);
  const [fear, setfear] = useState<content[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Employee_data[]>([]);
  const [agentname, setagentname] = useState("");
  const [username, setusername] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [confirmemail, setconfirmemail] = useState("");
  const [dob, setdob] = useState(" ");
  const [tempId, setTempId] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [address, setaddress] = useState("");
  const [Agent, setAddagent] = useState(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [ids, setid] = useState<string>("");
  const [Name, setname] = useState<string>("");
  const [Phone, setphone] = useState<string>("");
  const [Role, setrole] = useState<string>("");
  const [deletepress, setdeletepress] = useState<boolean>(false);
  const [erroremail, seterroremail] = useState<boolean>(false);
  const [filter, setfilter] = useState<boolean>(false);
  const [tablecontents, settablecontents] = useState<boolean>(false);
  const [emailerror, setemailerror] = useState<boolean>();
  const [data, setdata] = useState("");
  const userphonenos = useRef<HTMLInputElement>(null);
  const useremails = useRef<HTMLInputElement>(null);
  const userdobs = useRef<HTMLInputElement>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const useraddress = useRef<HTMLInputElement>(null);
  const [showRoleSelect, setShowRoleSelect] = useState(false);
  const [error, setError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string | null>("");
  const [role, setRole] = useState("super admin");
  const [inputValue, setInputValue] = useState<string>("");
  const [doberror, setdobError] = useState<string | null>(null);
  const [isBranchSelected, setIsBranchSelected] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [usernameToDelete, setUsernameToDelete] = useState<string | null>(null);
  const [bussinessType, setBussinessType] = useState<string>("");
  const handlenameskeypress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      userphonenos.current?.focus();
    }
  };
  const handlePhoneskeypress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      useremails.current?.focus();
    }
  };
  const handleEmailskeypress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      userdobs.current?.focus();
    }
  };
  const handledobskeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      useraddress.current?.focus();
    }
  };
  const handleaddresskeypress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (agentname && phonenumber && confirmemail && dob && address) {
      } else {
        toast.error("please enter all details");
      }
    }
  };

  useEffect(() => {
    document.title = "Users - Settings - iMetanic";
    if (erroremail === false) {
      filters();
      setfilter(true);
    }
    if (agentname && phonenumber.length > 7 && email) {
      setvisible(true);
    } else {
      setvisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentname, confirmemail, phonenumber, email, erroremail]);

  const Bbutton = {
    Blue: "b",
    name: "Confirm",
  };

  function debounce<T extends (...args: any[]) => void>(
    func: T,
    timeout = 1000
  ) {
    let timer: ReturnType<typeof setTimeout> | undefined;
    return function (this: any, ...args: Parameters<T>): void {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const Update = async () => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2).padStart(2, "0");
    const customerdata = {
      employee_name: agentname,
      user_name: username,
      phone_number: phonenumber,
      email_id: confirmemail,
      role: Role,
      date_of_joined: dob,
      status: "inactive",
    };
    api
      .post(`/pos/add`, customerdata)
      .then((res) => {
        const responseData = res.data;
        const userId = responseData.Id;
        toast.success("User uploaded successfully", {
          position: "top-right",
          autoClose: 2100,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        orderemailmsg(userId);
      })
      .catch((error) => {
        console.error("Error during user upload:", error);
        if (
          error.response &&
          error.response.data.message === "Email Already Exists"
        ) {
          toast.error("Email already exist", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        } else if (
          error.response &&
          error.response.data.message === "Username Already Exist"
        ) {
          toast.error("Username already exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        }
      });
  };
  const fetchTableData = () => {
    api
      .get(`/pos/getUsers`)
      .then((res) => {
        setFilteredData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching table data", error);
      });
  };
  // if (agentname && phonenumber && confirmemail) {
  //   try {
  //     const response = await api.post(`/pos/add`, {
  //       employee_name: agentname,
  //       user_name: username,
  //       phone_number: phonenumber,
  //       email_id: confirmemail,
  //       // date_of_birth: dob,
  //       // address: address,
  //       // date_of_joined: `${day}/${month}/${year}`,
  //       role: Role,
  //     });
  //     console.log('working');
  //     if (response.status === 200) {
  //       const responseData = response.data;
  //       const userId = responseData.Id;
  //       toast.success("User uploaded successfully", {
  //         position: "top-center",
  //         theme: "colored",
  //         transition: Flip,
  //         autoClose: 2000,
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //       orderemailmsg(userId);
  //     }else {
  //       const responseData = response.data;
  //       if (response.status === 403) {
  //         console.log('hi iam working');
  //         if (responseData.message === "'Request failed with status code 403") {
  //           toast.error("Invalid phone number format.", {
  //             position: "top-center",
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //             transition: Flip,
  //           });
  //         } else if (responseData.message === "Username Already Exists") {
  //           toast.error("Username already exists.");
  //         } else if (responseData.message === "Email Already Exists") {
  //           toast.error("Email already exists.");
  //           console.log(responseData.message);
  //         }
  //         else {
  //           toast.error("Unknown error occurred.");
  //         }
  //       } else {
  //         console.error("Registration failed:", response.statusText);
  //         console.log(response);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error during registration:", error);
  //   }
  // } else {
  //   toast.error("Please fill in all required fields.", {
  //     position: "top-center",
  //     autoClose: 2000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  //     transition: Flip,
  //   });
  // }

  const expiryTime = new Date().getTime();
  const orderemailmsg = (id: string) => {
    const url = `${process.env.REACT_APP_WEB_URL}/imetanic/setpassword/${id}/${expiryTime}/2580`;
    const sendmail = {
      receiver_email: email,
      subject: "Set your new password",
      welcome_message: `${agentname}`,
      success_message: "Your account has been successfully created.",
      res_message: "We got a request to set your POS Loundary Login password.",
      description:
        "Your password will not be created if you ignore this message. If you didn't ask for a create password.",
      setPassword_link: `${url}`,
      user_name: `${username}`,
      thank_message: `Thanks,
                      The iMetanic Team`,
    };
    console.log("sendmail", sendmail);
    api.post(`/app/v1/pos/user_send_email`, sendmail).then((res) => {
      if (res.status === 201) {
        console.log(res);
        toast.success("Email sent successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
      } else {
        toast.warning(`Unexpected response: ${res.status}`, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
      setTimeout(() => {}, 5000);
    });
  };
  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    if (value.length < 8) {
      setPhoneError("Your phone number is incomplete");
    } else {
      setPhoneError(null);
    }
    event.target.value = value;
    setPhonenumber(value);
  };
  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const validDomains = [".com", ".in", ".co"];
      const hasValidDomain = validDomains.some((domain) =>
        value.endsWith(domain)
      );
      return hasValidDomain;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setEmail(value);
      setconfirmemail(value);
      setemailerror(false);
    } else {
      setEmail(value);
      setemailerror(true);
    }
  };
  // const handlenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setagentname(event.target.value.slice(0, 25));
  // };
  function handlenameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setagentname(event.target.value.slice(0, 25));
  }

  const handleinputnameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setusername(event.target.value.slice(0, 25));
  };
  const Edit_user = (id: string) => {
    setEdit(!edit);
    setid(id);
    setname(Name);
    setphone(Phone);
    setrole(Role);
  };
  const AddUserButton = {
    Blue: "blue",
    name: "Add User",
  };
  const cancelButton = {
    Cancel: "blue",
    name: "cancel",
  };
  const ConfirmMutton = {
    Cancel: "blue",
    name: "Confirm",
  };
  const errorconfirmButtonerr = {
    error: "blue",
    name: "Confirm",
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const confirm = () => {
    if (visible === true) {
      return (
        <div onClick={() => Update()} id="update_btn">
          <Buttons Buttondata={Bbutton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={errorconfirmButtonerr} />
        </div>
      );
    }
  };
  const Delete = (id: string) => {
    api
      .delete(`/pos/add/${id}`)
      .then((res) => {
        console.log(res);
        setTempId("");
        toast.success("User  deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });

        setEmployeedata((prevData) =>
          prevData.filter((item) => item._id.$oid !== id)
        );
        setFilteredData((prevData) =>
          prevData.filter((item) => item._id.$oid !== id)
        );
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        toast.error("Failed to delete user", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
      });
  };
  const handleDeleteClick = (userId: string, username: string) => {
    setUserToDelete(userId);
    setUsernameToDelete(username);
    setIsDeleteModalOpen(true);
  };
  const handleAddCardClick = (id: string) => {
    setdata(id);
    setShowRoleSelect(true);
    setIsBranchSelected(true);
  };
  const menu = {
    User: "user",
  };

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let newValue = value.replace(/[^0-9-]/g, "");
    const parts = newValue.split("-");
    if (parts.length > 2 && parts[2].length > 4) {
      parts[2] = parts[2].slice(0, 4);
    }
    if (parts.length === 1) {
      newValue =
        newValue.length > 2
          ? newValue.slice(0, 2) + "-" + newValue.slice(2)
          : newValue;
    } else if (parts.length === 2) {
      newValue =
        parts[0] +
        "-" +
        (parts[1].length > 2
          ? parts[1].slice(0, 2) + "-" + parts[1].slice(2)
          : parts[1]);
    }
    if (parts[0] && parseInt(parts[0], 10) > 31) {
      parts[0] = "31";
    }
    if (parts[1] && parseInt(parts[1], 10) > 12) {
      parts[1] = "12";
    }
    if (parts.length > 2 && parts[2] && parts[2].length > 4) {
      parts[2] = parts[2].slice(0, 4);
    }
    newValue = parts.join("-");
    setInputValue(newValue);
    if (newValue === "") {
      setdob("");
      setdobError(null);
      // setShowFormattedDob(false);
      return;
    }
    const formattedDate = formatDate(newValue);
    if (formattedDate) {
      setdob(formattedDate);
      setdobError(null);
      // setShowFormattedDob(true);
    } else {
      setdob("");
      setdobError("Invalid date format. Please enter in dd-mm-yyyy format.");
      // setShowFormattedDob(false);
    }
  };
  const formatDate = (dateString: string): string => {
    const parsedDate = parseDate(dateString);
    if (parsedDate) {
      const day = String(parsedDate.getDate()).padStart(2, "0");
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return "";
  };
  const parseDate = (dateString: string): Date | null => {
    const numericDateRegex =
      /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-(\d{4})$/;
    const numericMatch = dateString.match(numericDateRegex);
    if (numericMatch) {
      const [dayStr, monthStr, yearStr] = dateString.split("-");
      const month = parseInt(monthStr, 10);
      const day = parseInt(dayStr, 10);
      const year = parseInt(yearStr, 10);
      if (yearStr.length !== 4 || isNaN(year)) {
        return null;
      }
      if (month < 1 || month > 12) {
        return null;
      }
      if (day < 1 || day > 31 || !isValidDayForMonth(day, month, year)) {
        return null;
      }
      return new Date(year, month - 1, day);
    }
    return null;
  };
  const isValidDayForMonth = (
    day: number,
    month: number,
    year: number
  ): boolean => {
    const daysInMonth = [
      31,
      isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return day >= 1 && day <= daysInMonth[month - 1];
  };
  const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g, "");
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredData(Employeedata);
      setError("");
    } else {
      const formattedValue = value.trim();
      const filtered = Employeedata.filter((item) => {
        return (
          (item.phone_number && item.phone_number.includes(formattedValue)) ||
          (item.employee_name && item.employee_name.includes(formattedValue))
        );
      });
      if (filtered.length === 0) {
        setError("No results found.");
      } else {
        setError("");
      }
      setFilteredData(filtered);
    }
  };
  const tableformat = () => {
    if (filteredData === null) {
      return <h3>No result found</h3>;
    }
    return (
      <div className="customer_Table" id="customer_table">
        <Paper id="Table" sx={{ width: "89vw" }}>
          <TableContainer sx={{ maxWidth: 2050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Username
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Phone number
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Date of joining
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Date of leaving
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Selected branch
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Roles
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "large", zIndex: "0" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      sx={{ textAlign: "center", padding: "16px" }}
                    >
                      <p>No data available</p>
                    </TableCell>
                  </TableRow>
                ) : (
                  (rowsPerPage > 0
                    ? filteredData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredData
                  ).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{item.employee_name || item.name}</TableCell>
                      <TableCell>{item.user_name || "N/A"}</TableCell>
                      <TableCell>{item.phone_number}</TableCell>
                      <TableCell>{item.date_of_joined || "N/A"}</TableCell>
                      <TableCell>{item.date_of_leaving || "N/A"}</TableCell>
                      <TableCell>
                        {item.date_of_leaving && item.status !== "Inactive" ? (
                          <Tooltip title="Deleted" placement="bottom" arrow>
                            <IconButton>
                              <DeleteOutlineIcon sx={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        ) : item.password && item.status === "Active" ? (
                          <Tooltip title="Active" placement="bottom" arrow>
                            <IconButton>
                              <CheckCircleIcon sx={{ color: "green" }} />
                            </IconButton>
                          </Tooltip>
                        ) : item.status === "Inactive" ? (
                          <Tooltip
                            title="Waiting for password"
                            placement="bottom"
                            arrow
                          >
                            <IconButton>
                              <NewReleasesIcon sx={{ color: "orange" }} />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </TableCell>
                      <TableCell>{item.Branch_name || ""}</TableCell>
                      <TableCell>{item.role}</TableCell>
                      <TableCell>
                        {item.user_name ? (
                          <>
                            {
                              <Tooltip
                                title="Edit User"
                                placement="bottom"
                                arrow
                              >
                                <IconButton
                                  onClick={() => Edit_user(item._id.$oid)}
                                >
                                  <EditIcon sx={{ color: "black" }} />
                                </IconButton>
                              </Tooltip>
                            }
                            {item.employee_name && (
                              <Tooltip
                                title="Delete User"
                                placement="bottom"
                                arrow
                              >
                                {/* <IconButton onClick={() => Delete(item._id.$oid)}> */}
                                <IconButton
                                  onClick={() => {
                                    setdeletepress(!deletepress);
                                    setTempId(item._id.$oid);
                                    setusername(item.employee_name);
                                  }}
                                >
                                  <DeleteOutlineIcon sx={{ color: "red" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {item.employee &&
                            item.employee.some((i) => i.Branch_name) ? (
                              item.employee_name ? (
                                <Tooltip
                                  title="Edit Select Branch"
                                  placement="bottom"
                                  arrow
                                >
                                  <IconButton
                                    onClick={() =>
                                      handleAddCardClick(item._id.$oid)
                                    }
                                  >
                                    {isBranchSelected ? (
                                      <AddCardEditIcon
                                        sx={{ color: "black" }}
                                      />
                                    ) : (
                                      <ManageAccountsIcon
                                        sx={{ color: "black" }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              ) : null
                            ) : item.employee_name ? (
                              <Tooltip
                                title="Select Branch"
                                placement="bottom"
                                arrow
                              >
                                <IconButton
                                  onClick={() =>
                                    handleAddCardClick(item._id.$oid)
                                  }
                                >
                                  {isBranchSelected ? (
                                    <AddCardEditIcon sx={{ color: "black" }} />
                                  ) : (
                                    <ManageAccountsIcon
                                      sx={{ color: "black" }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  };
  const renderAction = (status: string) => {
    if (status === "active") {
      return (
        <Tooltip title="Edit User" placement="bottom" arrow>
          <IconButton>
            <EditIcon sx={{ color: "black" }} />
          </IconButton>
        </Tooltip>
      );
    } else if (status === "inactive") {
      return (
        <Tooltip title="Delete User" placement="bottom" arrow>
          <IconButton>
            <DeleteOutlineIcon sx={{ color: "red" }} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Delete User" placement="bottom" arrow>
          <IconButton>
            <DeleteOutlineIcon sx={{ color: "red" }} />
          </IconButton>
        </Tooltip>
      );
    }
  };
  const filters = () => {
    console.log(filter);
    if (filter === true) {
      api
        .get(`/pos/add_employee/DeletedUsers`)
        .then((res) => {
          const emergency: content[] = res.data.result;
          setfear(emergency);
          console.log("delete", res);
          if (res.data.message === "No deleted users found") {
            console.log("No content to display");
            const err = res.data.message;
            toast(err);
          }
          const user: Employee_data[] = res.data.result;
          if (user) {
            setEmployeedata(user);
            setFilteredData(user);
          }
        })
        .catch((error) => {
          const err =
            error.response?.data?.message || "An unknown error occurred";
          if (err === "Email Already Exists") {
            toast.error("Email Already Exists. Please try a different email.");
          } else {
            toast.error(err);
          }
        });
    } else {
      api.get(`/pos/get_user`).then((res) => {
        console.log(res);
        const user: Employee_data[] = res.data.result.filter(
          (data: { user_name: any }) => data.user_name !== undefined
        );
        setEmployeedata(user);
        setFilteredData(user);
      });
    }
  };
  //   function debounce(func: () => void, delay: number | undefined) {
  //     let timeout=null
  //     return () => {
  //         timeout=setTimeout(() => {
  //             func()
  //         }, delay)
  //     }
  // }
  // function setdeleted(arg0: boolean): void {
  //   throw new Error("Function not implemented.");
  // }

  const handleclear = () => {
    setagentname("");
    setusername("");
    setEmail("");
    setphone("");
    setInputValue("");
    setaddress("");
  };

  useEffect(() => {
    const bussinessType = sessionStorage.getItem("business_type");
    setBussinessType(String(bussinessType));
  }, []);

  const OldOrders = () => {};

  return (
    <div>
      {bussinessType === "Laundry" && <Header Header="Settings - Users" />}
      {bussinessType === "Restaurant" && (
        <Resheader Resheader={"Settings - Users"} OldOrders={OldOrders} />
      )}
      {bussinessType === "Carwash" && (
        <CarHeaderBox Carheader="Settings - Categories" />
      )}
      <div style={{ display: "flex" }}>
        {bussinessType === "Laundry" && <Menu />}
        {bussinessType === "Restaurant" && <Resmenu Resmenu={""} />}
        {bussinessType === "Carwash" && <CarMenuBox />}
        <div className="Edit-overall" id="edit_overall">
          <Editmenu editmenu={menu} />
          <div className="user-add" id="category_add">
            <div className="header-product-structure-user">
              <div
                id="Addcategory"
                onClick={() => {
                  setAddagent(true);
                  seterroremail(true);
                }}
              >
                <Buttons Buttondata={AddUserButton} />
              </div>
              <div className="search_box_user">
                <TextField
                  value={searchTerm}
                  id="Search-Phone-number"
                  label={"Search by Name or Phone number"}
                  variant="outlined"
                  sx={{ width: 400, marginRight: "10px", zIndex: "0" }}
                  type="tel"
                  onChange={handleSearch}
                />
              </div>
              <div className="Deleted_check">
                <FormControlLabel
                  control={<Checkbox />}
                  onClick={() => {
                    setfilter(!filter);
                    filters();
                  }}
                  label="View deleted users"
                />
              </div>
            </div>
          </div>
          {tableformat()}
        </div>
        <ToastContainer />
        {/* <Deleteusers
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          username={usernameToDelete}
        />
        {/* {deleted && <Deleteduser />} */}
        {Agent && (
          <div className="modals" id="modals">
            <div className="overlays" id="overlays"></div>
            <div className="modal-contents" id="modal_contents">
              <ToastContainer />
              <div className="edit-popupscreen" id="edit_popupscreen">
                <h2>Add user</h2>
                <button
                  onClick={() => setAddagent(false)}
                  className="cancel-icon-user"
                >
                  <CloseIcon />
                </button>
                <div className="user_input" id="user_input">
                  <TextField
                    sx={{ margin: "10px" }}
                    id="Name"
                    label={
                      <span>
                        Name{" "}
                        <span style={{ color: "#f04141", fontSize: "15px" }}>
                          *
                        </span>
                      </span>
                    }
                    variant="outlined"
                    value={agentname}
                    onChange={handlenameChange}
                    onKeyPress={handlenameskeypress}
                  />
                  <TextField
                    sx={{ margin: "10px" }}
                    id="Username"
                    label={
                      <span>
                        Username{" "}
                        <span style={{ color: "#f04141", fontSize: "15px" }}>
                          *
                        </span>
                      </span>
                    }
                    variant="outlined"
                    value={username}
                    onChange={handleinputnameChange}
                    onKeyPress={handlenameskeypress}
                  />
                  <TextField
                    sx={{ margin: "10px" }}
                    id="Phoneno"
                    label={
                      <span>
                        Phone number{" "}
                        <span style={{ color: "#f04141", fontSize: "15px" }}>
                          *
                        </span>
                      </span>
                    }
                    variant="outlined"
                    value={phonenumber}
                    onChange={handlePhoneChange}
                    onKeyPress={handlePhoneskeypress}
                    inputRef={userphonenos}
                    error={!!phoneError}
                    helperText={phoneError ? phoneError : ""}
                  />
                  <TextField
                    error={!!doberror}
                    sx={{ margin: "10px" }}
                    id="DOB"
                    label={<span>Date of joining</span>}
                    type="text"
                    value={inputValue}
                    variant="outlined"
                    helperText={
                      doberror
                        ? "Invalid date format, Please enter in dd-mm-yyyy format."
                        : ""
                    }
                    onChange={handleDobChange}
                    onKeyPress={handledobskeypress}
                    inputRef={userdobs}
                  />
                  <TextField
                    error={!!emailerror}
                    sx={{ margin: "10px" }}
                    id="Email"
                    label={
                      <span>
                        Email ID{" "}
                        <span style={{ color: "#f04141", fontSize: "15px" }}>
                          *
                        </span>
                      </span>
                    }
                    variant="outlined"
                    value={email}
                    // helperText={erroremail ? 'Email Already Exists' : "Please enter your email in the following format 'id@example.com'"}
                    onChange={customeremailid}
                    style={{ width: "193%" }}
                    onKeyPress={handleEmailskeypress}
                    inputRef={useremails}
                  />
                  <br />
                  <TextField
                    sx={{ margin: "10px" }}
                    id="Address"
                    label={<span>Address</span>}
                    variant="outlined"
                    onChange={(e) => setaddress(e.target.value)}
                    style={{ width: "193%" }}
                    onKeyPress={handleaddresskeypress}
                    inputRef={useraddress}
                  />
                </div>
                <div className="btnconfirm-edit" id="btn_confirm_edit">
                  <div
                    id="cancel_btn"
                    onClick={() => {
                      setAddagent(false);
                      handleclear();
                    }}
                  >
                    <Buttons Buttondata={cancelButton} />
                  </div>
                  {confirm()}
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        )}
        {deletepress && (
          <div className="modals">
            <div className="overlays"></div>
            <div className="modal-contents">
              <div className="edit-popupscreen">
                <div className="delete_user_setting">
                  <div className="username_identity">
                    <p className="user_name">User name</p>:
                    <p className="user_name">
                      {Employeedata.map((i) => i.employee_name)}
                    </p>
                  </div>
                  <p className="conformation">
                    Are you sure you want to delete this user?
                  </p>
                  <div className="btnconfirm-edit_user" id="btn_confirm_edit">
                    <div id="cancel_btn" onClick={() => setdeletepress(false)}>
                      <Buttons Buttondata={cancelButton} />
                    </div>
                    <div
                      id="confirm_btn"
                      onClick={() => {
                        Delete(tempId);
                        setdeletepress(false);
                      }}
                    >
                      <Buttons Buttondata={ConfirmMutton} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {edit && <Edituser id={ids} />}
        {showRoleSelect && <Roleselect id={data} />}
      </div>
    </div>
  );
}

export default AddAgent;
