import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import PieChart from "./PieCharts";
import { useNavigate } from "react-router-dom";
import api from "../../axiosConfig";

interface Product {
  productName: string;
  amount: number;
  quantity: number;
  totalPrice: number;
}

interface Order {
  Customerid: any;
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    amount: number;
    quantity: number;
    totalPrice: number;
  }>;
  Orderid: number;
  OrderStatus: string;
  Orderdate: string;
  Ordertime: string;
  totalAmount: number;
  discount: number;
  Finalamount: number;
  payments: [
    {
      Paymentmethod: string;
      ReceivedAmount: number;
    }
  ]
  RemainingAmount: number;
  totalQuantity: number;
  Email: string;
  CustomerName: string;
  PhoneNumber: number;
}

function OrderHistory() {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderData, setOrderData] = useState<Order[]>([]);
  const [summary, setSummary] = useState({
    today: { orders: 0, amount: 0, received: 0 },
    yesterday: { orders: 0, amount: 0, received: 0 },
    lastMonth: { orders: 0, amount: 0, received: 0 },
  });

  const today = new Date().toISOString().split("T")[0];
  const getYesterdayDate = () => new Date(Date.now() - 86400000).toISOString().split("T")[0];
  const getLastMonthDate = () => new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0];

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(`/app/v1/pos/order`);
        setOrderData(response.data.result);
        calculateOrderSummary(response.data.result);
      } catch (error) {
        console.error("Error fetching orders:", error);
        localStorage.removeItem("jsonwebtoken");
        navigate("/");
      }
    };
    fetchOrders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const calculateOrderSummary = (data: Order[]) => {
    const summaryData = {
      today: { orders: 0, amount: 0, received: 0 },
      yesterday: { orders: 0, amount: 0, received: 0 },
      lastMonth: { orders: 0, amount: 0, received: 0 },
    };

    data.forEach(order => {
      const orderDate = order.Orderdate;
      let orderAmount = 0;
      if (order.Products) {
        orderAmount = order.Products.reduce((total, product) => total + product.totalPrice, 0);
      }

      if (orderDate === today) {
        summaryData.today.orders += 1;
        summaryData.today.amount += orderAmount;
        summaryData.today.received += order.payments[0].ReceivedAmount;
      } else if (orderDate === getYesterdayDate()) {
        summaryData.yesterday.orders += 1;
        summaryData.yesterday.amount += orderAmount;
        summaryData.yesterday.received += order.payments[0].ReceivedAmount;
      } else if (new Date(orderDate).getMonth() === new Date(getLastMonthDate()).getMonth()) {
        summaryData.lastMonth.orders += 1;
        summaryData.lastMonth.amount += orderAmount;
        summaryData.lastMonth.received += order.payments[0].ReceivedAmount;
      }
    });
    setSummary(summaryData);
  };

  return (
    <div className="dashboard">
      <OrderSummary
        title="Today’s Orders"
        orders={summary.today.orders}
        amount={summary.today.amount}
        received={summary.today.received}
      />
      <OrderSummary
        title="Yesterday’s Orders"
        orders={summary.yesterday.orders}
        amount={summary.yesterday.amount}
        received={summary.yesterday.received}
      />
      <OrderSummary
        title="Last Month’s Orders"
        orders={summary.lastMonth.orders}
        amount={summary.lastMonth.amount}
        received={summary.lastMonth.received}
      />
    </div>
  );
}

interface OrderSummaryProps {
  title: string;
  orders: number;
  amount: number;
  received: number;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ title, orders, amount, received }) => (
  <div className="order-summary-card">
    <h2>{title}</h2>
    <PieChart Orders={orders} amount={amount} ReceivedAmount={received} />
  </div>
);

export default OrderHistory;
