import React, { useState, useEffect } from "react";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  id: string | undefined;
  name: string;
  refreshCustomerList: () => void;
}

const DeleteScreen: React.FC<Props> = ({ id, name, refreshCustomerList }) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [modal, setModal] = useState<boolean>(true);

  useEffect(() => {
    const fetchCustomer = async () => {
      if (id) {
        try {
          const res = await api.get(`/app/v1/pos/customer/${id}`);
          setCustomer(res.data);
        } catch (error) {
          console.error("Error fetching customer details:", error);
          toast.error("Failed to fetch customer details.", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      }
    };

    fetchCustomer();
  }, [id]);

  const Deletescreen = async () => {
    if (!id) return;
    try {
      const res = await api.delete(`/app/v1/pos/customer/${id}`);
      console.log(res);

      toast.success("Customer deleted successfully", {
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });

      // await sleep(500);
      setCustomer(null);
      setModal(false);
      refreshCustomerList();
    } catch (error) {
      console.error("Error deleting customer:", error);
      toast.error("Failed to delete customer.", {
        position: "top-right",
        autoClose: 2100,
        theme: "colored",
      });
    }
  };

  if (isDeleted) return null;
  const confirmButton = {
    Blue: "blue",
    name: "confirm",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };
  return (
    <div>
      {modal && (
        <div className="modals">
          <div className="overlays"></div>
          <div className="modal-contents">
            <div className="edit-popupscreen">
              <button
                onClick={() => setModal(false)}
                className="cancel-icon-productdelete"
              >
                <CloseIcon />
              </button>
              <div
                className="addcategory-edit-product"
                style={{ marginTop: "-20px" }}
              >
                <h1 style={{ marginTop: "20px" }}>Delete Confirmation</h1>
                {name && (
                  <p style={{ fontWeight: "bold", marginTop: "15px" }}>
                    Customer name : {name}
                  </p>
                )}
                <p style={{ marginTop: "20px" }}>
                  Are you sure you want to delete this customer?
                </p>
                <div style={{ display: "flex", width: "400px" }}>
                  <div className="btnconfirm-edit">
                    <div
                      onClick={() => setModal(false)}
                      style={{ marginRight: "5px" }}
                    >
                      <Buttons Buttondata={CancelButton} />
                    </div>
                    <div onClick={() => Deletescreen()}>
                      <Buttons Buttondata={confirmButton} />
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteScreen;
