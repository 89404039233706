import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Flip, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../axiosConfig';

const SetPasswordNew: React.FC = () => {
    const { userid, expiryTime } = useParams<{ userid: string; expiryTime?: string }>();
    const navigate = useNavigate();
    const [createPassword, setCreatePassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showCreatePassword, setShowCreatePassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [visible, setVisible] = useState<boolean>(false);
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false);

    useEffect(() => {
        if (expiryTime) {
            const expiryDate = new Date(parseInt(expiryTime, 10) + 3600000);
            const now = new Date();
            const timeDifference = expiryDate.getTime() - now.getTime();
            const countdown = setTimeout(() => {
                navigate('/');
            }, timeDifference);
            return () => clearTimeout(countdown);
        }
        return () => clearTimeout(0);
    }, [expiryTime, navigate]);

    const validatePassword = (password: string) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasNumber = /\d/.test(password);
        const isValidLength = password.length >= 8 && password.length <= 15;

        if (!isValidLength || !hasUpperCase || !hasLowerCase || !hasSpecialChar || !hasNumber) {
            setError('Please enter your password must be between 8 and 15 characters, contain at least one number, and have a mixture of uppercase and lowercase letters with one special character.');
        } else {
            setError('');
        }
    };

    const handleCreatePasswordChange = (password: string) => {
        setCreatePassword(password);
        validatePassword(password);
        setVisible(password.length > 0);
    };

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
    
        if (createPassword !== confirmPassword) {
            toast.error("Passwords do not match", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Flip,
            });
            return;
        }
    
        if (error) {
            toast.error("Please fix the password errors", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Flip,
            });
            return;
        }
    
        try {
            await api.put(`/pos/update/${userid}`, { password: createPassword }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            toast.success("Password created successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Flip,
            });
    
            // await sleep(2000);
            navigate('/');
        } catch (error) {
            toast.error("An error occurred", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Flip,
            });
        }
    };
    

    return (
        <Container
            style={{
                display: 'flex',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#EEF4F7',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '400px',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                }}
            >
                <Typography variant="h4" gutterBottom>Create Your Password</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Create Password"
                        type={showCreatePassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={createPassword}
                        onChange={(e) => handleCreatePasswordChange(e.target.value)}
                        required
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle create password visibility"
                                        onClick={() => setShowCreatePassword(!showCreatePassword)}
                                        edge="end"
                                    >
                                        {showCreatePassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {visible && error && (
                        <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                            {error}
                        </Typography>
                    )}
                    <TextField
                        label="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setPasswordMatch(createPassword === e.target.value);
                        }}
                        required
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        color={passwordMatch ? "success" : "error"}
                    />
                    <Button
                        type="submit"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "none",
                            color: "white",
                            fontWeight: "bold",
                            backgroundColor: "#007BFF",
                            marginTop: "20px",
                            marginLeft: "25%",
                            borderRadius: "40px",
                            height: "40px",
                            width: "50%",
                            fontSize: "medium",
                        }}
                    >
                        Set Password
                    </Button>
                </form>
            </Box>
            <ToastContainer />
        </Container>
    );
};

export default SetPasswordNew;
