import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './CarOrder.css';
import api from '../../axiosConfig';
import CarMenuBox from '../../components/car-wash/CarMenuBox';
import CarHeaderBox from '../../components/car-wash/CarHeaderBox';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Item {
  amount: string;
  category: string;
  itemId: number;
  itemName: string;
  tax: string;
}

interface Order {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  car_details_id: string;
  order: {
    discount: string | number;
    items: Item[];
    OrderId: number | string;
    subtotal: string | number;
    total: string | number;
    totaltax: string | number;
  };
  totalAmount: number;
  totalQuantity: number;
  Orderid: number | string;
  Customerid: string;
  OrderStatus: string;
  Orderdate: string;
  Ordertime: string;
  Paymentmode: string;
  customer: {
    Date: string;
    cusCar: string[];
    cusName: string;
    cusPhone: string | number;
  };
  productTotal: string[];
  discount: number;
  productDetails: Array<{
    cName: string;
    price: string | number;
    product: string;
  }>;
  totalPrice: string | number;
  subtotal: string | number;
}


const CarOrderList: React.FC = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState<string>('Today');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [exportFormat, setExportFormat] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    fetchOrders();
  }, [filter, selectedDate, selectedDate2]);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await api.get('/app/v1/pos/order');
      const order: Order[] = response.data.result;
      setOrders(order);
      applyFilter(order);
    } catch (err) {
      // setError('Failed to fetch orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerClick = (orderid: any) => {
    navigate(`/v1/car-order-details/${orderid}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const filteredData = Array.isArray(orders)
    ? orders.filter(
      (order) =>
        order.CustomerName.toLowerCase().includes(searchQuery) ||
        order.Orderid.toString().toLowerCase().includes(searchQuery) 
    ) : [];
    
  const applyFilter = (orders: Order[]) => {
    let filteredOrders = orders;
    const currentDate = new Date();
    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    switch (filter) {
      case 'Today':
        filteredOrders = orders.filter((order) => order.Orderdate === formatDate(currentDate));
        break;
      case 'Yesterday':
        currentDate.setDate(currentDate.getDate() - 1);
        filteredOrders = orders.filter((order) => order.Orderdate === formatDate(currentDate));
        break;
      case 'Last Week':
        currentDate.setDate(currentDate.getDate() - 7);
        filteredOrders = orders.filter((order) => new Date(order.Orderdate) >= currentDate);
        break;
      case 'Last Month':
        currentDate.setMonth(currentDate.getMonth() - 1);
        filteredOrders = orders.filter((order) => new Date(order.Orderdate) >= currentDate);
        break;
      case '180 days':
        currentDate.setDate(currentDate.getDate() - 180);
        filteredOrders = orders.filter((order) => new Date(order.Orderdate) >= currentDate);
        break;
      case 'CustomizeDate':
        if (selectedDate && selectedDate2) {
          filteredOrders = orders.filter(
            (order) => order.Orderdate >= selectedDate && order.Orderdate <= selectedDate2
          );
        }
        break;
      default:
        break;
    }
    setOrders(filteredOrders);
  };
  useEffect(() => {
    // Only apply filter after fetching orders
    if (orders.length > 0) {
      applyFilter(orders);
    }
  }, [filter, selectedDate, selectedDate2]);


  const handleDownload = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;

    const query = orders.map(data => `order_id=${encodeURIComponent(data.Orderid)}`).join('&');
    setLoadingExport(true); 

    try {
      if (selectedOption === "csv") {
        const response = await api.get(`/app/v1/import_export?${query}`);
        if (response.status === 201) {
          toast.success("CSV email sent successfully", { position: 'top-center' });
        } else {
          toast.error("Failed to send CSV email");
        }
      } else if (selectedOption === "pdf") {
        const response = await api.get(`/pos/order_export_pdf?${query}`);
        if (response.status === 201) {
          toast.success("PDF email sent successfully", { position: 'top-center' });
        } else {
          toast.error("Failed to send PDF email");
        }
      }
    } catch (error) {
      console.error('Error exporting file:', error);
      toast.error("Error exporting file. Please try again.");
    } finally {
      setLoadingExport(false); 
    }
  };

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="Order list" />
      <div style={{ display: 'flex' }}>
        <CarMenuBox />
        <div className="table-container-wrapper">
          {loading ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : error ? (
            <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
          ) : (
            <> Select a date range
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <div>
                  <select
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    style={{ marginRight: '16px', height: '50px', width: '200px' }}
                  >
                    <option value="" disabled>Select a date range</option>
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Last Week">Last Week</option>
                    <option value="Last Month">Last Month</option>
                    <option value="180 days">Last 180 days</option>
                    <option value="CustomizeDate">Customize Date</option>
                  </select>
                </div>
                {filter === 'CustomizeDate' && (
                  <div>
                    <label htmlFor="from" style={{ marginRight: '8px' }}>From:</label>
                    <input
                      id="from"
                      type="date"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      style={{ marginBottom: '10px' }}
                    />
                    <label htmlFor="to" style={{ marginRight: '8px' }}>To:</label>
                    <input
                      id="to"
                      type="date"
                      value={selectedDate2}
                      onChange={(e) => setSelectedDate2(e.target.value)}
                    />
                  </div>
                )}
                {loadingExport && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )}

                <div style={{ marginTop: '-10px' }}>
                  <label htmlFor="exportFormat" style={{ marginRight: '8px' }}>Export as:</label>
                  <select
                    id="exportFormat"
                    value={exportFormat}
                    onChange={handleDownload}
                    style={{ height: '40px', width: '150px' }}
                  >
                    <option value="" disabled>Select format</option>
                    <option value="csv">CSV</option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>

                <TextField
                  label="Search orders"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    marginRight: '16px',
                    width: '300px',
                    backgroundColor: 'white',
                  }}
                />
              </div>
              <TableContainer component={Paper} className="order-table">
                <Table>
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell align="left">S.no</TableCell>
                      <TableCell align="left">Customer name</TableCell>
                      <TableCell align="left">Order ID</TableCell>
                      <TableCell align="left">Order status</TableCell>
                      <TableCell align="center">Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">No orders available</TableCell>
                      </TableRow>
                    ) : (
                      filteredData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((order, index) => (
                          <TableRow key={order?.Orderid} className="table-row">
                            <TableCell align="left">
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell align="left">{order?.customer?.cusName || "N/A"}</TableCell>
                            <TableCell align="left">{order?.Orderid}</TableCell>
                            <TableCell align="left">
                              <span
                                style={{
                                  color: order?.OrderStatus === 'Confirm' ? 'red' :
                                    order?.OrderStatus === 'Pending' ? 'orange' :
                                      'green'
                                }}
                              >
                                {order?.OrderStatus}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                className="view-details-button"
                                onClick={() => handleCustomerClick(order?.Orderid)}
                              >
                                View details
                              </Button>
                            </TableCell>
                          </TableRow>
                        )))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </>
          )}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default CarOrderList;
