import React, { ChangeEvent, useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
// import Dones from "../../assets/Images/Done.png";
import Dones from "../../../assets/Images/success-image.png"
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import TextField from '@mui/material/TextField';
import { Email, Style } from "@mui/icons-material";
import 'react-toastify/dist/ReactToastify.css';
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Print from "./Print";

interface Props {
  orderid: string | undefined;
}
interface Product {
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    arabicword: string;
    amount: number;
    quantity: number;
    totalPrice: number;
  }>;
  Orderdate: string;
  Ordertime: string;
  payments: [
    {
      Paymentmethod: string;
      ReceivedAmount: number;
    }
  ]
  RemainingAmount: number;
  Paymentmode: string;
  OrderStatus: string;
  discount: number;
  Finalamount: number;
  totalAmount: number;
  totalQuantity: number;
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: number;
  customer_id: number;
  Customerid: string;
  Orderid: string;
}

interface OSB {
  Orderdate: string; 
  Ordertime: string; 
  discount: number;
  id: string;
  new_order_amount: number;
  order_id: string;
  order_oid: string;
  outstanding_balance: number;
  pay_amount: number;
}

interface OrganizationDetails {
  address_line_1: string;
  business_type: string;
  country: string;
  state: string;
  org_arabic_name?: string;
  business_location?: string | null;
}


const Done: React.FC<Props> = ({ orderid }) => {
  console.log(orderid);
  const [bproducts, setBproducts] = useState<Product>();
  const [agent, setAgent] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [confirmemail, setconfirmemail] = useState("");
  const [emailerror, setEmailerror] = useState<boolean>(false);
  const [emaihas, setEmailhas] = useState<boolean>(false);
  const navigator = useNavigate();
  const [geterror, setGeterror] = useState<any>("");
  const [employeesData, setemployeesData] = useState(false);
  const [osb,setOsb]=useState<number>()
  const token = localStorage.getItem("jsonwebtoken");
  const [logo, setlogo] = useState<any>();
  const [filteredData, setFilteredData] = useState<OrganizationDetails[]>([]);
  const [filteredCustomerId, setFilteredCustomerId] = useState<string>("")
  const [outstangingamount, setoutstangingamount] = useState<number | string>(0);
  const generatePdf = async (): Promise<Blob> => {

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Invoice</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  background-color: #f8f9fa;
              }
              .invoice-box {
                  max-width: 800px;
                  margin: auto;
                  padding: 20px;
                  border: 1px solid #ddd;
                  background-color: #fff;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
              }
              .invoice-header {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .invoice-header h1 {
                  margin: 0;
              }
              .invoice-header p {
                  margin: 0;
                  font-size: 14px;
                  color: #888;
              }
              .invoice-details {
                  margin-bottom: 20px;
              }
              .invoice-details .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
              }
              .invoice-details .detail span {
                  font-size: 16px;
              }
              .invoice-details .detail .label {
                  font-weight: bold;
              }
              .product-list {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
              }
              .product-list th, .product-list td {
                  padding: 10px;
                  border: 1px solid #ddd;
                  text-align: left;
              }
              .product-list th {
                  background-color: #f1f1f1;
              }
              .total {
                  text-align: right;
                  font-size: 18px;
                  font-weight: bold;
              }
              .payment-info {
                  margin-top: 20px;
              }
              .payment-info .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
              }
          </style>
      </head>
      <body>
      <div class="invoice-box">
          <div class="invoice-header">
              <h1>Invoice</h1>
              <p>Company Name: <strong>Imetanic LLP</strong></p>
          </div>
          <div class="invoice-details">
              <div class="detail">
                  <span class="label">Customer Name:</span>
                  <span>{CustomerName}</span>
              </div>
              <div class="detail">
                  <span class="label">Order Date:</span>
                  <span>{Orderdate}</span>
              </div>
          </div>
          <table class="product-list">
              <thead>
                  <tr>
                      <th>S No</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Total</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>1</td>
                      <td>Product 1</td>
                      <td>2</td>
                      <td>50 QAR</td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>Product 2</td>
                      <td>1</td>
                      <td>25 QAR</td>
                  </tr>
              </tbody>
          </table>
          <div class="total">
              <span>Total Amount: 75 QAR</span>
          </div>
          <div class="payment-info">
              <div class="detail">
                  <span class="label">Received Amount:</span>
                  <span>75 QAR</span>
              </div>
              <div class="detail">
                  <span class="label">Remaining Amount:</span>
                  <span>0 QAR</span>
              </div>
              <div class="detail">
                  <span class="label">Payment Mode:</span>
                  <span>Cash</span>
              </div>
              <div class="detail">
                  <span class="label">Order Status:</span>
                  <span>Paid</span>
              </div>
          </div>
      </div>
      </body>
      </html>
    `;

    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.zIndex = '-9999';
    container.innerHTML = htmlContent;
    document.body.appendChild(container);

    const canvas = await html2canvas(container);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 210;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    const pdfBlob = pdf.output('blob');
    document.body.removeChild(container);

    return pdfBlob;
  };
  const sendpdf = async () => {
    const pdfBlob = await generatePdf();
    
    const formData = new FormData();
    formData.append('file', pdfBlob, 'Invoice.pdf'); 

    try {
        const response = await fetch('YOUR_SERVER_ENDPOINT', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json(); 
        console.log('PDF sent successfully:', responseData);
    } catch (error) {
        console.error('Error sending PDF:', error);
    }
  }
  useEffect(() => {
    api
      .get("/pos/get_user")
      .then((res) => {
        console.log("API Response:", res.data.result);
        setFilteredData(res.data.result);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  useEffect(() => {
    api
      .get(`/app/v1/pos/order/${orderid}`)
      .then((res) => {
        const categoryData: Product = res.data.result;
        const emailvf = res.data.result.CustomerEmail;
        
        setEmailhas(!!res.data.result.CustomerEmail);

        setBproducts(categoryData);

        api.get(`/pos/ledger/${categoryData.Customerid}`)
        .then((res)=>{
          const Ledgers:OSB = res.data.ledger[0]
          setOsb(Ledgers.outstanding_balance)
        })
      })
      .catch((err) => {
        console.log(err);
      });

     const bussnessname = sessionStorage.getItem("organization_name")
     const logo = sessionStorage.getItem(`Logo${bussnessname}`)
     setlogo(logo)
  }, [orderid, token]);

  const customerid = bproducts?.Customerid;

  useEffect(() => {
    const fetchLedgerData = async () => {
      if (customerid) {
        try {
          const ledgerRes = await api.get(`/pos/ledger/${customerid}`);
          const osbprint = ledgerRes.data.ledger.map(
            (i: { total_outstanding_balance: any }) => i.total_outstanding_balance
          );
          setoutstangingamount(osbprint);
        } catch (error) {
          console.error('Error fetching ledger data:', error);
        }
      }
    };  
    fetchLedgerData();
  }, [customerid]);

  const customerId = bproducts?.Customerid;
  useEffect(() => {
    const fetchCustomerData = async () => {
      if (customerId) {
        try {
          const response = await api.get(`/app/v1/pos/customer/${customerId}`);
          console.log("Full API Response:", response.data);
  
          const result = response.data.result || {};
  
          if (result && typeof result === "object") {
            let clientId = "0";
            console.log("Extracted Customer ID:", result.customer_ref_id);
            if (result.customer_ref_id) {
              clientId = String(result.customer_ref_id).padStart(4, "0");
            } else {
              clientId = String("0").padStart(4, "0");
            }
            console.log("Extracted Customer ID:", clientId);
            setFilteredCustomerId(clientId);
          } 
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      } 
    };
  
    fetchCustomerData();
  }, [customerId]);
  
  const businessArabicName = filteredData.find( (item): item is OrganizationDetails =>(item as OrganizationDetails).org_arabic_name !== undefined)?.org_arabic_name || "";

  const businessLocation = filteredData.find((item): item is OrganizationDetails =>(item as OrganizationDetails).business_location !== undefined)?.business_location || "";

  const printData = {
    CustomerName: bproducts?.CustomerName || "",
    CustomerPhon: bproducts?.CustomerPhoneNo?.toString() || "",
    OrderId: bproducts?.Orderid || "",
    OrderStatus: bproducts?.OrderStatus || "",
    Date: bproducts?.Orderdate || "",
    Time: bproducts?.Ordertime || "",
    Products: bproducts?.Products || [],
    Paid:
    (bproducts?.payments?.reduce((sum, i) => sum + (i.ReceivedAmount || 0), 0) || 0) < 0
      ? 0
      : bproducts?.payments?.reduce((sum, i) => sum + (i.ReceivedAmount || 0), 0) || 0,
    unpaid: Math.max(0, Number(bproducts?.RemainingAmount)),
    OSB: outstangingamount || "",
    busnessName: sessionStorage.getItem("organization_name") || "",
    busnessArabicName: businessArabicName,
    businessLocation: businessLocation,
    phoneNum: sessionStorage.getItem("business_phone") || "",
    total: bproducts?.totalAmount || "",
    clientId: filteredCustomerId || "",
    imglogo:logo
  };

  useEffect(() => {
    const employee = () => {
      const employeeData = sessionStorage.getItem('employee')
      if (employeeData) {
        setemployeesData(true);
      } else {
        setemployeesData(false);
      }
    }
    employee()
  }, []);


const updateCustomerEmail = async () => {
  if (confirmemail) {
    const updatePayload = { Email: confirmemail };
    try {
      const res = await api.put(`/app/v1/pos/customer/${bproducts?.Customerid}`, updatePayload);

      console.log("Customer email updated:", res.data);

      toast.success("Customer email updated successfully", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });

      // await sleep(100); 
      setAgent(false);
      orderemailmsg();

      setGeterror("");
    } catch (err) {
      toast.error("Email already exists", {
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  } else {
    toast.error("No email provided to update.", {
      position: "top-right",
      autoClose: 2100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Flip,
    });
  }
};


const orderemailmsg = async () => {
  const toastId = toast.loading("Waiting...");
  try {
    const res = await api.post(`/app/v1/pos/orders_send_email`, {
      receiver_email: confirmemail || bproducts?.CustomerEmail,
      cnames: bproducts?.CustomerName,
      id: bproducts?.Orderid,
      TotalAmount: bproducts?.totalAmount,
      ReceivedAmount: bproducts?.payments[0].ReceivedAmount,
      RemainingAmount: bproducts?.RemainingAmount,
      file: sendpdf(),
    });

    const customer: Product[] = res.data.result;
    console.log(customer);

    toast.dismiss(toastId);
    toast.success("Mail sent successfully", { autoClose: 2000, theme: "colored" });

    // await sleep(100); 
    window.location.reload();
  } catch (error) {
    console.error("Error sending mail:", error);

    toast.dismiss(toastId);
    toast.error("Failed to send email.", { autoClose: 2000, theme: "colored" });

    // await sleep(100); 
    window.location.reload();
  }
};

const ordermsg = async (phone: any) => {
  toast.success("Message sent successfully", { autoClose: 2000, theme: "colored" });

  // await sleep(100); 
  window.location.reload();
};

const Doneorder = () => {
  toast.success("The order has been taken successfully", {
    autoClose: 2000,
    theme: "colored",
    position: "top-right",
  });
  setTimeout(() => {
        window.location.reload();
  }, 2000);
};

  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const validDomains = ['.com', '.in', '.co'];
      const hasValidDomain = validDomains.some(domain => value.endsWith(domain));
      return hasValidDomain;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setEmail(value);
      setconfirmemail(value)
      setEmailerror(false)
    } else {
      setEmail(value);
      setEmailerror(true)
    }
  }
  const print = () => {
    navigator(`/v1/print/${orderid}`);
  };
  const update = {
    Blue: "blue",
    name: "update",
  };
  const Cancel = {
    Cancel: "red",
    name: "Cancel",
  };
  const SendMail = {
    Blue: "blue",
    name: "Send Mail",
  };
  const SendMessage = {
    Blue: "blue",
    name: "Send Message",
  };
  const DONE = {
    Blue: "blue",
    name: "done",
  };

  return (
    <div>
      <div className="Popup" id="popup">
        <img
          src={Dones}
          alt=""
          style={{
            width: "250px",
            height: "250px",
            marginLeft: "50px",
            marginBottom: "70px",
            marginTop: "-20%",
          }}
        />
        <p style={{ marginLeft: "20%", width: "90%", marginTop: '-10%', textAlign: "center" }}>Your payment has been completed successfully</p>
      </div>
      <div style={{ display: "flex", marginLeft: "-23px", marginTop: '-30%', }}>
        {emaihas ? (
          <div
            id="done_btn"
            style={{ marginRight: "10px" }}
            onClick={() => orderemailmsg()}
          >
            <Buttons Buttondata={SendMail} />
          </div>
        ) : (
          <>
            <div
              style={{ marginRight: "10px" }}
              id="done_btn"
              onClick={() => setAgent(!agent)}
            >
              <Buttons Buttondata={SendMail} />
            </div>
            {agent && (
              <div className="modal">
                <div className="modal-overlay">
                  <div className="modal-content-email">
                    <div className="modal-header">
                      <h4>Please enter the Email address</h4>
                    </div>
                    <div>
                      <TextField
                        error={!!emailerror}
                        className="input_emil_text"
                        id="outlined-basic"
                        label="Email ID"
                        value={email}
                        // helperText={emailerror ? 'Invalid email format' : "Please enter your email in the following format 'id@example.com'"}
                        onChange={customeremailid}
                        variant="outlined"
                      />
                      {geterror ? <div className="error">{geterror}</div> : ""}
                    </div>
                    <div className="modal-footer">
                      <div onClick={() => setAgent(false)}>
                        <Buttons Buttondata={Cancel} />
                      </div>
                      <div style={{ marginLeft: "40px" }} onClick={updateCustomerEmail}>
                        <Buttons Buttondata={update} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div
          style={{ marginRight: "10px" }}
          id="send_mail_btn"
          onClick={() => ordermsg(bproducts?.CustomerPhoneNo)}
        >
          <Buttons Buttondata={SendMessage} />
        </div>
        <div id="sent_messages_btn" onClick={Doneorder}>
          <Print PrintData={{ ...printData, Paid: printData.Paid ?? 0 }} BtnName={"Done"} username="" />
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ zIndex: 9999 }} 
      />
    </div>
  );
};

export default Done;


