import React, { useState, useEffect } from "react";
import "./CarWashHome.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { log } from "console";
import api from "../../axiosConfig";
// import CarHeaderBox from "./CarHeaderBox";
import CarMenuBox from "./CarMenuBox";
import CarHeaderBox from "./CarHeaderBox";

interface CarDetail {
  _id: { $oid: string };
  customername: string;
  customerphone: number;
  car_number: string;
  date: string;
}

interface Order {
  orderId: string;
  customer: {
    id: string;
    name: string;
    phone: string;
    email: string;
  };
  CarProducts: {
    CarproductId: number;
    category: string;
    CarproductName: string;
    amount: number;
    tax: number;
  }[];
  subtotal: number;
  totaltax: number;
  discount: number;
  total: number;
  received: number;
  balance: number;
}

interface CarDetailState {
  _id: { $oid: string };
  CategoryName: string;
}

const CarWashHome: React.FC = () => {
  // const [atom, setAtom] = useState<CarDetail[]>([]);
  const [getdata, setGetdata] = useState<CarDetailState[]>([]);

  const [binary, setBinary] = useState({
    car_number: "",
    customername: "",
    customerphone: "",
    date: new Date().toISOString().split("T")[0],
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.title = " Customer Details - iMetanic";
    api.get("/app/v1/pos/category").then((res) => {
      const cardetailing: CarDetailState[] = res.data.result;
      setGetdata(cardetailing);
    });
  }, []);
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBinary((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleServiceCardClick = async (serviceType: string) => {
    if (
      // !binary.car_number ||
      !binary.customername ||
      !binary.customerphone ||
      !binary.date
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      const response = await api.post("/app/v1/pos/customer", binary);
      // setAtom((prev) => [...prev, response.data]);
      toast.success("Car order has been submitted successfully!");

      setBinary({
        car_number: "",
        customername: "",
        customerphone: "",
        date: "",
      });
      navigate(`/v1/car-wash/${serviceType}?id=${response.data._id}`);
    } catch (error) {
      console.error("Error submitting car order:", error);
      toast.error("Failed to submit car order. Please try again later.");
    }
  };

  return (
    <div className="home-container">
      <CarHeaderBox Carheader="CarWash POS Home" />
      <div style={{display:"flex"}}>
        <CarMenuBox  />
          <div className="content_container">
            <div className="collecting_details">
              <h1>Welcome to Car Wash Services</h1>
              <p>
                Find the best car cleaning and polishing services tailored to
                your needs!
              </p>
              <div className="cardetails_collecting">
                <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Customer name
                </label>
                <input
                  type="text"
                  value={binary.customername}
                  placeholder="Enter the customer name"
                  name="customername"
                  onChange={handleInput}
                />
                <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Phone number
                </label>
                <input
                  type="number"
                  value={binary.customerphone}
                  name="customerphone"
                  placeholder="Enter the phone number"
                  onChange={handleInput}
                />
                {/* <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Car number
                </label>
                <input
                  className="carnumber"
                  type="text"
                  value={binary.car_number}
                  name="car_number"
                  placeholder="Enter the car number"
                  onChange={handleInput}
                /> */}
                <label style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Date
                </label>
                <input
                  type="date"
                  value={binary.date} 
                  name="date"
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="services_container">
              <div className="services_container">
                <button
                  className="order_button"
                  onClick={() => {
                    if (getdata.length > 0) {
                      handleServiceCardClick(getdata[0]._id.$oid);
                    } else {
                      toast.error("No services available.");
                    }
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
       
      </div>
    </div>
  );
};


export default CarWashHome;
