import React from 'react';
import './Reservation.css';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import Resheader from '../pages/components/Restaurant/Resheader';
import Resmenu from '../pages/components/Restaurant/Resmenu';
import Buttons from '../pages/common-component/Buttons';

const Resreservation = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const reservationData = [
    { id: 1, date: '12/05/2023', time: '10:00 AM', name: 'MS Dhoni', tableNo: '1', noOfPersons: 2 },
    { id: 2, date: '12/06/2023', time: '12:00 PM', name: 'Virat Kohli', tableNo: '2', noOfPersons: 4 },
  ];

  const filteredData = reservationData; 

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const BButton = {
    Blue: "blue",
    name: "+ Reservation",
  };
  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const oldOrders =()=>{
    
  }

  return (
    <div style={{ display: "flex", flexDirection: 'column', overflow: 'hidden' }}>
      <div>
        <Resheader Resheader={"Reservation"}  OldOrders={oldOrders}/>
        <div style={{ display: "flex" }}>
          <Resmenu Resmenu={""} />
          <div className='reservation_head'>
            <div className='reservation_cart'>
              <Buttons Buttondata={BButton} />
            </div>
            <div className='Table_header'>
              <Paper id="Table" sx={{ width: "87%" }}>
                <TableContainer sx={{ maxWidth: 2050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>S.No</TableCell>
                        <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>Date</TableCell>
                        <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>Time</TableCell>
                        <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>Name</TableCell>
                        <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>Table No</TableCell>
                        <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>No. Of persons</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                          <TableCell align="center">{row.date}</TableCell>
                          <TableCell align="center">{row.time}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.tableNo}</TableCell>
                          <TableCell align="center">{row.noOfPersons}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                      count={filteredData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resreservation;
