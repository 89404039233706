import {
  Box,
  Button,
  FormControl,
  InputBase,
  InputLabel,
  NativeSelect,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import LoadingScreen from "../../common-component/LoadingScreen";

interface CategoryButton {
  _id: { $oid: string };
  CategoryName: string;
}

interface Props {
  id: string | undefined;
  Name: string;
  Amount: string;
  Category: string;
  arabic: string;
  Images: string | File | null;
  closeEdit:() => void;
  onEdit: (editedProduct: any) => void;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const EditProduct: React.FC<Props> = ({
  id,
  Name,
  Amount,
  Category,
  arabic,
  Images,
  onEdit,
  closeEdit
}) => {
  const [Loading, setLoading] = useState<boolean>(false);
  const [buttonNames, setButtonNames] = useState<CategoryButton[]>([]);
  const [editproductdata, setEditproductdata] = useState<any>("");
  const [loading, setloading] = useState<boolean>(false);
  const [editarabic, seteditarabic] = useState<any>("");
  const [editamount, setEditamount] = useState<any>("");
  const [selectedIndex, setSelectedIndex] = React.useState<any>();
  const [selectedcategort, setSelectedcategort] = React.useState<any>("");
  const [errormsg, setErrormsg] = useState<boolean>();
  const [visible, setvisible] = useState<boolean>(false);
  const [categoryname, setcategoryname] = useState<any>();
  const productamountref = useRef<HTMLInputElement>(null);
  const selectcategoryref = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const handleproductnameskeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      productamountref.current?.focus();
    }
  };
  const handleproductamountsKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      selectcategoryref.current?.focus();
    }
  };
  const handleselectcategoryKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (
        editproductdata === Name &&
        editamount === Amount &&
        selectedIndex === categoryname
      ) {
      } else {
        if (Name && Amount && Category) {
          handleUpdate();
        } else {
          toast.error("Please fill in required fields.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        }
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    setEditproductdata(Name);
    setEditamount(Amount);
    setSelectedcategort(Category);
    seteditarabic(arabic);
    api
      .get(`/app/v1/pos/category`)
      .then((res) => {
        const categoryData: CategoryButton[] = res.data.result;
        setButtonNames(categoryData);
        const categoryname = categoryData.find(
          (item) => item.CategoryName === selectedcategort
        );
        setSelectedIndex(categoryname?._id.$oid || "");
        setcategoryname(categoryname?._id.$oid || "");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [Amount, Category, Name, arabic, selectedcategort]);

  useEffect(() => {
    if (
      editproductdata === Name &&
      editamount === Amount &&
      selectedIndex === categoryname &&
      editarabic === arabic
    ) {
      setvisible(false);
    } else {
      if (
        editproductdata.length > 0 &&
        editamount.length > 0 &&
        selectedIndex.length > 0 &&
        editarabic.length > 0
      ) {
        setvisible(true);
      } else {
        setvisible(false);
      }
    }
  }, []);


  const handleUpdate = async () => {
    setloading(true);
    const updates: {
      ProductName: string;
      Amount: number;
      ArabicName: string;
      category_id: number;
      image?: File | undefined;
    } = {
      ProductName: editproductdata,
      Amount: editamount,
      ArabicName: editarabic,
      category_id: selectedIndex,

    };

    if (image) {
      updates.image = image;
    }

    try {
      const res = await api.put(`/app/v1/pos/product/${id}`, updates, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Product updated successfully", {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });

      onEdit({
        _id: { $oid: id },
        ProductName: editproductdata,
        Amount: editamount,
        ArabicName: editarabic,
        category_id: selectedIndex,
        image_url: typeof image === "string" ? image : null,
        image_file: typeof image === "object" ? image : null,
      });
      closeEdit();

    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Failed to update product.", {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } finally {
      // Ensure loading is stopped regardless of success or error
      setloading(false);
    }
  };

  const updateButton = {
    Blue: "blue",
    name: "update",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const ErrorupdateButtonerr = {
    error: "blue",
    name: "update",
  };
  const confirm = () => {
    if (!isDisabled) {
      return (
        <div onClick={handleUpdate}>
          <Buttons Buttondata={updateButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={ErrorupdateButtonerr} />
        </div>
      );
    }
  };

  const generateCategoryAbbreviation = (categoryName: string): string => {
    const words = categoryName.split(" ");
    return words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const categoryId = event.target.value as string;
    setSelectedIndex(categoryId);
    const selectedCategory = buttonNames.find(
      (cat) => cat._id.$oid === categoryId
    );

    if (selectedCategory) {
      const categoryAbbreviation = generateCategoryAbbreviation(
        selectedCategory.CategoryName
      );
      setEditproductdata((prevName: string) => {
        const currentName = prevName.replace(/\[.*?\]$/, "").trim();
        return `${currentName} [${categoryAbbreviation}]`;
      });
      setcategoryname(selectedCategory._id.$oid);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditproductdata(e.target.value);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    if (/^\d*\.?\d*$/.test(term)) {
      setEditamount(term);
    }
  };

  const formatAmount = (value: string) => {
    if (!value) return "0.00";
    let [integerPart, decimalPart] = value.split(".");
    if (!integerPart) integerPart = "0";
    if (!decimalPart) decimalPart = "00";
    else if (decimalPart.length === 1) decimalPart = decimalPart + "0";
    else if (decimalPart.length > 2) decimalPart = decimalPart.slice(0, 2);
    return `${integerPart}.${decimalPart}`;
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreviewImage(reader.result as string);
      reader.readAsDataURL(file);
      console.log(file);
      setImage(file);
    }
  };

  const handleBlur = () => {
    setEditamount(formatAmount(editamount));
  };

  const disable = [
    editproductdata === Name,
    editamount === Amount,
    selectedIndex === categoryname,
    editarabic === arabic,
    Images === image,
  ];

  const isDisabled = disable.every((condition) => condition);

  return (
        <div className="modals">
          <div className="overlays"></div>
          <div className="modal-contenters">
            <div className="edit-popupscreens">
              <h2>
                Edit product list
                <button
                  onClick={() => closeEdit()}
                  className="cancel-icon-EditProduct"
                >
                  <CloseIcon />
                </button>
              </h2>
              <div className="edit_productsty">
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "500px",
                    marginTop: "20px",
                    borderBlockWidth: "20px",
                    marginLeft: "20px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {errormsg ? (
                    <TextField
                      label="Product Name *"
                      error
                      value={editproductdata}
                      onChange={handleSearch}
                      onKeyPress={handleproductnameskeyPress}
                    />
                  ) : (
                    <TextField
                      label="Product name *"
                      value={editproductdata}
                      onChange={handleSearch}
                      onKeyPress={handleproductnameskeyPress}
                    />
                  )}
                  <TextField
                    label="Arabic word *"
                    sx={{ marginTop: "10px" }}
                    value={editarabic}
                    onChange={(e) => seteditarabic(e.target.value)}
                    onKeyPress={handleproductnameskeyPress}
                  />
                  <TextField
                    label="Product price *"
                    value={editamount}
                    onChange={handleAmountChange}
                    onBlur={handleBlur}
                    type="number"
                    sx={{ marginTop: "20px" }}
                    onKeyPress={handleproductamountsKeyPress}
                    inputRef={productamountref}
                  />
                </Box>
                <FormControl sx={{ m: 1 }} variant="standard">
                  <InputLabel
                    htmlFor="demo-customized-select-native"
                    style={{ marginLeft: "10px" }}
                  >
                    Category type *
                  </InputLabel>
                  <NativeSelect
                    style={{
                      marginLeft: "8px",
                      marginBottom: "-10px",
                      width: "100%",
                    }}
                    id="SelectCategory"
                    value={selectedIndex}
                    onChange={handleChange}
                    onKeyPress={handleselectcategoryKeyPress}
                    inputRef={selectcategoryref}
                    input={<BootstrapInput />}
                  >
                    {buttonNames.map((item) => (
                      <option key={item._id.$oid} value={item._id.$oid}>
                        {item.CategoryName}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    margin: "10px 10px 10px 16px",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={uploadImageRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => uploadImageRef.current?.click()}
                  >
                    Upload Image
                  </Button>
                  {previewImage && (
                    <img
                      src={
                        typeof previewImage === "string"
                          ? previewImage
                          : URL.createObjectURL(previewImage)
                      }
                      alt="Preview"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="confirm_btn_edit">
                <div onClick={() => closeEdit()}>
                  <Buttons Buttondata={CancelButton} />
                </div>
                {confirm()}
              </div>
            </div>
          </div>
          {loading ? <LoadingScreen /> : ""}
          <ToastContainer />
        </div>
  );
};

export default EditProduct;