import { TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { AxiosError } from "axios";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";

interface Props {
  id: string;
  phone: string;
  alternativephone: string;
  name: string;
  email: string;
  address: string;
  address2: string;
  address3: string;
  closeEditcustomer: () => void;
  // onEdit: (customer: any) => void; 
}

const EditCustomer: React.FC<Props> = ({
  id,
  name,
  phone,
  alternativephone,
  email,
  address,
  address2,
  address3,
  closeEditcustomer,
  // onEdit, 
}) => {
  const [Name, setName] = useState(name || "");
  const [Phonenumber, setPhonenumber] = useState(phone || "");
  const [alternative, setAlternative] = useState(alternativephone || "");
  const [Email, setEmail] = useState(email || "");
  const [customeraddress1, setCustomeraddress1] = useState<string>(address || "");
  const [customeraddress2, setCustomeraddress2] = useState<string>(address2 || "");
  const [customeraddress3, setCustomeraddress3] = useState<string>(address3 || "");
  const [visible, setVisible] = useState<boolean>(false);

  const phonenoref = useRef<HTMLInputElement>(null);
  const emailref = useRef<HTMLInputElement>(null);
  const streetsref = useRef<HTMLInputElement>(null);
  const statesref = useRef<HTMLInputElement>(null);
  const pincoderef = useRef<HTMLInputElement>(null);

  // Handle Enter key press for navigation
  const handlenamesKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      phonenoref.current?.focus();
    }
  };

  const handlePhonenoKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      emailref.current?.focus();
    }
  };

  const handleemailKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      streetsref.current?.focus();
    }
  };

  const handlestreetsKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      statesref.current?.focus();
    }
  };

  const handleaddressKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      pincoderef.current?.focus();
    }
  };

  const handlepincodesKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (
        Name !== name ||
        Phonenumber !== phone ||
        Email !== email ||
        customeraddress1 !== address ||
        customeraddress2 !== address2 ||
        customeraddress3 !== address3
      ) {
        if (Name && Phonenumber) {
          update();
        } else {
          toast.error("Please fill in required fields.");
        }
      }
    }
  };

  useEffect(() => {
    setName(name || "");
    setPhonenumber(phone || "");
    setAlternative(alternativephone || "");
    setEmail(email || "");
    setCustomeraddress1(address || "");
    setCustomeraddress2(address2 || "");
    setCustomeraddress3(address3 || "");
  }, [name, phone, alternativephone, email, address, address2, address3]);

  useEffect(() => {
    const isDataChanged =
      Name !== name ||
      Phonenumber !== phone ||
      alternative !== alternativephone ||
      Email !== email ||
      customeraddress1 !== address ||
      customeraddress2 !== address2 ||
      customeraddress3 !== address3;

    setVisible(isDataChanged && Phonenumber.length >= 8 && Name.trim() !== "");
  }, [Name, Phonenumber, alternative, Email, customeraddress1, customeraddress2, customeraddress3, name, phone, alternativephone, email, address, address2, address3]);

  const validateEmail = async (email: string) => {
    if (email.trim() === "") return true;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address with @ and a domain (eg., .co, .com).", { theme: "colored", autoClose: 2000 });
      return false;
    }
    try {
      const response = await api.get(`/app/v1/pos/customer/email-check?email=${email}`);
      if (response.data && response.data.exists) {
        toast.error("Email already exists", { theme: "colored", autoClose: 2000 });
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error validating email:", error);
      toast.error("Error checking email. Please try again.");
      return false;
    }
  };

  const update = async () => {
    if (!Name.trim() || !Phonenumber.trim()) {
      toast.error("Please fill in the required fields: Name and Phone Number.", { theme: "colored", autoClose: 2000 });
      return;
    }
  
    const isEmailValid = await validateEmail(Email);
    if (!isEmailValid) {
      return;
    }
  
    try {
      const data = {
        Name: Name || "",
        phone_number: Phonenumber || "",
        alternativenumber: alternative || "",
        Email: Email || "",
        Address: {
          customeraddress1: customeraddress1 || "",
          customeraddress2: customeraddress2 || "",
          customeraddress3: customeraddress3 || "",
        },
      };
  
      const res = await api.put(`/app/v1/pos/customer/${id}`, data);
      toast.success("Customer Updated successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        window.location.reload()
      }, 100);
      // onEdit(res.data);
  
      closeEditcustomer();
    } catch (error: unknown) {
      console.error("Error updating customer:", error);
  
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message || "Error updating customer. Please try again.";
  
        if (errorMessage.includes("Email already exists")) {
          toast.error("Email already exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (errorMessage.includes("Phone Number already exists")) {
          toast.error("Phone Number already exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Error updating customer. Please try again.");
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    event.target.value = value;
    setPhonenumber(value);
  };

  // Handle name change
  const handlenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value.slice(0, 25));
  };

  // Handle alternative phone number change
  const alternativephonenumber = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    event.target.value = value;
    setAlternative(value);
  };

  // Render update button
  const UpdateButton = {
    Blue: "blue",
    name: "Update",
  };
  const CancelButton = {
    Cancel: "red",
    name: "Cancel",
  };
  
  const errorupdateButtonerr = {
    error: "blue",
    name: "update",
  };

  const confirm = () => {
    if (visible) {
      return (
        <div id="update_btn" style={{ marginLeft: "5px" }} onClick={update}>
          <Buttons Buttondata={UpdateButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={errorupdateButtonerr} />
        </div>
      );
    }
  };

  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="scroll-button">
        <div className="modal-contents">
          <ToastContainer />
          <div>
            <h1 className="Edit_page_Name">
              Edit customer
              <button
                onClick={() => closeEditcustomer()}
                className="cancel-icon-Editcustomer"
              >
                <CloseIcon />
              </button>
            </h1>
            <div className="open-poppuscreen">
              <TextField
                id="Name"
                className="Inputusername"
                label={
                  <span>
                    Name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                  </span>
                }
                variant="outlined"
                value={Name}
                onChange={handlenameChange}
                onKeyPress={handlenamesKeyPress}
              />
              <TextField
                id="phoneno"
                label={
                  <span>
                    Phone number <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                  </span>
                }
                variant="outlined"
                className="inputphone"
                value={Phonenumber}
                onChange={handlePhoneChange}
                onKeyPress={handlePhonenoKeyPress}
                inputRef={phonenoref}
              />
            </div>
            <div style={{ display: "flex", marginLeft: "50px", marginTop: "10px", gap: "16px" }}>
              <div>
                <TextField
                  id="phoneno"
                  label="Alternative Phone number"
                  variant="outlined"
                  className="inputphone"
                  value={alternative}
                  onChange={alternativephonenumber}
                  inputRef={phonenoref}
                />
              </div>
              <div>
                <TextField
                  id="Email"
                  label={
                    <span>
                      Email (Optional)
                    </span>
                  }
                  className="inputphone"
                  variant="outlined"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={handleemailKeyPress}
                  inputRef={emailref}
                />
              </div>
            </div>
            <div className="Aaddress">
              <div className="input-typeaddress">
                <ul className="address-optional">
                  <p>Address (Optional):</p>
                  <TextField
                    id="standard-basic"
                    label="Street/Village"
                    variant="standard"
                    value={customeraddress1}
                    onChange={(e) => setCustomeraddress1(e.target.value)}
                    onKeyPress={handlestreetsKeyPress}
                    inputRef={streetsref}
                  />
                  <TextField
                    id="standard-basic"
                    label="District/State"
                    variant="standard"
                    value={customeraddress2}
                    onChange={(e) => setCustomeraddress2(e.target.value)}
                    onKeyPress={handleaddressKeyPress}
                    inputRef={statesref}
                  />
                  <TextField
                    id="standard-basic"
                    label="Pincode/Zipcode"
                    variant="standard"
                    value={customeraddress3}
                    onChange={(e) => setCustomeraddress3(e.target.value)}
                    onKeyPress={handlepincodesKeyPress}
                    inputRef={pincoderef}
                  />
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: "100px" }}>
              <div
                id="cancel_btn"
                style={{ marginRight: "5px" }}
                onClick={() => closeEditcustomer()}
              >
                <Buttons Buttondata={CancelButton} />
              </div>
              {confirm()}
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomer;