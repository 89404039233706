import React, { useState, useEffect, useRef } from 'react'
import './CarProfile.css'
import { Button, TextField, IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Flip, toast, ToastContainer } from 'react-toastify';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import api from '../../axiosConfig';
// import CarHeaderBox from '../../../components/car-wash/CarHeaderBox';
import CarMenuBox from '../../components/car-wash/CarMenuBox';
import ChangeCircleTwoToneIcon from "@mui/icons-material/ChangeCircleTwoTone";
import CarHeaderBox from '../../components/car-wash/CarHeaderBox';

interface ProfileData {
  userprofile: any;
  _id: { $oid: string };
  name: string;
  user_name: string;
  email_id: string;
  phone_number: string;
  organization_name: string;
  address_line_1: string;
  role: string;
  business_type: string;
}
const CarProfile = () => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [business, setBusiness] = useState('');
  const [address, setAddress] = useState('');
  const [open, setOpen] = useState(false);
  const [editName, setEditName] = useState('');
  const [editUserName, setEditUserName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editPhone, setEditPhone] = useState('');
  const [editAddress, setEditAddress] = useState('');
  const [editBusiness, setEditBusiness] = useState('');
  const [role, setRole] = useState("super admin");
  const [editRole, setEditRole] = useState<string>("");
  const [businesstype, setBusinesstype] = useState('');
  const [editBusinesstype, setEditBusinesstype] = useState('');
  const [data, setData] = useState<ProfileData | undefined>(undefined);
  const [userprofile, setuserprofile] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(false);
  const [profileimg, setprofileimg] = useState<string>("/assets/userprofile.png");
  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    api.get(`/pos/profile`).then((response) => {
      const profileData = response.data.message;
      setData(profileData);
      setuserprofile(profileData);
      setName(profileData.name);
      setUsername(profileData.user_name);
      setEmail(profileData.email_id);
      setPhone(profileData.phone_number);
      setBusiness(profileData.organization_name);
      setAddress(profileData.address_line_1);
      setRole(profileData.role);
      setBusinesstype(profileData.business_type);
    }).catch((error) => {
      console.error("Error fetching profile data:", error);
    });
    const persion = sessionStorage.getItem("profilename")
    const image = sessionStorage.getItem(`Profilelogo${persion}`);
    if (image) {
      api.get(`/pos/profile_images/${image}.png`, { responseType: "arraybuffer" })
        .then((response) => {
          const blob = URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
          setprofileimg(blob)
          console.log("img", blob);
        })
    }
    //else {
    //setprofileimg(profileimgdata)
    //}
  }, []);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('profile_image', file);
      const id = userprofile?._id.$oid;
      if (!id) {
        toast.error('ID is missing!');
        return;
      }
      api.put(`/pos/images/${id}.png`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success('Profile image updated successfully');
            const newImageUrl = URL.createObjectURL(file);
            setprofileimg(newImageUrl);
          }
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          toast.error('Error uploading profile image');
        });
    }
  };
  const handleEdit = () => {
    setEditName(name || '');
    setEditUserName(username || '');
    setEditPhone(phone || '');
    setEditEmail(email || '');
    setEditAddress(address || '');
    setEditRole(role || '');
    setEditBusinesstype(businesstype || '');
    setEditBusiness(business || '');
    setOpen(!open);
  }
  const handleUpdate = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phoneRegex = /^\d{8}$/;
    if (!emailRegex.test(editEmail)) {
      toast.error('Please enter a valid email.');
      return;
    }

    if (!phoneRegex.test(editPhone)) {
      toast.error('Please enter a valid phone number.');
      return;
    }


    const id = userprofile?._id.$oid;
    setLoading(true);
    const updatedData = {
      name: editName,
      user_name: editUserName,
      email_id: editEmail,
      phone_number: editPhone,
      address_line_1: editAddress,
      role: editRole,
      business_type: editBusinesstype,
      organization_name: editBusiness,
    };
    api.put(`/pos/add/${id}`, updatedData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        toast.success("Profile updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
        setTimeout(() => {
          api.get('/pos/profile')
            .then((response) => {
              const profileData = response.data.message;
              setData(profileData);
              setName(profileData.name);
              setUsername(profileData.user_name);
              setEmail(profileData.email_id);
              setPhone(profileData.phone_number);
              setBusiness(profileData.organization_name);
              setAddress(profileData.address_line_1);
              setRole(profileData.role);
              setBusinesstype(profileData.business_type);
              setOpen(false);
            })
            .catch((error) => {
              console.error("Error fetching updated profile data:", error);
            });
        }, 2000);
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        toast.error("Error updating profile");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isFormChanged = (
    editName !== name || editUserName !== username || editEmail !== email || editPhone !== phone || editRole !== role || editAddress !== address || editBusiness !== business
  );
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value) || value === '') {
      setEditName(value);
    }
  }
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <CarHeaderBox Carheader="Profile" />
      <div style={{ display: 'flex' }}>
        <CarMenuBox />
        <div className='header-profile'>
          <div className='profile-container'>
            <div className='profile-first'>
              {open && <CloseIcon className='closeicon' onClick={handleEdit} style={{ cursor: 'pointer' }} />}
              <div className="logo-containers">
                {!open && (
                  <div className="logo-display">
                    <img src={profileimg} alt="" className="logo" />
                    <div className="logo-upload">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                        <IconButton onClick={handleClick}>
                          <ChangeCircleTwoToneIcon
                            className="logo_change_button"
                            id="Inventory_icon"
                            style={{ fontSize: "30px" }}
                          />
                        </IconButton>
                    </div>
                  </div>
                )}
                {open && (
                  <div className="logo-edit-display">
                    <img src={profileimg} alt="" className="logo" />
                    <div className="logo-upload">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                        <IconButton onClick={handleClick}>
                          <ChangeCircleTwoToneIcon
                            className="logo_change_button"
                            id="Inventory_icon"
                            style={{ fontSize: "30px" }}
                          />
                        </IconButton>
                    </div>
                  </div>
                )}
              </div>
              <hr className='custom-hr'></hr>
              <div className='profile-details'>
                <div className="profile-item">
                  <label htmlFor="name" className="label">User name</label><span className="value">{username}</span>
                  <label htmlFor="name" className="label">Business type</label><span className="value">{businesstype}</span>
                  <label htmlFor="name" className="label">Name</label>
                  {open ? (
                    <>
                      <TextField
                        value={editName}
                        onChange={handleNameChange}
                        margin="normal"
                        className='input-box'
                      />
                    </>
                  ) : (
                    <span className="value">{name}</span>
                  )}
                  <label htmlFor="name" className="label">Role</label>
                  {open ? (
                    <TextField
                      value={editRole}
                      onChange={(e) => setEditRole(e.target.value)}
                      margin="normal"
                      className='input-box'
                    />
                  ) : (
                    <span className="value">{role}</span>
                  )}
                  <label htmlFor="name" className="label">Email ID</label>
                  {open ? (
                    <TextField
                      value={editEmail}
                      onChange={(e) => setEditEmail(e.target.value)}
                      margin="normal"
                      className='input-box'
                    />
                  ) : (
                    <span className="value">{email}</span>
                  )}
                  <label htmlFor="name" className="label">Phone number</label>
                  {open ? (
                    <TextField
                      value={editPhone}
                      onChange={(e) => setEditPhone(e.target.value)}
                      margin="normal"
                      className='input-box'
                      inputProps={{ maxLength: 8 }}
                    />
                  ) : (
                    <span className="value">{phone}</span>
                  )}
                  <label htmlFor="name" className="label">Busniess name</label>
                  {open ? (
                    <TextField
                      value={editBusiness}
                      onChange={(e) => setEditBusiness(e.target.value)}
                      margin="normal"
                      className='input-box'
                    />
                  ) : (
                    <span className="value">{business}</span>
                  )}
                  <label htmlFor="name" className="label">Address</label>
                  {open ? (
                    <TextField
                      value={editAddress}
                      onChange={(e) => setEditAddress(e.target.value)}
                      margin="normal"
                      className='input-box'
                    />
                  ) : (
                    <span className="value">{address}</span>
                  )}
                </div>
              </div>
              <div>
                {open ? (
                  <div className="custom-dialog-actions">
                    <Button
                      sx={{
                        backgroundColor: 'red',
                        borderRadius: '20px',
                        border: '1px solid',
                        width: '15%',
                        fontSize: '15px',
                        color: 'white',
                        fontWeight: 'bold',
                        marginRight: '20px',
                        left: '-60px',
                        textTransform: 'none',
                        marginTop: '20px',
                        '&:hover': {
                          backgroundColor: 'red',
                        },
                        '&:active': {
                          backgroundColor: 'red',
                        },
                        '&:focus': {
                          backgroundColor: 'red',
                        },
                      }}
                      onClick={handleEdit}
                      color="primary">
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: 'aqua',
                        borderRadius: '20px',
                        border: '1px solid',
                        width: '15%',
                        fontSize: '15px',
                        color: 'white',
                        fontWeight: 'bold',
                        left: '150px',
                        textTransform: 'none',
                        marginTop: '20px',
                        '&:hover': {
                          backgroundColor: 'aqua',
                        },
                        '&:active': {
                          backgroundColor: 'aqua',
                        },
                        '&:focus': {
                          backgroundColor: 'aqua',
                        },
                      }}
                      onClick={handleUpdate}
                      disabled={!isFormChanged}
                      color="primary">
                      Save changes
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={handleEdit}
                    style={{
                      padding: '5px',
                      backgroundColor: 'rgb(121,219,236)',
                      borderRadius: '20px',
                      color: 'white',
                      width: '30%',
                      top: '30px',
                      fontSize: '18px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      marginBottom: '40px',
                      marginTop: '20px',
                    }}>
                    Edit profile
                  </Button>
                )}
                <input
                  type="file"
                  hidden
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  )
}
export default CarProfile