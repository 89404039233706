import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";

interface Props {
  Orders: number | null;
  amount: number | null;
  ReceivedAmount: number | null;
}

const PieCharts: React.FC<Props> = ({ Orders, amount, ReceivedAmount }) => {
  const isAllZero = (Orders || 0) === 0 && (amount || 0) === 0 && (ReceivedAmount || 0) === 0;

  const data = isAllZero
    ? [
        { name: "Orders", value: 0 },
        { name: "Amount", value: 0 },
        { name: "Received", value: 0 },
      ]
    : [
        { name: "Orders", value: Orders || 0 },
        { name: "Amount", value: amount ? parseFloat(amount.toFixed(2)) : 0 },
        { name: "Received", value: ReceivedAmount ? parseFloat(ReceivedAmount.toFixed(2)) : 0 },
      ];

  const colors = ["#4e73df", "#1cc88a", "#f6c23e"];

  const renderCustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul style={{ listStyle: "none", padding: 0, textAlign: "center" }}>
        {["Orders", "Amount", "Received"].map((category, index) => (
          <li
            key={`item-${index}`}
            style={{
              marginBottom: "5px",
              color: colors[index % colors.length],
              fontWeight: "bold",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: colors[index % colors.length],
                borderRadius: "50%",
                marginRight: "8px",
              }}
            ></span>
            {`${category}: ${
              payload.find((entry: any) => entry.payload.name === category)?.payload.value || 0
            }`}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="chart-container">
      <h3 className="chart-title">Order Summary</h3>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="30%"
            innerRadius="45%"
            outerRadius="60%"
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                stroke="#fff"
                strokeWidth={2}
              />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
              fontSize: "14px",
            }}
            formatter={(value: number) => value.toFixed(2)}
          />
          <Legend
            height={50}
            iconType="circle"
            iconSize={10}
            wrapperStyle={{
              color: "#666",
              fontSize: "13px",
              textAlign: "center",
              marginBottom: "30px",
            }}
            content={renderCustomLegend}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieCharts;
