import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import "./Model.css";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
import Bom from "./Bom";
interface Props {
  cartId: string | null;
  backHome: any;
}
interface Customerdata {
  _id: {
    $oid: string;
  };
  Out_standing_balance: string;
  total_outstanding_balance: string;
  Order_Details: Array<{}>;
  Name: string;
  phone_number: string;
  Email: string;
  Address: string;
}
interface ledgerdata {
  customer: {
    _id: {
      $oid: any;
    };
    Email: string;
    Name: string;
    phone_number: string;
    Address: {
      customeraddress1: string;
      customeraddress2: string;
      customeraddress3: string;
    };
  };
  ledger: Array<{
    OrderDetails: Array<{
      Orderamount: string;
      Orderdate: string;
      ReceivedAmount: string;
      RemainingAmount: string;
      Totalamount: string;
      orderid: string;
    }>;
    customer_id: string;
    customer_name: string;
    customer_phone_number: string;
    new_order_amount: string;
    total_outstanding_balance: string;
    outstanding_balance: string;
    pay_amount: string;
  }>;
}
const PopupScreen: React.FC<Props> = ({ cartId, backHome }) => {
  const [selectedCustomerData, setSelectedCustomerData] =
    useState<ledgerdata | null>(null);
  const [selectedCustomers, setSelectedCustomers] =
    useState<Customerdata | null>(null);
  const [Customer, setCustomer] = useState<boolean>(false);
  const [customerdata, setcustomerdata] = useState<Customerdata[]>([]);
  const [Addcustomer, setAddcustomer] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailerror, setEmailerror] = useState<boolean>(false);
  const [erroremail, seterroremail] = useState<boolean>(false);
  const [confirmemail, setconfirmemail] = useState("");
  const [filteredData, setFilteredData] = useState<Customerdata[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [geterror, setGeterror] = useState<any>("");
  const [visible, setvisible] = useState<boolean>(false);
  const [phonenu, setPhonenu] = useState("");
  const [alternatephone, setAlternatiephone] = useState("");
  const [customeraddress1, setCustomeraddress1] = useState<string>("");
  const [customeraddress2, setCustomeraddress2] = useState<string>("");
  const [customeraddress3, setCustomeraddress3] = useState<string>();
  const [phonenumbererror, setphonenumbererror] = useState<boolean>(false);
  const [alterphonenumbererror, setalterphonenumbererror] = useState<boolean>(false);
  const [customerledger, setCustomerledger] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const addCustomer = (customer: string, getitem: Customerdata) => {
    api.get(`/pos/ledger/${customer}`).then((response) => {
      console.log(response);

      const respons: ledgerdata = response.data;
      if (respons?.ledger.map((i) => i.total_outstanding_balance)) {
        setSelectedCustomerData(respons)
        console.log(respons);
        setCustomerledger(true);
      }
    });
    setSelectedCustomers(getitem);
  };
  api.get(``);
  const Boms = () => {
    setCustomer(true);
    setCustomerledger(false);
  };
  const Addbutton = () => {
    setAddcustomer(!Addcustomer);
    setName("");
    setPhonenu("");
    setAlternatiephone("");
    setEmail("");
    setGeterror("");
    setCustomeraddress1("");
    setCustomeraddress2("");
    setCustomeraddress3("");
    setphonenumbererror(false);
    setalterphonenumbererror(false);
  };
  const phonenoref = useRef<HTMLInputElement>(null);
  const emailsref = useRef<HTMLInputElement>(null);
  const address1ref = useRef<HTMLInputElement>(null);
  const address2ref = useRef<HTMLInputElement>(null);
  const address3ref = useRef<HTMLInputElement>(null);

  const handlenamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      phonenoref.current?.focus();
    }
  };
  const handlephonenoKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      emailsref.current?.focus();
    }
  };
  const handleemailsKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      address1ref.current?.focus();
    }
  };
  const handleaddress1KeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      address2ref.current?.focus();
    }
  };
  const handleaddress2KeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      address3ref.current?.focus();
    }
  };
  const handleaddress3KeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (name && phonenu && email) {
        addCustomers();
      } else {
        toast.error("please enter all details");
      }
    }
  };

  useEffect(() => {
    getcustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (name && phonenu.length > 7) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [name, phonenu]);
  const getcustomer = () => {
    api
      .get(`/app/v1/pos/customer`)
      .then((res) => {
        const customerdatas: Customerdata[] = res.data.result;
        setcustomerdata(customerdatas);
        setFilteredData(customerdatas);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, "");

    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    setPhonenu(value);
    if (phonenumbererror) {
      setphonenumbererror(false);
    }
  };

  const handleAlternativePhoneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value.replace(/\D/g, "");

    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    setAlternatiephone(value);
    if (alterphonenumbererror) {
      setalterphonenumbererror(false);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/[!@#$%^&?*<>|/{}():_+=";'.,[\]`-]/g, "");
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredData(customerdata);
    } else {
      const formattedValue = value.toLowerCase().trim();
      const filtered = customerdata.filter(
        (item) =>
          (item.phone_number &&
            item.phone_number.toLowerCase().includes(formattedValue)) ||
          (item.Name && item.Name.toLowerCase().includes(formattedValue))
      );
      if (filtered.length === 0) {
        setError("No customer data");
      } else {
        setError("");
      }
      setFilteredData(filtered);
    }
  };
  const tableformet = () => {
    if (filteredData === null) {
      return <div>No data available</div>;
    }
    return (
      <div className="customerdeatiles">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", textAlign: "left" }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontSize: "large", textAlign: "right" }}
                >
                  Phone Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No customer available
                  </TableCell>
                </TableRow>
              ) : (
                Array.isArray(filteredData) &&
                filteredData.map((item) => (
                  <TableRow
                    key={item._id.$oid}
                    className="customer"
                    onClick={() => addCustomer(item._id.$oid, item)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell align="left" className="deatil_name">
                      {item.Name}
                    </TableCell>
                    <TableCell align="right" className="deatil_ph">
                      {item.phone_number}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value.slice(0, 25));
  };
  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const validDomains = [".com", ".in", ".co"];
      const hasValidDomain = validDomains.some((domain) =>
        value.endsWith(domain)
      );
      return hasValidDomain;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setEmail(value);
      setconfirmemail(value);
      setEmailerror(false);
    } else {
      if (value === "") {
        setEmailerror(false);
        setconfirmemail(value);
        setEmail(value);
      } else {
        setEmail(value);
        setconfirmemail(value);
        setEmailerror(true);
      }
    }
  };
  const addCustomers = () => {
    const regex = /^[a-zA-Z0\s]*$/;
    if (regex.test(name)) {
      if (name && phonenu) {
        const customerData = {
          Name: name,
          phone_number: phonenu,
          alternativenumber: alternatephone,
          Email: confirmemail,
          Address: {
            customeraddress1,
            customeraddress2,
            customeraddress3,
          },
          Out_standing_balance: 0,
          Order_Details: [],
        };
        api
          .post(`/app/v1/pos/customer`, customerData)
          .then((res) => {
            console.log("Customer added successfully:", res.data);
            toast.success("Customer added successfully", {
              position: "top-right",
              autoClose: 2100,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Flip,
            });
            setTimeout(() => {
              api
                .get(`/app/v1/pos/customer`)
                .then((res) => {
                  const customerdatas: Customerdata[] = res.data.result;
                  setcustomerdata(customerdatas);
                  setFilteredData(customerdatas);
                  const formattedValue = searchTerm.toLowerCase().trim();
                  const filtered = customerdatas.filter(
                  (item) =>
                    (item.phone_number && item.phone_number.toLowerCase().includes(formattedValue)) ||
                    (item.Name && item.Name.toLowerCase().includes(formattedValue))
                  );
                setFilteredData(filtered);
                })
                .catch((err) => {
                  console.log(err);
                });
              setAddcustomer(!Addcustomer);
            }, 2000);
            setName("");
            setPhonenu("");
            setEmail("");
            setGeterror("");
            setCustomeraddress1("");
            setCustomeraddress2("");
            setCustomeraddress3("");
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.message === "Phone Number already Exists") {
              setphonenumbererror(true);
            } else if (err.response.data.message === "Alternative Phone number already exists") {
              setalterphonenumbererror(true);
            } else if (err.response.data.message === "Email already Exists") {
              setEmailerror(true);
              seterroremail(true);
            }
          });
      } else {
        toast.error("Please fill in all required fields.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
      }
    } else {
      toast.error("Special characters are not allowed in name.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  };
  const AddCustomerF = {
    Blue: "Blue",
    name: "Add customer",
  };
  const Cancel = {
    Cancel: "Blue",
    name: "Cancel",
  };
  const AddCustomerB = {
    Blue: "Blue",
    name: "Add Customer",
  };
  const AddCustomerBB = {
    error: "blue",
    name: "Add Customer",
  };
  const Confirm = {
    Blue: "Blue",
    name: "Confirm",
  };
  const AddProduct1 = {
    Blue: "Blue",
    name: "Add Product",
  };
  const AddProduct2 = {
    Blue: "Blue",
    name: "Add Product",
  };
  const addmember = () => {
    if (visible === true) {
      return (
        <div onClick={addCustomers}>
          <Buttons Buttondata={AddCustomerB} />
        </div>
      );
    } else {
      return (
        <div>
          <Buttons Buttondata={AddCustomerBB} />
        </div>
      );
    }
  };
  const formatPincode = (value: string) => {
    return value.replace(/(\d{3})(\d{3})/, "$1 $2");
  };
  const zip_pin = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s/g, "");
    if (value === "" || (Number(value) <= 999999 && /^[0-9]*$/.test(value))) {
      setCustomeraddress3(value === "" ? undefined : value);
    }
  };

  const roundValue = (value: string | undefined) => {
    if (!value) return "0.00";
    const num = parseFloat(value);
    if (isNaN(num)) return "0.00";
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };
  const backHomebom = (data: any) => {
    backHome("false");
  };
  const backHomepay = (data: any) => {
    backHome("false");
  };
  return (
    <div>
      {Customer ? (
        <Bom
          cartId={cartId}
          customerData={selectedCustomers}
          customerid={String(selectedCustomerData?.customer._id.$oid)}
          backHomebom={backHomebom}
          backHomepay={backHomepay}
          backHome={backHome}
        />
      ) : (
        <div
          style={{
            marginLeft: "20px",
          }}
        >
          <ToastContainer />
          {Addcustomer ? (
            <div className="customerscreen">
              <div>
                <h2 className="details">
                  <button
                    onClick={() => Addbutton()}
                    className="cancel-icon-add-custom"
                  >
                    <CloseIcon />
                  </button>
                  Enter customer details
                </h2>
              </div>
              <div className="customerdetails ">
                <div className="customername"></div>
                <div className="input-name">
                  <TextField
                    id="outlined-basic"
                    label={
                      <span>
                        Name{" "}
                        <span style={{ color: "#f04141", fontSize: "14px" }}>
                          *
                        </span>
                      </span>
                    }
                    variant="outlined"
                    style={{ width: "240%" }}
                    value={name}
                    onChange={handleChange}
                    onKeyPress={handlenamesKeyPress}
                  />
                </div>
              </div>
              <div>
                <div>
                  <div className="input-phone">
                    {phonenumbererror ? (
                      <TextField
                        error
                        label={
                          <span>
                            Phone number{" "}
                            <span
                              style={{ color: "#f04141", fontSize: "15px" }}
                            >
                              *
                            </span>
                          </span>
                        }
                        helperText="Phone number already exists."
                        value={phonenu}
                        onChange={handlePhoneChange}
                        style={{ width: "95%" }}
                      />
                    ) : (
                      <TextField
                        label={
                          <span>
                            Phone number{" "}
                            <span
                              style={{ color: "#f04141", fontSize: "15px" }}
                            >
                              *
                            </span>
                          </span>
                        }
                        variant="outlined"
                        value={phonenu}
                        onChange={handlePhoneChange}
                        onKeyPress={handlephonenoKeyPress}
                        inputRef={phonenoref}
                        style={{ width: "95%" }}
                      />
                    )}
                  </div>
                  <div className="input-phone">
                    {alterphonenumbererror ? (
                      <TextField
                        error
                        label="Alternative Phone Number"
                        helperText="Alternative Phone number already exists."
                        value={alternatephone}
                        onChange={handleAlternativePhoneChange}
                        style={{ width: "95%" }}
                      />
                    ) : (
                      <TextField
                        label="Alternative Phone Number"
                        variant="outlined"
                        value={alternatephone}
                        onChange={handleAlternativePhoneChange}
                        onKeyPress={handlephonenoKeyPress}
                        inputRef={phonenoref}
                        style={{ width: "95%" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="input-Email">
                  {emailerror ? (
                    <TextField
                      error
                      id="outlined-basic"
                      label="Email ID (Optional)"
                      variant="outlined"
                      style={{ width: "95%" }}
                      helperText={
                        erroremail
                          ? "Email already exists"
                          : "Please enter your email in the following format 'id@example.com'"
                      }
                      value={email}
                      onChange={customeremailid}
                      type="email"
                    />
                  ) : (
                    <TextField
                      id="outlined-basic"
                      label="Email ID (Optional)"
                      variant="outlined"
                      style={{ width: "95%" }}
                      value={email}
                      onChange={customeremailid}
                      type="email"
                      onKeyPress={handleemailsKeyPress}
                      inputRef={emailsref}
                    />
                  )}
                  {geterror ? <div className="error">{geterror}</div> : ""}
                  {/* {emailerror && (
                  <span style={{ color: "red" }}>Invalid email address</span>
                )} */}
                </div>
              </div>
              <div className="Aaddress ">
                <div className="input-address">
                  <p style={{ fontWeight: "normal" }}>Address (Optional):</p>
                  <ul>
                    <TextField
                      id="standard-basic"
                      label="Street/Village"
                      variant="standard"
                      value={customeraddress1}
                      onChange={(e) => setCustomeraddress1(e.target.value)}
                      onKeyPress={handleaddress1KeyPress}
                      inputRef={address1ref}
                    />
                    <TextField
                      id="standard-basic"
                      label="District/State"
                      variant="standard"
                      value={customeraddress2}
                      onChange={(e) => setCustomeraddress2(e.target.value)}
                      onKeyPress={handleaddress2KeyPress}
                      inputRef={address2ref}
                    />
                    <TextField
                      id="standard-basic"
                      label="Pincode/Zipcode"
                      variant="standard"
                      value={formatPincode(customeraddress3 ?? "")}
                      onChange={zip_pin}
                      onKeyPress={handleaddress3KeyPress}
                      inputRef={address3ref}
                    />
                  </ul>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  width: "470px",
                  justifyContent: "space-between",
                }}
              >
                <div onClick={() => Addbutton()}>
                  <Buttons Buttondata={Cancel} />
                </div>
                {addmember()}
              </div>
              <ToastContainer />
            </div>
          ) : customerledger ? (
            <div className="customerledger">
              <button
                onClick={() => setCustomerledger(false)}
                className="arrowback-ledger"
              >
                <ArrowBackIcon />
              </button>
              <ul>
                <div style={{ width: "90%" }}>
                  <div style={{ width: "100%" }}>
                    <h1
                      style={{
                        position: "relative",
                        left: "90px",
                        width: "100%",
                      }}
                    >
                      Customer ledger
                    </h1>
                  </div>
                  <div>
                    <div>
                      <div
                        onClick={() => backHome("false")}
                        style={{ marginTop: "5px", marginLeft: "110px" }}
                      >
                        <Buttons Buttondata={AddProduct2} />
                      </div>
                    </div>

                    {/* <Tooltip title="Add product" placement="bottom" arrow>
                      <button
                        onClick={() => backHome('false')}
                        className="backhome-btn"
                        style={{ border: "none", marginTop: "15px", }}
                      >
                        <ControlPointDuplicateIcon style={{ cursor: "pointer", border: "none" }} />
                      </button>
                    </Tooltip> */}
                  </div>
                </div>
                <li style={{ marginLeft: "70px" }}>
                  <p className="label">Name</p>
                  <p className="label">
                    :{selectedCustomerData?.customer.Name}
                  </p>
                </li>
                <li style={{ marginLeft: "70px" }}>
                  <p className="label">Outstanding balance </p>
                  {selectedCustomerData &&
                  selectedCustomerData.ledger?.length > 0 ? (
                    <p className="label">
                      {selectedCustomerData.ledger.map((i, index) => (
                        <span key={index}>
                          :{roundValue(i.total_outstanding_balance)}
                        </span>
                      ))}
                    </p>
                  ) : (
                    <p>:0.00</p>
                  )}
                </li>
                <li style={{ marginLeft: "15px" }}>
                  <div onClick={() => setCustomerledger(false)}>
                    <Buttons Buttondata={Cancel} />
                  </div>
                  <div onClick={() => Boms()}>
                    <Buttons Buttondata={Confirm} />
                  </div>
                  <button
                    onClick={() => setCustomerledger(false)}
                    className="cancel-icon-ledger"
                  >
                    <CloseIcon />
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <div
              className="overall-customerselection"
              id="overall_customer_selection"
            >
              <div className="customersearch" id="customersearch">
                <div className="aero" id="aera">
                  <h2 className="power" id="power">
                    Select customer
                  </h2>
                  <button
                    onClick={() => window.location.reload()}
                    className="cancel-icon"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="add_item">
                  <div>
                    <div onClick={() => backHome("false")}>
                      <Buttons Buttondata={AddProduct1} />
                    </div>
                    {/* <Tooltip title="Add product" placement="bottom" arrow>
                      <button
                        onClick={() => backHome('false')}
                      >
                        <ControlPointDuplicateIcon style={{ cursor: "pointer", border: "none" }} />
                      </button>
                    </Tooltip> */}
                  </div>
                  <div onClick={() => Addbutton()} id="add_btn">
                    <Buttons Buttondata={AddCustomerF} />
                  </div>
                </div>
              </div>
              <div className="search-on" id="search_on">
                <TextField
                  label="Search by Name or Phone number"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearch}
                  sx={{ width: "500px", marginRight: "15px" }}
                />
              </div>

              {tableformet()}
              <div className="note-style">
                <b>
                  <i>
                    Note:Go to the settings page if you need to modify any
                    customer information.
                  </i>
                </b>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PopupScreen;
