import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Tables from "../../common-component/Table";
import api from "../../axiosConfig";

interface Getdata {
  Customerid: any;
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    amount: number;
    quantity: number;
    totalPrice: number;
  }>;
  Orderid: number;
  OrderStatus: string;
  Orderdate: string;
  Ordertime: string;
  totalAmount: number;
  discount: number;
  Finalamount: number;
  payments: [
    {
      Paymentmethod: string;
      ReceivedAmount: number;
    }
  ]
  RemainingAmount: number;
  totalQuantity: number;
  Email: string;
  CustomerName: string;
  PhoneNumber: number;
}

function SetBarChart() {
  const [orderStats, setOrderStats] = useState({
    ordersCount: Array(12).fill(0),
    salesTotal: Array(12).fill(0),
  });
  const [isSalesView, setIsSalesView] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await api.get(`/app/v1/pos/order`);
        const orders: Getdata[] = res.data.result;
        const ordersCount = Array(12).fill(0);
        const salesTotal = Array(12).fill(0);

        orders.forEach((order) => {
          const orderDate = new Date(order.Orderdate);
          if (orderDate.getFullYear() === selectedYear) {
            const month = orderDate.getMonth();
            ordersCount[month] += 1;
  
            // Ensure Products is an array and handle gracefully if undefined
            if (Array.isArray(order.Products)) {
              const orderTotalAmount = order.Products.reduce((sum, product) => {
                return sum + (product.totalPrice || 0);
              }, 0);
              salesTotal[month] += orderTotalAmount;
            } else {
              console.warn(
                `Products field is invalid for order ID: ${order.Orderid}`,
                order
              );
            }
          }
        });

        setOrderStats({ ordersCount, salesTotal });
      } catch (error) {
        console.error("Failed to fetch orders data:", error);
      }
    };

    fetchOrders();
  }, [selectedYear]);

  const monthLabels = () => {
    return [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  };
  const products = {
    titlesno: "S.No",
    titlename1: "Months",
    titlename2: "Total Orders",
    titlename3: "Total Sales",
    S_no: monthLabels().map((_, index) => index + 1),
    bodyname1: monthLabels(),
    bodyname2: orderStats.ordersCount,
    bodyname3: orderStats.salesTotal.map((sale) => sale.toFixed(2)),
  };

  const handleYearChange = (increment: number) => {
    setSelectedYear((prevYear) => prevYear + increment);
  };

  return (
    <div className="dashboard-containers">
      <div className="barchart-container">
        <div className="chart-toggle">
          <h4>Orders</h4>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={() => setIsSalesView(!isSalesView)} />}
              label=""
            />
          </FormGroup>
          <h4>Sales</h4>
          <div className="year-selector">
            <button style={{border:"none"}} onClick={() => handleYearChange(-1)}>
              &lt;
            </button>
            <span className="year">{selectedYear}</span>
            <button style={{border:"none"}} onClick={() => handleYearChange(1)}>
              &gt;
            </button>
          </div>
        </div>
        <h1>{selectedYear} {isSalesView ? "Sales" : "Order Details"}</h1>
        <BarChart
          series={[{
            data: isSalesView 
            ? orderStats.salesTotal.map((sale) => parseFloat(sale.toFixed(2)))
            : orderStats.ordersCount,
            label: isSalesView ? "Number of Sales" : "Number of Orders",
            stack: "A",
          }]}
          width={600}
          height={350}
          xAxis={[{ scaleType: "band", data: monthLabels() }]}
          colors={isSalesView ? ["#4CAF50"] : ["#1B7ACE"]}
        />
      </div>
      <div className="table-container">
        <Tables product={products} />
      </div>
    </div>
  );
}

export default SetBarChart;