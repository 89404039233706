import React, { useEffect, useState } from "react";
// import payimg from "../../assets/Images/pay.png";
import payimg from '../../../assets/Images/pay.png';
import money from "../../../assets/Images/1635d86b32b60583e7f022b9c3324b3d.png";
// import money from '../../../assets/Images/cash.png'
import { Button, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fontStyle } from "html2canvas/dist/types/css/property-descriptors/font-style";
import CloseIcon from '@mui/icons-material/Close';
import Payment from "./Payment";
import Done from "./Done";
import api from "../../axiosConfig";
import Buttons from "../../common-component/Buttons";
interface Props {
  cartId: string | null;
  backHome: any;
  backHomepay: any
}
interface Product {
  _id: {
    $oid: string;
  };
  Finalamount: number;
  Customerid: string;
  products: Array<{
    amount: string;
    productId: string;
    productName: string;
    quantity: string;
    totalPrice: string;
  }>;
  totalAmount: string;
  discount: string;
  vat: string;
}
interface Customerdata {
  customer: {
    _id: {
      $oid: any;
    }
    Email: string;
    Name: string;
    phone_number: string;
    Address: {
      customeraddress1: string;
      customeraddress2: string;
      customeraddress3: string;
    }
  }
  ledger: Array<{
    OrderDetails: Array<{
      Orderamount: string;
      Orderdate: string;
      ReceivedAmount: string;
      RemainingAmount: string;
      Totalamount: string;
      orderid: string;
    }>
    customer_id: string;
    customer_name: string;
    customer_phone_number: string;
    new_order_amount: string;
    total_outstanding_balance: string;
    outstanding_balance: string;
    pay_amount: string;
  }>
}
interface Productdata {
  amount: number;
  productId: string;
  discount: number;
  productName: string;
  quantity: number;
  totalPrice: number;
}

const COMPLETE = {
  Blue: "blue",
  name: "COMPLETE",
};
const AddProduct5 = {
  Blue: "blue",
  name: "add product",
};
const CANCEL = {
  Cancel: "red",
  name: "CANCEL",
};
const Paylate: React.FC<Props> = ({ cartId, backHomepay, backHome }) => {
  const [bproducts, setBproducts] = useState<Product[]>([]);
  const [productdata, setproductdata] = useState<Productdata[]>([]);
  const [customerdata, setCustomerdata] = useState<any>('');
  const [customer, setCustomer] = useState<Customerdata | null>(null);
  const [receivedAmount, setReceivedAmount] = useState<any>(0);
  const [remainingAmount, setRemainingAmount] = useState<number>(0);
  const [orderid, setOrderId] = useState<any>();
  const [backpage, setBackpage] = useState<boolean>(false);
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const [paymentsuccess, setPaymentsuccess] = useState<boolean>(false);
  const [time, setTime] = useState({
    hours: "",
    minutes: "",
    seconds: "",
    day: "",
    month: "",
    year: ""
  })
  useEffect(() => {
    const customerid = String(sessionStorage.getItem('customerId'));
    setCustomerdata(customerid)
    setReceivedAmount("0");
    setRemainingAmount(
      bproducts.reduce((acc, item) => acc + Number(item.totalAmount), 0) -
      receivedAmount
    );
  }, [receivedAmount, bproducts]);
  useEffect(() => {
    date()
    api
      .get(`/app/v1/pos/shopping_cart/${cartId}`)
      .then((res) => {
        const categoryData: Product[] = res.data.result;
        setBproducts(categoryData);
        const product_data: Productdata[] = res.data.result.map((i: { products: any; }) => i.products)
        console.log('product', product_data);
        setproductdata(product_data);
        const customerid = categoryData.map(item => item.Customerid)
        setCustomerdata(customerid)
        console.log(customerid);
        api.get(`pos/ledger/${customerid}`)
          .then((response) =>
            setCustomer(response.data)
          ).catch((error) => {
            console.log(error);
          })
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cartId]);
  const ordersccuess = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const order = {
      Customerid: customer?.customer._id.$oid,
      Products: productdata[0],
      Orderid: `${month}${day}${hours}${minutes}${seconds}`,
      OrderStatus: "Confirm",
      Orderdate: `${time.year}-${time.month}-${time.day}`,
      Ordertime: ` ${time.hours}:${time.minutes}`,
      totalAmount: bproducts.map(i => i.totalAmount)[0],
      discount: bproducts.map(i => i.discount)[0],
      Finalamount: bproducts.map(i => i.Finalamount)[0],
      payments: [
        {
          Paymentmethod: "Buy on Credit",
          ReceivedAmount: Number(receivedAmount),
        },
      ],
      RemainingAmount: remainingAmount,
    };
    console.log('order', order);
    const osbs = () => {
      const cus = customer?.ledger
      console.log('cus', cus);

      if (cus !== undefined && cus.length > 0) {
        return (cus?.map(i => i.total_outstanding_balance)[0])
      } else {
        return (0)
      }
    }
    api
      .post(`/app/v1/pos/order`, order, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        setOrderId(order.Orderid);
        setPaymentsuccess(!paymentsuccess);
        const updatedOutstandingBalance = (customer?.ledger.map(i => i.outstanding_balance) === null || "") ? remainingAmount : (Number(customer?.ledger.map(i => i.outstanding_balance)) + remainingAmount);
        const updatedOrderDetails = [
          ...(customer?.ledger.map(i => i.OrderDetails) || []),
          {
            Totalamount: Number(customer?.ledger.map(i => i.outstanding_balance)) + Number(bproducts.map(product => product.Finalamount)),
            ReceivedAmount: receivedAmount,
            RemainingAmount: updatedOutstandingBalance,
            Orderamount: bproducts.map(product => product.Finalamount),
            Orderdate: `${year}-${month}-${day}`,
            orderid: `${month}${day}${hours}${minutes}${seconds}`,
            ordernaviid: res.data._id
          }
        ];
        const customerUpdate = {
          id: order.Customerid,
          outstanding_balance: osbs(),
          new_order_amount: order.totalAmount,
          pay_amount: order.payments[0].ReceivedAmount,
          order_id: order.Orderid,
          discount: order.discount,
          Orderdate: order.Orderdate,
          Ordertime: order.Ordertime,
        };
        try {
          await api.post(`pos/ledger`, customerUpdate);
          console.log("Ledger updated successfully");
          // await sleep(2000);
          const res = await api.delete(`/app/v1/pos/shopping_cart/${cartId}`);
          console.log("Shopping cart deleted:", res);
        } catch (error) {
          console.error("Error occurred:", error);
        }
      })
      .catch((error) => {
        console.error("Error saving cart:", error);
      });
  };
  const orderamount = bproducts.reduce((acc, p) => acc + parseFloat(String(p.Finalamount || '0')), 0);
  const totalOutstandingBalance = customer?.ledger?.reduce((acc, i) => acc + parseFloat(String(i.total_outstanding_balance || '0')), 0) || 0;
  const totalprice = totalOutstandingBalance + orderamount;
  const roundAmount = (value: any) => {
    const num = Number(value);
    if (isNaN(num)) {
      return '0.00';
    }
    const rounded = Math.floor(num * 100) / 100;
    const thirdDigit = Math.floor((num * 1000) % 10);
    return thirdDigit > 5 ? (rounded + 0.01).toFixed(2) : rounded.toFixed(2);
  };

  const date = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: day,
      month: month,
      year: year,
    })
  }

  return (
    <div>
      {backpage ? (
        <Payment cartId={cartId} backHomepay={backHomepay} backHome={backHome} />
      ) : (
        <div>
          {paymentsuccess ? (
            <Done orderid={orderid} />
          ) : (
            <div className="Popup-late">
              <div className="back-Arrow2">
                <Button onClick={() => setBackpage(!backpage)} className="atom">
                  <ArrowBackIcon style={{ color: "black", position: "relative", right: "10px", bottom: "10px" }} />
                </Button>
              </div>
              <Button onClick={() => setBackpage(!backpage)}>
                <CloseIcon style={{ marginBottom: "0px", marginTop: "-85px", position: "relative", left: "237px", color: "black" }} />
              </Button>
              <div className="Popup_bill">
                <h1 style={{ marginTop: "100px" }}>Payment summary</h1>
                <div onClick={() => backHome('false')}>
                  <Buttons Buttondata={AddProduct5} />
                </div>
                <img
                  src={payimg}
                  alt=""
                  style={{ width: "54px", height: "54px" }}
                />
                <h3>Selected customer</h3>
                <div className="paylate-info">
                  <div className="paylate-details">
                    <p>Payment method</p>
                    <p>Customer name</p>
                    <p>Order date</p>
                    <p>Order time</p>
                  </div>
                  <div className="paylate-values">
                    <p>: Buy on credit</p>
                    <p>: {customer?.customer.Name}</p>
                    <p>: {time.year}-{time.month}-{time.day}</p>
                    <p>: {time.hours}:{time.minutes}</p>
                  </div>
                </div>
              </div>
              {Array.isArray(bproducts) &&
                bproducts.map((item) => (
                  <div style={{ display: "grid" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          maxWidth: "245px",
                          minWidth: "190px",
                          flexDirection: "column",
                          height: "180px",
                          marginLeft: "-30px",
                          marginTop: "20px",
                          justifyContent: "space-around",
                        }}
                      >
                        <p style={{ fontWeight: "normal !important", fontSize: "18px" }}>Outstanding balance:</p>
                        <p style={{ marginTop: '6px', fontWeight: "normal !important", fontSize: "18px" }}>Order price:</p>
                        <p style={{ marginTop: '6px', fontWeight: "normal !important", fontSize: "18px" }}>Discount: </p>
                        <p style={{ marginTop: '6px', fontWeight: "normal !important", fontSize: "18px" }}>Final price (Incl Discount):</p>
                        <p style={{ marginTop: '6px', fontWeight: "normal !important", fontSize: "18px" }}>Total (Incl Outstanding):</p>
                      </div>
                      <div style={{ display: "grid" }}>
                        <img
                          src={money}
                          alt=""
                          style={{ height: "52px", width: "52px", marginTop: "10px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "52px", width: "52px", marginTop: "-15px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "52px", width: "52px", marginTop: "-15px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "52px", width: "52px", marginTop: "-15px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "52px", width: "52px", marginTop: "-15px" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "180px",
                          marginTop: "20px",
                          justifyContent: "space-around",
                        }}
                      >
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold", marginBottom: "1px" }}>
                          {roundAmount(customer?.ledger.map(i => i.total_outstanding_balance))} QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold", marginBottom: "1px" }}>
                          {roundAmount(item.totalAmount)} QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold", marginBottom: "1px" }}>
                          {roundAmount(item.discount)} QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold", marginBottom: "1px" }}>
                          {roundAmount(item.Finalamount)} QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold", marginBottom: "1px" }}>
                          {roundAmount(totalprice)} QAR
                        </p>
                      </div>
                    </div>
                    {/* <div className="psview">
                      <h3 className="delivery-date">Delivery Date : </h3>
                      <Input
                        type="Date"
                        value={DeliveryDate}
                        onChange={(e) => setDeliveryDate(e.target.value)}
                        className="input-date"
                      ></Input>
                    </div> */}
                  </div>
                ))}
              <div className="Product_data_save">
                <a href="/v1/home">
                  <div>
                    <Buttons Buttondata={CANCEL} />
                  </div>
                </a>
                <div onClick={ordersccuess}>
                  <Buttons Buttondata={COMPLETE} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Paylate;