import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import { Flip, toast, ToastContainer } from 'react-toastify';
import api from '../../axiosConfig';
import Buttons from '../../common-component/Buttons';

interface Props {
    id: string;
}
interface userpage {
    _id: { $oid: string };
    employee_name: string;
    phone_number: string;
    date_of_joined: string;
    address: string;
}
const EditUser: React.FC<Props> = ({ id }) => {
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [oldname, setoldName] = useState<string>("");
    const [oldphone, setoldphone] = useState<string>("");
    const [joiningDate, setjoiningDate] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [oldJoiningDate, setOldJoiningDate] = useState<string>("");
    const [oldAddress, setOldAddress] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>("");
    const [dobError, setDobError] = useState<string | null>(null);
    const [visible, setvisible] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    useEffect(() => {
        console.log("id", id);
        api.get(`/pos/add/${id}`)
            .then((res) => {
                const userData: userpage = res.data.message;
                console.log(userData?.employee_name);
                setName(userData?.employee_name);
                setPhone(userData?.phone_number);
                setoldphone(userData?.phone_number);
                setoldName(userData?.employee_name);
                setjoiningDate(userData?.date_of_joined);
                setOldJoiningDate(userData?.date_of_joined);
                setAddress(userData?.address);
                setOldAddress(userData?.address);
                setInputValue(userData?.date_of_joined || "");
            })
            .catch((err) => console.error(err));
    }, [id])

    const isLeapYear = (year: number): boolean => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };
    const handleDateValidation = (dateString: string): boolean => {
        const dateParts = dateString.split('-');
        if (dateParts.length !== 3) {
            setDobError("Date must be in dd-mm-yyyy format.");
            return false;
        }
        let day = parseInt(dateParts[0], 10);
        let month = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);
        if (dateParts[0].length > 2 || dateParts[1].length > 2 || dateParts[2].length > 4) {
            setDobError("Date, month, and year must not exceed two digits for day/month and four digits for year.");
            return false;
        }
        if (month < 1 || month > 12) {
            setDobError("Month must be between 01 and 12.");
        }
        if (day < 1 || day > 31) {
            day = 31;
            setDobError("Day must be between 01 and 31.");
        }
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > daysInMonth) {
            day = daysInMonth;
            setDobError(`Day must be between 01 and ${daysInMonth} for month ${month}`);
        }
        if (dateParts[2].length !== 4) {
            setDobError("Year must be a 4-digit number.");
            return false;
        }
        if (month === 2 && !isLeapYear(year) && day > 28) {
            day = 28;
            setDobError("February can only have 28 days in a non-leap year.");
        }
        setDobError(null);
        return true;
    };
    const handleDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.replace(/[^0-9-]/g, '');
        let parts = value.split('-');
        if (parts[0] && parts[0].length > 2) {
            parts[0] = parts[0].slice(0, 2);
        }
        if (parts[1] && parts[1].length > 2) {
            parts[1] = parts[1].slice(0, 2);
        }
        if (parts[2] && parts[2].length > 4) {
            parts[2] = parts[2].slice(0, 4);
        }
        if (parts[0] && parseInt(parts[0], 10) > 31) {
            parts[0] = '31';
        }
        if (parts[1] && parseInt(parts[1], 10) > 12) {
            parts[1] = '12';
        }
        if (parts.length > 3) {
            parts = parts.slice(0, 3);
        }
        const newValue = parts.join('-');
        setjoiningDate(newValue);
        setDobError(null);
    };
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const handleusers = () => {
        if (!phone || !phone.trim() || !address || !address.trim()) {
            toast.error("All fields are required!", {
                position: "top-right",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
    
        if (phone.length < 8) {
            toast.error("Phone number must be at least 8 digits", {
                position: "top-right",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
    
        const dateParts = joiningDate.split('-');
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    
        const update = {
            // employee_name: name,
            phone_number: phone,
            // date_of_joined: formattedDate,
            address: address,
        };
    
        api.put(`/pos/add/${id}`, update, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(() => {
            toast.success("User updated successfully", {
                position: "top-right",
                autoClose: 2000,
                theme: "colored",
            });
            setIsOpen(false);
            setTimeout(() => window.location.reload(), 2000);
        })
        .catch((err) => {
            console.error(err);
            toast.error("Error updating user", {
                position: "top-right",
                autoClose: 2000,
                theme: "colored",
            });
        });
    };
    
    
    useEffect(() => {
        if ( phone !== oldphone ||  address !== oldAddress) {
            if (phone.length === 8) {
                setvisible(true);
            } else { setvisible(false); }
        } else {
            setvisible(false);
        }
    }, [name, oldname, oldphone, phone, joiningDate, oldJoiningDate, address, oldAddress]);
    const updateButton = {
        Blue: "blue",
        name: "update",
    };
    const CancelButton = {
        Cancel: "red",
        name: "Cancel",
    };
    const errorupdateButtonerr = {
        error: "blue",
        name: "update",
    };
    const confirm = () => {
        if (visible === true) {
            return (
                <div >
                    <Buttons Buttondata={updateButton} />
                </div>
            )
        } else {
            return (
                <div id="confirm_btn">
                    <Buttons Buttondata={errorupdateButtonerr} />
                </div>
            )
        }
    }

    return isOpen? (
        <div>
            
            <div className="modals">
                <div className="overlays"></div>
                <div className="modal-confirm">
                    <div className="edit_userpop">
                        <div>
                            <div className='Edit_header'>
                                <h2>Edit user</h2>
                            </div>
                            <div>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="cancel-icon-Edituser"
                                >
                                    <Tooltip title="Close" placement="bottom" arrow>
                                        <IconButton style={{ color:'black'}}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                </button>
                            </div>
                            <div className='edit_textfield'>
                                <TextField
                                    id="outlined-basic"
                                    disabled
                                    label={
                                        <span>
                                            Name <span style={{ color: '#f04141', fontSize: "15px" }}></span>
                                        </span>
                                    }
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    sx={{ marginLeft: "30px" }}
                                    id="outlined-basic"
                                    label={
                                        <span>
                                            Phone number <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                                        </span>
                                    }
                                    variant="outlined"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <TextField
                                    error={!!dobError}
                                    sx={{ marginTop: "20px", width: "222px" }}
                                    label="Date of joining"
                                    value={joiningDate}
                                    variant="outlined"
                                    helperText={dobError}
                                    onChange={handleDateInputChange}
                                    disabled
                                />
                                <TextField
                                    sx={{ marginLeft: "30px", marginTop: "20px" }}
                                    id="outlined-basic"
                                    label="Address"
                                    variant="outlined"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                {/* <div className='Role_option'>
                                    <h3 className='Role_option_edit'>
                                        <div className='Mahi'>
                                            <p>
                                                Role
                                            </p>
                                            <p style={{ color: "red" }}>*</p>
                                        </div>
                                        <FormControlLabel
                                            required
                                            control={<Checkbox />}
                                            label="Admin"
                                        />
                                        <FormControlLabel
                                            required
                                            control={<Checkbox />}
                                            label="Employee"
                                        />
                                    </h3>
                                </div> */}
                            </div>
                            <div className='btn_group'>
                                <div onClick={() => setIsOpen(false)}>
                                    <Buttons Buttondata={CancelButton} />
                                </div>
                                {/* <div onClick={handleusers}>
                                    <Buttons Buttondata={GButton} />
                                     {confirm()}
                                </div> */}
                                <div onClick={handleusers}>
                                    {visible ? (
                                        <Buttons Buttondata={updateButton} />
                                    ) : (
                                        <Buttons Buttondata={errorupdateButtonerr} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    ):null;
}

export default EditUser