import React, { useEffect, useRef, useState } from "react";
import "./Profile.css";
import logosamble from '../../../assets/Images/imetanic-logo.png';
import ChangeCircleTwoToneIcon from "@mui/icons-material/ChangeCircleTwoTone";
import { IconButton, Tooltip } from "@mui/material";
import { Flip, ToastContainer, toast } from "react-toastify";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import EmailIcon from '@mui/icons-material/Email';
import profileimgdata from '../../../assets/Images/user-profile.png';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditUserProfile from "./EditUserProfile";
import api from "../../axiosConfig";
import { downloadFile } from "../../orders/laundry/OrderExport";
import Header from "../../components/laundry/Header";
import Menu from "../../components/laundry/Menu";
import Buttons from "../../common-component/Buttons";
import EditBusiness from "./EditBusiness";
import LoadingScreen from "../../common-component/LoadingScreen";
import Resheader from "../../components/Restaurant/Resheader";
import CarHeaderBox from "../../components/car-wash/CarHeaderBox";
import Resmenu from "../../components/Restaurant/Resmenu";
import CarMenuBox from "../../components/car-wash/CarMenuBox";

interface ProfileData {
  _id: { $oid: string };
  address_line_1: string;
  date_of_birth: string;
  name: string;
  phone_number: string;
  user_name: string;
  filename: string;
  organization_name: string;
  email_id: string;
}
interface themes {
  background_color: string;
  font_color: string;
  font_family: string;
}

function Profile() {
  const [logo, setLogo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [id, setid] = useState("");
  const [edit, setedit] = useState<boolean>(false);
  const [useredit, setuseredit] = useState<boolean>(false);
  const [business, setBusinessName] = useState("");
  const [data, setData] = useState<ProfileData | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [busness, setbusness] = useState<boolean>(true);
  const [profile, setprofile] = useState<boolean>(false);
  const [theme, settheme] = useState<boolean>(false);
  const [security, setsecurity] = useState<boolean>(false);
  const [imports, setimport] = useState<boolean>(false);
  const [exports, setexport] = useState<boolean>(false);
  const [headname, setheadname] = useState<string>("");
  const [username, setusername] = useState<any>();
  const [profileimg, setprofileimg] = useState<string>("");
  const [backcolor, setbackcolor] = useState<string>("");
  const [fontcolour, setfontcolour] = useState<string>("");
  const [fontFamilys, setfontfamilys] = useState<string>("");
  const [, setstroedcolor] = useState<themes[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [bussinessTpe, setBussinessType] = useState<string>("");
  useEffect(() => {
    setLoading(true);
    document.title = "Profile - iMetanic";
    api.get(`/pos/profile`).then((response) => {
      setData(response.data.message);
      setid(response.data.message._id.$oid);
      const businessl = response.data.message.organization_name;
      setBusinessName(response.data.message.organization_name);
      const storedLogo = sessionStorage.getItem(`Logo${businessl}`);
      setusername(sessionStorage.getItem(`Profilename`));
      if (storedLogo) {
        setLogo(storedLogo);
      } else {
        setLogo(logosamble);
      }
      setheadname("Business Profile")
      setLoading(false);
    });
    const persion = sessionStorage.getItem("profilename")
    const image = sessionStorage.getItem(`Profilelogo${persion}`);
    console.log(image);
    if(image) {
      api.get(`/pos/profile_images/${image}.png`, { responseType: "arraybuffer" })
        .then((response) => {
          const blob = URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
            setprofileimg(blob)
            console.log("img", blob);
        })
    }else{
      setprofileimg(profileimgdata)
    }
    api.get(`/pos/theme`).then((res) => {
      const themedata: themes[] = res.data.result;
      setstroedcolor(themedata)
      const backcolor = () => {
        return String(themedata.map(color => color.background_color))
      }
      const fcolor = () => {
        return String(themedata.map(color => color.font_color))
      }
      const ffamily = () => {
        return String(themedata.map(color => color.font_family))
      }
      setbackcolor(backcolor());
      setfontcolour(fcolor());
      setfontfamilys(ffamily());
      document.documentElement.style.setProperty('--backgroundcolor', backcolor());
      document.documentElement.style.setProperty('--font-color', fcolor());
      document.documentElement.style.setProperty('--font-family', ffamily());
    })
  }, [username]);
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
  
        const response = await api.put(
          `/pos/image/${id}?filename=${business}.png`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.status === 200 && response.data.message === "File updated successfully") {
          const imgName = `${business}.png`;
          const logoResponse = await api.get(`/pos/logo/${imgName}`, { responseType: "arraybuffer" });
          const blob = new Blob([logoResponse.data], { type: "image/png" });
          const imageUrl = URL.createObjectURL(blob);
  
          sessionStorage.setItem(`Logo${business}`, imageUrl);
          setLogo(imageUrl);
          console.log("Logo updated successfully");
        }
      } catch (error) {
        console.error("Image upload error:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const onclickhr = (name: any) => {
    console.log(name);
    setheadname(name)
    if (name === "Business Profile") {
      setbusness(true);
      settheme(false);
      setprofile(false);
      setsecurity(false);
      setimport(false);
      setexport(false);
    } if (name === "My Profile") {
      setbusness(false);
      settheme(false);
      setprofile(true);
      setsecurity(false);
      setimport(false);
      setexport(false);
    } if (name === "Theme") {
      setbusness(false);
      settheme(true);
      setprofile(false);
      setsecurity(false);
      setimport(false);
      setexport(false);
    } if (name === "Security") {
      setbusness(false);
      settheme(false);
      setprofile(false);
      setsecurity(true);
      setimport(false);
      setexport(false);
    } if (name === "Data Import") {
      setbusness(false);
      settheme(false);
      setprofile(false);
      setsecurity(false);
      setimport(true);
      setexport(false);
    } if (name === "Data Export") {
      setbusness(false);
      settheme(false);
      setprofile(false);
      setsecurity(false);
      setimport(false);
      setexport(true);
    } if (name === "Add Mumbers") {
      setbusness(false);
      settheme(false);
      setprofile(false);
      setsecurity(false);
      setimport(false);
      setexport(false);
    }
  }
  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; 
    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
  
        const response = await api.post(
          `/pos/profile_img?filename=${username}.png`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (
          response.status === 201 &&
          response.data.message === "File uploaded successfully"
        ) {
          toast.success("Image uploaded successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
          setprofileimg(URL.createObjectURL(file));
        }
        setLoading(false);
      } catch (error) {
        console.error("Image upload error:", error);
        setLoading(false);
        toast.error("Failed to upload image. Please try again.", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };
  

  const color = (background: string, font: string) => {
    setbackcolor(background)
    setfontcolour(font)
    document.documentElement.style.setProperty('--backgroundcolor', background);
    document.documentElement.style.setProperty('--font-color', font);
  }
  const fontFamily = (fontmodel: string) => {
    console.log(fontmodel);
    setfontfamilys(fontmodel)
    document.documentElement.style.setProperty('--font-family', fontmodel);
  }
  const fontStyle = (fontmodel: string, name: string) => {
    return (<li style={{ fontFamily: fontmodel, display: 'flex', alignItems: 'center', width: '70px', fontSize: '15px' }} onClick={() => fontFamily(fontmodel)}>
      {fontmodel === fontFamilys ? <div className="select_font_type"><CircleIcon sx={{ fontSize: '18px', fontWeight: '2px' }} /></div> :
        <div className="select_font_type"><CircleOutlinedIcon sx={{ fontSize: '18px', fontWeight: '2px' }} /></div>}Font Style</li>)
  }
  const Updatethemebutton = {
    Blue: "b",
    name: "Update Theme",
  }
  const Resetthemebuttons = {
    Blue: "b",
    name: "Reset Theme",
  }
  const button_import = {
    Blue: "blue",
    name: "Import",
  };
  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      api.post(`/app/v1/import_export`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).catch(error => {
        console.error('Error importing file:', error);
      });
    }
  };
  const handleDownload = () => {
    api.get(`/app/v1/import_export`, { responseType: "blob" }).then((response) => {
      const pageName = "orders";
      downloadFile({
        data: response.data,
        fileName: `${pageName}.xlsx`,
      });
    }).catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  const update_theme = () => {
    const data = {
      background_color: backcolor,
      font_color: fontcolour,
      font_family: fontFamilys,
    };
  
    api.put(`/pos/theme`, data).then((response) => {
      console.log(response.data.message);
  
      // Remove previous settings and save the new theme
      sessionStorage.removeItem(`background_color${business}`);
      sessionStorage.removeItem(`font_color${business}`);
      sessionStorage.removeItem(`font_family${business}`);
      sessionStorage.setItem(`background_color${business}`, backcolor);
      sessionStorage.setItem(`font_color${business}`, fontcolour);
      sessionStorage.setItem(`font_family${business}`, fontFamilys);
  
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
  
      sleep(2000)
      .then(() => {
        window.location.reload(); 
      });
    });
  };
  

  const Reset_theme = () => {
    console.log('Resetting theme');
    const data = {
      background_color: '#6495ED',
      font_color: 'white',
      font_family: 'Franklin Gothic Medium',
    };
  
    api.put('/pos/theme', data)
      .then((response) => {
        console.log(response.data.message);
  
        // Save the default theme settings to sessionStorage
        sessionStorage.setItem(`background_color${business}`, '#6495ED');
        sessionStorage.setItem(`font_color${business}`, 'white');
        sessionStorage.setItem(`font_family${business}`, 'Franklin Gothic Medium');
  
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
  
        sleep(2000).then(() => {
          window.location.reload(); 
        });
      })
      .catch((error) => {
        console.error('Error updating theme:', error);
        toast.error('Failed to reset theme. Please try again later.', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
      });
  };
  
  const Edits = (
    id: string,
    Businessname: string,
    Address: string,
    Email: string,
    Businesstype: string,
  ) => {
    const data = {
      setedit: (!edit),
      setid: id,
      setBusinessName: Businessname,
      setaddress: Address,
      setemail: Email,
      setbusinesstype: Businesstype,
    }
    api.put(`/pos/profile`, data).then((response) => {
      console.log(response.data.message);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    })
  }

   useEffect(()=>{
    const businessType = sessionStorage.getItem("business_type");
  setBussinessType(String(businessType));
   },[])

   const OldOrders = () => {};
  return (
    <div style={{ width: "100vw", height: '100vh', overflow: 'hidden' }}>
     { bussinessTpe === "Laundry" && <Header Header="Profile" />}
     {bussinessTpe === "Restaurant" &&  <Resheader Resheader={"Profile"} OldOrders={OldOrders} /> }
     {bussinessTpe === "Carwash" && <CarHeaderBox Carheader="Profile" />}
      <div style={{ display: 'flex' }}>
       {bussinessTpe === "Laundry" && <Menu />}
       {bussinessTpe === "Restaurant" && <Resmenu Resmenu={""} />}
       {bussinessTpe === "Carwash" &&<CarMenuBox /> }
        <div className="ovetall_profile">
          <ToastContainer />
          <div className="Profile_menu">
            <h1>{headname} :</h1>
          </div>
          <div className="page_setting">
            <div className="profile_menu">
              <ul className="inside_content">
                {profile ? <li className="onclick_Style" defaultChecked>My Profile</li> : <li onClick={() => onclickhr("My Profile")} >My Profile</li>}
                {busness ? <li className="onclick_Style">Business Profile</li> : <li onClick={() => onclickhr("Business Profile")} >Business Profile</li>}
                {theme ? <li className="onclick_Style">Theme</li> : <li onClick={() => onclickhr("Theme")}>Theme</li>}
                {security ? <li className="onclick_Style">Security</li> : <li onClick={() => onclickhr("Security")}>Security</li>}
                {imports ? <li className="onclick_Style">Data Import</li> : <li onClick={() => onclickhr("Data Import")}>Data Import</li>}
                {exports ? <li className="onclick_Style">Data Export</li> : <li onClick={() => onclickhr("Data Export")}>Data Export</li>}
              </ul>
            </div>
            <div className="profile_display">
              {busness && <div className="profileall">
                <div className="Profile_logo_page">
                  <div className="logo_display">
                    <img src={logo} alt="Logo" className="logodata" />
                    <div className="logoupload">
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                      />
                      <Tooltip title="Change Logo" placement="bottom" arrow>
                        <IconButton onClick={handleClick}>
                          <ChangeCircleTwoToneIcon
                            className="logo_change_button_icon"
                            id="Inventory_icon"
                            style={{ fontSize: "30px" }}
                          />
                       </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="profile_heading">
                    <ul>
                      <BusinessIcon style={{ position: "relative", top: "40px" }} />
                      <li><h3>Business name</h3>
                        <p>: {data?.organization_name}</p></li>
                      <HomeIcon style={{ position: "relative", top: "20px" }} />
                      <li style={{ position: "relative", bottom: "20px" }}><h3>Home branch address</h3>
                        <p>: {data?.address_line_1}</p></li>
                      {/* <li><p>Opening Date</p>
                      <p>:</p></li> */}
                      <AddBusinessIcon style={{ position: "relative", top: "10px" }} />
                      <li style={{ position: "relative", bottom: "30px" }}><h3>Business type</h3>
                        <p>:{bussinessTpe} </p></li>
                      <EmailIcon style={{ position: "relative", top: "10px" }} />
                      <li style={{ position: "relative", bottom: "30px" }}><h3>Email ID</h3>
                        <p>:{data?.email_id}</p></li>
                      {/* <EditIcon style={{ position: "relative", left: "257px", color: "green" }} />
                      <button className="Edit_profile">Edit Profile</button> */}
                      <div>
                        <button className="Profile_edit_page" onClick={() => setedit(!edit)}>Edit profile</button>
                      </div>
                    </ul>
                  </div>
                </div>
                {loading && <LoadingScreen />}
              </div>}
              {profile && <div className="My_Profile">
                <div className="logo_display">
                  <img src={profileimg} alt="" className="logodata" />
                  <div className="logoupload">
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={fileInputRef}
                    />
                    <Tooltip title="Change Logo" placement="bottom" arrow>
                      <IconButton onClick={handleClick}>
                        <ChangeCircleTwoToneIcon
                          className="logo_change_button_icon"
                          id="Inventory_icon"
                          style={{ fontSize: "30px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                {/* <div className="user_profile">
                  <ul>
                    <li><p><PersonIcon style={{position:"relative",top:"5px"}}/> Name</p><p style={{position:"relative",right:"50%"}}>: {data?.name}</p>
                      </li>
                    <li> <p><CalendarMonthIcon style={{position:"relative",top:"5px"}}/>Date of Birth </p><p style={{position:"relative",right:"50%"}}>: {data?.date_of_birth}</p>
                      </li>
                    <li>
                      <p><EmailIcon style={{position:"relative",top:"5px"}}/> Email Id</p><p style={{position:"relative",right:"44%"}}>: {data?.email_id} </p>
                    </li>
                    <li>
                      <p><HomeIcon style={{position:"relative",top:"5px"}}/> Address</p><p style={{position:"relative",right:"26%"}}>: {data?.address_line_1}</p>
                    </li>
                    <div>
                      <button className="Profile_edit_page_user" onClick={()=>setuseredit(!useredit)}>Edit Profile</button>
                    </div>
                  </ul>
                </div> */}
                <div className="user_profile">
                  <ul>
                    <li className="profile_item">
                      <PersonIcon className="icon" />
                      <span className="label">Name</span>
                      <span className="value">: {data?.name}</span>
                    </li>
                    <li className="profile_item">
                      <CalendarMonthIcon className="icon" />
                      <span className="label">Date of birth</span>
                      <span className="value">: {data?.date_of_birth}</span>
                    </li>
                    <li className="profile_item">
                      <EmailIcon className="icon" />
                      <span className="label">Email ID</span>
                      <span className="value">: {data?.email_id}</span>
                    </li>
                    <li className="profile_item">
                      <HomeIcon className="icon" />
                      <span className="label">Address</span>
                      <span className="value">: {data?.address_line_1}</span>
                    </li>
                    {/* <div className="button_container">
        <button className="Profile_edit_page_user" onClick={() => setuseredit(!useredit)}>Edit Profile</button>
      </div> */}
                    <div>
                      <button className="Profile_edit_page_user" onClick={() => setuseredit(!useredit)}>Edit Profile</button>
                    </div>
                  </ul>
                </div>
              </div>}
              {theme &&
                <div className="profileall">
                  <div className="Theme_selection">
                    <body>
                      <h3>Colours:</h3>
                      <ul>
                        <li style={{ backgroundColor: '#F48FB1' }} onClick={() => color("#F48FB1", "white")}>{backcolor === "#F48FB1" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>Illusion</h5></> : <p>Illusion</p>}</li>
                        <li style={{ backgroundColor: '#FF4500' }} onClick={() => color("#FF4500", "white")}>{backcolor === "#FF4500" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>OrangeRed</h5></> : <p>OrangeRed</p>}</li>
                        <li style={{ backgroundColor: '#FFD700' }} onClick={() => color("#FFD700", "black")}>{backcolor === "#FFD700" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>Gold</h5></> : <p>Gold</p>}</li>
                        <li style={{ backgroundColor: '#BDB76B' }} onClick={() => color("#BDB76B", "white")}>{backcolor === "#BDB76B" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>DarkKhaki</h5></> : <p>DarkKhaki</p>}</li>
                        <li style={{ backgroundColor: '#8A2BE2' }} onClick={() => color("#8A2BE2", "white")}>{backcolor === "#8A2BE2" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>BlueViolet</h5></> : <p>BlueViolet</p>}</li>
                        <li style={{ backgroundColor: '#1E90FF' }} onClick={() => color("#1E90FF", "white")}>{backcolor === "#1E90FF" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>DodgerBlue</h5></> : <p>DodgerBlue</p>}</li>
                        <li style={{ backgroundColor: '#6495ED' }} onClick={() => color("#6495ED", "white")}>{backcolor === "#6495ED" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>CornflowerBlue</h5></> : <p>CornflowerBlue</p>}</li>
                        <li style={{ backgroundColor: '#FFE4E1' }} onClick={() => color("#FFE4E1", "black")}>{backcolor === "#FFE4E1" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>MistyRose</h5></> : <p>MistyRose</p>}</li>
                        <li style={{ backgroundColor: '#A9A9A9' }} onClick={() => color("#A9A9A9", "white")}>{backcolor === "#A9A9A9" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>DarkGray</h5> </> : <p>DarkGray</p>}</li>
                        <li style={{ backgroundColor: '#FDF5E6' }} onClick={() => color("#FDF5E6", "black")}>{backcolor === "#FDF5E6" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>OldLace</h5></> : <p>OldLace</p>}</li>
                        <li style={{ backgroundColor: '#9ACD32' }} onClick={() => color("#9ACD32", "white")}>{backcolor === "#9ACD32" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>YellowGreen</h5> </> : <p>YellowGreen</p>}</li>
                        <li style={{ backgroundColor: '#3CB371' }} onClick={() => color("#3CB371", "white")}>{backcolor === "#3CB371" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>SeaGreen</h5></> : <p>SeaGreen</p>}</li>
                        <li style={{ backgroundColor: '#C71585' }} onClick={() => color("#C71585", "white")}>{backcolor === "#C71585" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>SeaGreen</h5></> : <p>VioletRed</p>}</li>
                        <li style={{ backgroundColor: '#FF7F50' }} onClick={() => color("#FF7F50", "white")}>{backcolor === "#FF7F50" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /><h5>Coral</h5></> : <p>Coral</p>}</li>
                        <li style={{ backgroundColor: 'red' }} onClick={() => color("red", "white")}>{backcolor === "red" ? <> <DoneIcon sx={{ color: fontcolour, fontSize: '40px' }} /> <h5>Red</h5></> : <p>Red</p>}</li>
                      </ul>
                    </body>
                    <body>
                      <h3>Font style:</h3>
                      <ul style={{ padding: '0px' }}>
                        {fontStyle('cursive', "Helvetica")}
                        {fontStyle('Bolder Sans', 'sans-serif')}
                        {fontStyle('Franklin Gothic Medium', "Tahoma")}
                        {fontStyle('Haettenschweiler', "Lucida")}
                        {fontStyle('impact', "Impact")}
                        {fontStyle('Gill Sans MT, sans-serif', "Georgia")}
                        {fontStyle('Segoe UI', "Andale Mono")}
                        {fontStyle('Lucida Sans Unicode', "Helvetica")}
                        {fontStyle('Times New Roman', 'serif')}
                        {fontStyle('Trebuchet', 'sans-serif')}
                      </ul>
                    </body>
                  </div>
                  <div style={{ display: 'flex'}}>
                    <div className="theme_trail">
                      <header><p>Point of Sale</p>
                        <h4><AccountCircleIcon sx={{ fontSize: "20px" }} />
                          {data?.name}<ArrowDropDownIcon sx={{ fontSize: "20px", marginTop: '2px', marginLeft: '10px' }} />
                        </h4></header>
                      <div className="body_page_trail">
                        <div className="menu_trail">
                          <ul>
                            <img src={logo} alt="" />
                            <li><DashboardRoundedIcon sx={{ fontSize: '20px', color: backcolor }} /></li>
                            <li><InventoryIcon sx={{ fontSize: '20px', color: backcolor }} /></li>
                            <li><ShoppingCartIcon sx={{ fontSize: '20px', color: backcolor }} /></li>
                            <li><SettingsSuggestIcon sx={{ fontSize: '20px', color: backcolor }} /></li>
                          </ul>
                        </div>
                        <div className="bodytheme">
                          <ul>
                            <li>Font theme:</li>
                            <li>This is a font style trail</li>
                            <li><button className="cancelbtn">CANCEL</button><button className="confirmbtn">CONFIRM</button></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="Update_Theme">
                      <div style={{ margin: '10px' }} onClick={() => update_theme()}><Buttons Buttondata={Updatethemebutton} /></div>
                      <div style={{ margin: '10px' }} onClick={() => Reset_theme()}><Buttons Buttondata={Resetthemebuttons} /></div>
                    </div>
                  </div>
                </div>
              }
              {security && <div>security</div>}
              {imports &&
                <div>
                  <div className="data_button">
                    <h2 className="import_heading">Data Import</h2>
                    <div className="Im_Expo" onClick={() => inputRef.current?.click()}>
                      <button className="import_button_profile">Data file import</button>
                      <input
                        ref={inputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleImport}
                      />
                      <FileDownloadIcon style={{ height: "3rem", width: "6%", position: "relative", top: "17px", left: "7px", border: "1px solid black", borderRadius: "20px", boxShadow: "10px 10px lightblue", cursor: "pointer" }} />
                    </div>
                  </div>
                </div>}
              {exports &&
                <div>
                  <div className="data_button">
                    <h2 className="import_heading">Data  Export</h2>
                    <button className="import_button_profile" onClick={handleDownload}>Data file export</button>
                    <FileDownloadIcon onClick={handleDownload} style={{ height: "3rem", width: "6%", position: "relative", top: "17px", left: "7px", border: "1px solid black", borderRadius: "20px", boxShadow: "10px 10px lightblue", cursor: "pointer" }} />
                  </div>
                </div>}
            </div>
            {edit && (<EditBusiness />
            )}
            {useredit && (
              <div className="modals">
                <div className="overlays"></div>
                <div className="modal-contents">
                  <div className="edit-popupscreen">
                    <EditUserProfile />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
