import React, { useEffect, useRef, useState } from "react";
import "./Resorder.css";
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../axiosConfig";
import Resheader from "../../components/Restaurant/Resheader";
import Resmenu from "../../components/Restaurant/Resmenu";

interface OrderList {
  CustomerEmail: string;
  CustomerName: string;
  CustomerPhoneNo: string;
  Customerid: string;
  GrandTotal: string;
  OrderType: string;
  OrderStatus: string;
  Orderdate: string;
  Orderid: string;
  Ordertime: string;
  Paymentmode: string;
  Products: Array<{
    Amount: string;
    notes: string;
    ArabicName: string;
    ProductName: string;
    category_id: string;
    createdBy: string;
    createdOn: {
      $date: string;
    };
    image_url: string;
    quantity: number;
    _id: {
      $oid: string;
    };
  }>;
  ServiceCharge: number;
  Subtotal: string;
  table_number: string;
  totalAmount: number;
  totalQuantity: number;
  _id: {
    $oid: string;
  };
}

const Resorders = () => {
  const [orderList, setOrderList] = useState<OrderList[]>([]);
  const [showPrintContent, setShowPrintContent] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<OrderList | null>(null);
  const [filter, setFilter] = useState<string>("Today");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedDate2, setSelectedDate2] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const handlesumbit = () => {
    if (!selectedOrder) {
      console.error("No order selected to submit.");
      return;
    }
    try {
      const id = selectedOrder.Orderid;
      navigate(`/v1/Respayment/${id}`);
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const res = await api.get(`/app/v1/pos/order`);
      setOrderList(res.data.result);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const printerDetails = ({ selectedOrder }: { selectedOrder: any }) => (
    <div className="print-content">
      <div className="Print_popup_restarunt_order">
        <h3 style={{ fontFamily: "cursive" }}>Invoice</h3>
        <div
          style={{
            padding: "3px",
            display: "grid",
            gridAutoFlow: "dense",
            gridTemplateRows: "auto auto auto",
            direction: "rtl",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <span>{selectedOrder?.Orderdate}</span>
            <p>:Date</p>
          </div>
          <div style={{ display: "flex" }}>
            <span>{selectedOrder?.guess || "N/A"}</span>
            <p>:Dine In</p>
          </div>
        </div>
        <hr style={{ width: "99%", border: "1px dotted black" }} />
        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>Name:</p>
            <span>{selectedOrder?.CustomerName || "N/A"}</span>
          </div>
          <div style={{ display: "flex" }}>
            <p>Type:</p>
            <span>Cash</span>
          </div>
        </div>
        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>items:</p>
          </div>
          <div style={{ display: "flex" }}>
            <p>Ph.No:</p>
            <span>{selectedOrder?.CustomerPhoneNo || "N/A"}</span>
          </div>
        </div>
        <hr style={{ width: "99%" }} />
        <div style={{ width: "100%", marginTop: "5px" }}>
          <div
            style={{
              backgroundColor: "#e9eaea",
              display: "flex",
              justifyContent: "space-around",
            }}
          />
          <hr style={{ width: "99%" }} />
          <div style={{ display: "grid", width: "100%" }}>
            <div
              style={{
                backgroundColor: "#f4f4f4",
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#e9eaea",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                <h4 style={{ margin: 0, flex: 1, textAlign: "left" }}>
                  Item(s)
                </h4>
                <h4 style={{ margin: 0, flex: 1, textAlign: "center" }}>Qty</h4>
                <h4 style={{ margin: 0, flex: 1, textAlign: "right" }}>
                  Price
                </h4>
              </div>
              <div style={{ marginTop: "10px" }}>
                {selectedOrder?.Products?.map(
                  (
                    product: { ProductName: any; quantity: any; Amount: any },
                    index: React.Key | null | undefined
                  ) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        fontSize: "14px",
                        backgroundColor: "#ffffff",
                        borderRadius: "5px",
                      }}
                    >
                      <div style={{ flex: 1, textAlign: "left" }}>
                        {product.ProductName || "N/A"}
                      </div>
                      <div style={{ flex: 1, textAlign: "center" }}>
                        {product.quantity || 1}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        {product.Amount || 0}
                      </div>
                    </div>
                  )
                )}
              </div>
              <hr style={{ width: "100%", marginTop: "10px" }} />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>Sub Total</div>
                <div>
                  <b>{selectedOrder?.Subtotal || 0}</b>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <b style={{ width: "60%" }}>Total Price</b>
            <b>{selectedOrder?.Subtotal || 0}</b>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginRight: "50px",
            }}
          >
            <b style={{ width: "60%", marginRight: "55px" }}>Service Charges</b>
            <b>{selectedOrder?.ServiceCharge || 0}</b>
          </div>
          <hr style={{ width: "99%" }} />
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "5px",
              }}
            >
              <h2 style={{ width: "60%" }}>Grand Total</h2>
              <h2>{selectedOrder?.GrandTotal || 0}</h2>
            </div>
          </div>
          <hr style={{ width: "99%" }} />
        </div>
        <div className="footer">
          <i>Thank you !!! & Visit Again !!!</i>
          <b>Powered by iMetanic</b>
        </div>
      </div>
    </div>
  );

  const printpreview = () => {
    setShowPrintContent(true);
    setTimeout(() => {
      window.print();
      setShowPrintContent(false);
    }, 500);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = orderList.filter((order) => {
    const matchesSearch =
      order.CustomerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.Orderid.toLowerCase().includes(searchQuery.toLowerCase());

    let matchesDate = true;
    if (filter === "CustomizeDate" && selectedDate && selectedDate2) {
      matchesDate =
        new Date(order.Orderdate) >= new Date(selectedDate) &&
        new Date(order.Orderdate) <= new Date(selectedDate2);
    } else if (filter === "Today") {
      const today = new Date().toISOString().split("T")[0];
      matchesDate = order.Orderdate === today;
    } else if (filter === "Yesterday") {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      matchesDate = order.Orderdate === yesterday.toISOString().split("T")[0];
    }

    return matchesSearch && matchesDate;
  });

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      api.post(`/app/v1/import_export`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).catch((error: any) => {
        console.error('Error importing file:', error);
      });
    }
  };

  const handleDownload = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    const query = orderList.map(data => `order_id=${encodeURIComponent(data.Orderid)}`).join('&');
    setLoading(true);

    if (selectedOption === "CSV") {
      try {
        const response = await api.get(`/app/v1/res_import_export?${query}`);
        if (response.status === 201) {
          toast.success("CSV email sent successfully");
          window.location.reload();
        } else {
          toast.error("Failed to send CSV email");
        }
      } catch (error) {
        console.error('Error sending CSV via email:', error);
        toast.error("Error sending CSV via email. Please try again.");
      } finally {
        setLoading(false);
      }
    } else if (selectedOption === "PDF") {
      try {
        const response = await api.get(`/pos/res_order_export_pdf?${query}`);
        if (response.status === 201) {
          toast.success("PDF email sent successfully");
          window.location.reload();
        } else {
          toast.error("Failed to send PDF email");
        }
      } catch (error) {
        console.error('Error sending PDF:', error);
        toast.error("Error sending PDF via email. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const oldOrders = () => { };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      <Resheader Resheader={"Orders"} OldOrders={oldOrders} />
      <div style={{ display: "flex" }}>
        <Resmenu Resmenu={""} />
        <div className="order-head-over">
          <div className="over-order">
            <div className="first-order">
              <div className="order-dropdown">
                <div className="res-head-drop">
                  <label className="running-label">Running orders:</label>
                </div>
                {loading ? (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <CircularProgress />
                  </div>
                ) : error ? (
                  <div style={{ textAlign: "center", color: "red" }}>
                    {error}
                  </div>
                ) : (
                  <>
                    <div className="filter-continer">
                      <select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="date-filter-select"
                      >
                        <option value="" disabled>
                          Select a date range
                        </option>
                        <option value="Today">Today</option>
                        <option value="Yesterday">Yesterday</option>
                        <option value="Last Week">Last Week</option>
                        <option value="Last Month">Last Month</option>
                        <option value="CustomizeDate">Customize Date</option>
                      </select>

                      {filter === "CustomizeDate" && (
                        <div>
                          <label htmlFor="from" style={{ marginRight: "8px" }}>
                            From:
                          </label>
                          <input
                            id="from"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            style={{ marginRight: "8px" }}
                            className="Customizedate-filter-select"
                          />
                          <label htmlFor="to" style={{ marginRight: "8px" }}>
                            To:
                          </label>
                          <input
                            id="to"
                            type="date"
                            value={selectedDate2}
                            className="Customizedate-filter-select"
                            onChange={(e) => setSelectedDate2(e.target.value)}
                          />
                        </div>
                      )}
                      <div className="import-button-wrapper" onClick={() => inputRef.current?.click()}>
                        <Button
                          className="import-button"
                          variant="contained"
                          color="primary"
                        >
                          Import
                        </Button>
                        <input
                          ref={inputRef}
                          type="file"
                          className="hidden-input"
                          onChange={handleImport}
                        />
                      </div>

                      <div className="export-container">
                        <label className="export-label">Choose export file:</label>
                        <select className="export-select" onChange={handleDownload}>
                          <option value="">Select</option>
                          <option value="CSV">CSV</option>
                          <option value="PDF">PDF</option>
                        </select>
                      </div>

                      <TextField
                        className="Res-order-search"
                        label="Search orders"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div>
                      <Paper
                        style={{
                          margin: "20px",
                          height: "550px",
                          overflow: "auto",
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  S.No
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Table Number
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Customer Name
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Phone Number
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Order Type
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Status
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Time
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ color: "white", fontSize: "large" }}
                                >
                                  Total Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredData.length > 0 ? (
                                filteredData
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((order, index) => (
                                    <TableRow
                                      key={order._id.$oid}
                                      onClick={() => setSelectedOrder(order)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <TableCell align="center">
                                        {page * rowsPerPage + index + 1}
                                      </TableCell>
                                      <TableCell align="center">
                                        {order.table_number}
                                      </TableCell>
                                      <TableCell align="center">
                                        {order.CustomerName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {order.CustomerPhoneNo}
                                      </TableCell>
                                      <TableCell align="center">
                                        {order.OrderType}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          color:
                                            order.OrderStatus?.toLowerCase() ===
                                            "completed"
                                              ? "green"
                                              : order.OrderStatus?.toLowerCase() ===
                                                "pending"
                                              ? "#f5da42"
                                              : order.OrderStatus?.toLowerCase() ===
                                                "cancel"
                                              ? "red"
                                              : "inherit",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {order.OrderStatus || "Unknown"}
                                      </TableCell>
                                      <TableCell align="center">
                                        {order.Ordertime}
                                      </TableCell>
                                      <TableCell align="center">
                                        {order.GrandTotal}
                                      </TableCell>
                                    </TableRow>
                                  ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    No orders available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableContainer>
                      </Paper>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="order-details-container">
              <h2 className="order-header">Order Details</h2>
              {selectedOrder ? (
                <div className="order-content">
                  <div className="order-info-section">
                    <div className="info-row">
                      <strong>Customer Name:</strong>
                      <span>{selectedOrder.CustomerName}</span>
                    </div>
                    <div className="info-row">
                      <strong>Products:</strong>
                      <div className="product-table">
                        <div className="product-header">
                          <span className="product-column">Product Name</span>
                          <span className="product-column">Quantity</span>
                          <span className="product-column">Total</span>
                        </div>
                        {selectedOrder.Products.map((product, index) => (
                          <div key={index} className="product-row">
                            <span className="product-column">
                              {product.ProductName}
                            </span>
                            <span className="product-column">
                              {product.quantity}
                            </span>
                            <span className="product-column">
                              {product.Amount}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="total-container">
                    <div className="total-label">
                      <strong>Total:</strong>
                    </div>
                    <div className="total-value">
                      {selectedOrder.GrandTotal}
                    </div>
                  </div>

                  <div className="action-buttons">
                    <button
                      className="print-button-order"
                      onClick={printpreview}
                    >
                      Print Receipt
                    </button>
                    {selectedOrder?.OrderStatus?.toLowerCase() !==
                      "completed" && (
                      <button className="pay-button" onClick={handlesumbit}>
                        Pay Now
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <p className="no-order-message">
                  Click on an order to see details.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {showPrintContent && selectedOrder && (
        <div className="print-only">
          {printerDetails({ selectedOrder })}
        </div>
      )}
    </div>
  );
};

export default Resorders;